import React from 'react';
import toFixedFloat from '../helpers/toFixedFloat';

const BundlePrice = ({
  className,
  currency,
  orignalPrice,
  price,
  quantity = 1,
  hideStrikePrice = false,
  otherAttributes = {}
}) => {
  if (orignalPrice && price != orignalPrice) {
    return (
      <>
        <span {...otherAttributes} className={`price discount ${className || ''}`}>{`${currency}${toFixedFloat(
          price * quantity
        )}`}</span>

        {!hideStrikePrice ? (
          <small className="strike-text hide-for-maison">
            {`${currency}${toFixedFloat(orignalPrice * quantity)}`}
            &nbsp;
          </small>
        ) : null}
      </>
    );
  }
  return (
    <div className={`price ${className || ''}`} {...otherAttributes}>
      {currency}
      {toFixedFloat(price * quantity)}
    </div>
  );
};

export default BundlePrice;

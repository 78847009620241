import React from 'react';
import Image from 'next/image';

import appConfig from '../../../appConfig';
import * as WEBSITES from '../../../constants/website';

import wunderfooter from '../../../public/static/images/wunderfooter.png';
import iconemail from '../../../public/static/images/Icon_Email.png';


function KoanFooter() {
  if (process.env.NEXT_PUBLIC_APP === WEBSITES.WUNDER) {
    return (
      <div className="wunder-footer text-center">
        <div className="testimonial-slider">
        </div>
        <div className="blue-section">
          <div className="d-f-inner">
            <div className="text-uppercase bottom-help-text accumin"> <h2> Fabulous Flavors <br /> Deserve Phenomenal feels.</h2></div>
          </div>
          <Image className="img-fluid p-lg-5" src={wunderfooter} alt="" />
        </div>
        <div className="d-bottom-strip two-rem-mt p-5 mt-lg-5">
          <h3>Have questions?</h3>
          {/* <a className="phone" href="sms: 4153297995">(415) 329-7995</a> */}
          <div className="d-b-inner accumin">
            <div className="d-flex align-items-center mx-4 mt-0">
              <Image className="pt-3 img-fluid" width="40" height="40" src={iconemail} alt="" />
              <div className="pl-3 text-left"> Email us at <br /><a target="_blank" href="mailto:hello@findwunder.com" rel="noreferrer"> hello@findwunder.com </a></div>
            </div>
            {/* <img className="img-fluid p-lg-5" src="/static/images/wunderfooter.png" alt="" /> */}
          </div>
          <div className="d-bottom-strip two-rem-mt p-5 mt-lg-5">
            <h3>Have questions? </h3>
            <h3>fastest support is the live chat in the bottom corner.</h3>
            {/* <a className="phone" href="sms: 4153297995">
            (415) 329-7995
          </a> */}
            <div className="d-b-inner accumin">
              <div className="d-flex align-items-center mx-4">
                <img className="img-fluid" width="40" height="40" src="/static/images/Icon_Email.png" alt="" />
                <div className="pl-3 text-left">
                  {' '}
                  Chat unavailable, email our partners at
                  <br />
                  <a target="_blank" href="mailto:support@grassdoor.com" rel="noreferrer">
                    {' '}
                    support@grassdoor.com
                    {' '}
                  </a>
                </div>
              </div>
              {/* <div className="d-flex align-items-center mx-4">
            <img className="img-fluid" width="40" src="/static/images/Icon_Instagram.png" alt="" />
            <div className="pl-3 text-left"> Follow WUNDER at <br /><a target="_blank" href="https://instagram.com/findwunder" rel="noreferrer"> @findwunder </a></div>
          </div> */}
            </div>
            <div className="copyrights row justify-content-center">
              <span>
                @{new Date().getFullYear()}
                {' '}
                {appConfig.APP_NAME}, Please Enjoy Responsibly.
                {' '}
              </span>
              <span> License Number: CDPH-10003710</span>
              <span>
                {' '}
                <a
                  href={appConfig.PRIVACY ? appConfig.TERMS : '/termsandconditions'}
                  target="_blank"
                  className="footer-links"
                  rel="noreferrer"
                  test-id="#terms_of_use"
                >
                  {' '}
                  Terms of Use
                </a>
                •
                {' '}
                <a
                  href={appConfig.PRIVACY ? appConfig.PRIVACY : '/privacypolicy'}
                  target="_blank"
                  className="footer-links"
                  rel="noreferrer"
                  test-id="#privacy_policy"
                >
                  {' '}
                  Privacy Policy
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

export default KoanFooter;

import React from 'react';
import AddressHistoryItem from './AddressHistoryItem';

import { useAddressHistory } from '../hooks/User';

const AddressHistory = ({ selectedAddress, onAddressChange }) => {
  const { data: addressHistory } = useAddressHistory();

  if (addressHistory && addressHistory.length) {
    return (
      <React.Fragment>
        {/* <small className=" bold one-rem-mt half-rem-mb d-block address-selector-title">History</small> */}
        {addressHistory.map((item, i) => (
          <AddressHistoryItem
            key={`address_${item.address_id}`}
            id={`historyLocation_${i + 1}`}
            data={item}
            onAddressChange={onAddressChange}
            isSelected={selectedAddress === item.address_id}
          />
        ))}
      </React.Fragment>
    );
  }
  return null;
};

export default AddressHistory;

import { createSlice } from "@reduxjs/toolkit";
import storageService from "../../services/storageService";
export function createInitialStore() {
  const searchQuery = '';
  const searchProductCount = {
    asapCount: 0,
    scheduleCount: 0
  }
  const searchResutState = '';
  const recentSearches = storageService.getRecentSearch();
  const selectedPriceSort = '';
  return {
    searchQuery,
    searchProductCount,
    searchResutState,
    recentSearches,
    selectedPriceSort
  };
}
const searchSlice = createSlice({
  name: "search",
  initialState: createInitialStore(),
  reducers: {
    setSearchState: (state, action) => {
      state.searchQuery = action.payload
    },
    setSearchProductCount: (state, action) => {
      state.searchProductCount = action.payload

    },
    setResultState: (state, action) => {
      state.searchResutState = action.payload

    },
    setRecentSearch: (state, action) => {
      state.recentSearches = action.payload
    },
    setSelectedPriceSort: (state, action) => {
      state.selectedPriceSort = action.payload
    }
  }
})

export const { setSearchState, setSearchProductCount, setResultState, setRecentSearch, setSelectedPriceSort } = searchSlice.actions

export default searchSlice.reducer
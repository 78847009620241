import React from 'react';
import MetricTag from './myOrders/MetricTag';

const XyProductOffers = ({ data, isAvailedCount, showOnlyApplied, confirmation, delivered }) => {
  const { products, description } = data;

  return (
    <>
      {showOnlyApplied ? null : (
        <div className="offer-product-title">
          {confirmation ? null : <i className="icon-offer-tag mr-2 default-l-primary popup-title-icon mb-0" />}
          <span className="c-text align-self-center offer-title">{description}</span>
        </div>
      )}
      <div className="d-flex">
        {/* <span className="offer-tag">
        <span className="icon-offer-tag"></span>
        Offers
      </span> */}

        <div className="products-holder">
          {products?.map((product, index) => {
            return (
              <div className="single-product" key={`xy-product${product.name || product.product_name}${index}`}>
                <img className="" src={product.product_image} />
                <div className="product-content">
                  <div className="product-name">
                    {product.name || product.product_name ? product.name || product.product_name : ''}

                    <span className="grams">
                      {product.product_unit === '1/8 oz' || product.product_unit === '1/2 oz'
                        ? `${product.product_unit}`
                        : `${product.product_weight} ${product.product_unit}`}
                    </span>
                  </div>
                  <div className="sub-content">
                    <small className="medium-grey">{product.category_name}</small>
                    <small className="quantity medium-grey">
                      Quantity:{' '}
                      {confirmation ? product.product_quantity : (showOnlyApplied && isAvailedCount
                        ? (product.get_quantity || product.product_quantity) * isAvailedCount
                        : isAvailedCount ? (product.get_quantity || product.product_quantity) * isAvailedCount : (product.get_quantity || product.product_quantity))}
                    </small>
                  </div>
                  {delivered && product.metric_tags && product.metric_tags.length > 0 ? (
                    <div className="metric-tag-container">
                      <span>METRC :&nbsp;</span>
                      {product.metric_tags.map((item, index) => (
                        <MetricTag key={index} tag={item} />
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })}
          <div className="products-holder">
            <div className="tax-tag mt-3">* Additional taxes may be applicable</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default XyProductOffers;

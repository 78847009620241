import React from 'react';
import PropTypes from 'prop-types';

const InlineLoader = ({ text = '', positionRelative, className }) => (
  <div className={`inline-loader ${positionRelative ? 'relative' : ''} ${className || ''}`}><span>{text}</span></div>
);

InlineLoader.propTypes = {
  text: PropTypes.string
};

InlineLoader.defaultProps = {
  text: ''
};

export default InlineLoader;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useRouter } from 'next/router';
import { connectRefinementList } from 'react-instantsearch-dom';
import find from 'lodash/find';
import { useZipCodeStatus, useDeliveryDetails } from '../hooks/app';
import getDefaultTab from '../helpers/getDefaultTab';
import appContext from '../Context/appContext';
import { ASAP } from '../constants/deliveryType';
import { GANJAGODDESS } from '../constants/website';
import { useDispatch } from 'react-redux';
import { setSearchSelectedTab } from '../redux/slices/userSlice';

const isGG = process.env.NEXT_PUBLIC_APP == GANJAGODDESS;

/* eslint-disable */
const RefinementListRadio = ({ items, refine, defaultSearchTab, forCompare }) => {
  const {
    data: { zoneId }
  } = useZipCodeStatus();

  const {
    data: {
      lastSlotCutOff,
      asapEndTime,
      asapStartTime,
      asapEnabled
    } = {},
  } = useDeliveryDetails();
  const dispatchRedux = useDispatch()

  const asap = find(items, { label: String(zoneId) }) || {};
  const schedule = find(items, { label: 'schedule' }) || {};

  const asapIsRefined = asap.isRefined;
  const scheduleIsRefined = schedule.isRefined;

  // const { dispatch } = useContext(appContext);

  const [selectedTab, setSelectedTab] = useState(false);
  const router = useRouter();

  const {
    query: { tab },
  } = router;

  useEffect(() => {
    let currentTab = forCompare ? 'schedule' : defaultSearchTab ? defaultSearchTab : tab || getDefaultTab({
      lastSlotCutOff,
      asapEndTime,
      asapStartTime,
      asapEnabled
    });
    if (isGG) {
      currentTab = 'schedule'
    }
    const firstRefine = async () => {
      if (refine) {
        if (currentTab === ASAP) {
          refine([zoneId]);
        } else {
          refine([currentTab]);
        }

        setSelectedTab(currentTab);
        // dispatch({ type: 'setSearchSelectedTab', payload: { searchSelectedTab: currentTab } });
        dispatchRedux(setSearchSelectedTab({ searchSelectedTab: currentTab }))
      }
    };
    firstRefine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!asapIsRefined && !scheduleIsRefined && selectedTab) {
      refine([selectedTab]);
    }
  }, [asapIsRefined, scheduleIsRefined]);

  return (
    <>
      {!isGG && (<ul className={` f-inner ${!asap.label && !schedule.label && 'no-border'} `}>
        {/* {items.map(item => ( */}
        {asap.label && (
          <li key={`${asap.label}-${asap.isRefined}`} className="custom-underline-radio">
            <input
              type="radio"
              name="NumericMenu"
              checked={!!asap.isRefined}
              id={`${asap.label}-radio`}
              onClick={event => {
                event.preventDefault();
                refine([asap.label]);
                setSelectedTab(asap.label);
                // dispatch({ type: 'setSearchSelectedTab', payload: { searchSelectedTab: ASAP } });
                dispatchRedux(setSearchSelectedTab({ searchSelectedTab: ASAP }))
              }}
            />
            <label className="" htmlFor={`${asap.label}-radio`}>
              <i className="icon-stopwatch" /> {`ASAP (${asap.count || 0})`}
            </label>
          </li>
        )}
        {schedule.label && (
          <li key={`${schedule.label}-${schedule.isRefined}`} className="custom-underline-radio">
            <input
              type="radio"
              name="NumericMenu"
              checked={!!schedule.isRefined}
              id={`${schedule.label}-radio`}
              onClick={event => {
                event.preventDefault();
                refine([schedule.label]);
                setSelectedTab(schedule.label);
                // dispatch({ type: 'setSearchSelectedTab', payload: { searchSelectedTab: schedule.label } });
                dispatchRedux(setSearchSelectedTab({ searchSelectedTab: schedule.label }))
              }}
            />
            <label className="" htmlFor={`${schedule.label}-radio`}>
              <i className="icon-calendar" /> {`Scheduled (${schedule.count || 0})`}
            </label>
          </li>
        )}
        {/* ))} */}
      </ul>)}
    </>
  );
};
/* eslint-enable */

/* eslint-disable */
const RefinementListComp = ({ items, refine }) => {
  useEffect(() => {
    const firstRefine = async () => {
      if (refine) {
        refine(['0']);
      }
    };
    firstRefine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ul className=""></ul>;
};
/* eslint-enable */

/* eslint-disable */
const RefinementListLocations = ({ items, refine }) => {
  const {
    data: { locationId }
  } = useZipCodeStatus();
  useEffect(() => {
    const firstRefine = async () => {
      if (refine) {
        refine([locationId]);
      }
    };
    firstRefine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ul className=""></ul>;
};
/* eslint-enable */

/* eslint-disable */
const RefinementListEnabled = ({ items, refine }) => {
  useEffect(() => {
    const firstRefine = async () => {
      if (refine) {
        refine(['1']);
      }
    };
    firstRefine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ul className=""></ul>;
};
/* eslint-enable */

/* eslint-disable */
const RefinementListListed = ({ items, refine }) => {
  useEffect(() => {
    const firstRefine = async () => {
      if (refine) {
        refine(['0']);
      }
    };
    firstRefine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ul className=""></ul>;
};
/* eslint-enable */

/* eslint-disable */
const RefinementListPreDeal = ({ items, refine }) => {
  useEffect(() => {
    const firstRefine = async () => {
      if (refine) {
        refine(['0']);
      }
    };
    firstRefine();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ul className=""></ul>;
};

const CustomRefinementListRadio = connectRefinementList(RefinementListRadio);
const CustomRefinementListDisabled = connectRefinementList(RefinementListComp);
const CustomRefinementListLocations = connectRefinementList(RefinementListLocations);
const CustomRefinementListEnabled = connectRefinementList(RefinementListEnabled);
const CustomRefinementListListed = connectRefinementList(RefinementListListed);
const CustomRefinementListPreDeal = connectRefinementList(RefinementListPreDeal);

const FilterBox = ({ defaultSearchTab, forCompare = false }) => {
  const {
    data: { locationId }
  } = useZipCodeStatus();
  const defaultSearchTabs = isGG ? 'schedule' : defaultSearchTab;
  return (
    <div className="filter-box-parent">
      <CustomRefinementListRadio
        attribute={`location_specific_details.${locationId}.delivery`}
        defaultSearchTab={defaultSearchTabs}
        forCompare={forCompare}
        limit={30}
      />
      <CustomRefinementListDisabled attribute={`location_specific_details.${locationId}.disabled`} />
      <CustomRefinementListLocations attribute="locations" />
      <CustomRefinementListEnabled attribute="enabled_categories" />
      <CustomRefinementListListed attribute="is_unlisted" />
      <CustomRefinementListPreDeal attribute={`domain_location_specific_details.${locationId}.is_pre_deal_available`} />
      <div />
    </div>
  );
};

export default FilterBox;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import ActiveLink from '../ActiveLink';
import SearchBar from '../SearchBar';
import CustomRadio from '../CustomRadioType1';
import AddressHistory from '../AddressHistory';
import { SCHEDULE_TYPE_ASAP, SCHEDULE_TYPE_FUTURE } from '../../constants/scheduleType';
import deliveryAddressPopup from '../../public/static/lotties/DeliveryAddressPopup.json';
import deliveryAddressPopupGG from '../../public/static/lotties/gg.json';
import deliveryAddressPopupBento from '../../public/static/lotties/bento.json';
import * as WEBSITES from '../../constants/website';

import timeToDecimal from '../../helpers/timeToDecimal';
import getFirstAvailableTimeSlot from '../../helpers/getFirstAvailableTimeSlot';
import getDefaultDeliveryDate from '../../helpers/getDefaultDeliveryDate';
import { getCurrentTime } from '../../helpers/timeHelpers';

import notificationTypes from '../../constants/modalNotificationConst';

import appContext from '../../Context/appContext';

import appConfig from '../../appConfig';

import { useDeliveryDetails } from '../../hooks/app';

import useAPI from '../../NetworkCall/API';
import { DEFAULT_DATETIME, DEFAULT_ZONE_ID } from '../../constants/default';
import { setRollbarWarning } from '../../helpers/rollBar';
import { BENTO, GANJAGODDESS } from '../../constants/website';
import { popSpecifictNotification, pushNotification } from '../../redux/slices/modalSlice';
import { useDispatch } from 'react-redux';
import { saveLocation } from '../../redux/slices/userSlice';

const DeliveryAddressDetails = ({ dismiss, user }) => {
  const { isLoggedIn, deliveryDetails: initialDeliverDetails } = user;

  const [deliveryDetails, setDeliveryDetails] = useState(initialDeliverDetails);

  const [currentLocation, setCurrentLocation] = useState(true);

  const dispatchRedux = useDispatch()

  const {
    data: { deliveryDays, hasGeofenceInventory, asapEnabled, locationId },
    isLoading
  } = useDeliveryDetails();

  const geoFenceRef = useRef(hasGeofenceInventory);

  const locationIdRef = useRef(locationId);
  useEffect(() => {
    geoFenceRef.current = hasGeofenceInventory;
  }, [hasGeofenceInventory]);

  useEffect(() => {
    if (!isLoading) {
      locationIdRef.current = locationId;
    }
  }, [locationId, isLoading]);

  const { checkZipCodeStatus, getPlaceId, addAddress } = useAPI();
  const [applying, setApplying] = useState(false);

  const [showSearchBox, setShowSearchBox] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setShowSearchBox(true);
    }, 0);
  }, []);

  useEffect(() => {
    try {
      /**
       * Re-check if selected schedule time is available
       */
      if (deliveryDetails.deliveryTypeSelected === SCHEDULE_TYPE_FUTURE && deliveryDetails.deliveryDate === 0) {
        const firstAvailableTime = getFirstAvailableTimeSlot(0, appConfig.isGrassdoor, deliveryDays);
        if (timeToDecimal(deliveryDetails.deliveryTime) < timeToDecimal(firstAvailableTime)) {
          setDeliveryDetails({ ...deliveryDetails, deliveryTime: firstAvailableTime, deliveryDays });
        }
      }
    } catch (error) {
      setRollbarWarning('DeliveryAddressDetails.jsx_use_effect_deliveryDetails', error);
      console.error('DeliveryAddressDetails.jsx_use_effect_deliveryDetails', error);
    }
  }, [deliveryDetails, deliveryDays]);

  // TODO: window.analytics.track('address_selected', data);

  function savePlaceToState(placeObj) {
    setDeliveryDetails({
      ...deliveryDetails,
      address: placeObj.address,
      address_id: placeObj.address_id,
      city: placeObj.city,
      country: placeObj.country,
      latitude: placeObj.latitude,
      longitude: placeObj.longitude,
      postcode: placeObj.postcode,
      state: placeObj.state,
      placeId: placeObj.placeId,
      fromMap: placeObj.fromMap,
      streetNumber: placeObj.streetNumber,
      streetName: placeObj.streetName
    });
  }

  async function placeChanged(placeObj) {
    // if place id not present generate a new one

    if (placeObj) {
      if (placeObj.placeId) {
        savePlaceToState({ ...placeObj, fromMap: false });
      } else if (placeObj.place_id) {
        // if address from history
        savePlaceToState({ ...placeObj, placeId: placeObj.place_id, fromMap: false });
      } else {
        const data = await getPlaceId({ lat: placeObj.latitude, lng: placeObj.longitude });
        const newPlaceObj = { ...placeObj, placeId: data.placeId, fromMap: false };
        savePlaceToState(newPlaceObj);
      }
    }
    setCurrentLocation(false);
  }

  function placeInputChange(placeObj) {
    if (placeObj) {
      savePlaceToState({ ...placeObj, address_id: 0 });
    }
  }

  async function handleApply() {
    setApplying(true);
    // dispatch({
    //   type: 'saveLocation',
    //   payload: { ...deliveryDetails, fromMap: false, deliveryDateTime: DEFAULT_DATETIME, modalClosed: true }
    // });
    dispatchRedux(saveLocation({ ...deliveryDetails, fromMap: false, deliveryDateTime: DEFAULT_DATETIME, modalClosed: true }))
    // dispatch({ type: 'saveLocation', payload: deliveryDetails });
    const data = await checkZipCodeStatus(deliveryDetails);

    if (!data.validZipcode && !data.error) {
      dispatchRedux(pushNotification({ type: notificationTypes.subscribeEmail }))
    } else if (data.error) {
      dismiss();
    } else {
      const newDeliveryDetails = {
        ...deliveryDetails,
        deliveryDate: 0,
        deliveryTypeSelected:
          (deliveryDetails.deliveryTypeSelected == SCHEDULE_TYPE_ASAP && asapEnabled) || geoFenceRef.current
            ? SCHEDULE_TYPE_ASAP
            : SCHEDULE_TYPE_FUTURE,
        deliveryTime:
          deliveryDetails.deliveryTypeSelected == SCHEDULE_TYPE_ASAP || geoFenceRef.current
            ? getCurrentTime()
            : getFirstAvailableTimeSlot(getDefaultDeliveryDate(), appConfig.isGrassdoor, deliveryDays),
        deliveryDateTime: DEFAULT_DATETIME,
        locationId: locationIdRef.current,
        zoneId: data?.zoneId || DEFAULT_ZONE_ID
      };
      dispatchRedux(popSpecifictNotification({ type: notificationTypes.deliverAddressPopup }));
      toast.success(`Success! You are in ${appConfig.APP_NAME}’s coverage area.`);
      await addAddress({ ...deliveryDetails }, true);
      // dispatch({ type: 'saveLocation', payload: { ...newDeliveryDetails, fromMap: false } });
      dispatchRedux(saveLocation({ ...newDeliveryDetails, fromMap: false }))
    }
    setApplying(false);
    dismiss();
  }

  const dismissModel = () => {
    const newDeliveryDetails = {
      ...initialDeliverDetails,
      fromMap: false
    };
    // dispatch({ type: 'saveLocation', payload: { ...newDeliveryDetails, modalClosed: true, fromMap: false } });
    dispatchRedux(saveLocation({ ...newDeliveryDetails, modalClosed: true, fromMap: false }))
    dismiss();
  };

  const handleChange = () => {
    setCurrentLocation(true);
  };
  // mobile-bottom-sticky-desktop-center-popup
  return (
    // <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
    <div className="modal d-block delivery-details-popup animated-popup-wrapper no-top-img-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog  " role="document">
        <div className="modal-content">
          <div className="modal-header ">
            <button data-testid="#close-address-popup" type="button" className="btn" onClick={dismissModel}>
              <i className="icon-close" />
            </button>
          </div>
          <div className="modal-body">
            <div className="logo-container">
              {process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR && (
                <img src="/static/images/location-grassdoor.svg" alt="grassdoor location" />
              )}
              {process.env.NEXT_PUBLIC_APP === WEBSITES.BENTO && (
                <img src="/static/images/location-bento.svg" alt="Bento location" />
              )}
              {process.env.NEXT_PUBLIC_APP === WEBSITES.GANJAGODDESS && (
                <img src="/static/images/location-ganjag.svg" alt="GG location" />
              )}
            </div>
            <h6 className="modal-title bold schedule-title mb-10">Set Delivery Address</h6>
            <div className="input-parent relative">
              <span className="l-icon icon-pin" />
              {showSearchBox && <SearchBar onPlacesChanged={placeInputChange} deliveryDetails={deliveryDetails} />}
              <div data-testid="#pin-location" className="half-rem-mt map-open">
                <ActiveLink
                  href="/locationselect"
                  className="btn btn-link d-inline-block one-em-font text-left"
                  onClick={dismiss}
                >
                  <>
                    <span className="m-icon icon-map-center" />
                    &nbsp; Pin your location &nbsp;
                  </>
                </ActiveLink>
              </div>
            </div>
            <div className="address-selector two-rem-mb one-rem-mt">
              <small className=" bold half-rem-mb d-block address-selector-title">
                {isLoggedIn ? 'Saved' : 'Current'} Location
              </small>
              <div>
                <CustomRadio
                  name="address1"
                  heading="CURRENT LOCATION"
                  subtitle={deliveryDetails.address}
                  icon="icon-location"
                  id="currentLocation"
                  value="currentLocation"
                  isChecked={!!currentLocation}
                  handleChange={handleChange}
                />
              </div>
              <AddressHistory
                selectedAddress={deliveryDetails.fromMap || currentLocation ? 0 : deliveryDetails.address_id}
                onAddressChange={placeChanged}
              />
            </div>
            {/* <button
                disabled={!deliveryDetails.postcode}
                type="button"
                className="btn btn-primary min-width-btn "
                onClick={handleApply}
              >
                APPLY
              </button> */}
            <div className="" />
          </div>
          <div className="modal-footer justify-content-center a-btn-container">
            <button
              data-testid="#select-addess-button"
              disabled={!deliveryDetails.postcode || applying}
              type="button"
              className="btn btn-primary min-width-btn "
              onClick={handleApply}
            >
              {applying ? 'Delivering...' : 'Deliver Here'}
            </button>
          </div>
        </div>
      </div>
    </div>
    // </CSSTransition>
  );
};

export default React.memo(DeliveryAddressDetails);

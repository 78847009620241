// import * as modal from './modal';
// import * as cart from './cart';
// import * as user from './user';
import * as shop from './shop';
import * as chat from './chat';
// import * as rating from './rating';
// import * as compare from './compare';
// import * as search from './search';
import storageService from '../services/storageService';
import cookie from 'js-cookie';
import * as COOKIE_VARIABLES from '../constants/cookieVariables';
import isEmpty from 'lodash/isEmpty';

export const ACTION_TYPES = {
  TOGGLE_SIDEBAR: 'toggleSidebar',
  TOGGLE_COUPON_SIDEBAR: 'toggleCouponSidebar',
  REQUEST_OTP: 'requestOTP',
  LOGIN: 'login',
  PUSH_NOTIFICATION: 'pushNotification',
  POP_NOTIFICATION: 'popNotification'
};

export const initialState = {
  sideBar: { isSideBarOpen: false, shouldRenderSideBar: false, isRightSideBarOpen: false },
  couponSidebar: { isSideBarOpen: false },
  loginDrawer: {
    isOpen: false
  },
  // user: user.createInitialStore(),
  shop: shop.createInitialStore(),
  // modal: modal.createInitialStore(),
  chat: chat.createInitialStore(),
  // cart: cart.createInitialStore(),
  // rating: rating.createInitialStore(),
  // compare: compare.createInitialStore(),
  // search: search.createInitialStore(),
  positionID: null,
  lastAddedProduct: null,
  initialHistoryLength: null,
  optimizeLoaded: false,
  brazeLoaded: false,
  checkOutUpdateDetails: {
    customerDetails: false,
    deliveryDetails: false,
    paymentDetails: false,
    deliveryType: false
  },
  userID: null,
  location: null,
  onLandingPage: true,
  miniTabs: false,
  // wishlist: {
  //   saveForLaterItems: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getSaveForLaterItems() : [],
  //   totalSaveForLaterItemsCount: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getSaveForLaterItems()?.length : 0,
  //   favouriteItems: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getFavourites() : [],
  //   totalFavouriteItemsCount: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getFavourites()?.length : 0,
  //   isSaveForLaterItemsLoading: false,
  //   isFavouriteItemsLoading: false,
  //   filteredItemsCount: null,
  //   isFavouriteItemAddedOrRemoved: false,
  //   isSavedForLaterItemAddedOrRemoved: false,
  // },
  searchTabs: false,
  newAddressForProfile: {},
  productRecommendationsAsap: {},
  productRecommendationsSchedule: {}
};

let actions = {};
actions.updateCheckOutDetails = (state, action) => {
  return { ...state, checkOutUpdateDetails: { ...action.payload } };
};

actions.setProductRecommendationsAsap = (state, action) => {
  return { ...state, productRecommendationsAsap: { ...action.payload } };
};

actions.setProductRecommendationsSchedule = (state, action) => {
  return { ...state, productRecommendationsSchedule: { ...action.payload } };
};

actions.toggleSidebar = state => {
  return { ...state, sideBar: { isSideBarOpen: !state.sideBar.isSideBarOpen, shouldRenderSideBar: true } };
};
actions.toggleCouponSidebar = (state, action) => {
  return {
    ...state,
    couponSidebar: {
      isSideBarOpen: !state.couponSidebar.isSideBarOpen,
      ...action.payload
    },
  }
}

actions.toggleLoginDrawer = state => {
  return { ...state, loginDrawer: { isOpen: !state.loginDrawer.isOpen } };
};
actions.updateUserID = (state, action) => {
  return { ...state, userID: action.payload };
};

actions.updateUserData = (state, action) => {
  return { ...state, location: action.payload };
};

actions.CCPAconfirmOTP = (state, action) => {
  return { ...state, CCPAtoken: action.payload.CCPAtoken };
};

actions.CCPAsubmit = (state, action) => {
  return {
    ...state,
    CCPAtoken: action.payload.CCPAtoken,
    emailOTPDisplay: action.payload.emailOTPDisplay,
    phoneOTPDisplay: action.payload.phoneOTPDisplay
  };
};

actions.setPositionID = (state, action) => {
  return {
    ...state,
    positionID: action.payload.position
  };
};

actions.setLastAddedProduct = (state, action) => {
  return {
    ...state,
    lastAddedProduct: action.payload.product
  };
};

actions.setHistoryLength = (state, action) => {
  return {
    ...state,
    initialHistoryLength: action.payload
  };
};

// actions.setSeoData = (state, action) => {
//   return {
//     ...state,
//     seoData: action.payload
//   };
// };

actions.setCashbackData = (state, action) => {
  return {
    ...state,
    cashBacks: action.payload
  };
};

actions.setCurrentCustomerNumber = (state, action) => {
  return {
    ...state,
    currentCustomerNumber: action.payload
  };
};

actions.setOptimize = (state, action) => {
  return {
    ...state,
    optimizeLoaded: action.payload
  };
};

actions.setbraze = (state, action) => {
  return {
    ...state,
    brazeLoaded: action.payload
  };
};

actions.setStronholdData = (state, action) => {
  return {
    ...state,
    stronholdTokenData: action.payload
  };
};

actions.setLoading = (state, action) => {
  return {
    ...state,
    loading: action.payload
  };
};

actions.setLocationData = (state, action) => {
  return {
    ...state,
    locationData: action.payload
  };
};

actions.setOnLandingPage = (state, action) => {
  return {
    ...state,
    onLandingPage: action.payload
  };
};

actions.setMiniTabs = (state, action) => {
  return {
    ...state,
    miniTabs: action.payload
  };
};
actions.setSearchTabs = (state, action) => {
  return {
    ...state,
    searchTabs: action.payload
  };
};

// payload should have list of items
// actions.setSaveForLaterItems = (state, action) => {
//   return { ...state, wishlist: { ...state.wishlist, saveForLaterItems: action.payload } };
// };


// actions.setSaveForLaterItemsCount = (state, action) => {
//   return { ...state, wishlist: { ...state.wishlist, totalSaveForLaterItemsCount: action.payload } };
// };

// actions.setFilteredItemsCount = (state, action) => {
//   return { ...state, wishlist: { ...state.wishlist, filteredItemsCount: action.payload } };
// };

// // payload should have list of items
// actions.setFavouriteItems = (state, action) => {
//   return { ...state, wishlist: { ...state.wishlist, favouriteItems: action.payload } };
// };

// actions.setFavouriteItemsCount = (state, action) => {
//   return { ...state, wishlist: { ...state.wishlist, totalFavouriteItemsCount: action.payload } };
// };

// actions.setSaveForLaterItemsLoading = (state, action) => {
//   return {
//     ...state,
//     wishlist: { ...state.wishlist, isSaveForLaterItemsLoading: action.payload }
//   };
// };

// actions.setFavouriteItemsLoading = (state, action) => {
//   return {
//     ...state,
//     wishlist: { ...state.wishlist, isFavouriteItemsLoading: action.payload }
//   };
// };

// actions.setIsFavouriteItemAddedOrRemoved = (state, action) => {
//   return {
//     ...state,
//     wishlist: { ...state.wishlist, isFavouriteItemAddedOrRemoved: action.payload }
//   };
// };

// actions.setIsSavedForLaterItemAddedOrRemoved = (state, action) => {
//   return {
//     ...state,
//     wishlist: { ...state.wishlist, isSavedForLaterItemAddedOrRemoved: action.payload }
//   };
// };


actions = {
  ...actions,
  // ...user.actions,
  // ...modal.actions,
  // ...cart.actions,
  ...shop.actions,
  // ...rating.actions,
  // ...compare.actions,
  ...chat.actions,
  // ...search.actions
};

export const reducer = (state, action) => {
  if (actions[action.type]) {
    return actions[action.type](state, action);
  }
  return state;
};
actions.updateNewAddressForProfile = (state, action) => {
  return { ...state, newAddressForProfile: { ...action.payload } };
};

import React from 'react';
import ModalNotification from './ModalNotification';

import appContext from '../../Context/appContext';
import { useSelector } from 'react-redux';

const ModalNotificationWrapper = ({ initialDeliveryAddressDetails = false, domainName }) => {
  // const { state: { modal: { notifications, notificationsOverlap } = {} } = {} } = useContext(appContext);
  const { notifications, notificationsOverlap } = useSelector(state => state.modal)
  return (
    <>
      {notificationsOverlap && notificationsOverlap.length ? (
        <ModalNotification domainName={domainName} notifications={notificationsOverlap} overlap initialDeliveryAddressDetails={initialDeliveryAddressDetails} />
      ) : null}
      <ModalNotification domainName={domainName} notifications={notifications} initialDeliveryAddressDetails={initialDeliveryAddressDetails} />
    </>
  );
};

export default ModalNotificationWrapper;

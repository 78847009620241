import React from 'react';
import CustomRadioType from './CustomRadioType';

function AddressHistoryItem({ id, data, isSelected, onAddressChange, className, forAddressHistoryCheckout }) {
  function handleSelectAddress() {
    onAddressChange(data);
  }

  return (
    <CustomRadioType
      name="address"
      heading=""
      key={`address_${data.address_id}`}
      subtitle={data.address}
      icon="icon-history"
      id={id}
      value={data.address_id}
      isChecked={isSelected}
      handleChange={handleSelectAddress}
      className={className}
      forAddressHistoryCheckout={forAddressHistoryCheckout}
    />
  );
}

export default AddressHistoryItem;

import React, { useContext } from 'react';
import Router, { withRouter, useRouter } from 'next/router';

import notificationTypes from '../constants/modalNotificationConst';
import hideModalPages from '../constants/hideModalPages';

import appContext from '../Context/appContext';
import appConfig from '../appConfig';

import getRouterPath from '../helpers/getRouterPath';

import { useZipCodeStatus } from '../hooks/app';

import InnerHeader from './DTC/Header';
import envAppConfig from '../constants/envAppConfig';
import { useDispatch, useSelector } from 'react-redux';
import { pushIfNotPresent, pushNotification } from '../redux/slices/modalSlice';
import { toggleSidebar } from '../redux/slices/userSlice';

const Header = ({
  defaultSearchTab,
  isCartpage,
  cancelOrder,
  hideCartIcon,
  centerComponent,
  hasWhiteBg,
  pageName,
  hasShadow,
  centeredHeader,
  className,
  minimalNavbar,
  backPath,
  secondaryNavbarClassName,
  onClickFilter,
  search = true,
  showFilter,
  showLogin = true,
  currentTab,
  asapProductsCount,
  scheduledProductsCount,
  setTab,
  dark,
  hideTabs,
  initialLocationAddress,
  initialDeliveryAddressDetails,
  initialAnnouncmentDetails,
  h1Title,
  showLoginLink,
  initialAsapCategories,
  initialScheduleCategories,
  showProfile,
  hideTabsInError,
  handleCancel,
  serverHost,
  redirectionLoading,
  showUserInfo,
  showDeliverySpecificMessage,
  isSearchPage,
  showHappyHours,
  hideTeslaCountDown,
  help
}) => {
  const router = useRouter();

  // const {
  //   state: {
  //     initialHistoryLength,
  //     // user: { userSelectedTab }
  //   },
  //   dispatch
  // } = useContext(appContext);
  const { userSelectedTab, initialHistoryLength } = useSelector(state => state.user)
  const dispatchRedux = useDispatch()

  const { pathname } = router;

  const hideModalNotification = hideModalPages.includes(getRouterPath(pathname));

  const {
    data: { validZipcode }
  } = useZipCodeStatus(initialLocationAddress);

  const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

  function handleSideMenuClick() {
    // dispatch({ type: 'toggleSidebar' });
    dispatchRedux(toggleSidebar())
  }

  function goBack() {
    if (window.history.length > initialHistoryLength) {
      Router.back();
    } else {
      Router.push('/');
    }
  }

  function goHome() {
    if (isCheckoutOnly) {
      Router.push('/');
    } else {
      Router.push('/');
    }
  }

  function openSearchPopup() {
    if (validZipcode) {
      dispatchRedux(pushIfNotPresent({ type: notificationTypes.searchModal, data: { currentTab } }))
    } else {
      dispatchRedux(pushNotification({ type: notificationTypes.deliverAddressPopup }))
    }
  }

  function renderComponentForNewHeader() {

    if (pageName) {
      return <div className="logo-text bold">{pageName}</div>;
    }

    let imageSrc = '';

    if (isCheckoutOnly) {
      imageSrc = envAppConfig?.[serverHost]?.HEADER_LOGO_COLOR || envAppConfig?.grassdoor?.HEADER_LOGO_COLOR;
    } else {
      imageSrc = appConfig.HEADER_LOGO_COLOR;
    }

    return (
      <div className="main-logo ">
        <img
          data-testid="#main-logo-img"
          src={imageSrc}
          className={''}
          onClick={goHome}
          alt={appConfig.APP_NAME}
        />
      </div>
    );
  }

  return (
    <InnerHeader
      cancelOrder={cancelOrder}
      handleCancel={handleCancel}
      isCartpage={isCartpage}
      hasShadow={hasShadow}
      className={className}
      hideModalNotification={hideModalNotification}
      centeredHeader={centeredHeader}
      minimalNavbar={minimalNavbar}
      backPath={backPath}
      handleSideMenuClick={handleSideMenuClick}
      renderComponentForNewHeader={renderComponentForNewHeader}
      onClickFilter={onClickFilter}
      showFilter={showFilter}
      search={search}
      openSearchPopup={openSearchPopup}
      secondaryNavbarClassName={secondaryNavbarClassName}
      hideCartIcon={hideCartIcon}
      showLogin={showLogin}
      centerComponent={centerComponent}
      pageName={pageName}
      hasWhiteBg={hasWhiteBg}
      goHome={goHome}
      goBack={goBack}
      currentTab={currentTab}
      asapProductsCount={asapProductsCount}
      scheduledProductsCount={scheduledProductsCount}
      setTab={setTab}
      dark={dark}
      hideTabs={hideTabs || false}
      hideTabsInError={hideTabsInError || false}
      defaultSearchTab={defaultSearchTab}
      serverHost={serverHost}
      redirectionLoading={redirectionLoading}
      initialLocationAddress={initialLocationAddress}
      initialDeliveryAddressDetails={initialDeliveryAddressDetails}
      initialAnnouncmentDetails={initialAnnouncmentDetails}
      h1Title={h1Title}
      showLoginLink={showLoginLink}
      initialAsapCategories={initialAsapCategories}
      initialScheduleCategories={initialScheduleCategories}
      showUserInfo={showUserInfo}
      showDeliverySpecificMessage={showDeliverySpecificMessage}
      showHappyHours={showHappyHours}
      showProfile={showProfile}
      hideTeslaCountDown={hideTeslaCountDown}
      isSearchPage={isSearchPage}
      help={help}
    />
  );
};

export default withRouter(Header);

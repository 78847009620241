import { useDispatch } from 'react-redux';
import notificationTypes from '../constants/modalNotificationConst';
import { pushNotificationOverlap } from '../redux/slices/modalSlice';

const isLossOfWalletWithPurchaseRewardHelper = props => {
  const {
    currentProductDetails = {},
    highestUnlockedTierSubtotal,
    cartSubtotal,
    dispatch,
    requestedProductQuantity,
    onRemove
  } = props;

  // const dispatchRedux = useDispatch()

  const {
    quantity: currentProductQuantity,
    price: currentProductPrice,
    product_details_id: productDetailsId
  } = currentProductDetails;

  if (productDetailsId && requestedProductQuantity < currentProductQuantity) {
    const postRemovalPrice = (currentProductQuantity - requestedProductQuantity) * currentProductPrice;
    const isLosingWalletWithPurchaseReward =
      highestUnlockedTierSubtotal && cartSubtotal - postRemovalPrice < highestUnlockedTierSubtotal;

    if (isLosingWalletWithPurchaseReward) {
      // popup to inform user that deleting product, may cause loss of rewards
      // dispatch({
      //   type: 'pushNotificationOverlap',
      // payload: {
      //   type: notificationTypes.confirmProductRemovalPopup,
      //   data: { onRemove }
      // }
      // });
      dispatch({
        type: notificationTypes.confirmProductRemovalPopup,
        data: { onRemove }
      })
      return true;
    }

    return false;
  }

  return false;
};

export default isLossOfWalletWithPurchaseRewardHelper;

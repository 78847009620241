export default [
  'privacypolicy',
  'becomedriver',
  'blog',
  'login',
  'contact',
  'aboutus',
  'learnmore',
  'submitopportunities',
  'termsandconditions',
  'deliverylocations',
  'locationselect',
  'settings'
];

import React, { Fragment } from 'react';
import { withRouter } from 'next/router';
import Link from 'next/link';
import openInNewTab from '../helpers/openInNewTab';
import useSegment from '../hooks/segment';

const ReferralBanner = props => {
  const { trackEvent } = useSegment();
  function goToReferral(event) {
    event.preventDefault();
    trackEvent('Footer Event - Referral', {
      category: 'Footer Events',
      action: 'Click',
      label: 'Refer a Friend'
    });
    // open in blank page
    openInNewTab('/referral');
    // props.router.push('/referral');
  }

  return (
    <Fragment>
      <Link prefetch={false} href="/referral">
        <a className={`referral-banner cursor-pointer py-3 ${props.className}`} onClick={goToReferral}>
          <img
            width="380"
            height="63"
            alt="Earn up to 600$ for every friend you refer! "
            src="https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/grassdoor.com/web_static_files/refer-friend-footer-img.png"
            loading="lazy"
          />
          {/* <div className="bold mb-1 h5">PASS THE GRASS</div>
        <div className="semi-bold mb-1 ref-text">Refer a friend and earn up to $100 credit</div>
        <div className="dummy-link">
          <u>Click here to get your Referral Link</u>
        </div> */}
        </a>
      </Link>
    </Fragment>
  );
};

export default withRouter(ReferralBanner);

import appConfig from '../appConfig';
import * as WEBSITES from '../constants/website';

export default function generateFilters(data) {
  const { isDealsPage = false } = data;
  const filterList = [
    {
      title: ([WEBSITES.GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP) || process.env.NEXT_PUBLIC_DEFAULT_SITES) ? 'Sort' : 'Price',
      type: 'sort_key',
      name: 'product_price',
      values: []
    }
  ];

  if (![WEBSITES.FLOWERANDEDIBLES].includes(process.env.NEXT_PUBLIC_APP) && !process.env.NEXT_PUBLIC_DEFAULT_SITES) {
    filterList[0].values.push({
      name: 'product_price',
      value: 'None',
      id: 'none',
      type: 'radio',
      checked: false
    });
    filterList[0].values.push({
      name: 'product_price',
      value: 'Low to High',
      id: 'asc',
      type: 'radio',
      checked: !!appConfig.isGrassdoor
    });
    filterList[0].values.push({
      name: 'product_price',
      value: 'High to Low',
      id: 'desc',
      type: 'radio',
      checked: false
    });
  }

  if (process.env.NEXT_PUBLIC_APP === WEBSITES.GANJAGODDESS) {
    filterList[0].values.push({
      name: 'product_price',
      value: 'Recommended',
      id: 'none',
      type: 'radio',
      checked: false
    })
  }
  if ([WEBSITES.GRASSDOOR, WEBSITES.FLOWERANDEDIBLES].includes(process.env.NEXT_PUBLIC_APP) || process.env.NEXT_PUBLIC_DEFAULT_SITES) {

    /** If the page is not deals page, these 2 options will render first */
    filterList[0].values.push({
      name: 'product_price',
      value: 'Recommended',
      id: 'none',
      type: 'radio',
      checked: true
    });
    if (!isDealsPage) {
      filterList[0].values.push({
        name: 'deals_first',
        value: 'Deals First',
        id: 'asc',
        type: 'radio',
        checked: false
      });
      if (process.env.NEXT_PUBLIC_DEFAULT_SITES || [WEBSITES.GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP)) {
        filterList[0].values.push({
          name: 'freshdrops_first',
          value: 'Newest First',
          id: 'asc',
          type: 'radio',
          checked: false
        });
      }
    }
    filterList[0].values.push({
      name: 'product_price',
      value: appConfig.isGrassdoor || process.env.NEXT_PUBLIC_DEFAULT_SITES ? 'Price - Low to High' : 'Lowest Prices',
      id: 'asc',
      type: 'radio',
      checked: isDealsPage ? true : false
    });
    filterList[0].values.push({
      name: 'product_price',
      value: appConfig.isGrassdoor || process.env.NEXT_PUBLIC_DEFAULT_SITES ? 'Price - High to Low' : 'Highest Prices',
      id: 'desc',
      type: 'radio',
      checked: false
    });
    filterList[0].values.push({
      name: 'thc',
      value: appConfig.isGrassdoor || process.env.NEXT_PUBLIC_DEFAULT_SITES ? 'THC - Low to High' : 'Lowest THC Percentage',
      id: 'asc',
      type: 'radio',
      checked: false
    });
    filterList[0].values.push({
      name: 'thc',
      value: appConfig.isGrassdoor || process.env.NEXT_PUBLIC_DEFAULT_SITES ? 'THC - High to Low' : 'Highest THC Percentage',
      id: 'desc',
      type: 'radio',
      checked: false
    });

    /** If the page is deals page, this option will render last */
    if (isDealsPage) {
      if (process.env.NEXT_PUBLIC_DEFAULT_SITES || [WEBSITES.GRASSDOOR].includes(process.env.NEXT_PUBLIC_APP)) {
        filterList[0].values.push({
          name: 'freshdrops_first',
          value: 'Newest First',
          id: 'asc',
          type: 'radio',
          checked: false
        });
      }
    }
  }

  const categories = {
    title:
      process.env.NEXT_PUBLIC_APP === WEBSITES.DOSIST
        ? 'Formulas'
        : process.env.NEXT_PUBLIC_APP === WEBSITES.KIKOKO
          ? 'Benefit'
          : process.env.NEXT_PUBLIC_APP === WEBSITES.PERFECTBLENDS
            ? 'Form Factor'
            : process.env.NEXT_PUBLIC_APP === WEBSITES.BUYPROOFNOW ? 'Potency'
              : 'Categories',
    type: 'search',
    name: 'id'
  };

  const strains = {
    title: 'Strain Types',
    type: 'search',
    name: 'strains'
  };

  const brands = {
    title:
      process.env.NEXT_PUBLIC_APP === WEBSITES.PERFECTBLENDS
        ? 'Blend'
        : process.env.NEXT_PUBLIC_APP === WEBSITES.KURVANA
          ? 'Collections'
          : process.env.NEXT_PUBLIC_APP === WEBSITES.BUYPROOFNOW
            ? 'Effects'
            : 'Brands',
    type: 'search',
    name: 'brands'
  };

  const deviceTypes = {
    title: 'Device Types',
    type: 'search',
    name: 'deviceTypes'
  };

  const priceRange =
    appConfig.isGrassdoor && data && data.priceRange
      ? {
        title: 'Price Range',
        type: 'search',
        name: 'priceRange',
        isSet: false,
        minPrice: data.priceRange.minPrice,
        maxPrice: data.priceRange.maxPrice
      }
      : null;

  const values = data.fullShopCategories
    ? data.fullShopCategories.map((category, index) => ({
      name: categories.name,
      id: `${categories.name}-${category.id}`,
      order: index,
      value: category.category_name,
      type: 'checkbox',
      checked: false,
      slug: category.category_slug
    }))
    : [];

  const valuesStrain = data.productStrainTypes
    ? data.productStrainTypes.map(strain => ({
      name: strains.name,
      id: `${strains.name}-${strain.id}`,
      value: strain.category_name,
      type: 'checkbox',
      checked: false
    }))
    : [];

  const valuesBrand = data.brands
    ? data.brands.map(brand => ({
      name: brands.name,
      id: `${brands.name}-${brand.id}`,
      value: brand.category_name,
      type: 'checkbox',
      checked: false
    }))
    : [];

  const valuesDeviceTypes = data.deviceTypes
    ? data.deviceTypes.map(deviceType => ({
      name: deviceType.device_name,
      id: `${deviceType.id}`,
      value: deviceType.device_name,
      type: 'checkbox',
      checked: false
    }))
    : [];
  if (priceRange != null) {
    const valuesPriceRange = data.priceRange
      ? [
        {
          name: 'min_price',
          id: `min_price`,
          value: data.priceRange.minPrice,
          text: null,
          type: 'slider'
        },
        {
          name: 'max_price',
          id: `max_price`,
          value: data.priceRange.maxPrice,
          type: 'slider'
        }
      ]
      : [];
    priceRange.values = valuesPriceRange;
    filterList.push(priceRange);
  }
  strains.values = valuesStrain;
  categories.values = values;
  brands.values = valuesBrand;
  deviceTypes.values = valuesDeviceTypes;
  filterList.push(strains);

  filterList.push(categories);

  if (process.env.NEXT_PUBLIC_APP !== WEBSITES.CANNABIOTIX) {
    filterList.push(brands);
  }

  filterList.push(deviceTypes);

  return filterList;
}

import React, { useState } from 'react';
import { useDeliveryDetails } from '../hooks/app';
import CountDown from './CountDown';

const HappyHours = ({ showText = true, scrollDirectionUp = null }) => {
  const {
    data: {
      happyHours: {
        end_time: happyHoursEndTime,
        code,
        is_coupon_discount_applicable,
        is_free_delivery_applicable,
        is_tax_discount_applicable
      }
    }
  } = useDeliveryDetails();

  const happyHoursActiveBenefits = {
    is_coupon_discount_applicable,
    is_free_delivery_applicable,
    is_tax_discount_applicable
  };

  const [hideTimerValues, setHideTimerValues] = useState(false);

  return !happyHoursEndTime || hideTimerValues ? null : (
    <>
      <div className={`happy-hours timer-sec ${scrollDirectionUp === false ? '' : 'sticky-timer'}`}>
        <CountDown
          countDownEndTime={happyHoursEndTime}
          setHideTimerValues={setHideTimerValues}
          happyHoursCode={code}
          happyHoursActiveBenefits={happyHoursActiveBenefits}
        />
      </div>
      {showText ? (
        <div className="ship-disc-text text-center">
          {is_free_delivery_applicable && (
            <div className=" ship-disc-text-item">
              <i className="icon-truck2" />
              <span className="fs-16 semibold">Free Delivery</span>
            </div>
          )}

          {is_tax_discount_applicable && (
            <div className=" ship-disc-text-item">
              <i className="icon-discount-2" />
              <span className="fs-16 semibold">Tax Discount</span>
            </div>
          )}
          {is_coupon_discount_applicable && (
            <div className=" ship-disc-text-item">
              <i className="icon-discount-2" />
              <span className="fs-16 semibold">Coupon Discount</span>
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default HappyHours;

import React from 'react';
import dynamic from 'next/dynamic';

import { BENTO } from '../../constants/website';

const OtherDeliveryTabs = dynamic(() => import('../DeliveryTabs'));
const BentoDeliveryTabs = dynamic(() => import('../FloatingDeliveryTabs'));

const DeliveryTabs = props => {
  switch (process.env.NEXT_PUBLIC_APP) {
    // case BENTO:
    //   return <BentoDeliveryTabs {...props} />;
    default:
      return <OtherDeliveryTabs {...props} />;
  }
};

export default DeliveryTabs;

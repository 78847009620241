import { useRouter } from 'next/router';
import { PRIMARY, TERTIARY } from '../constants/bannerTypes';
import isMobile from '../helpers/isMobile';
import useSegment from './segment';

export const useCommonFunctions = () => {
  const router = useRouter();
  const {
    query: { tab, slug }
  } = router;
  const { trackEvent } = useSegment();
  function setTab(newTab) {
    if (tab !== newTab) {
      const href = `${router.pathname.replace('[...slug]', slug)}?tab=${newTab}`;
      router.replace(href, undefined, { shallow: true });
    }
  }
  function redirectToLink(bannerItem, index, bannerType) {
    let header = null;
    let position = 'home_banner_top';
    const bannerLink = bannerItem.button_link;

    if (bannerType == PRIMARY) {
      header = {
        name: bannerItem.header,
        alt: bannerItem.header
      };
    }

    if (bannerType == TERTIARY) {
      header = {
        name: bannerItem.header,
        alt: bannerItem.header
      };
    }

    if (window.location.pathname.includes('brands')) {
      position = 'brand_banner_top';
    }
    trackEvent('Promotion Clicked', {
      promotion_id: `${index + 1}`,
      creative: isMobile() ? bannerItem.mobile_image_url : bannerItem.desktop_image_url,
      ...header,
      position,
      type: bannerType,
      action: 'Banner Click'
    });

    if (bannerLink) {
      const currentDomainHost = window.location.hostname;
      const currentHostWithoutWww = currentDomainHost.includes('www')
        ? currentDomainHost.slice(currentDomainHost.indexOf('www') + 4)
        : currentDomainHost;

      const bannerUrlObj = bannerItem?.button_link ? new URL(bannerItem.button_link) : null;
      const bannerPath = bannerUrlObj?.pathname || '';
      const bannerSearch = bannerUrlObj?.search || '';
      const bannerHost = bannerUrlObj?.hostname || '';
      const bannerHostWithoutWww = bannerHost.includes('www') ? bannerHost.slice(bannerHost.indexOf('www') + 4) : bannerHost;

      // use router.push for internal links to prevent page reload
      if (currentHostWithoutWww === bannerHostWithoutWww) {
        router.push({
          pathname: bannerPath,
          search: bannerSearch
        });
      } else {
        window.location = bannerLink;
      }
    }
  }
  return {
    redirectToLink,
    setTab,
    tab
  }
}
export const updateFilterList = (filterListTemp = [], filterData = {}) => {
  // let filterData = currentTab === ASAP ? asapFilterData : scheduleFilterData
  let newList = filterListTemp.map(data => {
    let countData = filterData[data.title] || {}
    let tempList = data.values.map(item => { return { ...item, count: countData[item.value] || 0 } })
    return {
      ...data,
      values: [...tempList]
    }
  })
  return newList
}
export function removeTags(str) {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/ig, '');
}
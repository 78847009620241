import { HYBRID, INDICA, INDICA_HYBRID, SATIVA, SATIVA_HYBRID } from '../constants/strainConsts';

const getStrainDetails = strainName => {
  switch (strainName) {
    case SATIVA:
      return {
        icon: 'icon-sun-outline',
        colour: 'sativa-color'
      };

    case INDICA:
      return {
        icon: 'icon-moon-2',
        colour: 'indica-color'
      };

    case HYBRID:
      return {
        icon: 'icon-day-night-outline',
        colour: 'hybrid-color'
      };

    case SATIVA_HYBRID:
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'sativa-dominant-color'
      };

    case INDICA_HYBRID:
      return {
        icon: 'icon-clouds-and-moon',
        colour: 'indica-dominant-color'
      };

    case 'CBD:THC':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'cbd-thc-color'
      };

    case 'CBN':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'cbn-color'
      };

    case 'THCa':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'thca-color'
      };

    case 'THC':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'thc-color'
      };

    case 'Total Cannabinoids':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'total-cannabinoids-color'
      };

    case 'THCV':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'thcv-color'
      };

    case 'CBDa':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'cbda-color'
      };
    case 'CBG':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'cbg-color'
      };
    case 'CBD':
      return {
        icon: 'icon-cloud-sun-outline',
        colour: 'cbd-color'
      };

    default:
      return {
        icon: 'icon-plus-outline',
        colour: 'other-strains-color'
      };
  }
};

export default getStrainDetails;

// CBD: THC
// CBN
// THCa
// Total Cannabinoids
// THCV
// CBDa
// CBG
// CBD

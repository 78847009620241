import { useRef, useState, useEffect } from 'react';
import { scrollDataAnalyser } from '../helpers/scroll';
import storageService from '../services/storageService';
import useSegment from './segment';
import isMobile from '../helpers/isMobile';

export default function useScroll() {
  const callPop = useRef();
  const { trackEvent } = useSegment();

  let heightArray = [];
  const handleUserOut = e => {
    const { clientY } = e;
    if (clientY < 80) {
      if (!callPop.current) {
        callPop.current = setTimeout(() => {
          document.removeEventListener('mousemove', handleUserOut);
          trackEvent('Exit Intent', { mobile: false });
          storageService.setItemSession('exitIntent', 'called');
        }, 3000);
      }
    } else if (callPop.current) {
      clearTimeout(callPop.current);
      callPop.current = null;
    }
  };

  const checkScrollHeight = () => {
    let timeout;
    heightArray = [...heightArray, window.scrollY];
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (heightArray.length > 5) {
        const scrollData = scrollDataAnalyser(heightArray);
        if (scrollData.top && scrollData.difference > 1000 && scrollData.position < 150) {
          trackEvent('Exit Intent', { mobile: true });
          storageService.setItemSession('exitIntent', 'called');
        }
      }
      heightArray = [];
      clearTimeout(timeout);
    }, 2000);
  };

  return { handleUserOut, checkScrollHeight };
}

export const useScrollTop = () => {

  const [scrollDirectionTop, setScrollDirectionTop] = useState(true);

  useEffect(() => {
    const handleNavigation = () => {
      const scrollY = window.pageYOffset;
      if (scrollY > 10) {
        setScrollDirectionTop(false);
      } else {
        setScrollDirectionTop(true);
      }
    };
    if (isMobile()) {
      window.addEventListener('scroll', handleNavigation, {
        capture: true,
        passive: true
      });
    }
    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, []);
  return { scrollDirectionTop }
}

export const useScrollDirection = (limit = 0, forDesktop = false) => {
  const [scrollDirectionUp, setScrollDirectionUp] = useState(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const handleNavigation = () => {
      const scrollY = window.pageYOffset;
      if (scrollY > limit) {
        const directionUp = !(Math.max(0, scrollY) > lastScrollY);
        if (directionUp !== scrollDirectionUp) {
          setScrollDirectionUp(directionUp);
        }
      } else {
        setScrollDirectionUp(true);
      }
      lastScrollY = scrollY || 0;
    };

    if (isMobile() || forDesktop) {
      window.addEventListener('scroll', handleNavigation, {
        capture: true,
        passive: true
      });
    }
    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, []);
  return { scrollDirectionUp };
};

import React from 'react';
import appConfig from '../appConfig';

function LicenseHolder({ className }) {
  return (
    <>
      <a href={appConfig.APP_URL} target="_blank" className={className} rel="noreferrer">
        {appConfig.APP_NAME} &nbsp;
      </a>
      (C9-0000485-LIC)
    </>
  );
}

export default LicenseHolder;

const symptomsOptions = [
  'Aids',
  'Anorexia',
  'Anxiety',
  'Arthritis',
  'Cachexia',
  'Cancer',
  'Chronic Pain',
  'Glaucoma',
  'Migraine',
  'Nausea',
  'Persistent Muscle Spasm',
  'Seizures',
  'Other Condition'
];
export default symptomsOptions;

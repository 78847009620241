import React from 'react';
import { CSSTransition } from 'react-transition-group';

export default function GiftConfirmation({ data: {title, text}, dismiss }) {
  return (
    <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
      <div className="modal d-block bcc-popup animated-popup-wrapper" tabIndex="-1" role="dialog">
        <div className="modal-dialog m-scroll gift-modal" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <img src="static/images/giftbox.svg" alt="" />
              <p className="title">{title}</p>
              <p className="subtitle">{text}</p>
            </div>
            <div className="modal-footer justify-content-center mt-2">
              <button type="button" className="btn btn-primary bold min-width-btn" onClick={dismiss} >
                Okay
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

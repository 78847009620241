import React, { useContext } from 'react';

import appContext from '../../Context/appContext';

import notificationTypes from '../../constants/modalNotificationConst';
import { increaseURLstack, popNotification, popStack, pushIfNotPresent, pushNotificationOverlap, pushOnTopIfNotPresent } from '../../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setUrlBeforeCompare, setUrlBeforeProduct } from '../../redux/slices/userSlice';

export default function DisplayMessage() {
  // const {
  //   dispatch
  // } = useContext(appContext);
  const notifications = useSelector(state => state.modal.notifications)
  const dispatchRedux = useDispatch()
  const notificationsOverlap = useSelector(state => state.modal.notificationsOverlap)


  const addToStack = (type, special = false) => {
    const url = '/compare';

    // dispatch({
    //   type,
    //   payload: { type: notificationTypes.comparePopup, data: { source: "Compare toast message" } }
    // });

    dispatchRedux(type == 'pushNotificationOverlap' ? pushNotificationOverlap({ type: notificationTypes.comparePopup, data: { source: "Compare toast message" } })
      : type == 'pushOnTopIfNotPresent' ? pushOnTopIfNotPresent({ type: notificationTypes.comparePopup, data: { source: "Compare toast message" } })
        : pushIfNotPresent({ type: notificationTypes.comparePopup, data: { source: "Compare toast message" } }))
    dispatchRedux(increaseURLstack());
    // dispatch({
    //   type: 'setUrlBeforeCompare',
    //   payload: { urlBeforeCompare: special ? window.location.origin : window.location.href }
    // });
    dispatchRedux(setUrlBeforeCompare({ urlBeforeCompare: special ? window.location.origin : window.location.href }))

    window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
  };

  const handleClick = () => {
    if (window.location.pathname === '/compare') return;

    if (notifications.length) {
      const { type } = notifications[0];

      if (type === notificationTypes.productDetails) {
        // dispatch({ type: 'setUrlBeforeProduct', payload: { urlBeforeProduct: '' } });
        dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: '' }))
        dispatchRedux(popStack())
        dispatchRedux(popNotification())

        addToStack(notificationsOverlap.length ? 'pushNotificationOverlap' : 'pushOnTopIfNotPresent', true);
      } else {
        addToStack(notificationsOverlap.length ? 'pushNotificationOverlap' : 'pushOnTopIfNotPresent');
      }
    } else {
      addToStack('pushIfNotPresent');
    }
  };

  return (
    <div className="d-flex flex-column align-items-start justify-content-center">
      <h6 style={{ margin: 0 }} className="m-0">
        Added product to compare
      </h6>

      {window.location.pathname === '/compare' || notifications[0]?.type === notificationTypes.comparePopup ? (
        ''
      ) : (
        <button type="button" onClick={handleClick} className="btn btn-link pl-0 my-0 btn-sm pb-0">
          <u>Show Product Comparison</u>
        </button>
      )}
    </div>
  );
}

import React, { useState } from 'react';

import capitalizeFirstLetter from '../../../helpers/capitaliseFirstLetter';

// import ProgressBar from '../../ProgressBar';

const TerpenesProfile = props => {
  const { terpenes = [] } = props;

  const [selectedTerpene, setSelectedTerpene] = useState(null);

  const handleTerpeneClick = (terpeneName, terpeneEffects, terpeneFlavours) => () => {
    if (terpeneEffects?.length || terpeneFlavours?.length) {
      setSelectedTerpene(prevState => {
        if (prevState === terpeneName) {
          return null;
        }

        return terpeneName;
      });
    }
  };

  return (
    <>
      <div className="terpen-title ">
        <b>Terpenes Profile</b>
      </div>

      <div>
        {terpenes.map(terpene => {
          const { color = '', effects = [], flavours = [], icon = '', name = '', value = 0 } = terpene;

          return (
            <div className="terpen-section">
              <div className="d-flex justify-content-between" onClick={handleTerpeneClick(name, effects, flavours)}>
                <div className="terpen-profile">
                  <i className={`terpen-icon ${selectedTerpene === name ? 'icon-angle-up' : 'icon-angle-down'}`} />

                  <img src={icon} alt={name} />
                </div>

                <div className="terpen-block">
                  <div className="terpen-name">{`${name} - ${value}%`}</div>

                  {/* <div style={{ width: '55%' }}>
                    <ProgressBar percentage={value} color={color} />
                  </div> */}
                </div>
              </div>

              {selectedTerpene === name ? (
                <div className="terpen-effects-flv">
                  {effects?.length ? (
                    <div className="d-flex align-items-start terpen-mt">
                      <span className="bold whitespace-nowrap">Effects - </span>

                      <div className="terpen-eff">
                        {effects.map((effect, count) => (
                          <span className="pl-1">
                            {capitalizeFirstLetter(effect)}
                            {effects.length == count + 1 ? ' ' : ', '}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : null}

                  {flavours?.length ? (
                    <div className="d-flex align-items-start mt-1">
                      <span className="bold whitespace-nowrap">Aroma - </span>

                      <div className="pl-1">
                        {flavours.map((flavour, count) => (
                          <span className=" ">
                            {capitalizeFirstLetter(flavour)}
                            {flavours.length == count + 1 ? ' ' : ', '}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TerpenesProfile;

import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { CSSTransition } from 'react-transition-group';
import appContext from '../../Context/appContext';
import getCardType from '../../helpers/getCardType';
import { useCardList } from '../../hooks/User';
import useAPI from '../../NetworkCall/API';
import CardView from '../CardView';
import AddNewPaymentCard from '../../public/static/lotties/AddNewPaymentCard.json';
import { useSelector } from 'react-redux';

const AddNewCard = ({ dismiss }) => {
  // const {
  //   state: {
  //     cart: { cartData: { is_super_or_verified: isSuperOrVerified, is_card_verified: isCardVerified } = {} }
  //   }
  // } = useContext(appContext);
  const { is_super_or_verified: isSuperOrVerified, is_card_verified: isCardVerified } = useSelector(state => state.cart?.cartData) || {}
  const { saveCardDetail } = useAPI();
  const { mutate: mutateCards } = useCardList({ revalidate: true });

  const dismissModal = () => {
    dismiss();
  };

  const handleApply = async (cardData, isDefault) => {
    const { cardNumber, cardExp, cardCVV, cardName, zipcode } = cardData;
    const monthAndYear = cardExp.split('/');
    const month = Number(monthAndYear[0]);
    const year = Number(monthAndYear[1]);
    const upperCaseCardType = getCardType(cardNumber.replace(/ /g, ''));
    const cardType = upperCaseCardType ? upperCaseCardType.toLowerCase() : null;
    const payload = {
      card_number: cardNumber.replace(/ /g, ''),
      card_month: month < 10 ? `0${month}` : `${month}`,
      card_year: year,
      card_exp: cardExp,
      card_cvv: cardCVV,
      card_name: cardName,
      card_type: cardType,
      card_save: true,
      card_zipcode: zipcode || null,
      is_default: isDefault
    };
    await saveCardDetail({ ...payload });
    mutateCards();
    toast.success('Card added');
    dismiss();
  };

  return (
    <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
      <div className="modal d-block add-address-popup animated-popup-wrapper new-payment-card" tabIndex="-1" role="dialog">
        <div className="modal-dialog  " role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h6 className="modal-title">Enter card details</h6>
              <button type="button" className="btn" onClick={dismissModal}>
                <i className="icon-close" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="logo-container">
                <Player className="lottie-address" loop autoplay src={AddNewPaymentCard} />
              </div>
              <div className="input-parent">
                <CardView
                  isSuperOrVerified={isSuperOrVerified}
                  isSaveCardCheckBox
                  title="Debit Card"
                  buttonText="Pay"
                  allowEditBillingAddress
                  isCardVerified={isCardVerified}
                  isProfileModule
                  callBackProfileModule={handleApply}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default AddNewCard;

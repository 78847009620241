module.exports = {
  categoryRedirect: {
    'pre-roll': '/categories/pre-rolls',
    wellness: '/categories/cannabis-wellness',
    flower: '/categories/cannabis-flower',
    vapes: '/categories/vapes',
    tinctures: '/categories/cannabis-tinctures',
    accessories: '/categories/cannabis-accessories',
    concentrates: '/categories/cannabis-concentrates',
    edibles: '/categories/cannabis-edibles',
    drinks: '/categories/cannabis-drinks'
  },
  brandRedirects: {
    'costal-sun': 'coastal-sun'
  },
  siteRedirect: {
    '/soft-gels-4ct': '/product/soft-gels-4ct',
    '/plum-dawg-millionaire-private-stock-eighth': '/product/plum-dawg-millionaire-private-stock-eighth',
    '/blue-dream-high-thc-cartridge-5g': '/product/blue-dream-high-thc-cartridge-5g',
    '/bliss-20116': '/product/bliss-20116',
    '/bundle/march-mayhem': '/product/bundle/march-mayhem',
    '/cookies-n-cream-75052': '/product/cookies-n-cream-75052',
    '/harmony-rose-cbd-cartridge': '/product/harmony-rose-cbd-cartridge',
    '/arouse-plus-w-lrte-by-dosist-pod': '/product/arouse-plus-w-lrte-by-dosist-pod',
    '/shirley-temple-sugar-sauce': '/product/shirley-temple-sugar-sauce',
    '/gas-plant': '/product/gas-plant',
    '/liquid-diamonds-sativa-soft-gels-4x25mg': '/product/liquid-diamonds-sativa-soft-gels-4x25mg',
    '/2pk-soft-gels-2-x-50mg': '/product/2pk-soft-gels-2-x-50mg',
    '/yellow-5-1-full-spectrum-tincture': '/product/yellow-5-1-full-spectrum-tincture',
    '/tet-tonic-4-pack': '/product/tet-tonic-4-pack',
    '/tres-leches-75407': '/product/tres-leches-75407',
    '/bundle/nothing-but-net': '/product/bundle/nothing-but-net',
    '/honey-buns': '/product/honey-buns',
    '/the-boost-7pk': '/product/the-boost-7pk',
    '/pax-diamonds-wedding-cake': '/product/pax-diamonds-wedding-cake',
    '/bloom-live-1-0g-gush-mintz': '/product/bloom-live-1-0g-gush-mintz',
    '/mango-ginger-spritz-single-cans': '/product/mango-ginger-spritz-single-cans',
    '/high-purity-thc-forbidden-fruit': '/product/high-purity-thc-forbidden-fruit',
    '/tet-tonic-single-cans': '/product/tet-tonic-single-cans',
    '/kush-mint-pie-badder': '/product/kush-mint-pie-badder',
    '/pancakes-infused-pre-roll': '/product/pancakes-infused-pre-roll',
    '/citrus-wave-5pk': '/product/citrus-wave-5pk',
    '/runtz-49610': '/product/runtz-49610',
    '/sour-diesel-75700': '/product/sour-diesel-75700',
    '/orange-3-1-full-spectrum-tincture': '/product/orange-3-1-full-spectrum-tincture',
    '/minis-pacific-dream-5pk': '/product/minis-pacific-dream-5pk',
    '/passionfruit-guava-jellies': '/product/passionfruit-guava-jellies',
    '/happy-camper-infused-blend-jar': '/product/happy-camper-infused-blend-jar',
    '/pick-me-up-infused-blend-jar': '/product/pick-me-up-infused-blend-jar',
    '/mango-ginger-spritz-4-pack': '/product/mango-ginger-spritz-4-pack',
    '/strawberry-basil-spritz-4-pack': '/product/strawberry-basil-spritz-4-pack',
    '/tangelo-75716': '/product/tangelo-75716',
    '/nightcap-infused-blend-jar': '/product/nightcap-infused-blend-jar',
    '/sour-gummies-pineapple-10pk': '/product/sour-gummies-pineapple-10pk',
    '/trippy-purp': '/product/trippy-purp',
    '/sleep-4-1-thc-cbd-2pk': '/product/sleep-4-1-thc-cbd-2pk',
    '/papaya-rose-5pk': '/product/papaya-rose-5pk',
    '/blood-orange-cardamom-roadies': '/product/blood-orange-cardamom-roadies',
    '/lips-ashtray': '/product/lips-ashtray',
    '/calm-19775': '/product/calm-19775',
    '/rosemary-jane-4-pack': '/product/rosemary-jane-4-pack',
    '/rosemary-jane-single-cans': '/product/rosemary-jane-single-cans',
    '/hangover-4-3-thc-cbd-8pk': '/product/hangover-4-3-thc-cbd-8pk',
    '/marathon-og-7pk': '/product/marathon-og-7pk',
    '/sfv-og-7pk': '/product/sfv-og-7pk',
    '/cookies-n-cream-73628': '/product/cookies-n-cream-73628',
    '/lost-farm-white-russian-x-white-widow-chews': '/product/lost-farm-white-russian-x-white-widow-chews',
    '/high-horse-infused-tonic': '/product/high-horse-infused-tonic',
    '/mint-73301': '/product/mint-73301',
    '/hawaiian-fanta-sun-grown-flower': '/product/hawaiian-fanta-sun-grown-flower',
    '/camino-sours-strawberry-sunset-chill': '/product/camino-sours-strawberry-sunset-chill',
    '/joker': '/product/joker',
    '/bliss-plus-w-lrte-by-dosist-pod': '/product/bliss-plus-w-lrte-by-dosist-pod',
    '/very-berry-solventless-gummies': '/product/very-berry-solventless-gummies',
    '/everyday-1-8-thc-cbd-8pk': '/product/everyday-1-8-thc-cbd-8pk',
    '/white-gushers': '/product/white-gushers',
    '/dosist-x-bear-extracts-pure-live-resin-dose-pen-orangeade-25787':
      '/product/dosist-x-bear-extracts-pure-live-resin-dose-pen-orangeade-25787',
    '/tropical-grapes-x-ozk-infused-5-pk': '/product/tropical-grapes-x-ozk-infused-5-pk',
    '/period-1-2-thc-cbd-2pk': '/product/period-1-2-thc-cbd-2pk',
    '/island-og': '/product/island-og',
    '/big-d-energy-diamonds': '/product/big-d-energy-diamonds',
    '/peanut-butter-breath-10g': '/product/peanut-butter-breath-10g',
    '/cotc-og-diamonds': '/product/cotc-og-diamonds',
    '/rainbow-beltz': '/product/rainbow-beltz',
    '/watermelon-zkittlez-frosted-infused-5pk': '/product/watermelon-zkittlez-frosted-infused-5pk',
    '/medellin-x-jet-fuel-diamond-infused': '/product/medellin-x-jet-fuel-diamond-infused',
    '/funky-fresh': '/product/funky-fresh',
    '/lost-farm-gummies-cinnamon-apple-cereal-milk': '/product/lost-farm-gummies-cinnamon-apple-cereal-milk',
    '/period-1-2-thc-cbd-8pk': '/product/period-1-2-thc-cbd-8pk',
    '/hangover-4-3-thc-cbd-2pk': '/product/hangover-4-3-thc-cbd-2pk',
    '/smoakland-live-resin-raspberry-gushers-1g': '/product/smoakland-live-resin-raspberry-gushers-1g',
    '/star-chaser-infused-3pk': '/product/star-chaser-infused-3pk',
    '/nova-madness-infused-3pk': '/product/nova-madness-infused-3pk',
    '/papaya-punch-75056': '/product/papaya-punch-75056',
    '/triple-barrel-apricot-papaya-live-resin-infused-pre-rolls':
      '/product/triple-barrel-apricot-papaya-live-resin-infused-pre-rolls',
    '/og-kush-x-orange-banana-infused-5-pk': '/product/og-kush-x-orange-banana-infused-5-pk',
    '/diablo-og-ultra-potent': '/product/diablo-og-ultra-potent',
    '/watermelon-pink-peppercorn-4pk': '/product/watermelon-pink-peppercorn-4pk',
    '/9-5-anodized-rig': '/product/9-5-anodized-rig',
    '/10-cryo-sphere-water-pipe': '/product/10-cryo-sphere-water-pipe',
    '/papaya-bomb-73626': '/product/papaya-bomb-73626',
    '/knights-templar-og-diamonds': '/product/knights-templar-og-diamonds',
    '/watermelon-pink-peppercorn-single': '/product/watermelon-pink-peppercorn-single',
    '/monster-cookies-infused-7pk': '/product/monster-cookies-infused-7pk',
    '/blueberry-cheesecake-live-resin-vape': '/product/blueberry-cheesecake-live-resin-vape',
    '/triple-barrel-mother-s-milk-diamond-infused-pre-rolls': '/product/triple-barrel-mother-s-milk-diamond-infused-pre-rolls',
    '/purple-punch-54519': '/product/purple-punch-54519',
    '/gwp-fresas-con-crema': '/product/gwp-fresas-con-crema',
    '/legend-og-74620': '/product/legend-og-74620',
    '/orange-burst-mates-infused-5pk': '/product/orange-burst-mates-infused-5pk',
    '/body-tincture-1-20': '/product/body-tincture-1-20',
    '/everyday-1-8-thc-cbd-2pk': '/product/everyday-1-8-thc-cbd-2pk',
    '/cherry-peach-liquid-diamonds-live-resin-gummies': '/product/cherry-peach-liquid-diamonds-live-resin-gummies',
    '/strawberry-cream-cookies-live-rosin-badder': '/product/strawberry-cream-cookies-live-rosin-badder',
    '/strawberry-provencal-lemongrass-4pk': '/product/strawberry-provencal-lemongrass-4pk',
    '/peche-wild-honeysuckle-4pk': '/product/peche-wild-honeysuckle-4pk',
    '/oreoz-x-sunset-cider': '/product/oreoz-x-sunset-cider',
    '/cherry-iceez': '/product/cherry-iceez',
    '/resting-kushface-diamonds': '/product/resting-kushface-diamonds',
    '/jelly-sour-watermelon': '/product/jelly-sour-watermelon',
    '/space-runtz': '/product/space-runtz',
    '/georgia-pie-3-5g': '/product/georgia-pie-3-5g',
    '/cereza': '/product/cereza',
    '/blueberry-kush-live-resin': '/product/blueberry-kush-live-resin',
    '/banana-mango-live-resin-vape': '/product/banana-mango-live-resin-vape',
    '/apples-bananas-74918': '/product/apples-bananas-74918',
    '/og-kush-73965': '/product/og-kush-73965',
    '/male-banger': '/product/male-banger',
    '/garlic-juice-premium-live-rosin-badder': '/product/garlic-juice-premium-live-rosin-badder',
    '/gdp-73967': '/product/gdp-73967',
    '/bundle/trey-city': '/product/bundle/trey-city',
    '/hardrockz-limoncello': '/product/hardrockz-limoncello',
    '/cookies-n-cream-pre-ground': '/product/cookies-n-cream-pre-ground',
    '/805-glue-14g': '/product/805-glue-14g',
    '/cherry-punch-live-resin': '/product/cherry-punch-live-resin',
    '/terry-t-x-gelato-33-2-1-cbd-50901': '/product/terry-t-x-gelato-33-2-1-cbd-50901',
    '/wedding-mints-37660': '/product/wedding-mints-37660',
    '/og-kush-74919': '/product/og-kush-74919',
    '/jane-dough': '/product/jane-dough',
    '/it-s-bananaz-indoor-pre-roll': '/product/it-s-bananaz-indoor-pre-roll',
    '/fresh-pressed-live-rosin-blue-dream': '/product/fresh-pressed-live-rosin-blue-dream',
    '/passion-fruit-crumble': '/product/passion-fruit-crumble',
    '/runtz-crumble': '/product/runtz-crumble',
    '/fresh-pressed-live-rosin-california-orange-1g': '/product/fresh-pressed-live-rosin-california-orange-1g',
    '/maui-wowie-lil-lefty-infused-pre-roll': '/product/maui-wowie-lil-lefty-infused-pre-roll',
    '/pax-diamonds-granddaddy-purple': '/product/pax-diamonds-granddaddy-purple',
    '/island-splash-crushed-diamonds': '/product/island-splash-crushed-diamonds',
    '/medellin-x-jet-fuel-gelato-diamond-infused': '/product/medellin-x-jet-fuel-gelato-diamond-infused',
    '/pacific-stone-flower-14-0g-pouch-hybrid-mvp-cookies': '/product/pacific-stone-flower-14-0g-pouch-hybrid-mvp-cookies',
    '/raspberry-haze-infused-3pk': '/product/raspberry-haze-infused-3pk',
    '/cherry-limeade-infused-3-pk': '/product/cherry-limeade-infused-3-pk',
    '/purple-jealousy-diamond-infused': '/product/purple-jealousy-diamond-infused',
    '/maui-waui-73964': '/product/maui-waui-73964',
    '/strawberry-provencal-lemongrass-single': '/product/strawberry-provencal-lemongrass-single',
    '/northern-lights-73963': '/product/northern-lights-73963',
    '/strawberry-lemon-tart-liquid-diamonds': '/product/strawberry-lemon-tart-liquid-diamonds',
    '/assorted-flavor-gummies-20pk': '/product/assorted-flavor-gummies-20pk',
    '/stone-fruit-french-vanilla-single': '/product/stone-fruit-french-vanilla-single',
    '/peche-wild-honeysuckle-single': '/product/peche-wild-honeysuckle-single',
    '/cherry-lime-sherblato-liquid-diamond-disposable': '/product/cherry-lime-sherblato-liquid-diamond-disposable',
    '/peppermint-bark-bar': '/product/peppermint-bark-bar',
    '/mendo-clouds-73830': '/product/mendo-clouds-73830',
    '/amarelo-11-premium-live-rosin': '/product/amarelo-11-premium-live-rosin',
    '/rambutan-premium-live-rosin': '/product/rambutan-premium-live-rosin',
    '/blewtooth': '/product/blewtooth',
    '/sierra-hills-flower-white-widow': '/product/sierra-hills-flower-white-widow',
    '/sensi-chew-energy-with-added-ginseng': '/product/sensi-chew-energy-with-added-ginseng',
    '/sensi-chew-amore-aphrodisiac': '/product/sensi-chew-amore-aphrodisiac',
    '/sensi-chew-indica': '/product/sensi-chew-indica',
    '/creative-awareness-blue-dream-green-crack-super-lemon-haze':
      '/product/creative-awareness-blue-dream-green-crack-super-lemon-haze',
    '/sour-strawberry-live-resin': '/product/sour-strawberry-live-resin',
    '/9-perc-water-pipe': '/product/9-perc-water-pipe',
    '/6-sherlock-lavender': '/product/6-sherlock-lavender',
    '/14mm-quartz-concentrate-bucket': '/product/14mm-quartz-concentrate-bucket',
    '/mandarin-meringue-live-rosin-badder': '/product/mandarin-meringue-live-rosin-badder',
    '/12-beaker-assorted-colors': '/product/12-beaker-assorted-colors',
    '/8-beaker-assorted-colors': '/product/8-beaker-assorted-colors',
    '/6-rig': '/product/6-rig',
    '/after-party-ready-to-use': '/product/after-party-ready-to-use',
    '/anesthesia-x-hammerhead-hash-and-diamond-infused-flower':
      '/product/anesthesia-x-hammerhead-hash-and-diamond-infused-flower',
    '/liquid-diamonds-indica-soft-gels-4x25mg': '/product/liquid-diamonds-indica-soft-gels-4x25mg',
    '/lemonade-x-white-gelato-infused-5pk': '/product/lemonade-x-white-gelato-infused-5pk',
    '/razz-tazz': '/product/razz-tazz',
    '/watermelon-x-gelato-chews': '/product/watermelon-x-gelato-chews',
    '/surf-0-5g-skywalker-sky': '/product/surf-0-5g-skywalker-sky',
    '/product/peanut-butter-cups-10pk': '/product/product/peanut-butter-cups-10pk',
    '/camino-sparkling-pear-cbd-social-gummies': '/product/camino-sparkling-pear-cbd-social-gummies',
    '/thin-mints-infused-7pk': '/product/thin-mints-infused-7pk',
    '/blue-dream-74090': '/product/blue-dream-74090',
    '/50mg-5pack-thc-beverage-enhancer': '/product/50mg-5pack-thc-beverage-enhancer',
    '/platinum-sour-diesel': '/product/platinum-sour-diesel',
    '/lemon-oz-live-resin': '/product/lemon-oz-live-resin',
    '/terra-chocolate-covered-blueberries': '/product/terra-chocolate-covered-blueberries',
    '/pineapple-express-ultra-potent': '/product/pineapple-express-ultra-potent',
    '/strawberry-cough-73806': '/product/strawberry-cough-73806',
    '/purple-jealousy-5g-diamond-infused-preroll': '/product/purple-jealousy-5g-diamond-infused-preroll',
    '/space-cookies-crumble': '/product/space-cookies-crumble',
    '/unicorn-cake-infused-3pk': '/product/unicorn-cake-infused-3pk',
    '/purple-kush-cake': '/product/purple-kush-cake',
    '/rainbow-lemon-smac': '/product/rainbow-lemon-smac',
    '/lemon-meringue-pie-infused-3pk': '/product/lemon-meringue-pie-infused-3pk',
    '/hazy-daze-live-resin': '/product/hazy-daze-live-resin',
    '/bali-sunset-diamonds': '/product/bali-sunset-diamonds',
    '/sunset-mojito': '/product/sunset-mojito',
    '/green-dream-crushed-diamonds': '/product/green-dream-crushed-diamonds',
    '/orange-dreamsicle': '/product/orange-dreamsicle',
    '/camino-watermelon-lemonade-bliss-gummies': '/product/camino-watermelon-lemonade-bliss-gummies',
    '/platinum-og-crushed-diamonds': '/product/platinum-og-crushed-diamonds',
    '/wedding-breath-live-resin': '/product/wedding-breath-live-resin',
    '/cherry-pie-diesel-live-resin': '/product/cherry-pie-diesel-live-resin',
    '/zookie-land-infused-3pk': '/product/zookie-land-infused-3pk',
    '/emerald-bay-purps': '/product/emerald-bay-purps',
    '/lavender-haze': '/product/lavender-haze',
    '/wedding-crashers-73271': '/product/wedding-crashers-73271',
    '/cbg-elixir-capsules': '/product/cbg-elixir-capsules',
    '/1-1-balanced-capsules': '/product/1-1-balanced-capsules',
    '/1-1-balanced-capsules-5pk': '/product/1-1-balanced-capsules-5pk',
    '/sensi-chew-insomnia-with-added-glycine': '/product/sensi-chew-insomnia-with-added-glycine',
    '/black-mamba-shatter': '/product/black-mamba-shatter',
    '/marathon-og-59030': '/product/marathon-og-59030',
    '/sleep-sour-cherry-with-cbn': '/product/sleep-sour-cherry-with-cbn',
    '/blackberry-gelato-24': '/product/blackberry-gelato-24',
    '/mother-s-milk-50885': '/product/mother-s-milk-50885',
    '/bento-sitemap.xml': '/product/bento-sitemap.xml',
    '/royal-dutch': '/product/royal-dutch',
    '/now-and-later-pre-ground': '/product/now-and-later-pre-ground',
    '/grapes-and-cream-liquid-diamonds-live-resin': '/product/grapes-and-cream-liquid-diamonds-live-resin',
    '/sherb-x-sweet-tea': '/product/sherb-x-sweet-tea',
    '/presidential-og-73273': '/product/presidential-og-73273',
    '/diamond-peaches-live-resin': '/product/diamond-peaches-live-resin',
    '/mango-mentality-private-stock-eighth': '/product/mango-mentality-private-stock-eighth',
    '/blue-dream-2pk-58335': '/product/blue-dream-2pk-58335',
    '/pink-champagne-2pk': '/product/pink-champagne-2pk',
    '/purple-cookies-2pk': '/product/purple-cookies-2pk',
    '/indica-babies-10pk': '/product/indica-babies-10pk',
    '/tropicana-cherry-liquid-diamonds-live-resin': '/product/tropicana-cherry-liquid-diamonds-live-resin',
    '/honeywine-liquid-diamonds-live-resin': '/product/honeywine-liquid-diamonds-live-resin',
    '/fat-ya-ya-premium-live-rosin': '/product/fat-ya-ya-premium-live-rosin',
    '/strawberry-jelly-premium-live-rosin': '/product/strawberry-jelly-premium-live-rosin',
    '/trop-x-wilson-premium-live-rosin': '/product/trop-x-wilson-premium-live-rosin',
    '/papaya-punch-premium-live-rosin': '/product/papaya-punch-premium-live-rosin',
    '/strawberries-and-cream-premium-live-rosin': '/product/strawberries-and-cream-premium-live-rosin',
    '/paradise-guava-57625': '/product/paradise-guava-57625',
    '/jet-mints': '/product/jet-mints',
    '/cherry-pie-57959': '/product/cherry-pie-57959',
    '/grapes-cream-sun-grown-flower': '/product/grapes-cream-sun-grown-flower',
    '/blue-cherry-gelato': '/product/blue-cherry-gelato',
    '/melonwreck': '/product/melonwreck',
    '/blue-dream-14g-58338': '/product/blue-dream-14g-58338',
    // '/product/apple-fritter-52192': '/product/product/apple-fritter-52192',
    '/triangle-kush-indoor-smalls': '/product/triangle-kush-indoor-smalls',
    '/lemon-cherry-dolce-indoor-smalls': '/product/lemon-cherry-dolce-indoor-smalls',
    '/gogi-berry-sugar': '/product/gogi-berry-sugar',
    '/guava-passion-fruit-1-1-gummies': '/product/guava-passion-fruit-1-1-gummies',
    '/clementine': '/product/clementine',
    '/guava-jelly-elite-live': '/product/guava-jelly-elite-live',
    '/lost-farm-tangerine-x-sunet-sherbert-chews': '/product/lost-farm-tangerine-x-sunet-sherbert-chews',
    '/forbidden-fruit-20624': '/product/forbidden-fruit-20624',
    '/bombastic-berry-73303': '/product/bombastic-berry-73303',
    '/platinum-og-58323': '/product/platinum-og-58323',
    '/airheads-56201': '/product/airheads-56201',
    '/be-high': '/product/be-high',
    '/be-better': '/product/be-better',
    '/be-productive': '/product/be-productive',
    '/white-oreoz-indoor-smalls': '/product/white-oreoz-indoor-smalls',
    '/cherry-gelato-indoor-smalls': '/product/cherry-gelato-indoor-smalls',
    '/tangerine-sorbet-73304': '/product/tangerine-sorbet-73304',
    '/all-times-mimosa-mintz': '/product/all-times-mimosa-mintz',
    '/peaches-n-dream-liquid-diamonds': '/product/peaches-n-dream-liquid-diamonds',
    '/raspberry-quinoa-single-serve-chocolate': '/product/raspberry-quinoa-single-serve-chocolate',
    '/be-active': '/product/be-active',
    '/lemonhead-x-strawberry-goo-hash-diamond-infused-5pk': '/product/lemonhead-x-strawberry-goo-hash-diamond-infused-5pk',
    '/space-cake-x-lava-cake-hash-diamond-infused-5pk': '/product/space-cake-x-lava-cake-hash-diamond-infused-5pk',
    '/horchata-48614': '/product/horchata-48614',
    '/gm-uhoh-51236': '/product/gm-uhoh-51236',
    '/cheesecake': '/product/cheesecake',
    '/skywalker-og-58118': '/product/skywalker-og-58118',
    '/pink-cookies-live-resin': '/product/pink-cookies-live-resin',
    '/heat-wave': '/product/heat-wave',
    '/mango-1-1': '/product/mango-1-1',
    '/space-cake-sauce': '/product/space-cake-sauce',
    '/jack-fruit-diamond-formulation-vape': '/product/jack-fruit-diamond-formulation-vape',
    '/truffle-runtz': '/product/truffle-runtz',
    '/triple-og-live-resin': '/product/triple-og-live-resin',
    '/jealousy-41-diamonds': '/product/jealousy-41-diamonds',
    '/purple-petrol': '/product/purple-petrol',
    '/monster-cookies-57957': '/product/monster-cookies-57957',
    '/atlas-peak-bar-salted-caramel-chocolate': '/product/atlas-peak-bar-salted-caramel-chocolate',
    '/true-berrymore-x-alien-cookies-hash-diamond-infused-flower':
      '/product/true-berrymore-x-alien-cookies-hash-diamond-infused-flower',
    '/red-pod-battery': '/product/red-pod-battery',
    '/blue-pod-battery': '/product/blue-pod-battery',
    '/blue-lime-pie': '/product/blue-lime-pie',
    '/pbr-high-seltzer-passion-fruit-pineapple': '/product/pbr-high-seltzer-passion-fruit-pineapple',
    '/strawberry-basil-spritz-single-cans': '/product/strawberry-basil-spritz-single-cans',
    '/grease-monkey-pre-roll': '/product/grease-monkey-pre-roll',
    '/blackberry-tincture': '/product/blackberry-tincture',
    '/monster-cookies-infused': '/product/monster-cookies-infused',
    '/dream-honey': '/product/dream-honey',
    '/biscotti-23145': '/product/biscotti-23145',
    '/purple-octane': '/product/purple-octane',
    '/sour-tangie-22520': '/product/sour-tangie-22520',
    '/purple-haze-shatter': '/product/purple-haze-shatter',
    '/dosilato-22872': '/product/dosilato-22872',
    '/alpha-dawg-live-resin': '/product/alpha-dawg-live-resin',
    '/blue-dream-57958': '/product/blue-dream-57958',
    '/georgia-peaches-diamonds': '/product/georgia-peaches-diamonds',
    '/bacio-58195': '/product/bacio-58195',
    '/kings-kush-live-resin': '/product/kings-kush-live-resin',
    '/green-pod-battery': '/product/green-pod-battery',
    '/orange-creamsicle-infused': '/product/orange-creamsicle-infused',
    '/garlic-juice-pre-roll': '/product/garlic-juice-pre-roll',
    '/grape-cookie-infused': '/product/grape-cookie-infused',
    '/full-spectrum-oil-fso-1g-1-0-ratio-high-thc': '/product/full-spectrum-oil-fso-1g-1-0-ratio-high-thc',
    '/pink-pod-battery': '/product/pink-pod-battery',
    '/blue-raspberry-drops': '/product/blue-raspberry-drops',
    '/strawberry-drops': '/product/strawberry-drops',
    '/jet-fuel-gelato-6pk': '/product/jet-fuel-gelato-6pk',
    '/octane-pop-6pk': '/product/octane-pop-6pk',
    '/all-gas-no-brakes': '/product/all-gas-no-brakes',
    '/fuel-og-6pk': '/product/fuel-og-6pk',
    '/banana-zkittlez': '/product/banana-zkittlez',
    '/1/feed': '/product/1/feed',
    '/mango-kush-48602': '/product/mango-kush-48602',
    '/high-cbd-rso': '/product/high-cbd-rso',
    '/wedding-cake-58200': '/product/wedding-cake-58200',
    '/not-your-father-s-root-beer': '/product/not-your-father-s-root-beer',
    '/creative-thinking-journal': '/product/creative-thinking-journal',
    '/pb-j-strawnana-hash-infused-5pk': '/product/pb-j-strawnana-hash-infused-5pk',
    '/product-page/romulan-live-resin-indica-bad-fish-extracts':
      '/product/product-page/romulan-live-resin-indica-bad-fish-extracts',
    '/project-cheetah': '/product/project-cheetah',
    '/grassdoor-white-battery': '/product/grassdoor-white-battery',
    '/high-tide-gummies': '/product/high-tide-gummies',
    '/805-glue-19958': '/product/805-glue-19958',
    '/mac-57728': '/product/mac-57728',
    '/clementine-x-lemon-bean-hash-diamond-infused-flower': '/product/clementine-x-lemon-bean-hash-diamond-infused-flower',
    '/ajo-blanco': '/product/ajo-blanco',
    '/mac-3-5g': '/product/mac-3-5g',
    '/blue-dream-ultra-potent': '/product/blue-dream-ultra-potent',
    '/strawberry-skittlez': '/product/strawberry-skittlez',
    '/creative-focus-berry-punch-xj-13-lemon-jack': '/product/creative-focus-berry-punch-xj-13-lemon-jack',
    '/supper-stocking-stuffer-white-widow-x-apple-fritter-infused-w-hash':
      '/product/supper-stocking-stuffer-white-widow-x-apple-fritter-infused-w-hash',
    // '/product/banana-jelly': '/product/product/banana-jelly?tab=schedule',
    '/10mg-single-thc-beverage-enhancer': '/product/10mg-single-thc-beverage-enhancer',
    '/sour-berry-og': '/product/sour-berry-og',
    '/gushers-smalls': '/product/gushers-smalls',
    '/watermelon-dreamz': '/product/watermelon-dreamz',
    '/ascnd-mimosa': '/product/ascnd-mimosa',
    '/ascnd-high-fashion': '/product/ascnd-high-fashion',
    '/mystery-og-x-space-dawg-hash-diamond-infused-flower': '/product/mystery-og-x-space-dawg-hash-diamond-infused-flower',
    '/champagne-kush-1g': '/product/champagne-kush-1g',
    '/gushers-56195': '/product/gushers-56195',
    '/watermelon-solventless-gummies': '/product/watermelon-solventless-gummies',
    '/black-sugar-rose-live-resin': '/product/black-sugar-rose-live-resin',
    '/wedding-cake-19997': '/product/wedding-cake-19997',
    '/cereal-milk': '/product/cereal-milk',
    '/level-hashtab-sativa-tablet': '/product/level-hashtab-sativa-tablet',
    '/gelatti-56187': '/product/gelatti-56187',
    '/vetcbd-extra-strength-cannabis-tincture-250mg-2oz': '/product/vetcbd-extra-strength-cannabis-tincture-250mg-2oz',
    '/vetcbd-reg-strength-cannabis-tincture-250mg-2oz': '/product/vetcbd-reg-strength-cannabis-tincture-250mg-2oz',
    '/space-dust-7-pk': '/product/space-dust-7-pk',
    '/jack-2-0': '/product/jack-2-0',
    '/venom-og-pre-roll': '/product/venom-og-pre-roll',
    '/tropical-blueberries-x-pink-picasso-infused-5pk': '/product/tropical-blueberries-x-pink-picasso-infused-5pk',
    '/thc100-capsules-10-caps': '/product/thc100-capsules-10-caps',
    '/watermelon-skittlez': '/product/watermelon-skittlez',
    '/gg-4-pre-roll': '/product/gg-4-pre-roll',
    '/grapefruit-rosemary-6-pk': '/product/grapefruit-rosemary-6-pk',
    '/krypto-chronic-57666': '/product/krypto-chronic-57666',
    '/vetcbd-reg-strength-cannabis-tincture-500mg-2oz': '/product/vetcbd-reg-strength-cannabis-tincture-500mg-2oz',
    '/electric-orchid': '/product/electric-orchid',
    '/chemdawg-faderz': '/product/chemdawg-faderz',
    '/slurricane-57730': '/product/slurricane-57730',
    '/green-crack-58339': '/product/green-crack-58339',
    '/kush-mints-22699': '/product/kush-mints-22699',
    '/wedding-cake-sugar-56568': '/product/wedding-cake-sugar-56568',
    '/skywalker': '/product/skywalker',
    '/citrus-punch-badder': '/product/citrus-punch-badder',
    '/zkittles-live-resin': '/product/zkittles-live-resin',
    '/ascnd-cherry-truffle': '/product/ascnd-cherry-truffle',
    '/tropicana-cookies-22403': '/product/tropicana-cookies-22403',
    '/lucid-dreams-gg4-og-kush-northern-lights': '/product/lucid-dreams-gg4-og-kush-northern-lights',
    '/brands/kiva-confection': '/product/brands/kiva-confection',
    '/watermelon-shot': '/product/watermelon-shot',
    '/play-batteries-black': '/product/play-batteries-black',
    '/bubblegum-kush-shatter': '/product/bubblegum-kush-shatter',
    '/grapefruit-rosemary-lite-6-pk': '/product/grapefruit-rosemary-lite-6-pk',
    '/mango-og-ultra-potent': '/product/mango-og-ultra-potent',
    '/lolo-cherry-lato-indoor-smalls': '/product/lolo-cherry-lato-indoor-smalls',
    '/orange-valley-cookies-hash-wrapped-smoke-1-25g': '/product/orange-valley-cookies-hash-wrapped-smoke-1-25g',
    '/creative-awareness-blue-dream-green-crack-super-lemon-haze-23522':
      '/product/creative-awareness-blue-dream-green-crack-super-lemon-haze-23522',
    '/grand-daddy-purple-liquid-diamonds': '/product/grand-daddy-purple-liquid-diamonds',
    '/positivi-tea-tin-of-4': '/product/positivi-tea-tin-of-4',
    '/little-helpers-20-ct-sleep': '/product/little-helpers-20-ct-sleep',
    '/indica-xtabs-30mg': '/product/indica-xtabs-30mg',
    '/little-helpers-20-ct-boost': '/product/little-helpers-20-ct-boost',
    '/sour-sangria-eighth': '/product/sour-sangria-eighth',
    '/blueberry-skittles': '/product/blueberry-skittles',
    '/cosmic-cake-shot': '/product/cosmic-cake-shot',
    '/cola-shot': '/product/cola-shot',
    '/cola-drops': '/product/cola-drops',
    '/pink-lemonade-drops': '/product/pink-lemonade-drops',
    '/blue-raspberry-shot': '/product/blue-raspberry-shot',
    '/cosmic-cake-drops': '/product/cosmic-cake-drops',
    '/papaya-live-liquid-diamonds-disposable': '/product/papaya-live-liquid-diamonds-disposable',
    '/mochi-5pk': '/product/mochi-5pk',
    '/dos-peaches-solventless': '/product/dos-peaches-solventless',
    '/bubble-gum-haze': '/product/bubble-gum-haze',
    '/cherry-lime-sherblato-live-resin': '/product/cherry-lime-sherblato-live-resin',
    '/little-helpers-focus-mints-20-ct': '/product/little-helpers-focus-mints-20-ct',
    '/sympa-tea-tin-of-4': '/product/sympa-tea-tin-of-4',
    // '/product/bubba-kush-7pk': '/product/product/bubba-kush-7pk?tab=asap',
    '/peach-jellies': '/product/peach-jellies',
    '/signature-wedding-crashers': '/product/signature-wedding-crashers',
    '/good-day-bites': '/product/good-day-bites',
    '/blue-dream-crumble': '/product/blue-dream-crumble',
    '/smoakland-black-dosilato-3-5g': '/product/smoakland-black-dosilato-3-5g',
    '/glow-bites': '/product/glow-bites',
    '/fresh-pressed-live-rosin-blueberry-og': '/product/fresh-pressed-live-rosin-blueberry-og',
    '/nite-bites': '/product/nite-bites',
    '/slurricane-faderz': '/product/slurricane-faderz',
    '/smoakland-black-kush-mintz-14g': '/product/smoakland-black-kush-mintz-14g',
    '/og-kush-mini-fuzzies-3pk': '/product/og-kush-mini-fuzzies-3pk',
    '/platinum-sour-diesel-crumble': '/product/platinum-sour-diesel-crumble',
    '/xtabs-indica': '/product/xtabs-indica',
    '/grape-slushie-shatter': '/product/grape-slushie-shatter',
    '/blue-dream-57513': '/product/blue-dream-57513',
    '/super-silver-haze-infused-3pk': '/product/super-silver-haze-infused-3pk',
    '/honeyshot-buzz-live-resin-ruthless-og': '/product/honeyshot-buzz-live-resin-ruthless-og',
    '/pax-era-pro-device-red': '/product/pax-era-pro-device-red',
    '/honeyshot-calm': '/product/honeyshot-calm',
    '/tranquili-tea-can-of-10': '/product/tranquili-tea-can-of-10',
    '/sympa-tea-can-of-10': '/product/sympa-tea-can-of-10',
    '/sour-berry-diamonds': '/product/sour-berry-diamonds',
    '/legend-og-live-resin-diamond-sauce': '/product/legend-og-live-resin-diamond-sauce',
    '/st-ides-high-tea-wild-raspberry': '/product/st-ides-high-tea-wild-raspberry',
    '/st-ides-high-tea-georgia-peach': '/product/st-ides-high-tea-georgia-peach',
    '/cereal-milk-20274': '/product/cereal-milk-20274',
    '/strawberry-lemonade-100mg-shot': '/product/strawberry-lemonade-100mg-shot',
    '/grassdoor-branded-aluminum-grinder-black': '/product/grassdoor-branded-aluminum-grinder-black',
    '/thcv-10pk-pink-boost-godess': '/product/thcv-10pk-pink-boost-godess',
    '/brighter-day-rosette-10pk': '/product/brighter-day-rosette-10pk',
    '/white-runtz-56194': '/product/white-runtz-56194',
    '/surf-0-5g-girl-scout-cookies-gsc': '/product/surf-0-5g-girl-scout-cookies-gsc',
    '/ae-el-blunto-especial-silver-blanco': '/product/ae-el-blunto-especial-silver-blanco',
    '/la-og-x-big-apple-infused-5pk': '/product/la-og-x-big-apple-infused-5pk',
    '/king-louis-20591': '/product/king-louis-20591',
    '/pineapple-runtz': '/product/pineapple-runtz',
    '/tropicanna-20151': '/product/tropicanna-20151',
    '/creative-imagination-gelato-33-chemdawg-lemon-jack': '/product/creative-imagination-gelato-33-chemdawg-lemon-jack',
    '/lemon-cherry-gelato-56102': '/product/lemon-cherry-gelato-56102',
    '/dark-chocolate-sea-salt-releaf-chocolate-bar': '/product/dark-chocolate-sea-salt-releaf-chocolate-bar',
    '/blue-razz-100mg-shot': '/product/blue-razz-100mg-shot',
    '/vetcbd-reg-strength-cannabis-tincture-125mg-1oz': '/product/vetcbd-reg-strength-cannabis-tincture-125mg-1oz',
    '/cereal-milk-22772': '/product/cereal-milk-22772',
    '/melted-ice-smalls': '/product/melted-ice-smalls',
    '/galactic-haze-live-resin-vape': '/product/galactic-haze-live-resin-vape',
    '/grape-sorbet-cdt': '/product/grape-sorbet-cdt',
    '/creative-reflection-sundae-driver-blue-dream-pineapple-express-27498':
      '/product/creative-reflection-sundae-driver-blue-dream-pineapple-express-27498',
    '/milk-releaf-chocolate-bar': '/product/milk-releaf-chocolate-bar',
    '/gastro-pop': '/product/gastro-pop',
    '/maui-wowie-20593': '/product/maui-wowie-20593',
    '/pineapple-jalapeno-6pk': '/product/pineapple-jalapeno-6pk',
    '/men-s-farm-jacket-green-xl': '/product/men-s-farm-jacket-green-xl',
    '/women-s-farm-jacket-gray-l': '/product/women-s-farm-jacket-gray-l',
    '/blueberry-cookies-55738': '/product/blueberry-cookies-55738',
    '/space-face-sfc': '/product/space-face-sfc',
    '/lights-on-energy-gummy-2-1-thc-thcv': '/product/lights-on-energy-gummy-2-1-thc-thcv',
    '/disco-mints-hash-wrapped': '/product/disco-mints-hash-wrapped',
    '/og-blueberry-creme-hash-wrapped': '/product/og-blueberry-creme-hash-wrapped',
    '/original-flavor-hemp-wraps-2pk': '/product/original-flavor-hemp-wraps-2pk',
    '/lemon-lavender-6-pk': '/product/lemon-lavender-6-pk',
    '/apple-fritter-52192': '/product/apple-fritter-52192',
    '/strawberry-cough-ultra-potent-disposable': '/product/strawberry-cough-ultra-potent-disposable',
    '/high-seltzer-mango-blood-orange-4pk': '/product/high-seltzer-mango-blood-orange-4pk',
    '/garlotti-49377': '/product/garlotti-49377',
    '/pave-red-dragon-hash-infused-5pk': '/product/pave-red-dragon-hash-infused-5pk',
    '/mangonada-gummies': '/product/mangonada-gummies',
    '/high-passion-fruit-pineapple-4pk': '/product/high-passion-fruit-pineapple-4pk',
    '/durban-venom-thcv-enhanced': '/product/durban-venom-thcv-enhanced',
    '/lemon-high-seltzer': '/product/lemon-high-seltzer',
    '/sunset-sherbet-cured-resin-sauce': '/product/sunset-sherbet-cured-resin-sauce',
    '/pbr-high-seltzer-lemon': '/product/pbr-high-seltzer-lemon',
    '/pbr-high-seltzer-mango-blood-orange': '/product/pbr-high-seltzer-mango-blood-orange',
    '/pbr-high-seltzer-energy-guava': '/product/pbr-high-seltzer-energy-guava',
    '/pbr-high-seltzer-strawberry-kiwi': '/product/pbr-high-seltzer-strawberry-kiwi',
    '/blue-dream-diamonds': '/product/blue-dream-diamonds',
    '/high-purity-thc-strawberry-creme': '/product/high-purity-thc-strawberry-creme',
    '/pbr-high-seltzer-midnight-berries': '/product/pbr-high-seltzer-midnight-berries',
    '/sour-diesel-pod': '/product/sour-diesel-pod',
    '/galactic-gas-5pk': '/product/galactic-gas-5pk',
    '/lemon-lavender-roadie-8-pk': '/product/lemon-lavender-roadie-8-pk',
    '/bloom-live-surf-pink-mimosa': '/product/bloom-live-surf-pink-mimosa',
    '/space-drops-sweet-assorted-flavors': '/product/space-drops-sweet-assorted-flavors',
    '/og-kush-56669': '/product/og-kush-56669',
    '/pow-noir-black-cherry-punch': '/product/pow-noir-black-cherry-punch',
    '/lemonberry': '/product/lemonberry',
    '/golden-citrus-bark': '/product/golden-citrus-bark',
    '/blood-orange-cardamom-hi-boy-4-pk': '/product/blood-orange-cardamom-hi-boy-4-pk',
    '/yuzu-elderflower-reserve-6-pk': '/product/yuzu-elderflower-reserve-6-pk',
    '/sour-maui-pineapple-gummies': '/product/sour-maui-pineapple-gummies',
    '/watermelon-z': '/product/watermelon-z',
    '/women-s-farm-jacket-green-xl': '/product/women-s-farm-jacket-green-xl',
    '/lost-farm-gummies-black-cherry-og-kush': '/product/lost-farm-gummies-black-cherry-og-kush',
    '/women-s-farm-jacket-green-m': '/product/women-s-farm-jacket-green-m',
    '/plus-mango-calm-10-1': '/product/plus-mango-calm-10-1',
    '/hvy-seltzer-acapulco-gold-25mg-rtd': '/product/hvy-seltzer-acapulco-gold-25mg-rtd',
    '/kosher-zkittlez-surf-rosin-kzk': '/product/kosher-zkittlez-surf-rosin-kzk',
    '/chem-kardashian': '/product/chem-kardashian',
    '/animal-tree-small-bud': '/product/animal-tree-small-bud',
    '/bloom-live-1-0g-melonade-mel': '/product/bloom-live-1-0g-melonade-mel',
    '/high-purity-thc-london-poundcake': '/product/high-purity-thc-london-poundcake',
    '/high-purity-thc-berry-gelato': '/product/high-purity-thc-berry-gelato',
    '/women-s-farm-jacket-green-l': '/product/women-s-farm-jacket-green-l',
    '/marathon-og': '/product/marathon-og',
    '/jelly-tangerine-gummies': '/product/jelly-tangerine-gummies',
    '/garlotti-57402': '/product/garlotti-57402',
    '/black-domina-elite-live': '/product/black-domina-elite-live',
    '/quiet-times-slims-diamond-infused': '/product/quiet-times-slims-diamond-infused',
    '/calm-dose-pen-100': '/product/calm-dose-pen-100',
    '/sunset-sherbet-private-stock-eighth': '/product/sunset-sherbet-private-stock-eighth',
    '/giggle-puffs': '/product/giggle-puffs',
    '/men-s-farm-jacket-gray-m': '/product/men-s-farm-jacket-gray-m',
    '/maui-waui': '/product/maui-waui',
    '/sugar-babies-3pk-diamond-and-crumble-infused-blunts-bentley-strain':
      '/product/sugar-babies-3pk-diamond-and-crumble-infused-blunts-bentley-strain',
    '/electric-melon-diamond-sauce': '/product/electric-melon-diamond-sauce',
    '/sweet-papaya-punch-gummies': '/product/sweet-papaya-punch-gummies',
    '/pink-lemonade-gummies': '/product/pink-lemonade-gummies',
    '/sour-guava-melon-berry-gummies': '/product/sour-guava-melon-berry-gummies',
    '/strawberry-biscotti-57403': '/product/strawberry-biscotti-57403',
    '/pow-noir-alien-creamsicle': '/product/pow-noir-alien-creamsicle',
    '/bloom-live-surf-0-5g-apple-fritter-afr': '/product/bloom-live-surf-0-5g-apple-fritter-afr',
    '/assorted-flavor-gummies': '/product/assorted-flavor-gummies',
    '/northern-lights-56576': '/product/northern-lights-56576',
    '/rainbow-rubble-hint-of-lychee-gummies': '/product/rainbow-rubble-hint-of-lychee-gummies',
    '/durban-lime': '/product/durban-lime',
    '/product/mad-lilly-tonic-w-cbn-thc-cbd': '/product/product/mad-lilly-tonic-w-cbn-thc-cbd',
    '/chocolope-diamond-sauce': '/product/chocolope-diamond-sauce',
    '/petra-cbn-2-1-blackberry-mints': '/product/petra-cbn-2-1-blackberry-mints',
    '/plug-exotics-apple-slushie': '/product/plug-exotics-apple-slushie',
    '/rpg-22703': '/product/rpg-22703',
    '/sour-gummies-watermelon-10pk': '/product/sour-gummies-watermelon-10pk',
    '/cool-grape-plus-2pk': '/product/cool-grape-plus-2pk',
    '/blue-dream-19315': '/product/blue-dream-19315',
    '/cereal-milk-50930': '/product/cereal-milk-50930',
    '/papaya-dosido': '/product/papaya-dosido',
    '/petra-saigon-cinnamon-mints': '/product/petra-saigon-cinnamon-mints',
    '/the-judge': '/product/the-judge',
    '/gelato-41-48603': '/product/gelato-41-48603',
    '/super-lemon-haze-56198': '/product/super-lemon-haze-56198',
    '/fruit-bubblegum-3-5g': '/product/fruit-bubblegum-3-5g',
    '/strawberry-cough-20630': '/product/strawberry-cough-20630',
    '/tiramisu-hash-infused-single': '/product/tiramisu-hash-infused-single',
    '/elite-live-wedding-crasher-x-white-runtz': '/product/elite-live-wedding-crasher-x-white-runtz',
    '/strawberry-fields': '/product/strawberry-fields',
    '/tsumosnacks-fiery-hot-crunchers-100mg-multiserve': '/product/tsumosnacks-fiery-hot-crunchers-100mg-multiserve',
    '/granddaddy-purple-20756': '/product/granddaddy-purple-20756',
    '/gelato-high-octane': '/product/gelato-high-octane',
    '/lemonade': '/product/lemonade',
    '/grape-ape-cliq-pod': '/product/grape-ape-cliq-pod',
    '/maple-sundae': '/product/maple-sundae',
    '/blue-dream-cliq-pod': '/product/blue-dream-cliq-pod',
    '/ghost-train-haze-cliq-pod': '/product/ghost-train-haze-cliq-pod',
    '/kush-mints-7g': '/product/kush-mints-7g',
    '/citrus-funk-crushed-diamonds': '/product/citrus-funk-crushed-diamonds',
    '/mystic-mist': '/product/mystic-mist',
    '/vegan-peanut-butter-chocolate-chip-cookies-10pk': '/product/vegan-peanut-butter-chocolate-chip-cookies-10pk',
    '/mendo-fuel': '/product/mendo-fuel',
    '/stardust-og': '/product/stardust-og',
    '/strawnana-surf-rosin-stn': '/product/strawnana-surf-rosin-stn',
    '/fast-acting-nano-cran-pomegranate-gummies': '/product/fast-acting-nano-cran-pomegranate-gummies',
    '/black-jack-pre-roll': '/product/black-jack-pre-roll',
    '/lost-farm-strawberry-rhubarb-x-headband-chews': '/product/lost-farm-strawberry-rhubarb-x-headband-chews',
    '/cherry-dosi-1g': '/product/cherry-dosi-1g',
    '/desert-cooler': '/product/desert-cooler',
    '/level-protab-sativa-tablets': '/product/level-protab-sativa-tablets',
    '/sour-orange-margarita-live-resin': '/product/sour-orange-margarita-live-resin',
    '/camino-holiday-punch-gummies': '/product/camino-holiday-punch-gummies',
    '/jungle-jam-crushed-diamonds': '/product/jungle-jam-crushed-diamonds',
    '/1g-single-pre-roll-coast-cbd': '/product/1g-single-pre-roll-coast-cbd',
    '/sour-melon': '/product/sour-melon',
    '/oahu-rose-crushed-diamonds': '/product/oahu-rose-crushed-diamonds',
    '/galactic-goo-ready-to-roll': '/product/galactic-goo-ready-to-roll',
    '/legend-og-22742': '/product/legend-og-22742',
    '/juicy-peach-x-mimosa-gummies': '/product/juicy-peach-x-mimosa-gummies',
    '/green-apple-runtz': '/product/green-apple-runtz',
    '/strawberry-daiquiri-triple-infused-5pk': '/product/strawberry-daiquiri-triple-infused-5pk',
    '/durban-poison-rso-syringe': '/product/durban-poison-rso-syringe',
    '/strawberry-lemonade-x-super-lemon-haze-gummies': '/product/strawberry-lemonade-x-super-lemon-haze-gummies',
    '/gush-mints-49613': '/product/gush-mints-49613',
    '/strawberry-x-gg4-chews': '/product/strawberry-x-gg4-chews',
    '/camino-sours-watermelon-spritz': '/product/camino-sours-watermelon-spritz',
    '/raspberry-x-wedding-cake-gummies': '/product/raspberry-x-wedding-cake-gummies',
    '/camino-sours-citrus-breeze': '/product/camino-sours-citrus-breeze',
    '/petra-pineapple-mints': '/product/petra-pineapple-mints',
    '/ice-cream-cake-rso-syringe': '/product/ice-cream-cake-rso-syringe',
    '/fatso-rosin': '/product/fatso-rosin',
    '/orange-cream-soda-sugar': '/product/orange-cream-soda-sugar',
    '/camino-sours-orchard-peach': '/product/camino-sours-orchard-peach',
    '/faq': '/support/solutions',
    '/terms--conditions.html': '/termsandconditions',
    '/scheduled-menu.html': '/?tab=schedule',
    '/order.html': '/',
    '/home.html': '/',
    '/order-719018.html': '/',
    '/where-bento-delivers.html': '/weed-delivery-near-me',
    '/privacy-policy.html': '/privacypolicy',
    '/slurty-3-76747': '/product/slurty-3-76747',
    '/live-rosin-gmo': '/product/live-rosin-gmo',
    '/pax-diamonds-cookies-creme': '/product/pax-diamonds-cookies-creme',
    '/contact-us.html': '/contact',
    '/pax-diamonds-kushberry-og': '/pax-diamonds-kushberry-og',
    '/super-silver-haze-50896': '/product/pax-diamonds-kushberry-og',
    '/product/mad-lilly-tonic-w-cbn-thc-cbd': '/',
    '/blog/strain-spotlight-moneybagg-runtz': '/blog',
    '/blog/how-to-roll-a-joint': '/blog',
    '/shop/buy-more-save': '/shop/bundle-more-save',
    '/supreme-diesel-premium-rosin': '/product/supreme-diesel-premium-rosin',
    '/strawberry-jam-jam-premium-live-rosin': '/product/strawberry-jam-jam-premium-live-rosin',
    '/shop/half-ounce-mix-n-match': '/',
    '/nightcap-infused-mini-prerolls': '/product/nightcap-infused-mini-prerolls',
    '/berry-250mg-syrup': '/product/berry-250mg-syrup',
    '/berry-1000mg-syrup': 'product/berry-1000mg-syrup',
    '/blog/how-to-best-store-cannabis-cartridges': '/blog',
    '/hardrockz-watermelon': '/product/hardrockz-watermelon',
    '/blog/top-5-ways-to-get-rid-of-weed-smell-indoors': '/blog',
    '/pride-rainbow-sherbet-gummies': '/product/pride-rainbow-sherbet-gummies',
    '/blog/the-mechanics-of-a-vape-pen': '/blog',
    '/blog/what-is-cannabis-shake-and-how-to-use-it': '/blog',
    '/shop/pods-1894': '/categories/vapes',
    '/blog/are-small-nugs-worth-the-money': '/blog',
    '/faq.html': '/',
    '/honeycrisp-indoor-flower': '/product/honeycrisp-indoor-flower',
    '/dr-mooon-beach-break-smalls-14g': '/product/dr-mooon-beach-break-smalls-14g',
    '/dr-mooon-818-3-5g-8th': '/product/dr-mooon-818-3-5g-8th',
    '/bermuda-triangle-50893': '/product/bermuda-triangle-50893',
    '/dr-mooon-818-smalls-14g': '/product/dr-mooon-818-smalls-14g',
    '/dr-mooon-mooon-coin-7g': '/product/dr-mooon-mooon-coin-7g',
    '/dr-mooon-818-7g': '/product/dr-mooon-818-7g',
    '/dr-mooon-mooon-coin-smalls-14g': '/product/dr-mooon-mooon-coin-smalls-14g',
    '/be-social': '/',
    '/shop/2-crystal-clear-1g-cartridges-for-50': '/categories/vapes',
    '/plus-tropical-twist-flow-gummies': '/product/plus-tropical-twist-flow-gummies',
    '/shop/infused-prerolls-and-preroll-packs': '/categories/pre-rolls',
    '/mimosa-92339': '/product/mimosa-92339',
    '/watermelon-1000mg-syrup': '/product/watermelon-1000mg-syrup',
    '/watermelon-250mg-syrup': '/product/watermelon-250mg-syrup',
    '/grape-250mg-syrup': '/grape-250mg-syrup',
    '/blueberry-haze-indoor-smalls': '/product/blueberry-haze-indoor-smalls',
    '/the-original-train-wreck-vape-cartridge': '/product/the-original-train-wreck-vape-cartridge',
    '/grape-1000mg-syrup': '/product1000mg-syrup',
    '/plus-pear-uplift-4-1-thcv': '/product/plus-pear-uplift-4-1-thcv',
    '/dr-mooon-415-3-5g-8th': '/product/dr-mooon-415-3-5g-8th',
    '/dr-mooon-mooon-coin-3-5g-8th': '/product/dr-mooon-mooon-coin-3-5g-8th',
    '/dr-mooon-beach-break-7g': '/product/dr-mooon-beach-break-7g',
    '/little-strong-drink-hibiscus-grape-w-cbn': '/product/little-strong-drink-hibiscus-grape-w-cbn',
    '/be-relaxed': '/product/be-relaxed',
    '/gwp-blackberry-lemon-godspeak-single': '/product/gwp-blackberry-lemon-godspeak-single',
    '/high-purity-thc-gmo-cookies': '/product/high-purity-thc-gmo-cookies',
    '/product/oreo-3pk-infused-prerolls': '/product/oreo-3pk-infused-prerolls',
    '/original': '/product/original',
    '/gg4-22873': '/product/gg4-22873',
    '/sour-apple': '/product/sour-apple',
    '/sci-fi': '/product/sci-fi',
    '/blog/what-are-protabs-who-might-enjoy-them': '/blog',
    '/pineapple-jack': '/product/pineapple-jack',
    '/top-gun': '/product/top-gun',
    '/vanilla': '/product/vanilla',
    '/mint': '/product/mint',
    '/bloom-live-surf-mona-lisa': '/product/bloom-live-surf-mona-lisa',
    '/lemon-jack-live-resin-surf': '/product/lemon-jack-live-resin-surf',
    '/banjo-38223': '/product/banjo-38223',
    '/white-cherry-runtz': '/product/white-cherry-runtz',
    '/shop/all-pods': 'categories/vapes',
    '/shop/all-510-vape-carts': '/categories/vapes',
    '/purple-punch-23403': '/product/purple-punch-23403',
    '/diamond-popz-fresh-frozen-live-resin-badder': '/product/diamond-popz-fresh-frozen-live-resin-badder',
    '/gwp-garlotti': '/product/gwp-garlotti',
    '/gelato-33-75258': '/product/gelato-33-75258',
    '/pax-diamonds-sunset-sherbet': '/product/pax-diamonds-sunset-sherbet',
    '/shop/santa-s-stash-save-up-to-75-off': '/',
    '/neapolitan-73397': '/product/neapolitan-73397',
    '/comfort-body-oil': '/product/comfort-body-oil',
    '/oreo-73299': '/product/oreo-73299',
    '/banana-pudding-73300': '/product/banana-pudding-73300',
    '/high-society-73284': '/product/high-society-73284',
    '/original-73298': '/product/original-73298',
    '/shop/quarters-halves-full-ounces': '/shop/quarters-halves-ounces',
    '/shop/buy-more-and-save-up-to-25-off': '/shop/bundle-more-save',
    '/gdp-91248': '/product/gdp-91248',
    '/shop/buy-more-and-save-up-to-25-off-on-raw-garden': '/',
    '/shop/batteries-vaporizers-and-pax-devices': '/',
    '/carbon21-lemon-walker-diamonds-all-in-one': '/product/carbon21-lemon-walker-diamonds-all-in-one',
    '/divine-storm-full-flower-5pk': '/product/divine-storm-full-flower-5pk',
    '/atlas-granola-clusters-lemon-blueberry': '/product/atlas-granola-clusters-lemon-blueberry',
    '/shop/all-in-one-vaporizers-1895': '/categories/vapes',
    '/fruit-punch-splash-gummies': '/product/fruit-punch-splash-gummies',
    '/gelonade-91410': '/product/gelonade-91410',
    '/bundle/nothing-but-net': '/',
    '/shop/edibles-1858': '/shop/edibles',
    '/vanilla-73297': '/product/vanilla-73297',
    '/lava-cake-14pk-pre-roll': '/product/lava-cake-14pk-pre-roll',
    '/kush-pie': '/product/kush-pie',
    '/shop/purchase-in-bulk-up-to-25-off': '/',
    '/shop/last-call-save-up-to-75-off': '/shop/bundle-more-save',
    '/godspeak-watermelon-basil-single-12oz': '/product/godspeak-watermelon-basil-single-12oz',
    '/sessions-first-class-funk-cake-live-rosin-badder': '/product/sessions-first-class-funk-cake-live-rosin-badder',
    '/mendo-sunset': '/product/mendo-sunset',
    '/passionfruit-haze': '/product/passionfruit-haze',
    '/body-comfort-balm-1-3': '/product/body-comfort-balm-1-3',
    '/marshmallow-og-73283': '/product/marshmallow-og-73283',
    '/oak-lato-1g-dual-pack-pre-roll': '/product/oak-lato-1g-dual-pack-pre-roll',
    '/v-power-1g-dual-pack-pre-roll': '/product/v-power-1g-dual-pack-pre-roll',
    '/plus-raspberry-balance-1-1': '/product/plus-raspberry-balance-1-1',
    '/candy-rain': '/product/candy-rain',
    '/blue-dream-small-bud': '/product/blue-dream-small-bud',
    '/lemon-drop-top-gold-cut': '/product/lemon-drop-top-gold-cut',
    '/dozicot-small-bud': '/product/dozicot-small-bud',
    '/hash-burger-small-bud': '/product/hash-burger-small-bud',
    '/motorhead-small-bud': '/product/motorhead-small-bud',
    '/tiger-moon-live-rosin': '/product/tiger-moon-live-rosin',
    '/peaches-n-cream-infused-prerolls-6pk': '/product/peaches-n-cream-infused-prerolls-6pk',
    '/northern-lights-92022': '/product/northern-lights-92022',
    '/cherry-breeze-diamond-infused-3pk': '/product/cherry-breeze-diamond-infused-3pk',
    '/grease-monkey-infused': '/product/grease-monkey-infused',
    '/cherry-lemon-who-infused': '/product/cherry-lemon-who-infused',
    '/strawberry-lemon-gelato': '/product/strawberry-lemon-gelato',
    '/gush-mints-92020': '/product/gush-mints-92020',
    '/blackberry-og-infused-3pk': '/blackberry-og-infused-3pk',
    '/blog.html': '/blog',
    '/twin-cherriez-infused-preroll': '/product/twin-cherriez-infused-preroll',
    '/sour-gummies-pomberry-5-1-thc-cbn-10-pk': '/product/sour-gummies-pomberry-5-1-thc-cbn-10-pk',
    '/lemon-drop-solventless-live-rosin': '/product/lemon-drop-solventless-live-rosin',
    '/cadillac-rainbowz-small-bud': '/product/cadillac-rainbowz-small-bud',
    '/modified-gushers': '/product/modified-gushers',
    '/watermelon-dreamz-badder': '/product/watermelon-dreamz-badder',
    '/guava-cake-84766': '/product/guava-cake-84766',
    '/white-widow-92023': '/product/white-widow-92023',
    '/papaya-punch-solventless-live-rosin': '/product/papaya-punch-solventless-live-rosin',
    '/goondox-rocks-infused': '/product/goondox-rocks-infused',
    '/space-cake-solventless-live-rosin': '/product/space-cake-solventless-live-rosin',
    '/ghost-train-haze-live-resin': '/product/ghost-train-haze-live-resin',
    '/sessions-mandarin-mac-second-press-live-rosin-badder': '/product/sessions-mandarin-mac-second-press-live-rosin-badder',
    '/product/jelly-sour-waternelon': '/',
    '/wyld-raspberry-gummies': '/product/wyld-raspberry-gummies',
    '/runtz-44-indoor-smalls': '/product/runtz-44-indoor-smalls',
    '/scheduled-menu': '/',
    '/prosecco-3-5g': '/product/prosecco-3-5g',
    '/high-c-3-5g': '/product/high-c-3-5g',
    '/apricot-haze': '/product/apricot-haze',
    '/blog/why-do-we-get-the-munchies': '/blog',
    '/fruit-smoothie': '/product/fruit-smoothie',
    '/white-panther-indoor-smalls': '/product/white-panther-indoor-smalls',
    '/full-spectrum-oil-fso-2-1-ratio-thc-cbd': '/product/full-spectrum-oil-fso-2-1-ratio-thc-cbd',
    '/shop/last-call': '/shop/last-call-save-up-to-25-off',
    '/blueberry-cookies-75260': '/product/blueberry-cookies-75260',
    '/muha-og-mates-infused': '/product/muha-og-mates-infused',
    '/green-apple-runtz-75403': '/product/green-apple-runtz-75403',
    '/bliss-wildberry': '/product/bliss-wildberry',
    '/mango-chelo': '/product/mango-chelo',
    '/kush-mints-57667': '/product/kush-mints-57667',
    '/pearl-og': '/product/pearl-og',
    '/banana-cream-cake-x-jealousy': '/product/banana-cream-cake-x-jealousy',
    '/grape-cake': '/product/grape-cake',
    '/double-dream-diamonds': '/product/double-dream-diamonds',
    '/cotc-og-live-resin-vape-cartridge': '/product/cotc-og-live-resin-vape-cartridge',
    '/galaxy-walker-infused-3pk': '/product/galaxy-walker-infused-3pk',
    '/georgia-peaches-live-resin-vape-cartridge': '/product/georgia-peaches-live-resin-vape-cartridge',
    '/peanut-butter-jelly-terp-diamond-infused-pre-roll': '/peanut-butter-jelly-terp-diamond-infused-pre-roll',
    '/boxed-wine': '/product/boxed-wine',
    '/yuzu-viper': '/product/yuzu-viper',
    '/galaxy-og-saphire-tsu-preroll': '/product/galaxy-og-saphire-tsu-preroll',
    '/blog/how-to-store-cannabis-flower': '/blog',
    '/paradise-og-live-resin': '/product/paradise-og-live-resin',
    '/kiwi-kush': '/product/kiwi-kush',
    '/chem-valley-x-sour-patch-hash-and-diamond-infused-flower':
      '/product/chem-valley-x-sour-patch-hash-and-diamond-infused-flower',
    '/sour-peach-infused-3pk': '/product/sour-peach-infused-3pk',
    '/pineapple-kush': '/product/pineapple-kush',
    '/kiwi-dream': '/product/kiwi-dream',
    '/medellin-jet-fuel-fresh-frozen-live-resin-badder': '/product/medellin-jet-fuel-fresh-frozen-live-resin-badder',
    '/midnight-berry-indica-21907': '/product/midnight-berry-indica-21907',
    '/pineapple-coast-hybrid-21908': '/product/pineapple-coast-hybrid-21908',
    '/winter-mint-indica': '/product/winter-mint-indica',
    '/desert-lime': '/product/desert-lime',
    '/pacific-mango-sativa': '/product/pacific-mango-sativa',
    '/thc-bomb-premium-live-rosin': '/product/thc-bomb-premium-live-rosin',
    '/diamond-peaches-liquid-diamonds-disposable': '/product/diamond-peaches-liquid-diamonds-disposable',
    '/bundle/dash-for-hash-at-75': '/',
    '/shop/bento-daily-deals': '/shop/daily-deals?tab=schedule',
    '/orange-sunset-23728': '/product/orange-sunset-23728',
    '/banana-cream-cake-x-jealousy-premium-nugs': '/product/banana-cream-cake-x-jealousy-premium-nugs',
    '/tropical-runtz-premium-nugs': '/product/tropical-runtz-premium-nugs',
    '/tsumosnacks-snazzle-os-spicy-onion-multiserve': '/product/tsumosnacks-snazzle-os-spicy-onion-multiserve',
    '/camino-10mg-chill-black-cherry-fruit-chews': '/product/camino-10mg-chill-black-cherry-fruit-chews',
    '/tsumosnacks-hint-of-lime-mini-tortilla-rounds-100mg-multiserve':
      '/product/tsumosnacks-hint-of-lime-mini-tortilla-rounds-100mg-multiserve',
    '/sin-mint-cookies': '/product/jiffy-cake-10pk',
    '/jiffy-cake-10pk': '/product/jiffy-cake-10pk',
    '/cookies-n-cream-disposable': '/product/cookies-n-cream-disposable',
    '/cheetah-piss-53630': '/product/cheetah-piss-53630',
    '/platinum-gelato-infused-preroll': '/product/platinum-gelato-infused-preroll',
    '/ramble-on-rose': '/product/ramble-on-rose',
    '/grape-gas-x-ogk-hb': '/product/grape-gas-x-ogk-hb',
    '/zruntz-bigs': '/product/zruntz-bigs',
    '/sour-apple-x-do-si-dos-gummies': '/product/sour-apple-x-do-si-dos-gummies',
    '/gmoasis': '/product/gmoasis',
    '/oasis-10pk': '/product/oasis-10pk',
    '/camino-10mg-uplifting-forest-berry-fruit-chews': '/product/camino-10mg-uplifting-forest-berry-fruit-chews',
    '/camino-sours-10mg-sleep-blackberry-dream-gummies': '/product/camino-sours-10mg-sleep-blackberry-dream-gummies',
    '/shop/last-call-save-up-to-50-off': '/categories/deals',
    '/camino-10mg-bliss-orchard-apple-fruit-chews': '/product/camino-10mg-bliss-orchard-apple-fruit-chews',
    '/purple-punch-liquid-diamonds': '/product/purple-punch-liquid-diamonds',
    '/sherbet-x-purple-milk-sugar': '/product/sherbet-x-purple-milk-sugar',
    '/s/1nv9npvxxj8l7vhd9pitgivq69z4q3z7': '/',
    '/wyld-elderberry-gummies-cbn': '/product/wyld-elderberry-gummies-cbn',
    '/lost-farm-papaya-x-grape-octane-live-resin-chews': '/product/lost-farm-papaya-x-grape-octane-live-resin-chews',
    '/apple-fritter-38759': '/product/apple-fritter-38759',
    '/sunset-sherb': '/product/sunset-sherb',
    '/chem-driver-97631': '/product/chem-driver-97631',
    '/capital-haze': '/product/capital-haze',
    '/gelato-23148': '/product/gelato-23148',
    '/carnival-candy-7pk': '/product/carnival-candy-7pk',
    '/fruit-gummies': '/product/fruit-gummies',
    '/shop/cartridges-1893': '/shop/cartridges',
    '/zkittles-x-runtz': '/product/zkittles-x-runtz',
    '/black-cherry-lemonade-indoor-smalls': '/product/black-cherry-lemonade-indoor-smalls',
    '/happy-camper-infused-mini-prerolls': '/product/happy-camper-infused-mini-prerolls',
    '/portable-case-rose-edition': '/product/portable-case-rose-edition',
    '/shop/flower-prerolls-and-preroll-packs': '/shop/infused-pre-rolls-and-pre-roll-packs',
    '/midnight-cherry-gummies': '/product/midnight-cherry-gummies',
    '/sun-smoke-pre-ground-flower-indica': '/product/sun-smoke-pre-ground-flower-indica',
    '/slurricrashers': '/product/slurricrashers',
    '/shop/concentrates-1873': '/shop/concentrates',
    '/shop/drinkables': '/shop/drinks',
    '/portable-case-green-edition': '/product/portable-case-green-edition',
    '/tropical-mind-balance': '/product/tropical-mind-balance',
    '/bundle/march-mayhem': '/',
    '/silver-stiiizy-power-case': '/product/silver-stiiizy-power-case',
    '/sun-smoke-sativa': '/product/sun-smoke-sativa',
    '/shop/santa-s-stash': '/',
    '/strawberry-full-mind': '/product/strawberry-full-mind',
    '/strawberry': '/product/strawberry',
    '/lemon-lime-thc-live-beverage-enhancer': '/product/lemon-lime-thc-live-beverage-enhancer',
    '/airgraft-2-battery': '/product/airgraft-2-battery',
    '/portable-case-orange-edition': '/product/portable-case-orange-edition',
    '/portable-case-purple-edition': '/product/portable-case-purple-edition',
    '/stiiizy-battery-starter-kit': '/product/stiiizy-battery-starter-kit',
    '/strawberry-lemonade-52667': '/product/strawberry-lemonade-52667',
    '/portable-case-rose-gold-edition': '/product/portable-case-rose-gold-edition',
    '/bundle/stiiizy-bundle': '/brands/stiiizy',
    '/portable-case-red-edition': '/product/portable-case-red-edition',
    '/fiancee': '/product/fiancee',
    '/portable-case-camo-edition': '/product/portable-case-camo-edition',
    '/sherb-crasher': '/product/sherb-crasher',
    '/live-resin-strawberry-basil-spritz-4-pack': '/product/live-resin-strawberry-basil-spritz-4-pack',
    '/live-resin-strawberry-basil-spritz-single-cans': '/product/live-resin-strawberry-basil-spritz-single-cans',
    '/releaf-patch-cbd': '/product/releaf-patch-cbd',
    '/mimosa-california-sauce-live-resin': '/product/mimosa-california-sauce-live-resin',
    '/cacao-keto-bites': '/product/cacao-keto-bites',
    '/strawberry-lime-jellies': '/product/strawberry-lime-jellies',
    '/25-1-cbd-rich': '/product/25-1-cbd-rich',
    '/gush-mints-49613': '/product/gush-mints-49613',
    '/women-s-farm-jacket-gray-xl': '/product/women-s-farm-jacket-gray-xl',
    '/mango-kush': '/product/mango-kush',
    '/shop/purchase-in-bulk-up-to-25-off': '/categories/deals',
    '/lemon-cherry-gelato-95201': '/product/lemon-cherry-gelato-95201',
    '/classic-variety-pack-gummies': '/product/classic-variety-pack-gummies',
    '/ric-flair-drip-hybrid-flower': '/product/ric-flair-drip-hybrid-flower',
    '/men-s-farm-jacket-green-l': '/product/men-s-farm-jacket-green-l',
    '/blue-stiiizy-power-case': '/product/blue-stiiizy-power-case',
    '/men-s-farm-jacket-green-m': '/product/men-s-farm-jacket-green-m',
    '/men-s-farm-jacket-gray-xl': '/product/men-s-farm-jacket-gray-xl',
    '/beed-machine-pink': '/product/beed-machine-pink',
    '/flos-kush-cake-indoor-smalls': '/product/flos-kush-cake-indoor-smalls',
    '/bic-lighter-zipps': '/product/bic-lighter-zipps',
    '/rolling-paper-zipps': '/',
    '/green-crack-96836': '/product/green-crack-96836',
    '/vanilla-custard': '/product/vanilla-custard',
    '/flos-gas-monkey-indoor-smalls': '/product/flos-gas-monkey-indoor-smalls',
    '/peach-creme-gelato-terp-tonic': '/product/peach-creme-gelato-terp-tonic',
    '/sleep-dose-pen-100': '/product/sleep',
    '/arouse-plus-dose-pen-100': '/product/arouse-plus-dose-pen-100',
    '/birthday-cake': '/product/birthday-cake',
    '/laughing-buddah': '/product/laughing-buddah'
  }
};

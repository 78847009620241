import React, { useEffect, useState, useContext } from 'react';
import findIndex from 'lodash/findIndex';
import toFixedFloat from '../helpers/toFixedFloat';

import appcontext from '../Context/appContext';
import { useSelector } from 'react-redux';

const Price = React.memo(
  ({
    productDetails: {
      product_currency: productCurrency,
      category_currency: categoryCurrency,
      product_details_id: productId,
      price = 0,
      asap,
      schedule,
      menu,
      bulk_base_price: bulkBasePrice,
      product_name: productName
    },
    priceClassName,
    orignalPrice = 0,
    categorySalePriceId = null,
    quantity = 1,
    hideStrikePrice = false,
    preventDiscountFormat = false,
    otherAttributes
  }) => {
    // const {
    //   state: { user: { couponData = {} } = {} }
    // } = useContext(appcontext);
    const couponData = useSelector(state => state.user.couponData);
    const [discountedPrice = 0, setDiscountedPrice] = useState(price);
    const currency = productCurrency || categoryCurrency;

    function applyDiscount() {
      const { discount = 0, slash_price: slashPrice } = couponData;
      if (slashPrice) {
        switch (Number(couponData.eligible_delivery_type)) {
          case 1:
            if (asap && menu != 2) {
              return (price * ((100 - discount) / 100)).toFixed(2);
            }
            return price;
          case 2:
            if (schedule && menu != 1) {
              return (price * ((100 - discount) / 100)).toFixed(2);
            }
            return price;
          default:
            return (price * ((100 - discount) / 100)).toFixed(2);
        }
      } else {
        return price;
      }
    }

    useEffect(() => {
      const calculatePrice = () => {
        let newDiscountedPrice = price;
        if ((orignalPrice && orignalPrice !== price && !bulkBasePrice) || categorySalePriceId !== null) {
          newDiscountedPrice = price;
        } else if (Array.isArray(couponData.product_ids) && couponData.product_ids.length) {
          if (findIndex(couponData.product_ids, o => parseInt(o, 10) === parseInt(productId, 10)) > -1) {
            newDiscountedPrice = applyDiscount();
          }
        } else {
          newDiscountedPrice = price;
        }
        setDiscountedPrice(parseFloat(newDiscountedPrice));
      };
      calculatePrice();
    }, [couponData, price, productId, orignalPrice, bulkBasePrice, menu]);

    if (price != discountedPrice || (orignalPrice && price != orignalPrice)) {
      return (
        <>
          <span {...otherAttributes} className={`price discount ${priceClassName || ''}`}>
            {`${currency}${toFixedFloat(discountedPrice * quantity)}`}
          </span>

          {!hideStrikePrice ? (
            <small className="strike-text ml-1">
              {`${currency}${orignalPrice ? toFixedFloat(orignalPrice * quantity) : toFixedFloat(price * quantity)}`}
              &nbsp;
            </small>
          ) : null}
        </>
      );
    }
    return (
      <div {...otherAttributes} className={`price ${priceClassName || ''}`}>
        {currency}
        {toFixedFloat(price * quantity)}
      </div>
    );
  }
);

export default Price;

// redux/rewardsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const rewardsSlice = createSlice({
    name: 'rewards',
    initialState: {
        isWalletCreditShown: false,
        isFilledRewardNotifcation: false,
        currentAvailedWalletReward: 0,
        currentUnlockedTier: 0,
        currentSubtotal: 0,
        requiredSubtotal: 0,
        rewardAmount: 0,
    },
    reducers: {
        setWalletCreditShown(state, action) {
            state.isWalletCreditShown = action.payload;
        },
        toggleWalletCreditShown(state) {
            state.isWalletCreditShown = !state.isWalletCreditShown;
        },
        setFilledRewardNotification(state, action) {
            state.isFilledRewardNotifcation = action.payload;
        },
        setWalletRewards(state, action) {
            const { walletRewards = {}, nextReward = {}, currentSubtotal = 0 } = action.payload;

            state.currentAvailedWalletReward = walletRewards.amount || 0;
            state.currentUnlockedTier = walletRewards.tier_unlock_subtotal_amount || 0;
            state.currentSubtotal = currentSubtotal || 0;
            state.requiredSubtotal = nextReward.required_subtotal || 0;
            state.rewardAmount = nextReward.amount || 0;
        }
    }
});

export const {
    setWalletCreditShown,
    toggleWalletCreditShown,
    setFilledRewardNotification,
    setWalletRewards,
} = rewardsSlice.actions;

export default rewardsSlice.reducer;

function setRollbarWarning(k, e) {
  if (typeof window.Rollbar === 'object') {
    if (window.rollbarQueue && window.rollbarQueue.length) {
      setTimeout(() => {
        window.rollbarQueue.forEach(({ k, e }) => {
          window.Rollbar.error(k, e);
        });
        window.rollbarQueue = [];
      }, 1);
    }
    window.Rollbar.error(k, e);
  } else {
    if (!window.rollbarQueue) {
      window.rollbarQueue = [];
    }
    window.rollbarQueue.push({ k, e });
  }
}

export { setRollbarWarning };

import React from 'react';
import dynamic from 'next/dynamic';

import { GRASSDOOR } from '../../constants/website';

// const DTCBundle = dynamic(() => import('./notGrassdoor/BundleDTC'));
const DTCBundle = dynamic(() => import('../ProductBundleContainer'));
// const GrassdoorBundle = dynamic(() => import('./grassdoor/BundleGrassdoor'));
const GrassdoorBundle = dynamic(() => import('../ProductBundleContainer'));

const Bundle = props => {
  switch (process.env.NEXT_PUBLIC_APP) {
    case GRASSDOOR:
      return <GrassdoorBundle grassdoorBundle {...props} />;
    default:
      return <DTCBundle DTCBundle {...props} />;
  }
};

export default Bundle;

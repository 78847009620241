import React, { useState, useContext } from 'react';
import BundlePrice from '../../BundlePrice';
import Price from '../../Price';
import Loader from '../../Loader';
import * as WEBSITES from '../../../constants/website';

import appContext from '../../../Context/appContext';

import { useModifyCart } from '../../../hooks/cart';
import { useDeliveryDetails, useZipCodeStatus } from '../../../hooks/app';
import notificationTypes from '../../../constants/modalNotificationConst';
import { setRollbarWarning } from '../../../helpers/rollBar';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotificationOverlap } from '../../../redux/slices/modalSlice';

const minQuantity = 1;

const AddToCartWidget = ({
  data: product,
  page,
  scheduleType = null,
  className,
  showSoldOut = false,
  maxQuantity = null,
  hideToast = false
}) => {
  const maxQuantityLimit = maxQuantity || product.product_max_quantity;
  // const {
  //   // state: {
  //   //   user: { isLoggedIn },
  //   //   cart: { cartData: { cart_items: cartItems = [], amount = 0 } = {}, updatingProductsList = [] }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const {
    cartData: { cart_items: cartItems = [], amount = 0 } = {}, updatingProductsList = []
  } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()

  const {
    data: { validZipcode }
  } = useZipCodeStatus();

  const { updateCartItem, addToCart } = useModifyCart({ page });

  const { data: { isWalletCreditWithPurchaseApplicable } = {} } = useDeliveryDetails();

  const [quantity, setQuantity] = useState(minQuantity);

  const {
    bundle_id: bundleID,
    original_price: originalPrice,
    bundle_selling_price: bundleSellingPrice,
    product_currency: productCurrency,
    is_deal_available: isDealAvailable,
    is_pre_deal_available: isPreDealAvailable,
    price_without_deal: priceWithoutDeal,
    theme = { backgroundColor: '#f3efa1', foregroundColor: '#666ccd' },
    is_sold_out: isSoldOut,
    limit_exceeded: limitExceeded
  } = product;

  const isSomeDealAvailable = isDealAvailable || isPreDealAvailable;
  const bulkBasePrice = product && product.bulk_base_price ? product.bulk_base_price : false;
  const orignalPrice = isSomeDealAvailable ? priceWithoutDeal : bulkBasePrice;

  const productPresent = product && !product.is_sold_out && !product.is_disabled;

  const showLoader = updatingProductsList.includes(product.bundle_id ? product.bundle_id : product.product_details_id);

  let hideQuantity = false;
  if (product.offer_category_id && !product.is_deal_available && !product.is_pre_deal_available) {
    hideQuantity = true;
  }
  if (product.bulk_prices) {
    hideQuantity = true;
  }

  function changeQuantity(e, changeType) {
    if (changeType === '-') {
      if (quantity !== minQuantity) {
        setQuantity(quantity - 1);
      }
    } else if (changeType === '+') {
      setQuantity(quantity + 1);
    }
  }

  async function clickAddToCart() {
    let itemInCart = null;
    if (product.bundle_id) {
      itemInCart = cartItems.find(item => item.bundle_id == product.bundle_id);
    } else {
      itemInCart = cartItems.find(item => item.product_details_id == product.product_details_id);
    }
    if (!itemInCart) {
      if (!validZipcode) {
        dispatchRedux(pushNotificationOverlap({ type: notificationTypes.deliverAddressPopup }))
      } else {
        await addToCart({
          product: {
            ...product,
            quantity: !hideQuantity ? quantity : undefined
          },
          callback: error => {
            if (error) {
              // dismiss();
            }
          },
          list: page,
          hideSuccess: hideToast
        });
        setQuantity(minQuantity);
      }
    } else {
      try {
        let walletWithPurchaseProps = null;

        if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
          walletWithPurchaseProps = {
            currentProductDetails: product || {},
            cartSubtotal: amount,
            dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
            requestedProductQuantity: !hideQuantity ? itemInCart.quantity + quantity : product.quantity,
            onRemove: () =>
              updateCartItem({
                product: { ...product, quantity: !hideQuantity ? itemInCart.quantity + quantity : undefined },
                hideSuccess: hideToast
              })
          };
        }

        await updateCartItem({
          product: { ...product, quantity: !hideQuantity ? itemInCart.quantity + quantity : undefined },
          hideSuccess: hideToast,
          walletWithPurchaseProps
        });
        setQuantity(minQuantity);
      } catch (error) {
        setRollbarWarning('AddToCartWidget.js_clickAddToCart_catch', error);
        console.error('AddToCartWidget.js_clickAddToCart_catch', error);
      }
    }
  }

  function renderSoldoutText() {
    let soldOutText = 'Sold Out';
    if (productPresent && limitExceeded) {
      soldOutText = 'Limit Exceeded';
    }
    return soldOutText;
  }

  return (
    <div
      className={`product-tile-controls drinkcann-product-controls ${process.env.NEXT_PUBLIC_APP === WEBSITES.KOAN ? 'koan-product-controls' : ''
        } ${bundleID ? 'bundle-control' : ''} ${isSoldOut || limitExceeded ? 'opacity-5 disable-click' : ''} ${className}`}
    >
      {hideQuantity ? null : (
        <div className="d-counter d-flex justify-content-center">
          <button
            type="button"
            className={`btn minus-btn ${quantity === minQuantity ? 'opacity-5 disable-click' : ''}`}
            onClick={e => changeQuantity(e, '-')}
          >
            -
          </button>
          <div className="count">{quantity}</div>
          <button
            type="button"
            className={`btn ${quantity === maxQuantityLimit ? 'opacity-5 disable-click' : ''}`}
            onClick={e => changeQuantity(e, '+')}
          >
            +
          </button>
        </div>
      )}
      <div className="d-btn-container">
        <button
          type="button"
          className={`btn btn-outline-primary w-100 height-100 ${!isSoldOut && !limitExceeded && quantity === 0 ? 'opacity-5 disable-click' : ''
            }`}
          onClick={clickAddToCart}
          test-id="#pdp_add_to_cart"
        >
          {showLoader ? (
            <Loader className="fs-14" />
          ) : (
            <>
              {bundleID ? (
                <BundlePrice
                  className="price"
                  currency={productCurrency}
                  orignalPrice={originalPrice}
                  price={bundleSellingPrice}
                  quantity={quantity === 0 ? 1 : quantity}
                />
              ) : (
                <Price productDetails={product} orignalPrice={orignalPrice} quantity={quantity === 0 ? 1 : quantity} />
              )}
              <div className="atc-text">
                <span className="dash">&nbsp;-&nbsp;</span>
                {(!productPresent || product.limit_exceeded) && showSoldOut ? renderSoldoutText() : 'Add to Cart'}
              </div>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default AddToCartWidget;

import React, { Component, Fragment } from 'react';
import findIndex from 'lodash/findIndex';
import Collapse, { Panel } from 'rc-collapse';
import { connectRefinementList, ClearRefinements } from 'react-instantsearch-dom';

const RefinementList = ({ items, refine, attribute }) => {
  if (items && items.length) {
    let title = '';
    switch (attribute) {
      case 'brands':
        title = 'Brands';
        break;
      case 'full_shop_categories':
        title = 'Categories';
        break;
      case 'deviceType':
        title = 'Device Type';
        break;
      default:
        break;
    }
    return (
      <Fragment>
        <Collapse defaultActiveKey="Price">
          <Panel header={title} key={attribute}>
            <div className="pill-container">
              {items.map(item => (
                <div key={`${item.label}-${item.isRefined}`} className="custom-checkbox ">
                  <input
                    type="checkbox"
                    checked={item.isRefined}
                    id={`${item.label}-${item.count}`}
                    onClick={event => {
                      event.preventDefault();
                      refine(item.value);
                    }}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-for */}
                  <label htmlFor={`${item.label}-${item.count}`}>{`${item.label} (${item.count})`}</label>
                </div>
              ))}
            </div>
          </Panel>
        </Collapse>
      </Fragment>
    );
  }
  return null;
};
const CustomRefinementList = connectRefinementList(RefinementList);

class FilterContent extends Component {
  state = {
    filterList: []
  };

  async componentDidMount() {
    const { filterList } = this.props;
    if (filterList) {
      this.setState({ filterList: JSON.parse(JSON.stringify(filterList)) });
    }
  }

  onChange = event => {
    const { filterList } = this.state;
    const filters = JSON.parse(JSON.stringify(filterList));
    const index = findIndex(filters, { name: event.target.name });
    if (index !== -1) {
      if (filters[index].type === 'sort_key') {
        filters[index].values.forEach((element, i) => {
          if (findIndex(filters[index].values, { id: event.target.id }) !== i) {
            element.checked = false;
          } else {
            element.checked = true;
          }
        });
      } else {
        const i = findIndex(filters[index].values, { id: event.target.id });
        if (i !== -1) {
          filters[index].values[i].checked = !filters[index].values[i].checked;
        }
      }
    }
    this.setState({ filterList: JSON.parse(JSON.stringify(filters)) });
  };

  renderSidebarContent = () => {
    const { filterList } = this.state;
    return filterList.map(item => {
      if (item.values && item.values.length) {
        return (
          <Panel header={item.title} key={item.title}>
            {item.values.map(subItem => {
              return (
                <div className={`custom-${subItem.type} `}>
                  <input {...subItem} onChange={this.onChange} />
                  <label htmlFor={subItem.id}>{subItem.value}</label>
                </div>
              );
            })}
          </Panel>
        );
      }
      return null;
    });
  };

  handleClose = () => {
    const { toggleFilterDrawer } = this.props;
    toggleFilterDrawer();
  };

  render() {
    const { locationId } = this.props;
    return (
      <div className="sidebarContent">
        <div className="sidebarBanner d-flex align-items-center justify-content-between">
          <h5 className="title m-0">
            <i className="icon-filter-funnel" />
            <span className="bold">&nbsp;Filters </span>
          </h5>
          <div>
            <ClearRefinements
              className="d-inline-block mt-2 mt-lg-0"
              transformItems={items => {
                return items.filter(
                  ({ attribute }) =>
                    attribute !== 'locations' &&
                    attribute !== `location_specific_details.${locationId}.disabled` &&
                    attribute !== `location_specific_details.${locationId}.delivery` &&
                    attribute !== 'enabled_categories' &&
                    attribute !== 'is_unlisted' &&
                    attribute !== `domain_location_specific_details.${locationId}.is_pre_deal_available`
                );
              }}
            />
            <button type="button" className="btn d-lg-none" onClick={this.handleClose}>
              <i className="icon-close white-color " />
            </button>
          </div>
        </div>
        <div className="menu-list" test-id="#sidebar_menu_list">
          <CustomRefinementList attribute="brands" showMoreLimit={30} limit={30} />
          <CustomRefinementList attribute="full_shop_categories" showMoreLimit={30} limit={30} />
          <CustomRefinementList attribute="deviceType" showMoreLimit={30} limit={30} />
        </div>
        {/* <div className="actions-btn row m-0 d-lg-none">
          <div className="col pl-4">
            <button
              type="button"
              className="btn btn-outline-primary rouded-button  btn-block f-close-btn"
              onClick={this.handleClose}
            >
              Close
            </button>
          </div>
        </div> */}
      </div>
    );
  }
}

export default FilterContent;

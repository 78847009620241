import { ASAP, SCHEDULE } from '../constants/deliveryType';
import { FLOWERANDEDIBLES, GRASSDOOR } from '../constants/website';

export default function getDefaultTab(deliveryResponse) {

  const isInRange = (value, point1, point2) => {
    if (value >= point1 && value <= point2) {
      return true;
    }
    return false;
  };

  if ([GRASSDOOR, FLOWERANDEDIBLES].includes(process.env.NEXT_PUBLIC_APP) || process.env.NEXT_PUBLIC_DEFAULT_SITES) {
    if (deliveryResponse) {
      const { lastSlotCutOff, asapStartTime, asapEndTime, asapEnabled } = deliveryResponse;
      if (!asapEnabled) return SCHEDULE
      const isCutOffInAsapRange = isInRange(lastSlotCutOff, asapStartTime, asapEndTime);

      let asapDefaultRange = [];

      if (isCutOffInAsapRange && lastSlotCutOff) {
        asapDefaultRange = [asapStartTime, asapEndTime];
      } else if (!lastSlotCutOff || lastSlotCutOff < asapStartTime) {
        asapDefaultRange = [asapStartTime, asapEndTime];
      }

      const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });
      if (isInRange(currentTime, asapDefaultRange[0], asapDefaultRange[1])) {
        return ASAP;
      }
      return SCHEDULE;
    }
  }

  return SCHEDULE;
}
// export default function getDefaultTab(deliveryResponse) {

//   const isInRange = (value, point1, point2) => {
//     if (value >= point1 && value <= point2) {
//       return true;
//     }
//     return false;
//   };

//   if ([GRASSDOOR, FLOWERANDEDIBLES].includes(process.env.NEXT_PUBLIC_APP) || process.env.NEXT_PUBLIC_DEFAULT_SITES) {
//     if (deliveryResponse) {
//       const { lastSlotCutOff, asapStartTime, asapEndTime, asapEnabled } = deliveryResponse;
//       if (!asapEnabled) return SCHEDULE
//       const isCutOffInAsapRange = isInRange(lastSlotCutOff, asapStartTime, asapEndTime);

//       let asapDefaultRange = [];

//       if (isCutOffInAsapRange && lastSlotCutOff) {
//         asapDefaultRange = [lastSlotCutOff, asapEndTime];
//       } else if (!lastSlotCutOff || lastSlotCutOff < asapStartTime) {
//         asapDefaultRange = [asapStartTime, asapEndTime];
//       }

//       const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });


//       console.log("default checks", asapStartTime, isInRange(currentTime, asapDefaultRange[0], asapDefaultRange[1]));
//       if (isInRange(currentTime, asapDefaultRange[0], asapDefaultRange[1])) {
//         return ASAP;
//       }
//       return SCHEDULE;
//     }
//   }

//   return SCHEDULE;
// }
export function getTab({ paramsString, deliveryResponse }) {
  const searchParams = new URLSearchParams(paramsString);

  return searchParams.get('tab') || getDefaultTab(deliveryResponse);
}

export function getMenuType(menu) {
  if (menu === 2) {
    return SCHEDULE;
  }
  return ASAP;
}

import React from 'react';

import { GRASSDOOR } from '../../constants/website';

import GDStaticFooter from './grassdoor/GDstaticFooter';
import BentoStaticFooter from './bento/BentoStaticFooter';

const CommonStaticFooter = props => {
  switch (process.env.NEXT_PUBLIC_APP) {
    case GRASSDOOR:
      return <GDStaticFooter {...props} />;
    default:
      if (process.env.NEXT_PUBLIC_DEFAULT_SITES) {
        return <BentoStaticFooter {...props} />;
      }
      return null;
  }
};

export default CommonStaticFooter;

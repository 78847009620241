import findIndex from 'lodash/findIndex';

import { isSortByDealFilterApplicable } from '../constants/feature';
import * as WEBSITES from '../constants/website';

export default function getFilterQueryString(filters, encodeUrl = false, isDealsPage = false) {
  let filtersQueryString = '';
  if (filters) {
    const index = findIndex(filters, { name: 'product_price' });
    let sortFilter = '';
    if (filters[index]) {
      sortFilter =
        findIndex(filters[index].values, { checked: true }) !== (process.env.NEXT_PUBLIC_APP == WEBSITES.GRASSDOOR ? -1 : 0)
          ? `sort_key=${filters[index].values[findIndex(filters[index].values, { checked: true })]?.name}&order=${filters[index].values[findIndex(filters[index].values, { checked: true })]?.id
          }&`
          : '';
    }

    const search = [];
    const idIndex = findIndex(filters, { name: 'id' });
    const idIndexStrain = findIndex(filters, { name: 'strains' });
    const idIndexBrand = findIndex(filters, { name: 'brands' });
    const idIndexDeviceType = findIndex(filters, { name: 'deviceTypes' });
    const valuesIndexPriceRangeType = findIndex(filters, { name: 'priceRange' });
    const ids = [];
    const strainIds = [];
    const brandIds = [];
    const deviceTypeIds = [];
    if (filters[idIndex]) {
      filters[idIndex].values.forEach(element => {
        if (element.checked) {
          ids.push(element.id.slice(element.name.length + 1));
        }
      });
    }
    if (filters[idIndexStrain]) {
      filters[idIndexStrain].values.forEach(element => {
        if (element.checked) {
          strainIds.push(element.id.slice(element.name.length + 1));
        }
      });
    }
    if (filters[idIndexBrand]) {
      filters[idIndexBrand].values.forEach(element => {
        if (element.checked) {
          brandIds.push(element.id.slice(element.name.length + 1));
        }
      });
    }
    if (filters[idIndexDeviceType]) {
      filters[idIndexDeviceType].values.forEach(element => {
        if (element.checked) {
          deviceTypeIds.push(element.id);
        }
      });
    }
    if (filters[valuesIndexPriceRangeType] && filters[valuesIndexPriceRangeType].isSet) {
      search.push({
        price: [filters[valuesIndexPriceRangeType].values[0].value, filters[valuesIndexPriceRangeType].values[1].value]
      });
    }
    if (ids && ids.length > 0) {
      search.push({ fs_cat_id: ids });
    }
    if (strainIds && strainIds.length > 0) {
      search.push({ pst_id: strainIds });
    }
    if (brandIds && brandIds.length > 0) {
      search.push({ brand_id: brandIds });
    }
    if (deviceTypeIds && deviceTypeIds.length > 0) {
      search.push({ device_id: deviceTypeIds });
    }
    const searchFilter = search && search.length > 0 ? `search=${JSON.stringify(search)}` : '';

    filtersQueryString = `${sortFilter}${encodeUrl ? encodeURI(searchFilter) : searchFilter}`;
  }
  if (!filtersQueryString && isDealsPage) {
    filtersQueryString = `sort_key=product_price&order=asc`;
  }

  if (!filtersQueryString && isSortByDealFilterApplicable) {
    filtersQueryString = `sort_key=deals_first&order=asc`;
  }

  if (filtersQueryString.slice(-1) === '&') {
    filtersQueryString = filtersQueryString.slice(0, -1);
  }
  return filtersQueryString;
}

export const GRASSDOOR = 'grassdoor';
export const LOWELL = 'lowell';
export const KIVA = 'kiva';
export const DOSIST = 'dosist';
export const CANNABIOTIX = 'cannabiotix';
export const CANNACRAFT = 'cannacraft';
export const SELECTCANNABIS = 'selectcannabis';
export const DRINKCANN = 'drinkcann';
export const KIKOKO = 'kikoko';
export const CANNDESCENT = 'canndescent';
export const PAPAANDBARKLEY = 'papaandbarkley';
export const CLAYBOURNE = 'claybourne';
export const ZIPPS = 'zipps';
export const MAVENCANNABISCLUB = 'mavencannabisclub';
export const PABSTLABS = 'pabstlabs';
export const PLUSPRODUCTS = 'plusproducts';
export const SMOAKLAND = 'delivermd';
export const MUNCHEEZ = 'muncheez';
export const KOAN = 'koan';
export const WUNDER = 'wunder';
export const HERVE = 'hervedibles';
export const TIMELESSHOB = 'timelesshob';
export const PHINEST = 'phinest';
export const THEPLUG = 'theplug';
export const GOVANA = 'govana';
export const GARDENHOUSE = 'gardenhouse';
export const BEED = 'beed';
export const PACIFICSTONE = 'pacificstone';
export const CANNADIPSTHC = 'cannadipsthc';
export const PERFECTBLENDS = 'perfectblends';
export const AUTUMNBRANDS = 'autumnbrands';
export const GRIZZLYPEAK = 'grizzlypeak';
export const POTLI = 'potli';
export const KURVANA = 'kurvana';
export const BENTO = 'bento';
export const CLOUD11 = 'cloud11';
export const STONEROAD = 'stoneroad';
export const DREWMARTIN = 'drewmartin';
export const ALTLIFE = 'altlife';
export const SFROOTS = 'sfroots';
export const HELLOAGAIN = 'helloagainproducts';
export const VETCBD = 'vetcbd';
export const BUYPROOFNOW = 'buyproofnow';
export const TRYCHEMISTRY = 'trychemistry';
export const WESTCOASTTRADING = 'westcoasttrading';
export const HUMBOLDTFAMILYFARMS = 'humboldtfamilyfarms';
export const MAISONBLOOM = 'maisonbloom';
export const BOASTCANNABIS = 'boastcannabis';
export const PUREBEAUTY = 'purebeauty';
export const CREAMOFTHECROP = 'creamofthecrop';
export const TEXTJOHNNIE = 'textjohnnie';
export const RIGHTRATIO = 'rightratio';
export const SULODISTRO = 'sulodistro';
export const COSMICFOG = 'cosmicfog';
export const MUHAMEDS = 'muhameds';
export const WAFERZ = 'waferz';
export const MADEBYPAX = 'madebypax';
export const OAKFRUITLAND = 'oakfruitland';
export const GETHERB = 'getherb';
export const FLOWERHEAD = 'flowerhead';
export const WELCOMETOWEEDLAND = 'welcometoweedland';
export const GETOOKA = 'getooka';
export const GANJAGODDESS = 'ganjagoddess';
export const SMOKELAND = 'smokeland';
export const FLOWERANDEDIBLES = 'flowerandedibles'
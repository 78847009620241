import React from 'react';

const FullscreenLoader = ({ hasTransparentBg }) => (
  <div className={`loader-full-screen ${hasTransparentBg ? 'transparent-bg' : ''}`}>
    <div className="loader loader-3dots">
      <span />
      <span />
      <span />
    </div>
  </div>
);

export default FullscreenLoader;

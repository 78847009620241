import React from 'react'
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-core';
import SearchInputBox from './SearchInputBox';

const searchClient = algoliasearch(process.env.NEXT_PUBLIC_SEARCH_CONTAINER, process.env.NEXT_PUBLIC_SEARCH_KEY);

export default function SearchInputBoxWrapper({ removeBg }) {
  return (
    <div id="search-category-wrapper" className={`search-category-wrapper ${removeBg ? "remove-bg" : ""}`}>
      <>
        <div className={`searchBox`}>
          <InstantSearch indexName={process.env.NEXT_PUBLIC_SEARCH_INDICE} searchClient={searchClient}>
            <SearchInputBox defaultSearchTab="schedule" />
          </InstantSearch>
        </div>
      </>
    </div>
  )
}

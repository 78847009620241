import * as WEBSITES from '../constants/website';

/**
 * Please make sure that the multiplier response is not in decimal
 */
const aspectHeight = 1.2;
const aspectWidth = 1.2;
const mobAspectHeight = 2;
const mobAspectWidth = 2;

function getInImgFormate(url = '', imgFilter = 'png') {
  let banner = {
    desktop: '',
    mobile: '',
    secondary: '',
    desktopBanner: '',
    desktopBannerFluid: ''
  };
  const imageQuality = 65;
  if (url) {
    if (process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR) {
      const mobArr = url.split('/');
      const desktopArr = url.split('/');
      const secArr = url.split('/');
      const dBArr = url.split('/');
      const dBFluidArr = url.split('/');
      // desktopArr.splice(3, 0, `filters:format(${imgFilter})`); // For desktop main banne
      // mobArr.splice(3, 0, `${375 * mobAspectWidth}x${200 * mobAspectHeight}/filters:format(${imgFilter})`); // For mobile main banner
      // secArr.splice(3, 0, `${365 * aspectWidth}x${150 * aspectHeight}/filters:format(${imgFilter})`); // For desktop secondary banner
      // dBArr.splice(3, 0, `${parseInt(760 * 1.5)}x${parseInt(315 * 1.5)}/filters:format(${imgFilter})`);
      // dBFluidArr.splice(3, 0, `${parseInt(1140 * 1.5)}x${parseInt(315 * 1.5)}/filters:format(${imgFilter})`);
      desktopArr.splice(3, 0, `filters:format(${imgFilter})/filters:quality(${imageQuality})`); // For desktop main banne
      mobArr.splice(3, 0, `filters:format(${imgFilter})/filters:quality(${imageQuality - 10})`); // For mobile main banner
      secArr.splice(3, 0, `filters:format(${imgFilter})/filters:quality(${imageQuality})`); // For desktop secondary banner
      dBArr.splice(3, 0, `filters:format(${imgFilter})/filters:quality(${imageQuality})`);
      dBFluidArr.splice(3, 0, `filters:format(${imgFilter})`);
      banner = {
        desktop: desktopArr.join('/'),
        mobile: mobArr.join('/'),
        secondary: secArr.join('/'),
        desktopBanner: dBArr.join('/'),
        desktopBannerFluid: dBFluidArr.join('/')
      };
    } else {
      const arr = url.split('/');
      arr.splice(3, 0, `filters:format(${imgFilter})`);
      banner = {
        desktop: arr.join('/'),
        mobile: arr.join('/'),
        secondary: arr.join('/')
      };
    }
  }
  return banner;
}

const replaceFilterUrl = (arr, imgFormat) => {
  const [indexOfImgFormatter] = arr
    .map((res, index) => ['filters:format(webp)', 'filters:format(png)'].includes(res) && index)
    .filter(Boolean);

  if (indexOfImgFormatter) {
    arr.splice(indexOfImgFormatter, 1, `filters:format(${imgFormat})`);
    return arr.join('/');
  }
  return arr.join('/');
};

const changeImgFormateFilter = (url, imgFilterType = 'png') => {
  if (url) {
    return replaceFilterUrl(url.split('/'), imgFilterType);
  }
};

export { getInImgFormate, changeImgFormateFilter };

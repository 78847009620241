import { createSlice } from "@reduxjs/toolkit";
import isEmpty from "lodash/isEmpty";
import * as COOKIE_VARIABLES from '../../constants/cookieVariables'

import cookie from 'js-cookie';
import storageService from "../../services/storageService";
export const wishlistSlice = createSlice({
  name: "wishlist",
  initialState: {
    saveForLaterItems: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getSaveForLaterItems() : [],
    totalSaveForLaterItemsCount: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getSaveForLaterItems()?.length : 0,
    favouriteItems: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getFavourites() : [],
    totalFavouriteItemsCount: isEmpty(cookie.get(COOKIE_VARIABLES.token)) ? storageService.getFavourites()?.length : 0,
    isSaveForLaterItemsLoading: false,
    isFavouriteItemsLoading: false,
    filteredItemsCount: null,
    isFavouriteItemAddedOrRemoved: false,
    isSavedForLaterItemAddedOrRemoved: false,
  },
  reducers: {
    setSaveForLaterItems: (state, action) => {
      state.saveForLaterItems = action.payload
    },
    setSaveForLaterItemsCount: (state, action) => {
      state.totalSaveForLaterItemsCount = action.payload
    },
    setFilteredItemsCount: (state, action) => {
      state.filteredItemsCount = action.payload
    },
    setFavouriteItems: (state, action) => {
      state.favouriteItems = action.payload
    },
    setFavouriteItemsCount: (state, action) => {
      state.totalFavouriteItemsCount = action.payload

    },
    setSaveForLaterItemsLoading: (state, action) => {
      state.isSaveForLaterItemsLoading = action.payload
    },
    setFavouriteItemsLoading: (state, action) => {
      state.isFavouriteItemsLoading = action.payload
    },
    setIsFavouriteItemAddedOrRemoved: (state, action) => {
      state.isFavouriteItemAddedOrRemoved = action.payload

    },
    setIsSavedForLaterItemAddedOrRemoved: (state, action) => {
      state.isSavedForLaterItemAddedOrRemoved = action.payload
    }
  }
})

export const {
  setSaveForLaterItems,
  setSaveForLaterItemsCount,
  setSaveForLaterItemsLoading,
  setFilteredItemsCount,
  setFavouriteItems,
  setFavouriteItemsCount,
  setFavouriteItemsLoading,
  setIsFavouriteItemAddedOrRemoved,
  setIsSavedForLaterItemAddedOrRemoved
} = wishlistSlice.actions

export default wishlistSlice.reducer
import React, { useContext, useEffect, useState } from 'react';
import { isWishlistApplicable } from '../../../constants/feature';
import storageService from '../../../services/storageService';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { setFavoriteStatusChangedForProduct } from '../../../redux/slices/userSlice';
import { addToFavouritesAPI, removeFromFavouritesAPI } from '../../../NetworkCall/apiCalls';
import { setIsFavouriteItemAddedOrRemoved } from '../../../redux/slices/wishlistSlice';

const FavouritesIcon = ({
  data,
  isSavedProduct = false,
  isMostPopularProduct = false,
  isFeaturedProduct = false,
  type,
  fromPage,
  initialLocationAddress = false,
  initialFreightLimit = false,
  initialPurchaseLimit = false,
  initialUpsellProducts = false,
  initialDeliveryAddressDetails = false
}) => {


  const dispatchRedux = useDispatch()

  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const favouriteItems = useSelector(state => state.wishlist.favouriteItems)

  const router = useRouter();
  const { pathname } = router;

  // const recommendedProduct = isRecommendedProduct || ['/categories', '/weed-delivery-near', '/weed-delivery-near-me', '/weed-delivery-near/[...slug]', '/brands', '/top-sellers-hub'].includes(pathname)

  const [isLoading, setIsLoading] = useState(false);

  const [isFavourite, setIsFavourite] = useState(
    process.browser && !isLoggedIn ? storageService.isInFavourites(data) : !!data?.is_favourite
  );

  // const { addToFavourites, removeFromFavourites } = useAPI();

  useEffect(() => {
    setIsFavourite(
      isLoggedIn
        ? ['/wishlist/favorites', '/wishlist/saved-for-later'].includes(pathname)
          ? isFavourite
          : !!data?.is_favourite
        : storageService.isInFavourites(data)
    );
    // setIsFavourite(
    //   !isLoggedIn
    //     ? storageService.isInFavourites(data)
    //     : recommendedProduct
    //     ? isRecommendedProductInFavourites()
    //     : !!data?.is_favourite
    // );
  }, [isLoggedIn, favouriteItems, data?.is_favourite]);

  // function isRecommendedProductInFavourites() {
  //   return allFavouriteItemIds?.includes(data?.product_details_id);
  // }

  const addFavourite = async () => {
    setIsLoading(true);
    const isAPISuccess = await addToFavouritesAPI({ items: [data], isMostPopularProduct, isFeaturedProduct, type, fromPage, isLoggedIn });
    console.log("isAPISuccess", isAPISuccess);
    if (isAPISuccess) {
      // dispatch({
      //   type: 'setFavoriteStatusChangedForProduct',
      //   payload: {
      //     favoriteStatusChangedForProduct: {
      //       productId: data?.bundle_id ? data.bundle_id : data?.product_id,
      //       is_favourite: true
      //     }
      //   }
      // });
      dispatchRedux(setIsFavouriteItemAddedOrRemoved(true))
      dispatchRedux(setFavoriteStatusChangedForProduct({
        favoriteStatusChangedForProduct: {
          productId: data?.bundle_id ? data.bundle_id : data?.product_id,
          is_favourite: true
        }
      }))
      setIsFavourite(true);
    }
    setIsLoading(false);
  };

  const removeFavourite = async () => {
    setIsLoading(true);
    const isAPISuccess = await removeFromFavouritesAPI({ item: data, isMostPopularProduct, isFeaturedProduct, type, fromPage, isLoggedIn });
    if (isAPISuccess) {
      // dispatch({
      //   type: 'setFavoriteStatusChangedForProduct',
      //   payload: {
      //     favoriteStatusChangedForProduct: {
      //       productId: data?.bundle_id ? data.bundle_id : data?.product_id,
      //       is_favourite: false
      //     }
      //   }
      // });
      dispatchRedux(setIsFavouriteItemAddedOrRemoved(true))
      dispatchRedux(setFavoriteStatusChangedForProduct({
        favoriteStatusChangedForProduct: {
          productId: data?.bundle_id ? data.bundle_id : data?.product_id,
          is_favourite: false
        }
      }))
      setIsFavourite(false);
    }
    setIsLoading(false);
  };

  if (isWishlistApplicable && !isSavedProduct && process.browser) {
    return (
      <div className="wishlist-icon">
        <button
          className={`${isFavourite ? ' wishlist-anim' : ''} btn wishlist-btn mt-1`}
          disabled={isLoading}
          onClick={isFavourite ? removeFavourite : addFavourite}
        >
          <span className={` ${isFavourite ? 'icon-heart-filled' : 'icon-heart-outline'}`} />
        </button>
      </div>
    );
  }
  return <div className="wishlist-icon" />;
};

export default FavouritesIcon;

const OtpMethods = {
  SMS: {
    value: 1,
    text: 'Text',
    dataCy: 'viaButton'
  },
  CALL: {
    value: 2,
    text: 'Call',
    dataCy: 'viaLink'
  }
};

export default {
  Primary: { ...OtpMethods.SMS },

  Secondary: { ...OtpMethods.CALL }
};

import findIndex from 'lodash/findIndex';

const calculateDiscount = (product, price, couponData) => {
  const { asap, schedule, menu } = product;
  const { discount = 0, slash_price: slashPrice } = couponData;
  if (slashPrice) {
    switch (Number(couponData.eligible_delivery_type)) {
      case 1:
        if (asap && menu != 2) {
          return (price * ((100 - discount) / 100)).toFixed(2);
        }
        return price;
      case 2:
        if (schedule && menu != 1) {
          return (price * ((100 - discount) / 100)).toFixed(2);
        }
        return price;
      default:
        return (price * ((100 - discount) / 100)).toFixed(2);
    }
  } else {
    return price;
  }
};

export default function applyDiscount(product, price, couponDetails) {
  if (Array.isArray(couponDetails.product_ids) && couponDetails.product_ids.length) {
    // Check for product_details_id
    if (findIndex(couponDetails.product_ids, o => parseInt(o, 10) === parseInt(product.product_details_id, 10)) > -1) {
      return calculateDiscount(product, price, couponDetails);
    }
    return price;
  }
  return calculateDiscount(product, price, couponDetails);
}

import React, { Component, Fragment } from 'react';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPos: 'left',
      loaded: false
    };
    this.hList = React.createRef();
    this.productCount = 0;
  }

  componentDidMount() {
    this.hListElem = this.hList.current;
    this.hListElem.addEventListener('scroll', this.scrollEventHandler, {passive: true});
    window.addEventListener('resize', this.scrollEventHandler);
    this.setState({ loaded: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.children?.length !== this.props.children?.length) {
      this.addRemoveEventListener();
    }
  }

  componentWillUnmount() {
    this.hListElem.removeEventListener('scroll', this.scrollEventHandler);
    window.removeEventListener('resize', this.scrollEventHandler);
  }

  scrollEventHandler = e => {
    if (!this.hListElem) {
      return;
    }
    if (this.props?.handleShowAllProducts) {
      this.props?.handleShowAllProducts();
    }
    const clientWidth = this.hListElem.clientWidth;
    const scrollWidth = this.hListElem.scrollWidth;
    let scrollLeft = 0;
    scrollLeft = this.hListElem.scrollLeft;
    if (clientWidth + scrollLeft + 20 > scrollWidth) {
      this.setState({
        scrollPos: 'right'
      });
    } else if (scrollLeft === 0) {
      this.setState({
        scrollPos: 'left'
      });
    } else {
      this.setState({
        scrollPos: null
      });
    }
  };

  handleScrollClick = direction => {
    let scrollAmount = 0;
    let step = 25;
    const speed = 19;
    const distance = 300;
    const easing = 0.08;

    const slideTimer = setInterval(() => {
      if (direction === 'left') {
        this.hListElem.scrollLeft -= step;
      } else {
        this.hListElem.scrollLeft += step;
      }
      scrollAmount += step;
      step = Math.max(0, step - easing * step);
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  addRemoveEventListener = () => {
    this.hListElem.removeEventListener('scroll', this.scrollEventHandler, {passive: true});
    this.hListElem.addEventListener('scroll', this.scrollEventHandler, {passive: true});
    this.scrollEventHandler();
  };

  displayScrollBtns = () => {
    const { loaded } = this.state;
    if (!loaded || !this.hListElem) {
      return;
    }
    const scrollWidth = this.hListElem.scrollWidth;
    const clientWidth = this.hListElem.clientWidth;

    if (scrollWidth > clientWidth) {
      if (this.state.scrollPos === 'left') {
        return (
          <button className="btn btn-outline-primary scroll-btn right" onClick={() => this.handleScrollClick('right')}>
            <i className="icon-chevron-right-f" />
          </button>
        );
      }
      if (this.state.scrollPos === 'right') {
        return (
          <button className="btn btn-outline-primary scroll-btn left" onClick={() => this.handleScrollClick('left')}>
            <i className="icon-chevron-right-f" />
          </button>
        );
      }
      return (
        <Fragment>
          <button className="btn btn-outline-primary scroll-btn left" onClick={() => this.handleScrollClick('left')}>
            {' '}
            <i className="icon-chevron-right-f " />
            {' '}
          </button>
          <button className="btn btn-outline-primary scroll-btn right" onClick={() => this.handleScrollClick('right')}>
            <i className="icon-chevron-right-f" />
          </button>
        </Fragment>
      );
    }
    return null;
  };

  render() {
    return (
      <div className={this.props.className}>
        <div className="h-list-parent">
          <div ref={this.hList} className="d-flex h-list">
            {this.props.children}
          </div>
          {this.displayScrollBtns()}
        </div>
      </div>
    );
  }
}

export default Slider;

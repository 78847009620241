import React, { useEffect, useContext } from 'react';
import { useRouter } from 'next/router';

import { countryCodeOption } from '../../constants/countryConst';
import useLogin from '../../hooks/useLogin';
import InputComponent from './InputComponent';
import alwaysTwoDigit from '../../helpers/alwaysTwoDigit';
import { GRASSDOOR } from '../../constants/website';
import VIA from '../../constants/via';

import appContext from '../../Context/appContext';
import storageService from '../../services/storageService';
import appConfig from '../../appConfig';
import { useDispatch, useSelector } from 'react-redux';
import { setSmsCheckBox } from '../../redux/slices/userSlice';

const LoginForm = props => {
  const userTempData = JSON.parse(storageService.getItem('userTempData')) || {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  };

  const router = useRouter();

  // const {
  //   dispatch,
  //   state: {
  //   user: { smsMessage, smsCheckbox, tcpaRewardAmount, isLoggedIn, userInfo } = {},
  //   cart: {
  //     customerData: { phone: loggedPhone }
  //   }
  //   } = {}
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()

  const {
    customerData: { phone: loggedPhone } } = useSelector(state => state.cart) || {}
  const { smsMessage, smsCheckbox, tcpaRewardAmount, isLoggedIn, userInfo } = useSelector(state => state.user)

  const { query: { redirect } = {} } = router;

  const {
    formData: { country, phone, otp, confirmingOTP, generatingOTP, switchBtn, apiError, phoneError, otpError },
    remainingTime,
    setPhone,
    setOTP,
    setCountry,
    requestOTP
  } = useLogin({
    redirectTo: redirect,
    defaultPhone: userInfo.phone || userTempData.phone,
    defaultCountry: userInfo.country,
    customTrigger: 'cart-page-logging-in'
  });

  const handleRequestOtp = otpMethod => () => {
    requestOTP(otpMethod);
  };

  const toggleSMSdisclaimer = () => {
    // dispatch({ type: 'setSmsCheckBox', payload: { smsCheckbox: !smsCheckbox } });
    dispatchRedux(setSmsCheckBox({ smsCheckbox: !smsCheckbox }))
  };

  useEffect(() => {
    return () => {
      // dispatch({ type: 'setSmsCheckBox', payload: { smsCheckbox: false } });
      dispatchRedux(setSmsCheckBox({ smsCheckbox: false }))
    };
  }, [dispatchRedux]);

  return (
    <>
      {isLoggedIn ? (
        <div className="auth-status text-primary d-flex align-items-center justify-content-between ">
          <div className="d-flex justify-content-center">
            <i className="icon-smartphone-call-bold" />

            <span className="bold">{` ${phone || (isLoggedIn ? userInfo.phone : '')}`}</span>
          </div>

          <div className="d-flex justify-content-center">
            <i className="icon-check-circle-out " />

            <span className="auth-msg ">You are logged in</span>
          </div>
        </div>
      ) : (
        <InputComponent
          id="customer-phone"
          className="relative phone-number"
          inputClassName="phone-number"
          icon="icon-phone"
          value={phone}
          type="tel"
          maxLength="10"
          placeholder="Mobile"
          onChange={setPhone}
          error={phoneError}
          loading={generatingOTP}
          loadingText="Generating Code"
          label="Mobile"
          otherAttributes={{
            'test-id': '#customer-phone'
          }}
        >
          <select
            className="form-control country-code"
            test-id="#login_country_code_select"
            value={country}
            onChange={setCountry}
          >
            {countryCodeOption.map(option => (
              <option key={option.name} value={option.code}>{`${option.name} +${option.code}`}</option>
            ))}
          </select>
        </InputComponent>
      )}

      {isLoggedIn && smsMessage && (
        <div className="custom-checkbox one-rem-mt one-rem-mb ">
          <input type="checkbox" id="smsDisclaimerCheckBox" checked={smsCheckbox} onClick={toggleSMSdisclaimer} />

          <label className="align-top m-0 text-left md-grey-color" htmlFor="smsDisclaimerCheckBox">
            {process.env.NEXT_PUBLIC_APP === GRASSDOOR ? (
              <span className="inner small-text ">
                By checking this box, you agree to receive marketing communications from Grassdoor via automated SMS technology.
                SMS consent is not required to purchase. Frequency of communications may vary. Message and data rates may apply.
              </span>
            ) : (
              <span className="inner small-text ">
                By checking this box, you agree to receive marketing communications from {appConfig.APP_NAME} and{' '}
                {appConfig.APP_NAME}
                's delivery partner via automated SMS technology. SMS consent is
                not required to purchase. Frequency of communications may vary. Message and data rates may apply.
              </span>
            )}
          </label>
        </div>
      )}

      {props.children}

      {isLoggedIn ? null : (
        <>
          <InputComponent
            className="relative"
            inputClassName="otp-field"
            icon="icon-otp2"
            value={otp}
            type="tel"
            maxLength="4"
            autocomplete="one-time-code"
            placeholder="Enter Verification Code"
            label="Verification Code"
            onChange={setOTP}
            error={otpError || apiError}
            disabled={confirmingOTP}
            loading={confirmingOTP}
            loadingText="Verifying"
            otherAttributes={{
              'test-id': '#login_otp_field_input'
            }}
          />

          <div className="otp-on-call text-center">
            {/* Primary OTP button */}
            <button
              type="button"
              className="one-rem-mb btn btn-sm btn-primary has-box-shadow btn-rounded"
              onClick={switchBtn ? handleRequestOtp(VIA.Secondary.value) : handleRequestOtp(VIA.Primary.value)}
              disabled={generatingOTP || remainingTime > 0}
              data-cy={switchBtn ? VIA.Secondary.dataCy : VIA.Primary.dataCy}
            >
              {remainingTime > 0 ? (
                `Resend Verification Code (00:${alwaysTwoDigit(remainingTime)})`
              ) : (
                <>
                  {switchBtn ? (
                    <span>
                      Resend Verification Code via
                      <span className="blink-me">{` ${VIA.Secondary.text}`}</span>
                    </span>
                  ) : (
                    `Get Verification Code via ${VIA.Primary.text}`
                  )}
                </>
              )}
            </button>

            <div className="line-behind-text">
              <span>Or</span>
            </div>

            {/* Secondary OTP button */}
            <button
              type="button"
              className="btn btn-sm btn-link  half-rem-mt underline"
              onClick={switchBtn ? handleRequestOtp(VIA.Primary.value) : handleRequestOtp(VIA.Secondary.value)}
              disabled={generatingOTP || remainingTime > 0}
              data-cy={switchBtn ? VIA.Primary.dataCy : VIA.Secondary.dataCy}
            >
              {remainingTime > 0 ? (
                `Resend Verification Code (00:${alwaysTwoDigit(remainingTime)})`
              ) : (
                <>
                  {switchBtn
                    ? `Resend Verification Code via ${VIA.Primary.text}`
                    : `Get Verification Code via ${VIA.Secondary.text}`}
                </>
              )}
            </button>

            {/* <div className="two-rem-mt ">
              <span>
                By entering a &quot;verification code&quot; you agree to receive SMS messages from {appConfig.APP_NAME}
{' '}
per our{' '}
                <a
                  href={appConfig.TERMS ? appConfig.TERMS : '/termsandconditions'}
                  target="_blank"
                  className="btn btn-sm btn-link p-0 one-em-font"
                >
                  Terms of Use
                </a>
              </span>
            </div> */}

            {smsMessage && (
              <div className="custom-checkbox one-rem-mt ">
                <input type="checkbox" id="smsDisclaimerCheckBox" checked={smsCheckbox} onClick={toggleSMSdisclaimer} />

                <label className="align-top m-0 text-left" htmlFor="smsDisclaimerCheckBox">
                  <span className="inner custo-info-font md-grey-color">
                    By checking this box, you agree to receive
                    {Number(tcpaRewardAmount) ? ` a $${tcpaRewardAmount} credit and more ` : ' '}
                    exclusive offers via automated SMS technology. Consent is not required to purchase.
                  </span>
                </label>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default LoginForm;

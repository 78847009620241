import { VALID_NAME_MIN_LENGTH } from '../constants/verificationConst';
import getNonWhiteSpaceCharacterLength from './getNonWhiteSpaceCharacterLength';
import validateAlphabet from './validateAlphabet';

export default function nameValidationWithError(name, field = 'Name') {
  if (!getNonWhiteSpaceCharacterLength(name)) {
    return `Please enter ${field}`;
  }
  if (getNonWhiteSpaceCharacterLength(name) < VALID_NAME_MIN_LENGTH && field !== 'Last Name' && field !== 'First Name') {
    return `${field} is too short`;
  }
  if (!validateAlphabet(name)) {
    return `Invalid ${field}`;
  }
  return null;
}

import React from 'react';

const LottieRender = ({path, className}) => {
  const ref = React.useRef();
  const [lottie, setLottie] = React.useState(null);

  React.useEffect(() => {
    import('lottie-web').then(Lottie => setLottie(Lottie.default));
  }, []);

  React.useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: path,
        controls: true
      });

      return () => animation.destroy();
    }
  }, [lottie]);
  return <div ref={ref} className={className}></div>;
};

export default LottieRender;

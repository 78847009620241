import isEmpty from 'lodash/isEmpty';
import { toast } from 'react-toastify';
import { OFFER_INVALID, OFFER_EXPIRED } from '../constants/offerMessage';

function getPromoErrorMessage(promoMessageData) {
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  let expiryDate = new Date(promoMessageData.expiry_date);
  expiryDate = new Date(expiryDate.getTime() - expiryDate.getTimezoneOffset() * -60000);
  if (promoMessageData.is_expired || (promoMessageData.expiry_date && currentDate - expiryDate > 0)) {
    return OFFER_EXPIRED;
  }
  if (promoMessageData.start_date && new Date() - new Date(promoMessageData.start_date) < 0) {
    return OFFER_INVALID;
  }
  return null;
}

function getPromoSuccessMessage(promoMessageData) {
  if (promoMessageData.popup_message) {
    return promoMessageData.popup_message;
  }
  if (!promoMessageData.popup_message && promoMessageData.discount_code === 'FIFTY') {
    return 'Enjoy 50% off on your first order';
  }
  return null;
}

// Data schema to store information coming from Branch.IO
function getBranchSchema(data = {}) {
  const obj = {
    // id: data.coupon_id,
    // discount_code: data.discount_code || data.code,
    // discount: data.discount,
    // coupon_type_id: data.coupon_type_id,
    // min_amount: data.min_amount ? data.min_amount : 0,
    // product_ids: data.product_ids ? data.product_ids : null,
    // popup_message: data.popup_message,
    // ImgURL: data.ImgURL,
    // token: data.token

    id: data.coupon_id,
    discount: data.discount,
    discount_code: data.discount_code,
    product_ids: data.product_ids,
    slash_price: data.show_slash_price,
    coupon_type: data.is_percentage ? 2 : 1,
    popup_message: data.popup_message,
    ImgURL: data.ImgURL,
    token: data.token,
    isAmbassador: data.is_ambassador,
    eligible_delivery_type: data.eligible_delivery_type
  };
  return obj;
}

// Data schema to store coupon information coming from apply discount API
function getCouponSchema(data = {}, withMessage = false, { totalHappyHourDiscount, happyHoursCode }) {
  const obj = {
    id: '',
    discount: 0,
    discount_code: '',
    product_ids: [],
    slash_price: false,
    coupon_type: 0,
    couponAvailed: false,
    user_coupon: 0
  };

  if (!isEmpty(data.discount_applied) && Array.isArray(data.discount_applied)) {
    data.discount_applied.forEach(discountObj => {

      const applyHappyHourDiscount = discountObj.coupon_code === happyHoursCode && totalHappyHourDiscount;


      if (discountObj.availed) {
        obj.discount = applyHappyHourDiscount ? totalHappyHourDiscount : discountObj.discount;
        obj.slash_price = discountObj.slash_price;
        obj.eligible_delivery_type = discountObj.eligible_delivery_type;

        if (withMessage) {
          if (discountObj.coupon_code) {
            obj.coupon_success_message = discountObj.message;
          } else {
            obj.success_message = discountObj.message;
          }
        }
      } else if (withMessage) {
        if (discountObj.coupon_code) {
          obj.coupon_warning_message = discountObj.message;
        } else {
          obj.warning_message = discountObj.message;
        }
      }

      if (discountObj.coupon_code) {
        obj.discount_code = discountObj.coupon_code;
        obj.id = discountObj.coupon_id;
        obj.coupon_type = discountObj.coupon_type_id;
        obj.product_ids = discountObj.product_ids;
        obj.couponAvailed = discountObj.availed;
        obj.user_coupon = discountObj.user_coupon;
        obj.eligible_delivery_type = discountObj.eligible_delivery_type;
        obj.type = applyHappyHourDiscount ? 1 : discountObj.type;
      }
    });
  }
  return obj;
}

function displayCouponApplyMessage(couponData = {}) {
  const {
    success_message: couponSuccessMessage,
    coupon_success_message: successMessage,
    warning_message: warningMessage
  } = couponData;

  if (couponSuccessMessage && typeof couponSuccessMessage === 'string') {
    toast.success(couponSuccessMessage);
  }

  if (successMessage && typeof successMessage === 'string') {
    toast.success(successMessage);
  }

  if (warningMessage && typeof warningMessage === 'string') {
    toast.warn(warningMessage);
  }
}

export { getPromoErrorMessage, getPromoSuccessMessage, getBranchSchema, getCouponSchema, displayCouponApplyMessage };

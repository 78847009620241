/* global google */

import React, { useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { compose, withProps } from 'recompose';
import { convertPlacesDataToLocalPlacesModel } from '../helpers/addressHelper';
import loadScript from '../helpers/loadScript';

const SearchBar = ({
  onPlacesChanged,
  className,
  deliveryDetails,
  isNotfocus,
  placeholder = 'Enter your address to check availability',
  value = '',
  handleKeyPress,
  changeInput = false
}) => {
  const autocompleteInput = useRef();
  const autocomplete = useRef();

  useEffect(() => {
    async function handlePlaceSelect() {
      const place = autocomplete.current.getPlace();
      const address = convertPlacesDataToLocalPlacesModel(place);

      if (!address.postcode) {
        toast.warn('Please select a precise address');
        autocompleteInput.current.value = '';
        return false;
      }
      // onplaces changed set it as current address
      if (changeInput) {
        const response = await onPlacesChanged(address);
        if (response === false) {
          autocompleteInput.current.value = '';
        }
      } else {
        onPlacesChanged(address);
      }
      return true;
    }

    function addPlacesChangedListener() {
      const options = {
        /**
         * Specify types to filter
         * @see https://developers.google.com/places/web-service/autocomplete#place_types
         * types: ['geocode', 'establishment'],
         */
        fields: ['address_components', 'formatted_address', 'geometry', 'name', 'place_id'],
        componentRestrictions: { country: 'us' }
      };
      if (typeof google === 'object') {
        autocomplete.current = new google.maps.places.Autocomplete(autocompleteInput.current, options);
        autocomplete.current.addListener('place_changed', handlePlaceSelect);
      }
    }

    async function loadAndAddCode() {
      if (!isNotfocus) {
        autocompleteInput.current.focus();
      }
      if (deliveryDetails && deliveryDetails.fromMap) {
        if (autocompleteInput?.current?.value) {
          autocompleteInput.current.value = deliveryDetails.address;
        }
      }
      if (window.google) {
        addPlacesChangedListener();
        return;
      }
      console.log("api key", process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY);
      await loadScript({
        url: `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY}&callback=Function.prototype&v=3.exp&libraries=geometry,drawing,places`
      });
      addPlacesChangedListener();
    }
    loadAndAddCode();
  }, []);

  function handleKeyPress(e) {
    if (e.which === 13) {
      e.preventDefault();
    }
  }

  return (
    <div className={className}>
      <form autoComplete="off" onKeyPress={handleKeyPress}>
        <input
          data-testid="#search-address-input"
          defaultValue={value}
          id="autocomplete"
          autoComplete="off"
          type="text"
          placeholder={placeholder}
          className="form-control"
          ref={autocompleteInput}
        />
      </form>
    </div>
  );
};

export default compose(
  withProps({
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />
  })
)(SearchBar);


export default function getProductIdsBeforeAddedTocart(cartItems) {
  let allProductsIds = [];

  if (cartItems?.length) {
    cartItems?.forEach((item) => {
      allProductsIds.push(item.product_id);
    });
  }

  return allProductsIds;
}

import getCartType from './getCartType';
import getProductType from './getProductType';
import { ASAP_AND_SCHEDULE } from '../constants/deliveryType';

export default function hasValidDeliveryType(cartItems, product) {
  if (cartItems && cartItems.length === 0) {
    return true;
  }
  if (getCartType(cartItems) === ASAP_AND_SCHEDULE || getProductType(product) === ASAP_AND_SCHEDULE) {
    return true;
  }
  if (getCartType(cartItems) === getProductType(product)) {
    return true;
  }
  return false;
}

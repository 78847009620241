import React, { useState, useEffect, useRef } from 'react';

import DangerousHTML from '../../DangerousHTML';

const SeeMore = ({ text = '', customElementName = '', height = 75 }) => {
  const [showMore, setShowMore] = useState(false);
  const [isElementOverflowing, setIsElementOverflowing] = useState(true);

  const observer = useRef(null);

  function handleSeeMore() {
    setShowMore(prevShowtext => {
      observer.current.disconnect();
      return !prevShowtext;
    });
  }

  useEffect(() => {
    const element = document.querySelector(`.${customElementName}`);

    if (element) {
      observer.current = new ResizeObserver(([entry]) => {
        const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;

        setIsElementOverflowing(entry.target.scrollHeight > height);
      });

      observer.current.observe(element);
    }
  }, []);

  if (customElementName && text) {
    return (
      <div className="see-more-content">
        <input id={`seeMoreButton_${customElementName}`} type="checkbox" />

        <div
          className={`${customElementName} ${isElementOverflowing ? 'overflowing' : 'not-overflowing'}`}
          style={{ maxHeight: height }}
        >
          <DangerousHTML html={text} className="font-medium cms-description" />
        </div>

        {isElementOverflowing ? (
          <label htmlFor={`seeMoreButton_${customElementName}`} onClick={handleSeeMore}>
            {showMore ? 'See Less' : 'See More'}
          </label>
        ) : null}
      </div>
    );
  }

  return null;
};

export default SeeMore;


import React, { useEffect, useState, useRef, useContext } from 'react';

const OfferTimer = ({ endDate }) => {
    const dest = new Date(endDate).getTime()
    const now = new Date().getTime();
    const [remainingTime, setRemainingTime] = useState(dest - now);

    let diff = dest - now;
    const interval = useRef(null);
    let days = parseInt(diff / (1000 * 60 * 60 * 24))
    let hours = parseInt((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let min = parseInt((diff % (1000 * 60 * 60)) / (1000 * 60))
    let sec = parseInt((diff % (1000 * 60)) / 1000)

    const clearIntervals = () => {
        clearInterval(interval.current);
    };

    useEffect(() => {
        const calculateAndSetTime = () => {
            if (remainingTime > 0) {
                setRemainingTime(remainingTime - 1);
            } else {
                clearIntervals();
            }
        };

        interval.current = setInterval(() => {
            calculateAndSetTime();
        }, 1000);

        return () => {
            clearIntervals();
        };
    }, [remainingTime]);

    return (
        <>
            <div className="offer-timer">
                <span className="">
                    {`Hurry! Offer ends in ${days > 0 ? days + "d" : ""} ${hours > 0 ? hours + "h" : ""} ${min}m ${sec}s`}
                </span>
            </div>
        </>
    )

}
export default OfferTimer
// import { Player } from '@lottiefiles/react-lottie-player';
import React, { useContext, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';
import appContext from '../../Context/appContext';
import useAPI from '../../NetworkCall/API';
import addNewAddress from '../../public/static/lotties/addNewAddress.json';
import UpdateAddress from './UpdateAddress';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { updateNewAddressForProfile } from '../../redux/slices/otherSlice';
import LottieRender from '../LottieRender';

const AddNewAddress = ({ dismiss }) => {
  // const {
  //   state: { newAddressForProfile = {} },
  //   dispatch
  // } = useContext(appContext);

  const newAddressForProfile = useSelector(state => state.other.newAddressForProfile) || {}

  const dispatchRedux = useDispatch()

  const { addAddress } = useAPI();
  const [isDisabled, setIsDisabled] = useState(false);

  const dismissModal = () => {
    // dispatch({
    //   type: 'updateNewAddressForProfile',
    //   payload: {}
    // });
    dispatchRedux(updateNewAddressForProfile({}))
    dismiss();
  };
  const handleApply = async () => {
    if (newAddressForProfile?.address) {
      setIsDisabled(true);
      await addAddress();
      // dispatch({
      //   type: 'updateNewAddressForProfile',
      //   payload: {}
      // });
      dispatchRedux(updateNewAddressForProfile({}))
      setIsDisabled(false);
      dismiss();
    } else {
      toast.error('Please select a valid address');
    }
  };
  return (
    <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
      <div className="modal  d-block add-address-popup animated-popup-wrapper" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header ">
              <h6 className="modal-title">Add New Address</h6>
              <button type="button" className="btn" onClick={dismissModal}>
                <i className="icon-close" />
              </button>
            </div>
            <div className="modal-body pt-0">
              <div className="logo-container">
                <LottieRender className="lottie-address" path='../../static/lotties/addNewAddress.json' />
                {/* <Player className="lottie-address" autoplay loop src={addNewAddress} /> */}
              </div>
              <div className="input-parent">
                <UpdateAddress
                  addressDetails={{
                    ...newAddressForProfile,
                    address_type: -1,
                    address_type_name: null,
                    address_id: 0,
                    is_default: -1
                  }}
                  is_new={true}
                />
              </div>
            </div>
            <div className="modal-footer justify-content-between a-btn-container mb-1">
              <div className="form-group ">
                {/* <div className="custom-checkbox">
                  <input
                    type="checkbox"
                    id="add-default-address"
                    checked=""
                    name="addDefaultAddress"
                    onChange=""
                    disabled=""
                  />
                  <label className="align-top m-0" htmlFor="add-default-address">
                    Make this Address Default
                  </label>
                </div> */}
              </div>
              <button
                disabled={isDisabled}
                type="button"
                className="btn outline-btn btn-small btn-sm p-btn btn-outline-secondary"
                onClick={handleApply}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default AddNewAddress;

import React from 'react';
import PropTypes from 'prop-types';

function CustomRadioType({ subtitle, id, name, value, isChecked, handleChange, className, forAddressHistoryCheckout }) {
  return (
    <div className={`custom-radio ${className}`}>
      {isChecked ? (
        <input
          name={name}
          type="radio"
          id={id}
          value={value}
          checked
          onChange={handleChange}
          onClick={forAddressHistoryCheckout ? handleChange : null}
        />
      ) : (
        <input name={name} type="radio" id={id} value={value} checked={false} onChange={handleChange} />
      )}
      <label htmlFor={id} className="">
        <div className="normal-font ">{subtitle}</div>
      </label>
    </div>
  );
}

CustomRadioType.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  forAddressHistoryCheckout: PropTypes.bool
};

CustomRadioType.defaultProps = {
  isChecked: false,
  className: '',
  forAddressHistoryCheckout: false,
  handleChange: () => { }
};

export default CustomRadioType;

export default function scheduleTimeBracketsArray(isGrassdoor) {
  return [
    { name: '09:00 AM - 10:30 AM', code: '09:00:00', start: 9, end: 10.5 },
    { name: '10:30 AM - 12:00 PM', code: '10:30', start: 10.5, end: 12 },
    { name: isGrassdoor ? '12:00 PM - 01:30 PM ( $1.00 Off )' : '12:00 PM - 01:30 PM', code: '12:00', start: 12, end: 13.5 },
    { name: isGrassdoor ? '01:30 PM - 03:00 PM ( $1.00 Off )' : '01:30 PM - 03:00 PM', code: '13:30', start: 13.5, end: 15 },
    // { name: '03:00 PM - 04:30 PM', code: '15:00', start: 15, end: 16.5 },
    { name: '04:30 PM - 06:00 PM', code: '16:30', start: 16.5, end: 18 },
    { name: '06:00 PM - 07:30 PM', code: '18:00', start: 18, end: 19.5 },
    { name: '07:30 PM - 09:00 PM', code: '19:30', start: 19.5, end: 21 },
    { name: '09:00 PM - 10:00 PM', code: '21:00', start: 21, end: 21.5 }
  ];
}

export const scrollDataAnalyser = (data = []) => {
  let [large, small, top] = [0, -1, false]
  for (let i = 0; i < data.length; i++) {
    if (data[i] > large) large = data[i]
    small == -1 ? small = data[i] : null
    if (data[i] < small) { small = data[i] }
  }
  top = data[0] > data[data.length - 1]
  return { difference: large - small, top, position: top ? small : large, max: large, min: small };
}


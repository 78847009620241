import { useContext, useEffect, useRef } from 'react';
import get from 'lodash/get';
import axios from '../utils/ajax';
import appContext from '../Context/appContext';
import { DEFAULT_ZIPCODE } from '../constants/default';
import getCartType from '../helpers/getCartType';
import { ASAP, ASAP_AND_SCHEDULE, SCHEDULE } from '../constants/deliveryType';
import { setRollbarWarning } from '../helpers/rollBar';
import getRouterPath from '../helpers/getRouterPath';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification } from '../redux/slices/modalSlice';
import notifications from '../constants/modalNotificationConst';

const useBraze = (pathname) => {
  // const {
  //   state: {
  //     user: {
  //       deliveryDetails: { postcode = DEFAULT_ZIPCODE }
  //     },
  //     cart: { cartData: { cart_items: cartItems = [] } = {} }
  //   }
  // } = useContext(appContext);
  const dispatchRedux = useDispatch();
  const { deliveryDetails: { postcode = DEFAULT_ZIPCODE } } = useSelector(state => state.user)

  const {
    isLoggedIn
  } = useSelector(state => state.user)
  const cartItems = useSelector(state => state.cart?.cartData?.cart_items) || []

  const postcodeRef = useRef(postcode);
  const cartItemsRef = useRef(cartItems);
  const isConfirmationPage = getRouterPath(pathname) === 'confirmation';

  useEffect(() => {
    postcodeRef.current = postcode;
  }, [postcode]);

  useEffect(() => {
    cartItemsRef.current = cartItems;
  }, [cartItems]);

  const getAvailableProducts = dealProducts => {
    const avaialableProducts = [];
    const cartType = getCartType(cartItemsRef.current);

    if (Array.isArray(dealProducts)) {
      dealProducts.forEach(item => {
        if (
          (cartType === ASAP && item.asap && !item.is_sold_out) ||
          (cartType === SCHEDULE && item.schedule && !item.schedule_is_sold_out) ||
          (cartType === ASAP_AND_SCHEDULE && (!item.is_sold_out || !item.schedule_is_sold_out))
        ) {
          avaialableProducts.push(item);
        }
      });
    }

    return avaialableProducts;
  };

  async function fetchProductDeals(popupTag, callback, header, sub, subBold, products, maxQty, overridePostcode) {
    if (!(isLoggedIn)) return

    try {
      const response = await axios({
        url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/deals/${postcodeRef.current}`,
        method: 'GET',
        headers: { zc: overridePostcode }
      });
      // const response = await axios({
      //   url: `${process.env.NEXT_PUBLIC_SECURE_EC2_URL}/products/deals/${postcodeRef.current}?product_id=${JSON.stringify(
      //     productsJson
      //   )}`,
      //   method: 'GET',
      //   headers: { zc: overridePostcode }
      // });

      if (response) {
        const popupHeader = { header, sub, subBold, maxQty: maxQty || null };
        const allProducts = get(response, 'data.data', []);
        const availableProducts = getAvailableProducts(allProducts);

        if (availableProducts?.length && callback && popupTag && !isConfirmationPage) {
          dispatchRedux(pushNotification({ type: notifications.dealsPopup, data: { popupHeader, allProductDeals: availableProducts } }))

        }

        if (allProducts?.length && callback && popupTag) {
          setTimeout(() => {
            if (typeof callback === 'function') callback();
          }, 100);
        }
      }
    } catch (error) {
      setRollbarWarning('appboy.js_fetchProductDeals_error', error);
      console.error('appBoy.js_fetchProductDeals error', error);
    }
  }

  function brazeInitialisation() {
    window.showPopup = fetchProductDeals;
  }

  async function initBraze() {
    brazeInitialisation();
  }

  return {
    initBraze,
    fetchProductDeals
  };
};

export default useBraze;

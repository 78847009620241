import { ASAP, ASAP_AND_SCHEDULE, SCHEDULE } from '../constants/deliveryType';

export default function getProductType(product) {
  let productType = ASAP_AND_SCHEDULE;
  if (!product) {
    return ASAP_AND_SCHEDULE;
  }

  if (product.ounceObj) {
    productType = product.asap ? ASAP : SCHEDULE;
  }

  if (product.asap && product.available_quantity > 0 && product.schedule && product.product_max_quantity > 0) {
    productType = ASAP_AND_SCHEDULE;
  } else if (product.asap && product.available_quantity > 0) {
    productType = ASAP;
  } else if (product.schedule && product.product_max_quantity > 0) {
    productType = SCHEDULE;
  } else if (product.bundle_id) {
    if (product.schedule && product.max_quantity > 0) {
      productType = SCHEDULE;
    }
  }

  return productType;
}

import React from 'react';

// import TestimonialSlider from './Testimonials';

const CannadipsFooter = () => (
  <div className="cannadips-footer">
    <div className="cannadips-footerbanner">
      <h1>
        FROM THE DIP LEGENDS
        <br /> OF HUMBOLDT COUNTY
      </h1>
    </div>
    <div className="cannadipsf-bg my-5 container">
      <h1 className="text-white text-center">JOIN THE CREW</h1>
      <div className="d-b-inner accumin d-flex justify-content-center">
        <div>
          <a test-id="#instagram" target="_blank" href="https://www.instagram.com/cannadips/" rel="noreferrer">
            {' '}
            <i className="icon-instagram mr-1" />
            @cannadips
          </a>
        </div>
        <div className="px-lg-5 px-3">
          <a target="_blank" href="https://www.facebook.com/cannadips" rel="noreferrer">
            <i className="icon-facebook-border mr-1" />
            /cannadips
          </a>
        </div>
        <div>
          <a test-id="#twitter" target="_blank" href="https://twitter.com/cannadips" rel="noreferrer">
            <i className="icon-twitter mr-1" />
            @cannadips
          </a>
        </div>
      </div>
      <div className="text-center w-100">
        <div className="d-flex justify-content-center">
          <div className="">
            <input type="email" className="form-control" placeholder="ENTER YOUR EMAIL" />
            <input type="Tel" className="form-control" placeholder="ENTER CELL NUMBER " />
          </div>
          <button className="btn btn-primary">
            SIGN
            {' '}
            <br /> UP
          </button>
        </div>
      </div>
    </div>
    <div className="d-f-inner text-center justify-content-center d-flex">
      <div className="">
        <h2>
          NOT IN CALIFORNIA?
          {' '}
          <br />
          BUY CANNADIPS CBD
          {' '}
        </h2>
        {/* <h4 class="mt-3">KINDLAND</h4> */}
        <a href="http://www.cannadipscbd.com/" className="btn btn-lg">
          SHOP CBD
        </a>
      </div>
    </div>
    <div className="d-bottom-strip container two-rem-mt pb-4">
      <div className="note">
        © 2021 Trinidad Consulting: Cannadips THC products are for adults only. This page is limited to visitors of Legal Age
        according to their respective country of residence. None of the content presented here is intended for the use by minors.
        By exploring this page, you accept that you are older than 21 years of age. The statements made regarding these products
        have not been evaluated by the Food and Drug Administration. The efficacy of these products has not been confirmed by
        FDA-approved research. These products are not intended to diagnose, treat, cure or prevent any disease. All information
        presented here is not meant as a substitute for or alternative to information from healthcare practitioners. Please
        consult your healthcare professional about potential interactions or other possible complications before using any
        product. The Federal Food, Drug, and Cosmetic Act requires this notice.
      </div>
      <div className="d-b-inner accumin p-5 d-flex justify-content-center">
        <div>
          <a target="_blank" href="https://www.instagram.com/cannadips/" rel="noreferrer">
            {' '}
            <i className="icon-instagram mr-1" />
            @cannadips
          </a>
        </div>
        <div className="px-lg-5 px-3">
          <a target="_blank" href="https://www.facebook.com/cannadips" rel="noreferrer">
            <i className="icon-facebook-border mr-1" />
            /cannadips
          </a>
        </div>
        <div>
          <a test-id="#twitter" target="_blank" href="https://twitter.com/cannadips" rel="noreferrer">
            <i className="icon-twitter mr-1" />
            @cannadips
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default CannadipsFooter;

import { toast } from "react-toastify";

const toastId = "wishlist"

export function wishlistToast(message) {
  if (toast.isActive(toastId)) {
    toast.update(toastId, {
      render: message
    });
  } else {
    toast.success(message, {
      toastId: toastId
    });
  }
}

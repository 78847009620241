import isEmpty from 'lodash/isEmpty';

import { COD, STRONGHOLD } from '../constants/paymentTypes';
import * as WEBSITES from '../constants/website';

import storageService from '../services/storageService';

export const defaultCardData = {
  cardNumber: '',
  cardNumberError: null,
  cardExp: '',
  cardExpError: null,
  cardCVV: '',
  cardCVVError: null,
  cardName: '',
  cardNameError: null,
  zipcode: '',
  zipcodeError: null,
  saveCard: false,
  newCardChecked: false
};

export function createInitialStore() {
  const userData = storageService.getUserData() || {};
  const userInfo = !isEmpty(userData) ? userData : {};
  const customerData = {
    name: userInfo.name,
    first_name: userInfo.first_name
      ? userInfo.first_name
      : userInfo.name
        ? userInfo.name.substr(0, userInfo.name.indexOf(' ')) === ''
          ? userInfo.name
          : userInfo.name.substr(0, userInfo.name.indexOf(' '))
        : '',
    last_name: userInfo.last_name
      ? userInfo.last_name
      : userInfo.name
        ? userInfo.name.substr(0, userInfo.name.indexOf(' ')) === ''
          ? ''
          : userInfo.name.substr(userInfo.name.indexOf(' ') + 1)
        : '',
    nameError: null,
    first_nameError: null,
    last_nameError: null,
    email: '',
    emailError: null,
    addressError: null,
    apt: '',
    notes: '',
    paymentType: COD,
    pay_with_card: 0,
    useWalletAmount: true,
    isNewCard: false,
    isLedgerGreenNewCard: false,
    ledgerGreenSelectedCard: {},
    debitSelectedCard: null,
    checkedLGCard: null,
    checkedDebitCard: null,
    cardData: storageService.getDebitCardSession() || defaultCardData,
    useGiftWalletAmount: true,
    complianceFee: null
  };
  const cartDetails = {
    driverTip: 0,
    appliedWallet: 0,
    appliedGiftCardWallet: 0
  };
  const ledgerGreenCardData = storageService.getLedgerGreenCardSession() || defaultCardData;
  const outOfStockProducts = [];
  const productAddedToCart = [];
  const recipientDetails = {};
  const cartData = undefined;
  const showCartWidget = false;
  const showAllCartProductsWidget = false;
  const cartWidgetProduct = '';
  const updatingProductsList = [];
  const updatingOunceType = [];
  const bccPopupDismiss = null;
  const checkoutPossible = true;
  const isAddToCartLoading = false;
  const PDPOpenedFromWidget = false;
  const updateProductWidget = false;
  const cartWidgetLastAddedProducts = [];
  const upsellRecommendedProducts = {};
  const upsellPopularProducts = [];
  const isCartRequestFailed = false;

  return {
    customerData,
    cartDetails,
    outOfStockProducts,
    cartData,
    updatingProductsList,
    updatingOunceType,
    cartLoading: false,
    viewCartLoading: false,
    addToCartLoader: false,
    showCartWidget,
    showAllCartProductsWidget,
    cartWidgetProduct,
    recipientDetails,
    bccPopupDismiss,
    checkoutPossible,
    ledgerGreenCardData,
    productAddedToCart,
    isAddToCartLoading,
    updateProductWidget,
    cartWidgetLastAddedProducts,
    upsellRecommendedProducts,
    upsellPopularProducts,
    isCartRequestFailed,
    PDPOpenedFromWidget
  };
}

export const actions = {};

actions.setBccPopupDismiss = (state, action) => {
  return { ...state, cart: { ...state.cart, bccPopupDismiss: action.payload } };
};

actions.setCustomerInfo = (state, action) => {
  return { ...state, cart: { ...state.cart, customerData: { ...state.cart.customerData, ...action.payload } } };
};

actions.setRecipientInfo = (state, action) => {
  return { ...state, cart: { ...state.cart, recipientDetails: action.payload } };
};

actions.setProductAddedToCart = (state, action) => {
  return { ...state, cart: { ...state.cart, productAddedToCart: action.payload } };
};

actions.setIsAddToCartLoading = (state, action) => {
  return { ...state, cart: { ...state.cart, isAddToCartLoading: action.payload } };
};

actions.setPDPOpenedFromWidget = (state, action) => {
  return { ...state, cart: { ...state.cart, PDPOpenedFromWidget: action.payload } };
};

actions.setCartDetails = (state, action) => {
  return { ...state, cart: { ...state.cart, cartDetails: { ...state.cart.cartDetails, ...action.payload } } };
};

actions.setOutOfStockProducts = (state, action) => {
  return { ...state, cart: { ...state.cart, outOfStockProducts: action.payload } };
};

actions.setCartData = (state, action) => {

  return { ...state, cart: { ...state.cart, cartData: action.payload, updatingProductsList: [] } };
};

actions.setCartWidget = (state, action) => {
  return { ...state, cart: { ...state.cart, showCartWidget: action.payload } };
};

actions.setCartWidgetProduct = (state, action) => {
  return { ...state, cart: { ...state.cart, cartWidgetProduct: action.payload } };
};

actions.setCartLoading = (state, action) => {
  return { ...state, cart: { ...state.cart, cartLoading: action.payload } };
};

actions.setViewCartLoading = (state, action) => {
  return { ...state, cart: { ...state.cart, viewCartLoading: action.payload } };
};

actions.setCartCheckoutPossible = (state, action) => {
  return { ...state, cart: { ...state.cart, checkoutPossible: action.payload } };
};

actions.setAddingToCart = (state, action) => {
  return { ...state, cart: { ...state.cart, addToCartLoader: action.payload } };
};

actions.setAllCartProductsWidget = (state, action) => {
  return { ...state, cart: { ...state.cart, showAllCartProductsWidget: action.payload }, user: { ...state.user, showUserDropdown: false } };
};

actions.setUpsellProducts = (state, action) => {
  return { ...state, cart: { ...state.cart, upsellPopularProducts: action.payload } };
};

actions.setUpsellRecommendedProducts = (state, action) => {
  return { ...state, cart: { ...state.cart, upsellRecommendedProducts: action.payload } };
};

actions.setUpdateProductsWidget = (state, action) => {
  return { ...state, cart: { ...state.cart, updateProductWidget: action.payload } };
};

actions.addUpdatingProducts = (state, action) => {
  let updatingProductsList = state.cart.updatingProductsList;

  if (!state.cart.updatingProductsList.includes(action.payload)) {
    updatingProductsList = [...updatingProductsList, action.payload];
  }

  return { ...state, cart: { ...state.cart, updatingProductsList } };
};

actions.setAddingNewProductToCart = (state, action) => {
  return { ...state, cart: { ...state.cart, addingNewProductLoader: action.payload } };
};

actions.setCartWidgetLastAddedProduct = (state, action) => {
  return { ...state, cart: { ...state.cart, cartWidgetLastAddedProducts: action.payload } };
};

actions.removeUpdatingProducts = (state, action) => {
  const filterdList = state.cart.updatingProductsList.filter(productID => productID != action.payload);

  return { ...state, cart: { ...state.cart, updatingProductsList: filterdList } };
};

actions.resetCardAndTips = state => {
  return {
    ...state,
    cart: {
      ...state.cart,
      customerData: {
        ...state.cart.customerData,
        paymentType: process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ? STRONGHOLD : COD,
        cardData: defaultCardData
      },
      cartDetails: { driverTip: 0 }
    }
  };
};

actions.setCartCount = (state, action) => {
  const { payload } = action;
  const cartData = { ...state.cart.cartData };
  // Set cart_items if sent in payload
  if (payload.cart_items) {
    cartData.cart_items = payload.cart_items;
    cartData.cart_item_count = payload.cart_item_count;
  }

  return {
    ...state,
    cart: {
      ...state.cart,
      cartData
    }
  };
};

actions.setStrongholdInfo = (state, action) => {
  return { ...state, cart: { ...state.cart, customerData: { ...state.cart.customerData, ...action.payload } } };
};
//call this at
actions.callOrderMadeSegment = (state, action) => {
  const t = setTimeout(() => {
    if (window?.analytics?.track && typeof window.analytics.track === 'function') {
      window.analytics.track('Order Made', action.payload, {
        integrations: {
          Klaviyo: true
        }
      });
    }

    clearTimeout(t);
  }, 4000);

  return state;
};

actions.setKindTapInfo = (state, action) => {
  return { ...state, cart: { ...state.cart, customerData: { ...state.cart.customerData, ...action.payload } } };
};

actions.setLedgerGreenCardData = (state, action) => {
  return { ...state, cart: { ...state.cart, ledgerGreenCardData: action.payload } };
};

actions.setLedgerGreenComplianceFee = (state, action) => {
  return { ...state, cart: { ...state.cart, complianceFee: action.payload } };
};

actions.setIsCartRequestFailed = (state, action) => {
  return { ...state, cart: { ...state.cart, isCartRequestFailed: action.payload } };
};

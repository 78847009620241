import React, { useContext, useEffect } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Header from '../components/Header';
import ItemNotFound from '../components/ItemNotFound';
import appContext from '../Context/appContext';
import * as WEBSITES from '../constants/website';
import { isNewHomePageApplicable } from '../constants/feature';
import { popNotificationAll } from '../redux/slices/modalSlice';
import { useDispatch } from 'react-redux';

function Page404({
  showNavbar,
  titleMessage = 'Error 404',
  subMessage = 'The page you are looking for does not exist',
  openInPopup = false,
  hideTabsInError = false,
  asapProductsCount = 0,
  scheduledProductsCount = 0
}) {
  // const { dispatch } = useContext(appContext);
  const dispatchRedux = useDispatch()
  const router = useRouter();

  function closeProductPopup() {
    dispatchRedux(popNotificationAll());
  }

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_APP === WEBSITES.CLAYBOURNE) {
      router.replace('/');
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" name="robots" content="noindex" />
        <title>404 | Not Found</title>
      </Head>

      {showNavbar ? (
        <Header
          hasShadow
          hasWhiteBg
          cartItemCount={0}
          validZipCode={null}
          hideTabsInError={hideTabsInError}
          showLogin={!isNewHomePageApplicable}
          showProfile
          asapProductsCount={asapProductsCount}
          scheduledProductsCount={scheduledProductsCount}
          showUserInfo={isNewHomePageApplicable}
          showDeliverySpecificMessage={isNewHomePageApplicable}
          help={isNewHomePageApplicable}
        />
      ) : null}

      <div className="container page-404">
        <ItemNotFound className="eight-rem-pt" text={subMessage}>
          <h3>{titleMessage}</h3>
        </ItemNotFound>
        <div className="text-center">
          {process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true' ? (
            <Link href="/checkout">
              <a href="/checkout" onClick={openInPopup ? closeProductPopup : null} className="text-underline">
                Go to Checkout
              </a>
            </Link>
          ) : (
            <Link prefetch={false} href="/">
              <a href="/" onClick={openInPopup ? closeProductPopup : null} className="text-underline">
                Go to Home
              </a>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}

export default Page404;

import React from 'react';

import { CLAYBOURNE, GRASSDOOR } from '../constants/website';
import envAppConfig from '../constants/envAppConfig';

import appConfig from '../appConfig';

const Prop65 = ({ className = '', cartPage = false, serverHost }) => {
  const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';
  const warningImage = isCheckoutOnly
    ? `${process.env.NEXT_PUBLIC_IMAGES_STATIC_URL}/images/warning.svg`
    : '/static/images/warning.svg';

  return (
    <>
      {process.env.NEXT_PUBLIC_APP === CLAYBOURNE ? (
        <div className={`prop-65  d-flex  align-items-start ${className}`}>
          <img alt="warning" className="mr-2 mt-1 warning-logo" height="25" src={warningImage} />

          <small className="p-text">
            All of our products are listed as tested, however, sometimes there may be a variance up to 10% in THC content
            depending on if there are multiple batches in our inventory.
          </small>
        </div>
      ) : null}

      <div className={`prop-65  d-flex  align-items-start ${className}`}>
        {/* <img alt="warning" className="mr-2 mt-1 warning-logo" height="25" src="/static/images/warning.svg" /> */}
        <small className="p-text">
          <b>WARNING: </b>
          {process.env.NEXT_PUBLIC_APP === 'cannacraft'
            ? 'Cancer and Reproductive Harm -'
            : 'This product can expose you to chemicals, including Cannabis Smoke, which are known to the State of California to cause cancer and birth defects or other reproductive harm. For more information go to'}{' '}
          <a
            href="https://www.p65warnings.ca.gov"
            target="_blank"
            rel="noreferrer"
            className="p-0 vertical-align-inherit break-links font-medium zipps-link link-color"
          >
            https://www.P65Warnings.ca.gov
          </a>
          <br />
          <br />
          {cartPage ? (
            <>
              {process.env.NEXT_PUBLIC_APP === GRASSDOOR && !isCheckoutOnly ? (
                <p>
                  By clicking below to place your order, you are reaffirming your acceptance of our{' '}
                  <a
                    className="text-underline"
                    test-id="#terms_of_use"
                    title="Terms of Use"
                    href="/termsandconditions"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{' '}
                  &{' '}
                  <a
                    className="text-underline"
                    test-id="#privacy_policy"
                    title="Privacy Policy"
                    href="/privacypolicy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </p>
              ) : (
                <p>
                  By clicking below to place your order, you are reaffirming your acceptance of our{' '}
                  <a
                    className={`text-underline`}
                    href={envAppConfig?.[serverHost]?.TERMS || appConfig.TERMS || '/termsandconditions'}
                    target="_blank"
                    test-id="#terms_of_use"
                    rel="noreferrer"
                  >
                    Terms of Use
                  </a>{' '}
                  &{' '}
                  <a
                    className={`text-underline`}
                    href={envAppConfig?.[serverHost]?.PRIVACY || appConfig.PRIVACY || '/privacypolicy'}
                    target="_blank"
                    rel="noreferrer"
                    test-id="#privacy_policy"
                  >
                    Privacy Policy
                  </a>{' '}
                  {/* and our partner’s{' '}
                  <a
                    className={`text-underline`}
                    href="https://grassdoor.com/termsandconditions"
                    target="_blank"
                    rel="noreferrer"
                    test-id="#terms_of_use"
                  >
                    Terms of Use
                  </a>{' '}
                  &{' '}
                  <a
                    className={`text-underline`}
                    href="https://grassdoor.com/privacypolicy"
                    target="_blank"
                    rel="noreferrer"
                    test-id="#privacy_policy"
                  >
                    Privacy Policy
                  </a> */}
                </p>
              )}
            </>
          ) : null}
          {isCheckoutOnly && cartPage ? (
            <p>
              Delivery operated and powered by GE United Technologies LLC d/b/a Grassdoor (License no C9-0000402-LIC,
              C9-0000491-LIC)
            </p>
          ) : null}
        </small>
      </div>
    </>
  );
};

export default Prop65;

import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import OfferTimer from './OfferTimer';
import XyProductOffers from './XyProductOffers';
import appContext from '../Context/appContext';
import notificationTypes from '../constants/modalNotificationConst';
import { SCHEDULE_TYPE_FUTURE } from '../constants/scheduleType';
import useAPI from '../NetworkCall/API';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { setCartData } from '../redux/slices/cartSlice';
import { pushNotification } from '../redux/slices/modalSlice';

const BuyXGetYOffersList = ({ data, showOnlyApplied, confirmation = false, delivered = false }) => {

  const [isofferValid, setIsOfferValid] = useState(false);

  // const {
  //   // state: {
  //   // user: {
  //   //   isLoggedIn,
  //   //   deliveryDetails: { deliveryTypeSelected }
  //   // },
  //   // cart: { cartData = {} }
  //   // },
  //   dispatch
  // } = useContext(appContext);

  const cartData = useSelector(state => state.cart?.cartData) || {}
  const {
    isLoggedIn,
    deliveryDetails: { deliveryTypeSelected }
  } = useSelector(state => state.user);

  const dispatchRedux = useDispatch()
  const { getCart, manageProductOffer } = useAPI();
  const { offer_product: offerProduct, is_sold_out } = data;
  const { cart_items: cartItems = [] } = cartData;

  const router = useRouter();
  const { pathname } = router;
  let itemInCart = null;
  if (data.bundle_id) {
    itemInCart = cartItems.find(item => item.bundle_id == data.bundle_id);
  } else {
    itemInCart = cartItems.find(item => item.product_details_id == data.product_details_id);
  }

  useEffect(async () => {
    await getCart();
  }, [itemInCart?.is_offer_allowed]);

  const removeOffer = async (product, status) => {
    const response = await manageProductOffer(product, status);
    if (response) {
      // Find the item in cart_items using master_product_id
      const updatedCartItems = cartData.cart_items.map(item => {
        if (item.master_product_id === product.master_product_id) {
          return { ...item, is_offer_allowed: status };
        }
        return item;
      });

      // Update cartData with the modified cart_items
      const updatedCartData = { ...cartData, cart_items: updatedCartItems };

      // Dispatch the updated cartData
      dispatchRedux(setCartData(updatedCartData));
    }
  };


  // const handleAddOfferToCart = offer => {
  //   let itemInCart = null;
  //   if (data.bundle_id) {
  //     itemInCart = cartItems.find(item => item.bundle_id == data.bundle_id);
  //   } else {
  //     itemInCart = cartItems.find(item => item.product_details_id == data.product_details_id);
  //   }

  //   const offerAvailed = data.offer_product.filter(item => {
  //     return item.id == offer.id;
  //   });

  //   // if (offerAvailed[0].is_avail) {
  //   //   offerAvailed[0].is_avail += 1;
  //   // } else {
  //   //   offerAvailed[0].is_avail = 1;

  //   // }
  //   const prevInstance = itemInCart ? JSON.parse(JSON.stringify(itemInCart)) : [];
  //   const freeQty = findFreeQty(prevInstance);
  //   if (offerAvailed[0].user_preference) {
  //     offerAvailed[0].user_preference += 1;
  //   } else {
  //     offerAvailed[0].user_preference = 1;
  //   }
  //   if (itemInCart) {
  //     const updatedOffer = [...new Set([...data?.offer_product])];

  //     const qty = freeQty ? offer?.buy_products_buy_quantity - freeQty : offer?.buy_products_buy_quantity;
  //     let newQty = 0;
  //     if (freeQty >= offer?.buy_products_buy_quantity) {
  //       newQty = offer?.buy_products_buy_quantity - (freeQty % offer?.buy_products_buy_quantity);
  //       offerAvailed[0].user_preference += parseInt(freeQty / offer?.buy_products_buy_quantity);
  //     } else if (freeQty < offer?.buy_products_buy_quantity) {
  //       newQty = offer?.buy_products_buy_quantity - freeQty;
  //     } else {
  //       newQty = offer?.buy_products_buy_quantity;
  //     }

  //     updateCartItem({ product: { ...data, quantity: itemInCart.quantity + newQty, offer_product: updatedOffer } });

  //     //   if(data?.product_max_quantity<itemInCart.quantity + offer?.buy_products_buy_quantity){
  //     //       toast.error("exceeding maximum quantity");
  //     //   }
  //     //   else{
  //     //       const updatedOffer=[...new Set([...data?.offer_product, offerAvailed[0]])]
  //     //       updateCartItem({ product: { ...data, quantity: itemInCart.quantity + offer?.buy_products_buy_quantity, offer_product:updatedOffer } }, "AvailOfferClicked");

  //     //   }
  //   } else {
  //     //   if(data?.product_max_quantity<offer?.buy_products_buy_quantity){
  //     //       toast.error("exceeding maximum quantity");
  //     //   }
  //     //   else{
  //     //       const updatedOffer=[...new Set([...data?.offer_product, offerAvailed[0]])]
  //     //       addToCart({ product: { ...data, quantity: offer?.buy_products_buy_quantity, offer_product:updatedOffer } }, "AvailOfferClicked");

  //     //   }

  //     const updatedOffer = [...new Set([...data?.offer_product, offerAvailed[0]])];
  //     addToCart(
  //       { product: { ...data, quantity: offer?.buy_products_buy_quantity, offer_product: updatedOffer } },
  //       'AvailOfferClicked'
  //     );
  //   }
  // };

  // const findFreeQty = itemInCart => {
  //   const totalQty = itemInCart?.quantity ? itemInCart?.quantity : 0;
  //   let usedUpQty = 0;

  //   if (itemInCart?.offer_product) {
  //     itemInCart?.offer_product.forEach(singleOffer => {
  //       if (singleOffer?.user_preference) {
  //         usedUpQty += singleOffer?.user_preference * singleOffer?.buy_products_buy_quantity;
  //       }
  //     });
  //   }
  //   const freeQty = totalQty - usedUpQty;
  //   return freeQty;
  // };

  const viewOffers = () => {
    dispatchRedux(pushNotification({
      type: notificationTypes.viewOffers,
      data
    }))
  };

  const isAvailed = itemInCart?.offer_product?.find(offer => offer.is_avail);

  useEffect(() => {
    if (itemInCart?.offer_product?.length || offerProduct?.length) {
      if ((showOnlyApplied && isAvailed) || (!showOnlyApplied && (itemInCart?.offer_product?.length || offerProduct?.length))) {
        const offerHaveProduct = (
          confirmation ? offerProduct : (itemInCart && itemInCart.offer_product) || offerProduct || []
        ).find(offer => offer.products.length > 0);
        if (offerHaveProduct) {
          setIsOfferValid(true);
        }
      }
    }
  }, [offerProduct]);

  if (showOnlyApplied && !isLoggedIn && deliveryTypeSelected !== SCHEDULE_TYPE_FUTURE) {
    return <></>;
  }

  return isofferValid || offerProduct?.length ? (
    <>
      {
        // viewOffer
        showOnlyApplied && offerProduct?.length ? (
          <div className="xy-product-offer widget-offer">
            <span className="icon-offer-tag" />
            <a onClick={viewOffers}>
              <span className="cursor-pointer">View offers</span>
            </a>
          </div>
        ) : null
      }


      {(showOnlyApplied && isAvailed) || (!showOnlyApplied && (itemInCart?.offer_product?.length || offerProduct?.length)) ? (
        <div className={itemInCart ? itemInCart?.is_offer_allowed ? 'xy-product-offers-wrapper offer-allowed' : 'xy-product-offers-wrapper offer-not-allowed' : 'xy-product-offers-wrapper offer-allowed'}>
          <span className="offer-tag">
            {/* <span className="icon-offer-tag"></span> */}
            {confirmation ? 'Offer Product' : isAvailed && itemInCart.is_offer_allowed ? 'Offer Applied' : 'Offer'}
          </span>

          {
            !pathname.includes('/confirmation/') && itemInCart && isAvailed ?
              itemInCart?.is_offer_allowed ?
                <button className="add-remove-offer" onClick={() => removeOffer(itemInCart, 0)}>
                  Remove Offer
                </button>
                :
                <button className="add-remove-offer" onClick={() => removeOffer(itemInCart, 1)}>
                  Add Offer
                </button>
              :
              null
          }

          {(confirmation ? offerProduct : (itemInCart && itemInCart.offer_product) || offerProduct || []).map(offer => {
            if (showOnlyApplied || confirmation) {
              return (offer?.is_avail || confirmation) ? (
                <div
                  className={`xy-product-offer ${offer?.is_avail || confirmation ? 'offer-applied' : ''}`}
                  key={`xy-offer${offer?.id}`}
                >
                  <XyProductOffers data={offer} isAvailedCount={offer?.is_avail} showOnlyApplied confirmation={confirmation} delivered={delivered} />
                </div>
              ) : null;
            }

            return (
              <div className={`xy-product-offer ${offer?.is_avail ? 'offer-applied' : ''}`} key={`xy-offer${offer?.id}`}>
                <XyProductOffers data={offer} isAvailedCount={offer?.is_avail} confirmation={confirmation} delivered={delivered} />
                {!is_sold_out ? (
                  <div className="text-center">
                    <OfferTimer endDate={new Date(offer.campaign_end_date)} />
                    {/* <button className="btn btn-outline-primary" onClick={() => handleAddOfferToCart(offer)}>
                        {!offer?.is_avail ? 'Avail Offer' : 'Offer availed, apply again'}
                      </button> */}
                  </div>
                ) : null}
                {
                /*offer?.is_avail && itemInCart?.is_offer_allowed ? (
                  <div className="availed-text text-center">
                    <span className="icon-benefits-tickmark" />
                    Offer Applied!
                  </div>
                ) : null
                */}
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  ) : null;
};
export default BuyXGetYOffersList;

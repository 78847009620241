import getCardType from './getCardType';
import nameValidationWithError from './nameValidationWithError';
import appConfig from '../appConfig';

export default function validateCard(cardDetails, additionalDetails) {
  const { number = '', exp = '', cvv, name, zipcode, legderGreenCard = false } = cardDetails;
  const cardErrors = {};
  if (!number || number.length < 1) {
    cardErrors.number = `Please enter a ${!legderGreenCard ? 'Debit' : ''} card number`;
  }
  if (!getCardType(number.replace(/ /g, '') || number.length < 5)) {
    cardErrors.number = `${!legderGreenCard ? 'Debit' : ''} Card number is not valid`;
  }
  if (!exp || exp.length < 1) {
    cardErrors.exp = `Please enter a ${!legderGreenCard ? 'Debit' : ''} card expiry date`;
  } else {
    const cardExpParts = exp.split('/');
    const cardExpMonth = cardExpParts[0];
    const cardExpYear = cardExpParts[1];
    if (cardExpParts.length < 2) {
      cardErrors.exp = `Please enter a month and year for ${!legderGreenCard ? 'Debit' : ''} card expiry`;
    }
    if (cardExpMonth < 0 || cardExpMonth > 12) {
      cardErrors.exp = 'Please enter a valid month';
    }
    const date = new Date();
    const currentYear = date.getFullYear() % 100;
    const currentMonth = date.getMonth() + 1; // as January is 0

    if (Number(cardExpYear) < currentYear || (Number(cardExpYear) <= currentYear && Number(cardExpMonth) < currentMonth)) {
      cardErrors.exp = `${!legderGreenCard ? 'Debit' : ''} Card has already expired`;
    }
  }
  if (!cvv || cvv.length < 1) {
    cardErrors.cvv = `Please enter your ${!legderGreenCard ? 'Debit' : ''} card CVV`;
  }
  const cardNameError = nameValidationWithError(name, 'Card Holder Name');
  if (cardNameError) {
    cardErrors.name = cardNameError;
  }
  if (legderGreenCard && (!zipcode || zipcode.trim().length < 1)) {
    cardErrors.zipcode = 'Please enter your zipcode';
  }
  // if (additionalDetails) {
  //   const { isSuperOrVerified, postcode, isCardVerified } = additionalDetails;
  //   if (!isSuperOrVerified && !isCardVerified) {
  //     if (!zipcode || zipcode.trim().length < 1) {
  //       cardErrors.zipcode = 'Please Enter your zipcode';
  //     }
  //     if (zipcode !== postcode && appConfig.isGrassdoor && !additionalDetails.hasGeofenceInventory) {
  //       cardErrors.zipcode = 'Debit card billing address zipcode must match delivery address zipcode';
  //     }
  //   }
  // }
  return cardErrors;
}

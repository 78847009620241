import getNonWhiteSpaceCharacterLength from './getNonWhiteSpaceCharacterLength';

export default function validNumber(number) {
  const trimmedNumber = number.trim();
  if (getNonWhiteSpaceCharacterLength(trimmedNumber) > 0) {
    const re = /^[0-9]{10,}$/;
    return re.test(trimmedNumber);
  }
  return false;
}

import React, { useContext, useRef, useEffect } from 'react';
import HraderDeliveryAddress from '../DTC/bento/HraderDeliveryAddress';
import { useRouter } from 'next/router';
import appContext from '../../Context/appContext';

const SearchButton = () => {
  // const { dispatch } = useContext(appContext);

  // const searchRef = useRef(null);
  const router = useRouter();
  const redirectTosearch = () => {
    router.push('/search');
  };

  // useEffect(() => {
  //   const currentRef = searchRef.current;
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.intersectionRatio === 0) {
  //         dispatch({ type: 'setSearchTabs', payload: true });
  //       } else {
  //         dispatch({ type: 'setSearchTabs', payload: false });
  //       }
  //     },
  //     {
  //       root: null,
  //       rootMargin: '0%',
  //       threshold: 0
  //     }
  //   );

  //   if (currentRef) {
  //     observer.observe(currentRef);
  //   }

  //   return () => {
  //     if (currentRef) {
  //       observer.unobserve(currentRef);
  //     }
  //   };
  // }, [searchRef.current]);

  return (
    <div id="search-category-wrapper" className={`search-category-wrapper`}>
      <div className={`searchBox`}>
        <div className="new-search-component">
          <div className="input-group searchBar">
            <div className="input-group searchBar">
              <HraderDeliveryAddress />
              <div onClick={redirectTosearch} className="mobile-search-box">
                <p className="w-100 d-flex align-items-center mb-0">Try "Indica" or "Hybrid"</p>
                <i className="icon-search" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchButton;

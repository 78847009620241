export default function emailValidationWithError(email, optional = false) {
  if (email && email.length > 0) {
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const success = emailRegEx.test(email);
    if (!success) {
      return 'Please enter a valid email';
    }
  } else if (!optional) {
    return 'Please enter an email';
  }
  return null;
}

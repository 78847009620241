import { ASAP, SCHEDULE, ASAP_AND_SCHEDULE } from '../constants/deliveryType';

export default function getCartType(cartItems) {
  let type = ASAP_AND_SCHEDULE;
  cartItems &&
    cartItems.every(cartItem => {
      if (!cartItem.asap) {
        type = SCHEDULE;
        return false;
      }
      if (!cartItem.schedule) {
        type = ASAP;
        return false;
      }
      if ((cartItem.asap && cartItem.available_quantity == 0) || (cartItem.asap && cartItem.available_quantity === null)) {
        type = SCHEDULE;
        return false;
      }
      if (cartItem.schedule && cartItem.product_max_quantity == 0) {
        type = ASAP;
        return false;
      }
      return true;
    });
  return type;
}

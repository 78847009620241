
export const DEFAULT_ADDRESS = 'Los Angeles, CA, USA';
export const DEFAULT_LOCATION = { lat: 34.0127573, lng: -118.3279172 };
export const DEFAULT_CITY = 'Carson';
export const DEFAULT_STATE = 'CA';
export const DEFAULT_COUNTRY = 'US';
export const DEFAULT_ZIPCODE = '00000';
export const DEFAULT_ZONE_ID = '0';
export const DEFAULT_DATETIME = 'select date time';
export const DEFAULT_CATEGORY_MENU = [
  {
    id: 1,
    name: 'All',
    link: 'https://grassdoor.com/',
    logo_url: 'https://prod-serverless.grassdoor.com/grassdoor.com/homepage/logo/1629460984.png',
    logo_thumb_url: 'https://prod-serverless.grassdoor.com/grassdoor.com/homepage/logo/thumb/1629460984.png'
  }
];
export const localLicenseNumber = `Grassdoor (C9-0000402-LIC, C9-0000491-LIC)`;
export const DEFAULT_GIFTCARD_ADDRESS = 'Oakwood, CA 90004, USA';
export const DEFAULT_GIFTCARD_LOCATION = { lat: 34.0748887, lng: -118.3082034 };
export const DEFAULT_GIFTCARD_CITY = 'Los Angeles';
export const DEFAULT_GIFTCARD_STATE = 'CA';
export const DEFAULT_GIFTCARD_COUNTRY = 'US';
export const DEFAULT_GIFTCARD_ZIPCODE = '90004';
export const DEFAULT_GIFTCARD_PLACE_ID = 'ChIJnUiVTaO4woARcteG_AhMtbg';

export const DEFAULT_CATEGORY = 'california-sun-grown';
export const DEFAULT_CATEGORY_2 = 'flower';

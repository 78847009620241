
export default {
  shopRedirects: {
    "cbd-products": "/categories/cannabis-edibles",
    "oil-cartridges-pax-pods-and-vape-pens": "/categories/vapes",
    "pre-rolls-and-blunts": "/categories/pre-roll",
    "thc-concentrates": "/categories/cannabis-concentrates",
    "abx-absolutextracts-products": "/brands/absolute-xtracts",
    "pacific-stone-live-resin-cartridges": "/",
    "cbd-care-by-design-products": "/brands/care-by-design",
    "premium-live-resin-pax-pods": "/brands/pax",
    "jeeter-juice-disposable-live-resin-straw": "/product/fruity-pebbles-jeeter-juice",
    "connected-live-resin-disposable-pen": "/product/gushers-live-resin",
    "lowell-farms-products": "/brands/lowell-farms",
    "cbd-care-by-design-products": "/brands/care-by-design",
    "raw-garden-disposable-pen": "/brands/raw-garden",
    "jetty-live-resin-510-cartridge": "/brands/jetty-extracts",
    "dosist-bundles": "/product/relief",
    "space-coyote-bundles": "/brands/space-coyote",
    "raw-garden-disposable-pen": "/brands/raw-garden",
    "indoor-1-2-ounce-flower": "/product/wedding-cake-559",
    "sun-grown-full-ounce-flower": "/",
    "papa-barkley-wellness-products": "/brands/papa-barkley",
    "plus-gummies-972": "/brands/PLUS",
    "dosist-vapes": "/brands/dosist",
    "heavy-hitters-disposable-pen": "/product/jack-herer-ultra-potent-disposable",
    "full-ounces-of-flower": "/categories/cannabis-flower",
    "jetty-gold-510-cartridge": "/product/pot-of-gold-cartridge",
    "click-sprays": "/product/click-spray-chill",
    "pride-month": "/",
    "bloom-farms-live-resin-510-cartridge": "/product/blueberry-cookies-live-resin",
    // "jelly-10-menu-deal": "/shop/jelly-10-menu-deal",
    "heavy-hitters-ultra-line": "/bundle/ultra-pure-ultra-potent",
    "1-4-ounces-of-flower": "/",
    "pride-month-bundles": "/",
    "1-2-ounces-of-flower": "/",
    "moxie-vape-cartridges": "/brands/mx",
    "sleepy-time-products": "/product/abx-sleepy-time-drops",
    "1-gram-flower": "/shop/grams-of-flower",
    "cbd-care-by-design-products": "/brands/care-by-design",
    "sativa-flower": "/shop/sativa-1-8-ounce-flower",
    "heavy-hitters-disposable-pen": "/product/jack-herer-ultra-potent-disposable",
    "stiizy-pods": "/",
    "bloom-farms-live-resin-510-cartridge": "/product/blueberry-cookies-live-resin",
    "legion-of-bloom-monarch-510-cartridge": "/product/tangelo"
  },
  brandRedirects: {
    "kingsgard": "/",
    "oceangrownextracts": "/product/sour-watermelon-gummies",
    "west-coast-sunrise": "/product/california-og",
    "westcoastsunrise": "/product/california-og",
    "1234": "/brands/emerald-sky",
    "PBR": "/brands/pabst",
    "SundaeSchool": "/brands/sundae-school",
    "almora": "/brands/almora-farm",
    "sauce": "/brands/sauce-essentials"
  },
  productRedirects: {
    "kiva-wild-berry-gummies": "/categories/cannabis-edibles",
    "skywalker-og-336": "/",
    "creative-thinking-journal": "/product/critical-thinking-journal",
    "jack-herer-sativa": "/categories/vapes",
    "doc-og-indica": "/categories/pre-roll",
    "grand-daddy-purple-indica": "/product/grandaddy-purple",
    "skywalker": "/",
    "watermelon-gummies-by-elefante": "/categories/cannabis-edibles",
    "blue-dream-hybrid-320": "/categories/vapes",
    "nina-limone-sativa": "/",
    "maui-wowie-sativa-363": "/",
    "mendo-breath-ab": "/product/mendo-breath",
    // "jack-herer-sativa": "/",
    "beary-white-indica": "/product/beary-white-800",
    "mendo-berries-560": "/product/mendo-berries",
    "orange-cookies-3-5g": "/product/orange-cookies-1110",
    "naked-fuzzies-indica-infused-5-pack": "/product/gellati-naked-fuzzies-live-resin-5-pack",
    "granddaddy-purple-indica": "/product/granddaddy-purple-1g",
    "pax-era-pro-red": "/product/pax-era-pro-device-red",
    "banana-bliss-7g": "/product/banana-bliss-7g",
    "pomegranate-punch-hybrid": "/product/pomegranate-punch",
    "cbd-10": "/",
    "blue-dream-sativa": "/product/blue-dream-977",
    // "do-si-dos-hybrid-335": "/product/do-si-dos-hybrid-335",
    "7-pre-rolls-tangerine-dream": "/product/tangerine-dream",
    "test_lighter_two": "/",
    "test-2": "/",
    "dream-queen-sativa-713": "/product/dream-queen-1194",
    "dosifi-43": "/product/dosifi-43-resin",
    "sleepy-time-vape-cartridge": "/product/sleepy-time-vape-cartridges-absolutextracts",
    "grape-chemade-hybrid": "/product/grape-jelly",
    "pax-era-pro-gray": "/product/pax-era-pro-device-gray",
    "king-louie-indica": "/product/king-louis-og",
    "lost-farm-raspberry-x-blue-dream-gummy": "/product/raspberry-x-blue-dream-gummies",
    "grand-daddy-purple-vape-cartridge": "/product/abx-grandaddy-purp",
    "blue-burst-hybrid": "/product/blue-burst",
    "wedding-crashers-by-jelly": "/product/wedding-crasher",
    "lime-walker-raw-garden": "/product/lime-walker-resin",
    "mandarin-guava-3-sativa": "/product/mandarin-guava-3",
    "original-glue-4-hybrid": "/product/original-glue-4",
    "3x-crazy-indica": "/product/3x-crazy",
    "white-fire-punch-raw-garden": "/product/white-fire-punch-resin",
    "presidential-og-indica": "/product/presidential-og",
    "maui-wowie-sativa-363": "/product/maui-wowie-one",
    "fire-og---indica": "/product/fire-og",
    "trainwreck-sherbert-hybrid": "/product/trainwreck-sherbert-61",
    "sleepy-time-sublingual-drops": "/product/abx-sleepy-time-drops",
    "purple-kush-indica": "/product/critical-purple-kush",
    "california-sauce-indica": "/product/cs",
    "xxx-og-hybrid": "/product/xxx-og-2784",
    "zookies-hybrid": "/product/zookies-548",
    "sleepy-time-thc-soft-gels": "/product/sleepy-time-5mg-thc-soft-gels-30-capsules",
    "do-si-dos-hybrid-335": "/product/do-si-dos-335",
    "jelly-sour-waternelon": "/product/jelly-sour-watermelon",
  },
  bundleRedirects: {
    "bliss-lemon-lime-gummies-4-for-3": "/product/bliss-lemon-lime",
    "bloom-vape-bogo": "/product/jet-fuel-live-resin-0-5g",
    "jeeter-bundle": "/product/baby-jeeter-fire-og-5-pack",
    "bliss-wildberry-gummies-4-for-3": "/product/bliss-wildberry",
    "bundle-3": "/",
    "a-little-bit-country-a-little-bit-rock-n-roll": "/",
    "white-walker-og-preroll-box": "/",
    "sativa-bogo": "/",
    "bundle-1": "/",
    "your-couches-best-friend-ultra-indica-bundle": "/",
    "live-resin-bogo": "/",
    "raw-garden-cart-bundle": "/brands/raw-garden",
    "liven-up-your-puff-ultra-sativa-bundle": "/",
    "fireworks-bundle": "/",
    "jah-goo-7g": "/product/jah-goo-7g",
    "hash-pack": "/",
    "jah-goo-1-8th": "/product/jah-goo",
    "diamonds-pack": "/",
    "pai-gow-1-8th": "/product/pai-gow-2846",
    "pineapple-punch-1-8th": "/product/pineapple-express",
    "kush-mountains-preroll-box": "/product/kush-mountains",
    "green-crack-king-louis-5g": "/product/green-crack-0-5g-2589",
    "hybrid-peanut-butter-cups": "/product/peanut-butter-cups-hybrid-10-pack",
    "live-resin-and-peanut-butter-cups": "/brands/1234",
    "cookies-and-peanut-butter-cups": "/product/halloween-peanutbutter-cups",
    "10-pack-lamb-s-bread": "/product/lamb-s-bread-7-pk",
    "blueberry-compote": "/product/blueberry-compote-7-pk",
    "sativa-peanut-butter-cups": "/product/peanut-butter-cups-sativa-10-pack",
    "live-resin-pack": "/",
    "nepali-pink-1-8th": "/product/nepali-pink",
    "10-pack-oreo-cookies": "/product/oreo-cookies-7-pk",
    "cranberry-sage-space-crystals-25-pack": "/product/cranberry-sage-space-crystals",
    "banana-bliss-7g": "/"
  },
  cityRedirects: {
    "bay-area/san-jose": "/weed-delivery-near/san-jose",
    "los-angeles/west-hollywood": "/weed-delivery-near/west-hollywood",
    "bay-area/daly-city": "/weed-delivery-near/daly-city",
    "los-angeles/long-beach": "/weed-delivery-near/long-beach",
    "bay-area/san-mateo": "/weed-delivery-near/san-mateo",
    "los-angeles/north-hollywood": "/weed-delivery-near/north-hollywood",
    "los-angeles/van-nuys": "/weed-delivery-near/van-nuys",
    "orange-county/la-habra": "/weed-delivery-near/la-habra",
    "los-angeles/woodland-hills": "/weed-delivery-near/woodland-hills",
    "bay-area/santa-clara": "/weed-delivery-near/santa-clara",
    "thousand-oaks/newbury-park": "/weed-delivery-near/newbury-park",
    "bay-area/santa-cruz": "/weed-delivery-near/santa-cruz",
    "los-angeles/huntington-beach": "/weed-delivery-near/huntington-beach",
    "bay-area/san-rafael": "/weed-delivery-near/san-rafael",
    "los-angeles/west-covina": "/weed-delivery-near/west-covina",
    "orange-county/mission-viejo": "/weed-delivery-near/mission-viejo",
    "los-angeles/redondo-beach": "/weed-delivery-near/redondo-beach",
    "bay-area/redwood-city": "/weed-delivery-near/redwood-city",
    "los-angeles/garden-grove": "/weed-delivery-near/garden-grove",
    "los-angeles/sherman-oaks": "/weed-delivery-near/sherman-oaks",
    "orange-county/laguna-hills": "/weed-delivery-near/laguna-hills",
    "bay-area/union-city": "/weed-delivery-near/union-city",
    "los-angeles/marina-del-rey": "/weed-delivery-near/marina-del-rey",
    "bay-area/menlo-park": "/weed-delivery-near/menlo-park",
    "bay-area/palo-alto": "/weed-delivery-near/palo-alto",
    "orange-county/dana-point": "/weed-delivery-near/dana-point",
    "orange-county/laguna-beach": "/weed-delivery-near/laguna-beach",
    "bay-area/san-bruno": "/weed-delivery-near/san-bruno",
    "bay-area/morgan-hill": "/weed-delivery-near/morgan-hill",
    "orange-county/newport-beach": "/weed-delivery-near/newport-beach",
    "los-angeles/san-gabriel": "/weed-delivery-near/san-gabriel",
    "los-angeles/valley-village": "/weed-delivery-near/valley-village",
    "los-angeles/west-hills": "/weed-delivery-near/west-hills",
    "los-angeles/culver-city": "/weed-delivery-near/culver-city",
    "los-angeles/pico-rivera": "/weed-delivery-near/pico-rivera",
    "orange-county/rancho-santa-margarita": "/weed-delivery-near/rancho-santa-margarita",
    "bay-area/alameda": "/weed-delivery-near/alameda",
    "bay-area/berkeley": "/weed-delivery-near/berkeley",
    "bay-area/brisbane": "/weed-delivery-near/brisbane",
    "bay-area/campbell": "/weed-delivery-near/campbell",
    "bay-area/dublin": "/weed-delivery-near/dublin",
    "bay-area/fremont": "/weed-delivery-near/fremont",
    "bay-area/hayward": "/weed-delivery-near/hayward",
    "bay-area/oakland": "/weed-delivery-near/oakland",
    "bay-area/sunnyvale": "/weed-delivery-near/sunnyvale",
    "bay-area/san-francisco": "/weed-delivery-near/san-francisco",
    "los-angeles/anaheim": "/weed-delivery-near/anaheim",
    "los-angeles/burbank": "/weed-delivery-near/burbank",
    "los-angeles/calabasas": "/weed-delivery-near/calabasas",
    "los-angeles/reseda": "/weed-delivery-near/reseda",
    "los-angeles/venice": "/weed-delivery-near/venice",
    "orange-county/irvine": "/weed-delivery-near/irvine",
    "orange-county/tustin": "/weed-delivery-near/tustin",
    "thousand-oaks/thousand-oaks": "/weed-delivery-near/thousand-oaks",
    "thousand-oaks": "/weed-delivery-near/thousand-oaks",
    "los-angeles/whittier": "/weed-delivery-near/whittier",
    "weed-delivery-san-jose": "/weed-delivery-near/san-jose",
    "weed-delivery-west-hollywood": "/weed-delivery-near/west-hollywood",
    "weed-delivery-daly-city": "/weed-delivery-near/daly-city",
    "weed-delivery-long-beach": "/weed-delivery-near/long-beach",
    "weed-delivery-san-mateo": "/weed-delivery-near/san-mateo",
    "weed-delivery-north-hollywood": "/weed-delivery-near/north-hollywood",
    "weed-delivery-van-nuys": "/weed-delivery-near/van-nuys",
    "weed-delivery-la-habra": "/weed-delivery-near/la-habra",
    "weed-delivery-woodland-hills": "/weed-delivery-near/woodland-hills",
    "weed-delivery-santa-clara": "/weed-delivery-near/santa-clara",
    "weed-delivery-newbury-park": "/weed-delivery-near/newbury-park",
    "weed-delivery-santa-cruz": "/weed-delivery-near/santa-cruz",
    "weed-delivery-huntington-beach": "/weed-delivery-near/huntington-beach",
    "weed-delivery-san-rafael": "/weed-delivery-near/san-rafael",
    "weed-delivery-west-covina": "/weed-delivery-near/west-covina",
    "weed-delivery-mission-viejo": "/weed-delivery-near/mission-viejo",
    "weed-delivery-redondo-beach": "/weed-delivery-near/redondo-beach",
    "weed-delivery-redwood-city": "/weed-delivery-near/redwood-city",
    "weed-delivery-garden-grove": "/weed-delivery-near/garden-grove",
    "weed-delivery-sherman-oaks": "/weed-delivery-near/sherman-oaks",
    "weed-delivery-laguna-hills": "/weed-delivery-near/laguna-hills",
    "weed-delivery-union-city": "/weed-delivery-near/union-city",
    "weed-delivery-marina-del-rey": "/weed-delivery-near/marina-del-rey",
    "weed-delivery-menlo-park": "/weed-delivery-near/menlo-park",
    "weed-delivery-palo-alto": "/weed-delivery-near/palo-alto",
    "weed-delivery-dana-point": "/weed-delivery-near/dana-point",
    "weed-delivery-laguna-beach": "/weed-delivery-near/laguna-beach",
    "weed-delivery-san-bruno": "/weed-delivery-near/san-bruno",
    "weed-delivery-morgan-hill": "/weed-delivery-near/morgan-hill",
    "weed-delivery-newport-beach": "/weed-delivery-near/newport-beach",
    "weed-delivery-san-gabriel": "/weed-delivery-near/san-gabriel",
    "weed-delivery-valley-village": "/weed-delivery-near/valley-village",
    "weed-delivery-west-hills": "/weed-delivery-near/west-hills",
    "weed-delivery-culver-city": "/weed-delivery-near/culver-city",
    "weed-delivery-pico-rivera": "/weed-delivery-near/pico-rivera",
    "weed-delivery-rancho-santa-margarita": "/weed-delivery-near/rancho-santa-margarita",
    "weed-delivery-alameda": "/weed-delivery-near/alameda",
    "weed-delivery-berkeley": "/weed-delivery-near/berkeley",
    "weed-delivery-brisbane": "/weed-delivery-near/brisbane",
    "weed-delivery-campbell": "/weed-delivery-near/campbell",
    "weed-delivery-dublin": "/weed-delivery-near/dublin",
    "weed-delivery-fremont": "/weed-delivery-near/fremont",
    "weed-delivery-hayward": "/weed-delivery-near/hayward",
    "weed-delivery-oakland": "/weed-delivery-near/oakland",
    "weed-delivery-sunnyvale": "/weed-delivery-near/sunnyvale",
    "weed-delivery-anaheim": "/weed-delivery-near/anaheim",
    "weed-delivery-burbank": "/weed-delivery-near/burbank",
    "weed-delivery-calabasas": "/weed-delivery-near/calabasas",
    "weed-delivery-reseda": "/weed-delivery-near/reseda",
    "weed-delivery-venice": "/weed-delivery-near/venice",
    "weed-delivery-irvine": "/weed-delivery-near/irvine",
    "weed-delivery-tustin": "/weed-delivery-near/tustin",
    "weed-delivery-thousand-oaks": "/weed-delivery-near/thousand-oaks",
    "weed-delivery-whittier": "/weed-delivery-near/whittier"
  },
  categoryRedirect: {
    "Tinctures": "/categories/cannabis-tinctures",
    "tinctures": "/categories/cannabis-tinctures",
    "topical": "/categories/cannabis-topicals",
    "wellness": "/categories/cannabis-wellness",
    "flower": "/categories/cannabis-flower",
    "vapes": "/categories/vapes",
    "pre-roll": "/categories/pre-rolls",
    "concentrates": "/categories/cannabis-concentrates",
    "accessories": "/categories/cannabis-accessories",
    "edibles": "/categories/cannabis-edibles",
    "drinks": "/categories/cannabis-drinks"
  },
  categoriesRedirect: {
    "pre-roll": "/categories/pre-rolls",
    "tinctures": "/categories/cannabis-tinctures"
  },
  weedDeliveryRedirects: {
    "apple-vally": "apple-valley",
    "el-monte-city": "el-monte"
  },
  brandNoIndex: [
    "alien-farms",
    "Atlas",
    "alphagreen-vape",
    "brothers-mark",
    "blaqstar-farms",
    "bodega",
    "blaqstar-farms",
    "calexo",
    "chemistry",
    "cosmic-fog-cannabis-co",
    "dime-bag",
    "dovetail",
    "emerald-bay",
    "fable",
    "fuzzies-fruitites",
    "fig-farms",
    "get-herb",
    "GoldenLiteWarehouse",
    "HighPower",
    "heirbloom",
    "kin-slips",
    "lime-cannabis",
    "lithouse",
    "mari-y-juana",
    "ooka",
    "oasis",
    "parch",
    "Praise",
    "proof",
    "ruby",
    "real-deal-resin",
    "ric-flair-drip-boutique",
    "solis",
    "SpaceGems",
    "super-wow",
    "terp-mansion",
    "tinleys",
    "the-yuzu-co",
    "zombi"
  ]
}

import { createSlice } from "@reduxjs/toolkit";

export const otherSlice = createSlice({
  name: "other",
  initialState: {
    seoData: {},
    miniTabs: false,
    searchTabs: false,
    userID: null,
    positionID: null,
    onLandingPage: true,
    newAddressForProfile: {},
    stronholdTokenData: undefined,
    checkOutUpdateDetails: {
      customerDetails: false,
      deliveryDetails: false,
      paymentDetails: false,
      deliveryType: false
    },
    optimizeLoaded: false,
    brazeLoaded: false
  },
  reducers: {
    setSeoData: (state, action) => {
      state.seoData = action.payload
    },
    setMiniTabs: (state, action) => {
      state.miniTabs = action.payload
    },
    setSearchTabs: (state, action) => {
      state.searchTabs = action.payload
    },
    updateUserID: (state, action) => {
      state.userID = action.payload
    },
    setPositionID: (state, action) => {
      state.positionID = action.payload.position
    },
    setOnLandingPage: (state, action) => {
      state.onLandingPage = action.payload
    },
    updateNewAddressForProfile: (state, action) => {
      state.newAddressForProfile = action.payload
    },
    setStronholdData: (state, action) => {
      state.stronholdTokenData = action.payload

    },
    updateCheckOutDetails: (state, action) => {
      state.checkOutUpdateDetails = action.payload
    },
    setOptimize: (state, action) => {
      state.optimizeLoaded = action.payload
    },
    setbraze: (state, action) => {
      state.brazeLoaded = action.payload
    },
  }
})

export const { setOptimize, setbraze, setSeoData, setStronholdData, updateCheckOutDetails, setMiniTabs, updateNewAddressForProfile, setOnLandingPage, setSearchTabs, updateUserID, setPositionID } = otherSlice.actions
export default otherSlice.reducer
import React, { useContext } from 'react';
import Router from 'next/router';
import { verificationCooldownTime } from '../constants/verificationConst';
import isNumber from '../helpers/isNumber';
import otpValidationWithError from '../helpers/otpValidationWithError';
import mobileNoValidationWithError from '../helpers/mobileNoValidationWithError';

import useAPI from '../NetworkCall/API';
import { popNotification } from '../redux/slices/modalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setUserInfo, toggleLoginDrawer } from '../redux/slices/userSlice';

const getInitialState = defaultValue => ({
  // Form state
  country: defaultValue.defaultCountry || '1',
  phone: defaultValue.defaultPhone || '',
  phoneError: null,
  otp: '',
  otpError: null,
  apiError: null,
  // UI state
  confirmingOTP: false,
  generatingOTP: false,
  switchBtn: false,
  displayOtpField: false,
  showVerificationField: false,
  showCancelButton: true,
  isFreeze: false,
  customTrigger: defaultValue.customTrigger
});

const defaultOptions = {
  redirectTo: true,
  defaultPhone: '',
  customTrigger: false
};

function loginReducer(state, updateState) {
  // Put vulnerable checking of coming state
  return { ...state, ...updateState };
}

const useLogin = (options = {}) => {
  const timer = React.useRef(null);
  const loginUptions = React.useRef({ ...defaultOptions, ...options });
  if (options) {
    loginUptions.current = { ...loginUptions.current, ...options };
  }
  const { confirmOtp: callConfirmOtp, requestOTP: callRequestOTP, getCart } = useAPI();

  const [state, dispatch] = React.useReducer(loginReducer, getInitialState(loginUptions.current));
  const dispatchRedux = useDispatch()
  const [remainingTime, setRemainingTime] = React.useState(0);
  // const {
  //   // state: {
  //   // user: { smsMessage, smsCheckbox, userInfo } = {} } = {},
  //   // },
  //   dispatch: appDispatch
  // } = useContext(appContext);

  const { smsMessage, smsCheckbox, userInfo } = useSelector(state => state.user)
  const freezeForm = () => {
    // setIsFreeze(true);
    dispatch({ isFreeze: true, apiError: null, otpError: null, phoneError: null, confirmingOTP: false });
    if (timer.current) {
      clearInterval(timer.current);
    }
  };

  const confirmOTP = async (
    inputOTP,
    dismiss,
    additionalDetails,
    marketingUser,
    dismissParentPopup = null,
    isGiftCardRedeemState = false
  ) => {
    const {
      query: { friends }
    } = Router;

    if (inputOTP && inputOTP.length > 3) {
      const errorOTP = otpValidationWithError(inputOTP);

      if (errorOTP) {
        // Set OTP error
        dispatch({ otpError: errorOTP });
      } else {
        // Process OTP
        dispatch({ apiError: null, otpError: null, confirmingOTP: true });

        try {
          await callConfirmOtp(
            state.country,
            state.phone,
            inputOTP,
            additionalDetails,
            smsMessage ? smsCheckbox : null,
            marketingUser,
            isGiftCardRedeemState,
            loginUptions.current.loginFromPopup
          );

          getCart();

          if (loginUptions.current.redirectOnLoginTo) {
            Router.push(loginUptions.current.redirectOnLoginTo);
          }

          if (!friends && loginUptions.current.redirectTo && !loginUptions.current.closeDrawer) {
            Router.replace(loginUptions.current.redirectTo);
            // if (!friends) {
            // }
          } else if (!friends && loginUptions.current.closeDrawer) {
            // appDispatch({ type: 'toggleLoginDrawer' })
            dispatchRedux(toggleLoginDrawer())
          } else if (!friends && loginUptions.current.closeLoginPopup) {
            dispatchRedux(popNotification())

          } else if (loginUptions.current.confirmLogin) {
            loginUptions.current.confirmLogin()

          } else {
            freezeForm();
          }

          if (state.customTrigger) {
            // if (cartItems && cartItems.length) {
            //   if (window.appboy) {
            //     window.appboy.logCustomEvent(`${state.customTrigger}-item-present`);
            //   }
            // } else if (window.appboy) {
            //   window.appboy.logCustomEvent(`${state.customTrigger}-no-cart-item`);
            // }
          }

          if (dismissParentPopup) {
            dismissParentPopup();
          }

          if (dismiss) {
            dismiss();
          }
        } catch (error) {
          console.log(error);
          let { data: { error: err = 'Something went Wrong.' } = {} } = error;
          if (typeof err !== 'string') {
            err = 'Something went Wrong.';
          }
          dispatch({ apiError: err, confirmingOTP: false });
        }
      }
    }
  };

  const setPhone = event => {
    if (isNumber(event.target.value)) {
      dispatch({ phone: event.target.value, phoneError: null });
    }
  };

  const setOTP = (event, marketingUser = false, dismiss = null, dismissParentPopup = null, isGiftCardRedeemState = false) => {
    if (isNumber(event.target.value)) {
      dispatch({ otp: event.target.value, apiError: null, otpError: null });
      confirmOTP(event.target.value, dismiss, null, marketingUser, dismissParentPopup, isGiftCardRedeemState);
    }
  };

  // const onChangeOTP = (event, dismiss, marketingUser = false) => {
  //   if (isNumber(event.target.value)) {
  //     dispatch({ otp: event.target.value, apiError: null, otpError: null });
  //     confirmOTP(event.target.value, dismiss, null, marketingUser);
  //   }
  // };

  const setCountry = event => {
    dispatch({ country: event.target.value });
    // appDispatch({ type: 'setUserInfo', payload: { ...userInfo, country: event.target.value } });
    dispatchRedux(setUserInfo({ ...userInfo, country: event.target.value }))
  };

  const startTimer = () => {
    setRemainingTime(verificationCooldownTime);
    timer.current = setInterval(() => {
      setRemainingTime(prevRemainingTime => {
        if (prevRemainingTime === 0) {
          clearInterval(timer.current);
          dispatch({ switchBtn: true });
        }
        return prevRemainingTime - 1;
      });
    }, 1000);
  };

  // Clear timer on unmount
  React.useEffect(
    () => () => {
      freezeForm();
    },
    []
  );

  const requestOTP = async via => {
    // Validate phone number
    const phoneError = mobileNoValidationWithError(state.phone);
    if (phoneError) {
      // Set phone error
      dispatch({ phoneError });
      dispatch({ showVerificationField: false });
    } else {
      // Request for OTP and display loader
      dispatch({ showCancelButton: false });
      dispatch({ showVerificationField: true });
      dispatch({ generatingOTP: true });
      try {
        await callRequestOTP(via, state.country, state.phone);
        startTimer();
        dispatch({ displayOtpField: true });
      } catch (error) {
        dispatch({ apiError: error.message });
      } finally {
        dispatch({ generatingOTP: false });
      }
    }
  };

  const validateForm = () => {
    const phoneError = mobileNoValidationWithError(state.phone);
    if (phoneError) {
      // Set phone error
      dispatch({ phoneError });
    }
    let otpError = null;
    if (state.displayOtpField) {
      otpError = otpValidationWithError(state.otp);
      if (otpError) {
        // Set otp error
        dispatch({ otpError });
      }
    } else {
      dispatch({ phoneError: 'Please verify the number' });
    }
  };

  return {
    formData: state,
    setPhone,
    setOTP,
    setCountry,
    requestOTP,
    remainingTime,
    confirmOTP,
    // onChangeOTP,
    validateForm
  };
};

export default useLogin;

import { createSlice } from "@reduxjs/toolkit";
import cookie from 'js-cookie';
import StorageService from "../../services/storageService";
import * as COOKIE_VARIABLES from '../../constants/cookieVariables'
import { DEFAULT_ADDRESS, DEFAULT_LOCATION } from "../../constants/default";
import { SCHEDULE_TYPE_ASAP } from "../../constants/scheduleType";
import { getCurrentTime } from "../../helpers/timeHelpers";
import { ASAP } from "../../constants/deliveryType";
import isEmpty from "lodash/isEmpty";


const defaultDeliveryDetails = {
  deliveryTypeSelected: SCHEDULE_TYPE_ASAP,
  address: DEFAULT_ADDRESS,
  latitude: DEFAULT_LOCATION.lat,
  longitude: DEFAULT_LOCATION.lng,
  deliveryDate: 0,
  deliveryTime: getCurrentTime(),
  fromMap: false,
  locationId: 1
};

function createInitialStore() {
  const isLoggedIn = !isEmpty(cookie.get(COOKIE_VARIABLES.token));
  const isEmailVerified = false;
  const userData = StorageService.getUserData() || {};
  const userInfo = !isEmpty(userData) ? userData : {};
  const deliveryDetails = StorageService.getDeliveryDetails() || defaultDeliveryDetails;
  const couponData = StorageService.getCouponDetails() || {};
  const smsCheckbox = null;
  const smsMessage = null;
  const tcpaRewardAmount = 0;
  const url = '';
  const urlBeforeProduct = '';
  const urlBeforeCompare = '';
  const userSelectedTab = ASAP;
  const isReOrder = false;
  const urlBeforeRating = '';
  const shouldFetchProduct = false;
  const shouldFetchProductOnLoginFromPopup = false;
  const shouldFetchAsap = false;
  const shouldFetchSchedule = false;
  const searchSelectedTab = ASAP;
  const cartSwitched = false;
  const inAppWebViewUser = false;
  const showUserDropdown = false;
  const deliverySelectedFromProfileMap = false;
  const selectedAddressForEdit = false;
  const walletWithPurchaseRewards = {};
  const favoriteStatusChangedForProduct = false;
  const scrollPositionPdp = 0;
  const tokenData = ''
  const loginDrawer = {
    isOpen: false
  }
  const sideBar = {
    isSideBarOpen: false,
    shouldRenderSideBar: false,
    isRightSideBarOpen: false
  }
  const searchTabs = false;
  const initialHistoryLength = null
  const location = null

  return {
    isLoggedIn,

    isEmailVerified,

    userInfo,

    deliveryDetails,

    couponData,

    smsCheckbox,

    smsMessage,

    tcpaRewardAmount,

    url,
    urlBeforeProduct,

    userSelectedTab,

    isReOrder,

    urlBeforeRating,

    urlBeforeCompare,

    shouldFetchProduct,

    shouldFetchProductOnLoginFromPopup,

    shouldFetchAsap,

    shouldFetchSchedule,

    searchSelectedTab,

    cartSwitched,

    inAppWebViewUser,

    showUserDropdown,

    deliverySelectedFromProfileMap,

    selectedAddressForEdit,

    walletWithPurchaseRewards,

    favoriteStatusChangedForProduct,

    scrollPositionPdp,
    tokenData,
    loginDrawer,
    sideBar,
    searchTabs,
    initialHistoryLength,
    location
  };
}
export const userSlice = createSlice({
  name: "user",
  initialState: createInitialStore(),
  reducers: {
    requestOTPSlice: (state, action) => {
      // state.requestOTP = action.payload.requestOTP;
      state.smsCheckbox = action.payload.smsCheckbox
      state.smsMessage = action.payload.smsMessage
      state.tcpaRewardAmount = action.payload.tcpaRewardAmount
    },
    login: (state, action) => {
      state.isLoggedIn = action.payload.isLoggedIn;
      state.userInfo = action.payload.userInfo
    },
    confirmEmailOTPSlice: (state, action) => {
      state.isEmailVerified = action.payload.isEmailVerified;
    },
    setCouponData: (state, action) => {
      state.couponData = action.payload.couponData;
    },
    setReferrerToken: (state, action) => {
      state.tokenData = action.payload.tokenData;
    },
    setSmsCheckBox: (state, action) => {
      state.smsCheckbox = action.payload.smsCheckbox;
    },
    setTCPAShowMessage: (state, action) => {
      state.TCPAShowMessage = action.payload.smsMessage;
    },
    setTCPARewardAmount: (state, action) => {
      state.TCPARewardAmount = action.payload.tcpaRewardAmount;
    },
    setUrlBeforeProduct: (state, action) => {
      state.urlBeforeProduct = action.payload.urlBeforeProduct
    },
    setOrderReOrder: (state, action) => {
      state.isReOrder = action.payload.orderReOrder;
    },
    setUrlBeforeCompare: (state, action) => {
      state.urlBeforeCompare = action.payload.urlBeforeCompare;
    },
    setUrlBeforeRating: (state, action) => {
      state.urlBeforeRating = action.payload.urlBeforeRating;
    },
    updateSelectedTab: (state, action) => {
      state.userSelectedTab = action.payload.userSelectedTab;
    },
    setProductFetchInfo: (state, action) => {
      state.shouldFetchProduct = action.payload.shouldFetchProduct;
    },
    setFetchProductOnLoginFromPopupInfo: (state, action) => {
      state.shouldFetchProductOnLoginFromPopup = action.payload.shouldFetchProductOnLoginFromPopup;
    },
    setAsapProductInfo: (state, action) => {
      state.asapProductInfo = action.payload.asapProductInfo;
    },
    setScrollPositionPdp: (state, action) => {
      state.scrollPositionPdp = action.payload.scrollPositionPdp;
    },
    setScheduleProductInfo: (state, action) => {
      state.shouldFetchSchedule = action.payload.shouldFetchSchedule;
    },
    setSearchSelectedTab: (state, action) => {
      state.searchSelectedTab = action.payload.searchSelectedTab;
    },
    setCartSwitched: (state, action) => {
      state.cartSwitched = action.payload.cartSwitched;
    },
    setLastAppliedCouponData: (state, action) => {
      state.lastAppliedCouponData = action.payload.lastAppliedCouponData;
    },
    setDeliverySelectedFromProfileMap: (state, action) => {
      state.deliverySelectedFromProfileMap = action.payload.deliverySelectedFromProfileMap;
    },
    setSelectedAddressForEdit: (state, action) => {
      state.selectedAddressForEdit = action.payload.selectedAddressForEdit;
    },
    setUserAction: (state, action) => {
      state.userAction = action.payload.userAction;
    },
    setFavoriteStatusChangedForProduct: (state, action) => {
      state.favoriteStatusChangedForProduct = action.payload.favoriteStatusChangedForProduct;
    },
    updateInWebAppUser: (state, action) => {
      if (action.payload) {
        StorageService.setInAppWebViewUser();
      } else {
        StorageService.removeInAppWebViewUser();
      }
      state.inAppWebViewUser = action.payload
    },
    //update cart separately,
    toggleUserDropdown: (state, action) => {
      state.showUserDropdown = action.payload === false ? action.payload : !state.showUserDropdown

    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
    },
    previousPath: (state, action) => {
      state.url = action.payload.url
    },
    setDeliverySelectedFromProfileMap: (state, action) => {
      state.deliverySelectedFromProfileMap = action.payload.deliverySelectedFromProfileMap
    },
    saveLocation: (state, action) => {

      StorageService.setDeliveryDetails({
        ...state.deliveryDetails,
        ...action.payload,
        fromMap: false
      });

      if (action.payload.postcode) {
        if (process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true') {
          cookie.set(COOKIE_VARIABLES.zipcode, action.payload.postcode, { expires: 30, sameSite: 'none', secure: true });
        } else {
          cookie.set(COOKIE_VARIABLES.zipcode, action.payload.postcode, { expires: 30, sameSite: 'lax' });
        }
      }

      if (action.payload.placeId) {
        cookie.set(COOKIE_VARIABLES.placeId, action.payload.placeId);
      }

      if (action.payload.zoneId) {
        cookie.set(COOKIE_VARIABLES.zoneId, action.payload.zoneId);
      }

      if (action.payload.latitude) {
        cookie.set(COOKIE_VARIABLES.latitude, action.payload.latitude, { expires: 30, sameSite: 'lax' });
      }

      if (action.payload.longitude) {
        cookie.set(COOKIE_VARIABLES.longitude, action.payload.longitude, { expires: 30, sameSite: 'lax' });
      }
      state.deliveryDetails = { ...state.deliveryDetails, ...action.payload }
    },
    updateLocation: (state, action) => {
      state.deliveryDetails = { ...state.deliveryDetails, ...action.payload }
    },
    toggleLoginDrawer: (state) => {
      state.loginDrawer.isOpen = !state.loginDrawer.isOpen

    },
    toggleSidebar: (state) => {
      state.sideBar.isSideBarOpen = !state.sideBar.isSideBarOpen
      state.sideBar.shouldRenderSideBar = true

    },
    setSearchTabs: (state, action) => {
      state.searchTabs = action.payload

    },
    setHistoryLength: (state, action) => {
      state.initialHistoryLength = action.payload
    },
    updateLocationId: (state, action) => {
      state.location = action.payload
    }
  }
})

export const {
  updateLocation,
  saveLocation,

  login,

  setCouponData,

  setUrlBeforeRating,

  setFavoriteStatusChangedForProduct,

  setUrlBeforeProduct,

  requestOTPSlice,

  confirmEmailOTPSlice,

  setReferrerToken,

  setSmsCheckBox,

  setTCPAShowMessage,

  setTCPARewardAmount,

  setOrderReOrder,

  setUrlBeforeCompare,

  updateSelectedTab,

  setProductFetchInfo,

  setFetchProductOnLoginFromPopupInfo,

  setAsapProductInfo,

  setScrollPositionPdp,

  setScheduleProductInfo,

  setSearchSelectedTab,

  setCartSwitched,

  setLastAppliedCouponData,

  setDeliverySelectedFromProfileMap,

  setSelectedAddressForEdit,

  setUserAction,

  updateInWebAppUser,

  toggleUserDropdown, // didnt remove yet

  setUserInfo,

  previousPath,
  toggleLoginDrawer,
  toggleSidebar,
  setSearchTabs,
  setHistoryLength,
  updateLocationId
} = userSlice.actions

export default userSlice.reducer

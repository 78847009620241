import React from 'react';
import storageService from '../../services/storageService';

import appConfig from '../../appConfig';
import useSegment from '../../hooks/segment';
import envAppConfig from '../../constants/envAppConfig';

const UpdatedAgeConfirmationPopup = ({ dismiss, data }) => {
  const { trackEvent } = useSegment();
  function exitSite() {
    window.location.replace('about:blank');
    dismiss();
  }

  function handleConfirm() {
    storageService.setUpdatedAgeConfirmationShowPopup(true);
    trackEvent('Accept Terms', {
      category: 'User Events',
      action: 'Click',
      label: 'Accept Terms'
    });
    // Braze custom event fired if app boy loaded in the window
    if (window.appboy) {
      trackEvent('accept-terms-condition');
    }
    dismiss();
  }

  const serverHost = typeof window == 'object' ? (window.location.host).split('.')[1] : '';

  return (
    <div className="modal age-popup d-block" tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body text-center">
            <div className="logo-container">
              <img alt="" src={process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true' ? envAppConfig && envAppConfig[data.domainName] ? envAppConfig[data.domainName].HEADER_LOGO_COLOR : envAppConfig['grassdoor'].HEADER_LOGO_COLOR : appConfig.LOGO} />
            </div>
            <p className="">
              Welcome back! We've recently updated our
              <a
                href={process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true' && envAppConfig ? envAppConfig[data.domainName] && envAppConfig[data.domainName].TERMS ? envAppConfig[data.domainName].TERMS : '/termsandconditions' : appConfig.TERMS ? appConfig.TERMS : '/termsandconditions'}
                target="_blank"
                className="d-inline-block"
                rel="noreferrer"
                test-id="#terms_of_use"
              >
                Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a
                href={process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true' && envAppConfig ? envAppConfig[data.domainName] && envAppConfig[data.domainName].PRIVACY ? envAppConfig[data.domainName].PRIVACY : '/privacypolicy' : appConfig.PRIVACY ? appConfig.PRIVACY : '/privacypolicy'}
                target="_blank"
                className="d-inline-block"
                rel="noreferrer"
                test-id="#privacy_policy"
              >
                Privacy Policy
              </a>
              .
              <br />
              By clicking on continue you agree to our Terms of Use and Privacy Policy.
            </p>

            <div className="tworem-mt text-center">
              <div>
                <button type="button" className="btn btn-primary rouded-button btn-block has-box-shadow " onClick={handleConfirm}>
                  Continue
                </button>
              </div>
              <div>
                <button type="button" className="btn btn-link half-rem-mt" onClick={exitSite}>
                  Exit this site
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedAgeConfirmationPopup;

const notifications = {
  ageConfirm: 'ageConfirm',
  updatedAgeConfirm: 'updatedAgeConfirm',
  promoOffer: 'promoOffer',
  linkOffer: 'linkOffer',
  deliverAddressPopup: 'deliverAddressPopup',
  subscribeEmail: 'subscribeEmail',
  switchDeliveryType: 'switchDeliveryType',
  simplePopup: 'simplePopup',
  deliveryAddressDetail: 'deliveryAddressDetail',
  showPromoCode: 'showPromoCode',
  imagePopup: 'imagePopup',
  signaturePopup: 'signaturePopup',
  ratingPopup: 'ratingPopup',
  versionModal: 'versionModal',
  dealsPopup: 'dealsPopup',
  redirectionPopup: 'redirectionPopup',
  pacPopup: 'pacPopup',
  bccPopup: 'bccPopup',
  searchModal: 'searchModal',
  productDetails: 'productDetails',
  bulkQuantityPopup: 'bulkQuantityPopup',
  loginModalGD: 'loginModalGD',
  loginPopup: 'loginPopup',
  dynamicPopup: 'dynamicPopup',
  productDetailsPopup: 'productDetailsPopup',
  categoryVolumePricing: 'categoryVolumePricing',
  ccpaPopUp: 'ccpaPopUp',
  youtubePopup: 'youtubePopup',
  strongholdPopUp: 'strongholdPopUp',
  friendsCart: 'friendsCart',
  friendsAndFamilyDiscount: 'friendsAndFamilyDiscount',
  friendsFamilyModal: 'friendsFamilyModal',
  friendsLogin: 'friendsLogin',
  scheduleSlot: 'scheduleSlot',
  ounceDiscountPopup: 'ounceDiscountPopup',
  cartWidget: 'cartWidget',
  imageCropPopup: 'imageCropPopup',
  viewOffers: 'viewOffers',
  giftRedeemPopup: 'giftRedeemPopup',
  emptyCartPopup: 'emptyCartPopup',
  comparePopup: 'comparePopup',
  ounceCorrectionPopup: 'ounceCorrectionPopup',
  rateAndReviewPopup: 'rateAndReviewPopup',
  showDrWeedyMedicalCard: 'showDrWeedyMedicalCard',
  simplePopupGiftCard: 'simplePopupGiftCard',
  orderCompletePopup: 'orderCompletePopup',
  switchPaymentPopup: 'switchPaymentPopup',
  ambassadorRewardsPopup: 'ambassadorRewardsPopup',
  priorityDeliveryLearnMore: 'priorityDeliveryLearnMore',
  addNewAddress: 'addNewAddress',
  addNewAccount: 'addNewAccount',
  addNewCard: 'addNewCard',
  verifyOTPPopup: 'verifyOTPPopup',
  verifyEmailPopup: 'verifyEmailPopup',
  tipPopUp: 'tipPopup',
  couponCodesPopup: 'couponCodesPopup',
  debitCardsFieldsPopup: 'debitCardsFieldsPopup',
  recipientList: 'recipientList',
  referralGiftCardSuccess: 'referralGiftCardSuccess',
  viewOrderCreditValues: 'viewOrderCreditValues',
  payOnlinePopup: 'payOnlinePopup',
  shareReferralLinkPopup: 'shareReferralLinkPopup',
  promoOfferSuccess: 'promoOfferSuccess',
  deleteAddressPopup: 'deleteAddressPopup',
  promoCodeApplied: 'promoCodeApplied',
  teslaMissingChancePopup: 'teslaMissingChancePopup',
  removeProductsPopup: 'removeProductsPopup',
  similarProductPopup: 'similarProductPopup',
  confirmProductRemovalPopup: 'confirmProductRemovalPopup',
  firstTimeUserReward: 'firstTimeUserReward'
};

export default notifications;

import { createSlice } from "@reduxjs/toolkit";
import hideModalPages from "../../constants/hideModalPages";
import notificationTypes from '../../constants/modalNotificationConst'
import getRouterPath from "../../helpers/getRouterPath";

const {
  friendsAndFamilyDiscount,
  deliverAddressPopup,
  simplePopupGiftCard,
  friendsCart,
  bccPopup,
  dealsPopup,
  categoryVolumePricing
} = notificationTypes;

function hideNotification(payload) {
  const routerPath = getRouterPath(window.location.pathname);

  if (payload.type === friendsAndFamilyDiscount && routerPath === 'login') {
    return false;
  }

  const hideModalNotification = hideModalPages.includes(routerPath);

  if (hideModalNotification) {
    return true;
  }

  const deliverAddressPopupHiddenPages = ['myorders', 'gift-cards', 'gift-cards-redeem'];

  if (
    (payload.type === deliverAddressPopup && deliverAddressPopupHiddenPages.includes(routerPath)) ||
    (['cart'].includes(routerPath) &&
      window.location.search.includes('giftcard') &&
      ![simplePopupGiftCard].includes(payload.type)) ||
    (process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true' && ['webview-connect'].includes(routerPath))
  ) {
    return true;
  }

  if (payload.type === friendsCart && ['cart'].includes(routerPath) && window.location.search.includes('giftcard')) {
    return true;
  }

  if (
    (payload.type === dealsPopup && window.location.search.includes('giftcard')) ||
    (payload.type === dealsPopup && routerPath.includes('confirmation'))
  ) {
    return true;
  }

  return false;
}

function createInitialStore() {
  const notifications = [];
  const notificationsOverlap = [];
  const pushedURL = '';
  const stackURL = 0;
  return { notifications, notificationsOverlap, pushedURL, stackURL };
}

export const modalSlice = createSlice({
  name: "modal",
  initialState: createInitialStore(),
  reducers: {
    pushNotification: (state, action) => {
      if (!hideNotification(action.payload)) {
        state.notifications = [ ...state.notifications,action.payload]
      }
    },
    pushIfNotPresent: (state, action) => {
      if (!hideNotification(action.payload) && !state.notifications.map(item => item.type).includes(action.payload.type)) {
        state.notifications = [ ...state.notifications,action.payload]
      }
    },
    popNotification: (state) => {
      state.notifications.splice(0, 1)
    },
    popSpecifictNotification: (state, action) => {
      state.notifications = state.notifications.filter(item => item.type !== action.payload.type)
    },

    popSpecifictNotificationOverlap: (state, action) => {
      state.notificationsOverlap = state.notificationsOverlap.filter(item => item.type !== action.payload.type)

    },
    popNotificationAll: (state, action) => {
      state.notifications = [];
      state.notificationsOverlap = []
    },
    popNotificationOverlap: (state, action) => {
      state.notificationsOverlap.splice(0, 1)
    },
    pushNotificationOverlap: (state, action) => {
      if (!hideNotification(action.payload)) {
        const currentNotificationTypes = state.notifications.map(item => item.type);
        const currentNotificationOverlapTypes = state.notificationsOverlap.map(item => item.type);

        if (![...currentNotificationTypes, ...currentNotificationOverlapTypes].includes(action.payload.type)) {
          if (action.payload.type === bccPopup && currentNotificationOverlapTypes.includes(categoryVolumePricing)) {
            state.notificationsOverlap = [action.payload, ...state.notificationsOverlap.filter(item => item.type !== categoryVolumePricing)]
          }
          state.notificationsOverlap = [action.payload, ...state.notificationsOverlap]
        }
      }
    },
    pushOnTopIfNotPresent: (state, action) => {
      if (!hideNotification(action.payload)) {
        if (!state.notifications.map(item => item.type).includes(action.payload.type)) {
          state.notifications = [action.payload, ...state.notifications]
        }
      }
    },
    pushURL: (state, action) => {
      state.pushedURL = action.payload.url;
      state.stackURL = 1
    },
    increaseURLstack: (state, action) => {
      state.stackURL = state.stackURL + 1;

    },
    popStack: (state, action) => {
      state.stackURL = 0
    }
  }
})
export const { pushNotification, pushIfNotPresent, popNotification, popSpecifictNotification, pushNotificationOverlap, popSpecifictNotificationOverlap, popNotificationAll, popNotificationOverlap, pushOnTopIfNotPresent, pushURL, increaseURLstack, popStack } = modalSlice.actions
export default modalSlice.reducer
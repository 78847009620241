const CustomOunceTypes = {
  HALF: {
    label: 'half',
    count: 4,
    price: 99,
    type: 1,
    img: 'https://saas3-operator1-public-data.s3.us-west-2.amazonaws.com/zips/web_static_files/static_images/HalfOunce_thumb_Web.png'
  },
  FULL: {
    label: 'full',
    count: 8,
    price: 199,
    type: 2,
    img: 'https://saas3-operator1-public-data.s3.us-west-2.amazonaws.com/zips/web_static_files/static_images/FullOunce_thumb_Web.png'
  }
};

export const defaultOunceItems = ({ halfOuncePrice, fullOuncePrice }) => {
  return [
    {
      image: 'https://prod-serverless.grassdoor.com/all_products/original/a5c3d61b226d5cf669ba2d8da8f8fa9d.png',
      title: 'Half Ounce',
      subtitle: `Starting at $${halfOuncePrice}`,
      content: 'Mix and match up to 4 strains. Custom ounces available for Express and scheduled delivery.',
      route: `/customounce/${CustomOunceTypes.HALF.label}`
    },
    {
      image: 'https://prod-serverless.grassdoor.com/all_products/original/4f523383c3beaf22661f3f1671822f59.png',
      title: 'Full Ounce',
      subtitle: `Starting at $${fullOuncePrice}`,
      content: 'Mix and match up to 8 strains. Custom ounces available for Express and scheduled delivery.',
      route: `/customounce/${CustomOunceTypes.FULL.label}`
    }
  ];
};

export default CustomOunceTypes;

import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import appContext from '../Context/appContext';
import { toggleLoginDrawer } from '../redux/slices/userSlice';

import LoginComponent from './LoginComponent';

export default function LoginDrawer() {
  // const {
  //   state: {
  //     // loginDrawer: { isOpen },
  //     seoData: { login_sidebar_image_data = {} } = {}
  //   }
  // } = useContext(appContext);
  const { login_sidebar_image_data = {} } = useSelector(state => state.other.seoData) || {}
  const isOpen = useSelector(state => state.user.loginDrawer.isOpen)
  const dispatchRedux = useDispatch()

  return (
    <CSSTransition in={isOpen} timeout={500} classNames="anim-drawer" mountOnEnter>
      <div className="login-drawer">
        <div className="backdrop" role="presentation" onClick={() => {
          // dispatch({ type: 'toggleLoginDrawer' })
          dispatchRedux(toggleLoginDrawer())
        }} />
        <div className="content-drawer">
          <div className="top-sec d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <i className="icon-login mr-3" />

              <h2 className="bold mb-0">Log In / Sign Up</h2>
            </div>

            <span
              onClick={() => {
                // dispatch({ type: 'toggleLoginDrawer' })
                dispatchRedux(toggleLoginDrawer())

              }}
              className="cursor-pointer white-color icon-close height-100 d-flex align-items-center justify-flex-end bold"
            />
          </div>

          <div className="pl-4 pr-4">
            <div className="new-login-component login">
              <div className="right-sec">
                <div className="img-container text-center" />

                <LoginComponent closeDrawer />
              </div>
            </div>

            {login_sidebar_image_data.image ? (
              <div className="login-banner-sec pl-4 pr-4">
                <img src={login_sidebar_image_data.image} alt="" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

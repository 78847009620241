import { createSlice } from "@reduxjs/toolkit";

export function createInitialStore() {
  const submitCount = 0;
  const ratingSubmitted = false
  return {
    submitCount,
    ratingSubmitted
  }
}

const ratingSlice = createSlice({
  name: "rating",
  initialState: createInitialStore(),
  reducers: {
    updateSubmitCount: (state, action) => {
      state.submitCount = action.payload
    },
    updateSubmitCountWithRating: (state, action) => {
      state.submitCount = action.payload.submitCount;
      state.ratingSubmitted = true
    }
  }
})
export const { updateSubmitCount, updateSubmitCountWithRating } = ratingSlice.actions
export default ratingSlice.reducer
export default {
  'localhost:3000': {
    APP_NAME: 'Grassdoor',
    HEADER_LOGO_COLOR: 'https://flowermarket.delivery/favicon.ico',
    PRIMARY: '#2c2c2c',
    FAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon-32x32.png',
    SMALLFAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon-16x16.png',
    SEGMENT_KEY: 'mHYYv03c0wIQX3WJRnDjDGz3Ci3LM72a',
    THIRD_PARTY_REVIEW_LINKS: [
      {
        name: 'Google',
        link: 'https://www.google.co.in/search?authuser=1&_ga=2.2642544.1319406346.1653886352-1847385761.1647924459&q=Grassdoor+Delivery&ludocid=7383455018093563885&lsig=AB86z5WM9QxaG2E16LRdSUrqnCrV#lrd=0x80c2cf28dbc6d073:0x66774d38408acfed,1,,',
        icon: '/images/google.svg'
      },
      {
        name: 'Yelp',
        link: 'https://www.yelp.com/biz/grassdoor-los-angeles-3',
        icon: '/images/yelp.svg'
      }
    ]
  },

  grassdoor: {
    APP_NAME: 'Grassdoor',
    HEADER_LOGO_COLOR: 'https://static-images.grassdoor.com/grassdoor.com/web_static_files/logo-grassdoor.svg',
    PRIMARY: '#13c7cd',
    FAVICON: 'https://static-images.grassdoor.com/grassdoor.com/web_static_files/favicon/favicon.ico',
    LARGEFAVICON: 'https://static-images.grassdoor.com/grassdoor.com/web_static_files/favicon/favicon-32x32.png',
    SMALLFAVICON: 'https://static-images.grassdoor.com/grassdoor.com/web_static_files/favicon/favicon-16x16.png',
    SEGMENT_KEY: 'mHYYv03c0wIQX3WJRnDjDGz3Ci3LM72a',
    // REVSCRIPT: 'https://db.revoffers.com/js/grassdoor.com.js',
    HEADER_LOGO_SHORT: 'https://static-images.grassdoor.com/grassdoor.com/web_static_files/grassdoor-logo-m.svg',
    THIRD_PARTY_REVIEW_LINKS: [
      {
        name: 'Google',
        link: 'https://www.google.co.in/search?authuser=1&_ga=2.2642544.1319406346.1653886352-1847385761.1647924459&q=Grassdoor+Delivery&ludocid=7383455018093563885&lsig=AB86z5WM9QxaG2E16LRdSUrqnCrV#lrd=0x80c2cf28dbc6d073:0x66774d38408acfed,1,,',
        icon: '/images/google.svg'
      },
      {
        name: 'Yelp',
        link: 'https://www.yelp.com/biz/grassdoor-los-angeles-3',
        icon: '/images/yelp.svg'
      }
    ]
  },

  flowermarket: {
    APP_NAME: 'Flowermarket',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/www.flowermarket.delivery/web_static_files/New+Flower+Market+Logo.png',
    HEADER_LOGO_SHORT:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/www.flowermarket.delivery/web_static_files/New+Flower+Market+Logo.png',
    PRIMARY: '#FA9379',
    FAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/www.flowermarket.delivery/web_static_files/New+Flower+Market+Logo.png',
    LARGEFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/www.flowermarket.delivery/web_static_files/New+Flower+Market+Logo.png',
    SMALLFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/www.flowermarket.delivery/web_static_files/New+Flower+Market+Logo.png',
    SEGMENT_KEY: 'oSU8XGls9tVw3wE31eajIVylnXFMWc8f',
    REVSCRIPT: 'https://db.revoffers.com/js/flowermarket.delivery.js',
    TERMS: 'https://www.flowermarket.delivery/terms-of-use',
    PRIVACY: 'https://www.flowermarket.delivery/privacy-policy',
    THIRD_PARTY_REVIEW_LINKS: [
      {
        name: 'Yelp',
        link: 'https://www.yelp.com/biz/flower-market-cannabis-delivery-santa-monica-3?osq=flower+market+cannabis+delivery',
        icon: '/images/yelp.svg'
      }
    ]
  },

  drinkcann: {
    APP_NAME: 'Cann',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/checkout.grassdoor.com/web_static_files/header-logo-color.png',
    PRIMARY: '#00a6c7',
    FAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon-32x32.png',
    SMALLFAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon-16x16.png',
    SEGMENT_KEY: 'eYrBkXidynmJk5UE7vgbPlswqafuFApf',
    TERMS: 'https://www.drinkcann.com/terms-conditions/',
    PRIVACY: 'https://www.drinkcann.com/privacy-policy/'
  },

  'aproperhigh.com': {
    APP_NAME: 'Aproperhigh',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/connect.aproperhigh.com/web_static_files/logo_aproperhigh/proper-header-logo.svg',
    PRIMARY: '#000000',
    FAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/connect.aproperhigh.com/web_static_files/favicon_aproperhigh/favicon-proper.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/connect.aproperhigh.com/web_static_files/favicon_aproperhigh/favicon-proper.ico',
    SMALLFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/connect.aproperhigh.com/web_static_files/favicon_aproperhigh/favicon-proper.ico',
    SEGMENT_KEY: 'ZGqPvkEgYkDRrLUOhF6w8rtRQKBeFrRB'
  },

  roselosangeles: {
    APP_NAME: 'Rose',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/roselosangeles/web_static_files/logo_roselosangeles/header_logo_rose.png',
    PRIMARY: '#000',
    FAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/roselosangeles/web_static_files/favicon_roselosangeles/favicon.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/roselosangeles/web_static_files/favicon_roselosangeles/favicon.ico',
    SMALLFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/roselosangeles/web_static_files/favicon_roselosangeles/favicon.ico',
    SEGMENT_KEY: 'rkfzrlH44XsMWSsFictqq1HEbe7jEDpD',
    TERMS: 'https://www.roselosangeles.com/legal/terms-of-use/',
    PRIVACY: 'https://www.roselosangeles.com/legal/privacy-policy/'
  },

  reorderdrinkcann: {
    APP_NAME: 'Cann',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/checkout.grassdoor.com/web_static_files/header-logo-color.png',
    PRIMARY: '#00a6c7',
    FAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon-32x32.png',
    SMALLFAVICON:
      'https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com/staging.drinkcann.com/web_static_files/favicon-16x16.png',
    SEGMENT_KEY: 'eYrBkXidynmJk5UE7vgbPlswqafuFApf',
    TERMS: 'https://www.drinkcann.com/terms-conditions/',
    PRIVACY: 'https://www.drinkcann.com/privacy-policy/'
  },

  niceandsticky: {
    APP_NAME: 'Grassdoor',
    // "HEADER_LOGO_COLOR": 'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/checkout.niceandsticky.com/web_static_files/logo_niceandsticky/header-logo-niceandsticky.png',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/checkout.niceandsticky.com/web_static_files/logo_niceandsticky/updated_logo_niceandsticky.png',
    PRIMARY: '#9400E9',
    FAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/checkout.niceandsticky.com/web_static_files/favicon_niceandsticky/header-logo-niceandsticky.png',
    LARGEFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/checkout.niceandsticky.com/web_static_files/favicon_niceandsticky/header-logo-niceandsticky.png',
    SMALLFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/checkout.niceandsticky.com/web_static_files/favicon_niceandsticky/header-logo-niceandsticky.png',
    SEGMENT_KEY: 'g0NaxH3gj4gw8kKKa5NlsBPYH2B63aSw',
    CUSTOME_ORDER_DETAILS_URL: 'https://niceandsticky.com/grassdoor/order/'
  },

  'checkout.papaandbarkley.com': {
    APP_NAME: 'Papa & Barkley',
    HEADER_LOGO_COLOR:
      'https://saas3-operator1-public-data.s3-us-west-2.amazonaws.com/papaandbarkley/web_static_files/logo-color.png',
    PRIMARY: '#5b7e96',
    FAVICON: 'https://saas3-operator1-public-data.s3-us-west-2.amazonaws.com/papaandbarkley/web_static_files/favicon/favicon.ico',
    LARGEFAVICON:
      'https://saas3-operator1-public-data.s3-us-west-2.amazonaws.com/papaandbarkley/web_static_files/favicon/favicon-32x32.png',
    SMALLFAVICON:
      'https://saas3-operator1-public-data.s3-us-west-2.amazonaws.com/papaandbarkley/web_static_files/favicon/favicon-16x16.png',
    SEGMENT_KEY: 'wTN9fPHgKlLI14JBC5O8x0DjAwXLwCzf'
  },

  'headless.getooka.com': {
    APP_NAME: 'Get Ooka',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/headless.getooka.com/web_static_files/header_logo.png',
    PRIMARY: '#000000',
    FAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/headless.getooka.com/web_static_files/favicon/favicon.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/headless.getooka.com/web_static_files/favicon/favicon.ico',
    SMALLFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/headless.getooka.com/web_static_files/favicon/favicon.ico',
    SEGMENT_KEY: 'tbveuoD2rA53T03mFCQl3pQyhPVUqPdR'
  },

  'grassdoor-checkout.leafly.com': {
    APP_NAME: 'Leafly',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/header_logo/Leafly+Logo.svg',
    PRIMARY: '#017C6B',
    FAVICON: 'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/favicon/favicon.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/favicon/favicon-32x32.png',
    SMALLFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/favicon/favicon-16x16.png'
  },

  'pre-leafly-checkout.grassdoor.com': {
    APP_NAME: 'Leafly',
    HEADER_LOGO_COLOR:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/header_logo/Leafly+Logo.svg',
    PRIMARY: '#017C6B',
    FAVICON: 'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/favicon/favicon.ico',
    LARGEFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/favicon/favicon-32x32.png',
    SMALLFAVICON:
      'https://grassdoor-public-data-live-backup-new.s3.us-west-2.amazonaws.com/leafly/web_static_files/favicon/favicon-16x16.png'
  }
};

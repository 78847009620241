import React from 'react';
import PropTypes from 'prop-types';

function CustomRadioType1({ heading, subtitle, icon, id, name, value, isChecked, handleChange }) {
  return (
    <div className="custom-radio">
      <input name={name} type="radio" id={id} value={value} checked={isChecked} onChange={handleChange} />
      <label htmlFor={id} className="">
        <div className="normal-font ">{subtitle}</div>
      </label>
    </div>
  );
}

CustomRadioType1.propTypes = {
  name: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  // subtitle: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  handleChange: PropTypes.func
};

CustomRadioType1.defaultProps = {
  // subtitle: '',
  icon: '',
  isChecked: false,
  handleChange: () => { }
};

export default CustomRadioType1;

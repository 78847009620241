import React, { Fragment } from 'react';
import appConfig from '../appConfig';
import * as WEBSITES from '../constants/website';

const DiscountInPercent = ({ productFinalPrice, priceWithoutDeal, dealPriceType, compare }) => {
  const discountPercentage =
    process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR
      ? Math.trunc(((priceWithoutDeal * 100 - productFinalPrice * 100) / 100 / priceWithoutDeal) * 100)
      : 0;
  const discountPrice = process.env.NEXT_PUBLIC_APP === WEBSITES.GRASSDOOR ? Math.trunc(priceWithoutDeal - productFinalPrice) : 0;
  const discountOrSale =
    discountPercentage === 0 && discountPrice === 0 ? (
      compare ? (
        '-'
      ) : appConfig.SALE_TAG_NAME ? (
        appConfig.SALE_TAG_NAME
      ) : (
        'SALE'
      )
    ) : (
      <span className="bold"><span className='minus-sale'>-</span>{dealPriceType == 1 ? `$${discountPrice}` : `${discountPercentage}%`} <span className='off-sale'>OFF</span></span>
    );

  return <Fragment>{discountOrSale}</Fragment>;
};

export default DiscountInPercent;

import findIndex from 'lodash/findIndex';
import cookie from 'js-cookie';
import { getCurrentTime } from '../helpers/timeHelpers';
import { token, agePopup, deliveryPopup } from '../constants/cookieVariables';
import { SCHEDULE_TYPE_ASAP } from '../constants/scheduleType';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import { MULTIORDER } from '../constants/couponTypes';
import { setRollbarWarning } from '../helpers/rollBar';
import { GOVANA } from '../constants/website';
import getDailyLimitValues from '../helpers/getDailyLimitValues';

const key = {
  TOKEN: 'token',
  FNF: 'fnf',
  CUSTOM_PAYLOAD: 'custom-payload',
  USER_DATA: 'userData',
  RECIPIENT_DATA: 'recipientData',
  DELIVERY_DETAILS: 'delivery_details',
  AGE_CONFIRMATION: 'age_confirmation',
  DELIVERY_POPUP: 'delivery_popup',
  UPDATED_AGE_CONFIRMATION: 'updated_age_confirmation_part2',
  USE_WALLET_AMOUNT: 'use_wallet_amount',
  PAYMENT_TYPE: 'payment_type',
  DRIVER_TIP: 'driver_tip',
  PROMO_OFFER_CONFIRMATION: 'promo_offer_confirmation',
  PROMO_REPO: 'PROMO_REPO',
  AMBASSADOR_REPO: 'AMBASSADOR_REPO',
  ORDER_DETAILS_REPO: 'ORDER_DETAILS_REPO',
  CART_REPO: 'CART_REPO',
  EMAIL: 'EMAIL',
  COUPON_MESSAGE: 'COUPON_MESSAGE',
  ZONE_NAME: 'ZONE_NAME',
  FNFSESSION: 'FNFSESSION',
  DELIVERYPOPUPSESSION: 'DELIVERYPOPUPSESSION',
  DOMAIN_NAME: 'DOMAIN_NAME',
  READONLYCART: 'READONLYCART',
  SUBSCRIPTION_DETAILS: 'subscription_details',
  HIDE_COUPON: 'hide_coupon',
  NEWUSERWITHPROMOTIION: 'NEWUSERWITHPROMOTIION',
  ROUTE_DATA: 'routeData',
  CHATBOX: 'CHATBOX',
  COMPARE_DATA_LIST: 'COMPARE_DATA_LIST',
  COMPARE_LIST: 'COMPARE_LIST',
  INAPPWEBVIEWUSER: 'INAPPWEBVIEWUSER',
  LEDGERGREENCARDDETAILS: 'LEDGERGREENCARDDETAILS',
  DEBITCARDDETAILS: 'DEBITCARDDETAILS',
  SAVEFORLATER: 'SAVEFORLATER',
  FAVORITES: 'FAVORITES',
  LAST_APPLIED_COUPON: 'LAST_APPLIED_COUPON',
  HAPPY_HOURS_BANNER_SEEN: 'HAPPY_HOURS_BANNER_SEEN',
  USER_REMOVED_HAPPY_HOURS: 'USER_REMOVED_HAPPY_HOURS',
  AUTO_APPLY_COUPON: 'AUTO_APPLY_COUPON',
  APPLIED_COUPONS: 'APPLIED_COUPONS',
  PROMO_CODE_POPUP_SHOWN: 'isPromoCodePopupShown',
  RECENT_SEARCHES: 'RECENT_SEARCHES',
  HEX_ASAP_DELIVERY_DETAILS: 'HEX_ASAP_DELIVERY_DETAILS',
  INITIAL_WEBVIEW_DATA: "INITIAL_WEBVIEW_DATA",
  IS_ZIPCODE_CORRECTED: 'isZipcodeCorrected',
  MOD_IS_ZIPCODE_CORRECTED: 'modIsZipcodeCorrected',
  RECENT_VIEWED_SEARCHED_PRODUCTS: 'RECENT_VIEWED_SEARCHED_PRODUCTS'
};

class StorageService {
  constructor() {
    if (process.browser) {
      this.storage = window.localStorage;
      this.sessionStorage = window.sessionStorage;
      this.migrateOldUserDataToNewUserData();
      this.isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';
    } else {
      this.storage = null;
    }
  }

  migrateOldUserDataToNewUserData() {
    const oldKey = {
      AUTH_REPO: 'AUTH_REPO',
      ADDRESS_REPO: 'ADDRESS_REPO',
      MARKETING_DETAILS_REPO: 'MARKETING_DETAILS_REPO',
      TERMS_CONFIRMATION: 'TERMS_CONFIRMATION',
      USER_REPO: 'USER_REPO',
      WORKINGHOURS_REPO: 'WORKINGHOURS_REPO',
      ADDRESS_EMAIL_REPO: 'ADDRESS_EMAIL_REPO',
      CAROUSEL_STATE: 'CAROUSEL_STATE',
      REFERRAL_REPO: 'REFERRAL_REPO'
    };

    if (
      !this.getItem(oldKey.AUTH_REPO) &&
      !this.getItem(oldKey.ADDRESS_REPO) &&
      !this.getItem(oldKey.MARKETING_DETAILS_REPO) &&
      !this.getItem(oldKey.TERMS_CONFIRMATION) &&
      !this.getItem(oldKey.USER_REPO) &&
      !this.getItem(oldKey.WORKINGHOURS_REPO) &&
      !this.getItem(oldKey.ADDRESS_EMAIL_REPO) &&
      !this.getItem(oldKey.CAROUSEL_STATE)
    ) {
      return;
    }

    // Keys to be removed
    this.storage.removeItem(oldKey.WORKINGHOURS_REPO);
    this.storage.removeItem(oldKey.ADDRESS_EMAIL_REPO);
    this.storage.removeItem(oldKey.CAROUSEL_STATE);

    const localCartDataString = this.getItem(key.CART_REPO);
    if (localCartDataString) {
      const localCartData = JSON.parse(localCartDataString);
      if (localCartData && localCartData.length) {
        // if local cart data does not have key: product_details_id, then it's the old cart data which is incompatible.
        if (!localCartData[0].product_details_id) {
          this.storage.removeItem(key.CART_REPO);
        }
      }
    }

    // Keys to be updated
    const oldTokenString = this.getItem(oldKey.AUTH_REPO);
    if (oldTokenString) {
      try {
        const oldToken = JSON.parse(oldTokenString).token;
        this.setToken(oldToken);
      } finally {
        this.storage.removeItem(oldKey.AUTH_REPO);
      }
    }

    const oldAddressString = this.getItem(oldKey.ADDRESS_REPO);
    if (oldAddressString) {
      try {
        const oldAddress = JSON.parse(oldAddressString);
        const newObj = {
          address: oldAddress.prefferedAddress,
          address_id: oldAddress.id,
          city: oldAddress.city,
          country: oldAddress.country,
          deliveryDate: 0,
          deliveryTime: getCurrentTime(),
          deliveryTypeSelected: SCHEDULE_TYPE_ASAP,
          latitude: oldAddress.coordinates.lat,
          longitude: oldAddress.coordinates.lng,
          postcode: oldAddress.zipcode,
          state: oldAddress.addressState
        };
        this.setDeliveryDetails(newObj);
      } finally {
        this.storage.removeItem(oldKey.ADDRESS_REPO);
      }
    }

    const oldMarketingDetailsString = this.getItem(oldKey.MARKETING_DETAILS_REPO);
    if (oldMarketingDetailsString) {
      try {
        const oldMarketingDetails = JSON.parse(oldMarketingDetailsString);
        const newObj = {
          id: oldMarketingDetails.marketingId,
          updatedTime: oldMarketingDetails.updatedTime
        };
        this.setPromoOfferConfirmationShowPopup(newObj);
      } finally {
        this.storage.removeItem(oldKey.MARKETING_DETAILS_REPO);
      }
    }

    const oldTermsString = this.getItem(oldKey.TERMS_CONFIRMATION);
    if (oldTermsString) {
      try {
        const oldTerms = JSON.parse(oldTermsString).hasConfirmed;
        this.setAgeConfirmationShowPopup(oldTerms, true);
      } finally {
        this.storage.removeItem(oldKey.TERMS_CONFIRMATION);
      }
    }
    const oldUserDataString = this.getItem(oldKey.USER_REPO);
    if (oldUserDataString) {
      try {
        const oldUserData = JSON.parse(oldUserDataString);
        const newObj = {
          country: oldUserData.countryCode,
          name: oldUserData.name,
          phone: oldUserData.telephone
        };
        this.setUserData(newObj);
      } finally {
        this.storage.removeItem(oldKey.USER_REPO);
      }
    }
    const oldPromoString = this.getItem(key.PROMO_REPO);
    if (oldPromoString) {
      try {
        const oldPromo = JSON.parse(oldPromoString);
        const newObj = {
          coupon_type: oldPromo.coupon_type_id,
          discount: oldPromo.discountPercent,
          discount_code: oldPromo.discount_code,
          id: oldPromo.coupon_id,
          keep_applied: false,
          product_ids: oldPromo.productIds,
          slash_price: oldPromo.isPercentage
        };

        this.setCouponDetails(newObj);
      } catch (error) {
        setRollbarWarning('storageService.js_migrateOldUserDataToNewUserData_oldPromoString_catch', error);
        console.error('storageService.js_migrateOldUserDataToNewUserData_oldPromoString_catch', error);
      }
    }

    const oldReferralString = this.getItem(oldKey.REFERRAL_REPO);
    if (oldReferralString) {
      try {
        const oldReferral = JSON.parse(oldReferralString);
        const newObj = {
          ...this.getCouponDetails(),
          type: oldReferral.type,
          token: oldReferral.token
        };
        this.setCouponDetails(newObj);
      } finally {
        this.storage.removeItem(oldKey.REFERRAL_REPO);
      }
    }
  }

  clearUserSpecificData() {
    this.clearEmail();
    cookie.remove(token);
    this.storage.removeItem(key.TOKEN);
    this.storage.removeItem(key.FNF);
    this.storage.removeItem(key.USER_DATA);
    this.storage.removeItem(key.PROMO_REPO);
    this.storage.removeItem(key.AMBASSADOR_REPO);
    this.storage.removeItem(key.ORDER_DETAILS_REPO);
    this.storage.removeItem(key.CART_REPO);
    this.storage.removeItem(key.CHATBOX);
    this.storage.removeItem(key.COMPARE_DATA_LIST);
    this.storage.removeItem(key.COMPARE_LIST);
    this.storage.removeItem(key.SAVEFORLATER);
    this.storage.removeItem(key.FAVORITES);
    this.storage.removeItem(key.IS_ZIPCODE_CORRECTED);
    this.storage.removeItem(key.MOD_IS_ZIPCODE_CORRECTED);
  }

  removeToken() {
    this.storage.removeItem(key.TOKEN);
  }

  getItem(property) {
    if (this.storage) {
      return this.storage.getItem(property);
    }
    return null;
  }

  setItem(property, value) {
    if (this.storage) {
      return this.storage.setItem(property, JSON.stringify(value));
    }

    return null;
  }

  getItemSession(property) {
    if (this.sessionStorage) {
      return this.sessionStorage.getItem(property);
    }
    return null;
  }

  getItemSessionParsed(property) {
    if (this.sessionStorage) {
      return JSON.parse(this.sessionStorage.getItem(property) || false);
    }
    return null;
  }
  removeItemSession(property) {
    if (this.sessionStorage) {
      return this.sessionStorage.removeItem(property);
    }
    return null;
  }

  setItemSession(property, value) {
    if (this.sessionStorage) {
      return this.sessionStorage.setItem(property, JSON.stringify(value));
    }
    return null;
  }

  setFriendsSession(value) {
    this.setItemSession(key.FNFSESSION, value);
  }

  removeFriendsSession(value) {
    this.removeItemSession(key.FNFSESSION);
  }

  getFriendsSession() {
    return this.getItemSession(key.FNFSESSION);
  }

  setHappyHoursBanner(value) {
    this.setItemSession(key.HAPPY_HOURS_BANNER_SEEN, value);
  }

  getHappyHoursBanner() {
    return this.getItemSession(key.HAPPY_HOURS_BANNER_SEEN);
  }

  setHappyHoursCouponCode(value) {
    this.setItemSession(key.USER_REMOVED_HAPPY_HOURS, value);
  }

  getHappyHoursCouponCode() {
    return this.getItemSession(key.USER_REMOVED_HAPPY_HOURS);
  }

  setLedgerGreenCardSession(value) {
    this.setItemSession(key.LEDGERGREENCARDDETAILS, value);
  }

  removeLedgerGreenCardSession() {
    this.removeItemSession(key.LEDGERGREENCARDDETAILS);
  }

  getLedgerGreenCardSession() {
    const data = this.getItemSession(key.LEDGERGREENCARDDETAILS);
    return JSON.parse(data);
  }

  setDebitCardSession(value) {
    this.setItemSession(key.DEBITCARDDETAILS, value);
  }

  removeDebitCardSession() {
    this.removeItemSession(key.DEBITCARDDETAILS);
  }

  getDebitCardSession() {
    const data = this.getItemSession(key.DEBITCARDDETAILS);
    return JSON.parse(data);
  }

  setDeliveryPopupSession(value) {
    this.setItemSession(key.DELIVERYPOPUPSESSION, value);
  }

  removeDeliveryPopupSession(value) {
    this.removeItemSession(key.DELIVERYPOPUPSESSION);
  }

  getDeliveryPopupSession() {
    return this.getItemSession(key.DELIVERYPOPUPSESSION);
  }

  removeItemSession(property) {
    if (this.sessionStorage) {
      this.sessionStorage.removeItem(property);
    }
  }

  getToken() {
    return this.getItem(key.TOKEN) || false;
  }

  deleteToken() {
    this.storage.removeItem(key.TOKEN);
  }

  isLoggedIn() {
    if (cookie.get(token)) {
      return true;
    }
    return false;
  }

  // When cart synced with server. Remove the following
  logoutClearUserData() {
    if (window.analytics) {
      window.analytics.track('User Login', {
        category: 'User Events',
        action: 'Logout',
        label: ''
      });
      this.setAmbassadorLocalStorage(null);
      this.storage.setItem(key.CART_REPO, JSON.stringify([]));
    }
  }

  getUserData() {
    try {
      return JSON.parse(this.getItem(key.USER_DATA));
    } catch (err) {
      return {};
    }
  }

  setUserData(data) {
    if (data) {
      this.storage.setItem(key.USER_DATA, JSON.stringify(data));
    }
  }

  setRecipientData(data) {
    if (data) {
      this.sessionStorage.setItem(key.RECIPIENT_DATA, JSON.stringify(data));
    }
  }

  getRecipientData() {
    const data = this.getItemSession(key.RECIPIENT_DATA);
    return JSON.parse(data);
  }

  clearRecipients() {
    this.sessionStorage.removeItem(key.RECIPIENT_DATA);
  }

  getEmail() {
    return this.getItem(key.EMAIL);
  }

  setEmail(email) {
    this.storage.setItem(key.EMAIL, email);
  }

  clearEmail() {
    this.storage.removeItem(key.EMAIL);
  }

  setToken(token) {
    if (token) {
      this.storage.setItem(key.TOKEN, token);
    }
  }

  setCustomPayload(payload) {
    this.storage.setItem(key.CUSTOM_PAYLOAD, payload);
  }

  getCustomPaylaod() {
    return this.getItem(key.CUSTOM_PAYLOAD);
  }

  setDeliveryDetails(payload) {
    const newPayload = payload;
    newPayload.fromMap = false;
    this.storage.setItem(key.DELIVERY_DETAILS, JSON.stringify(newPayload));
  }

  getDeliveryDetails() {
    try {
      return JSON.parse(this.getItem(key.DELIVERY_DETAILS));
    } catch (err) {
      return {};
    }
  }

  setAgeConfirmationShowPopup(payload, session = 0) {
    if (!session) {
      this.storage.setItem(key.AGE_CONFIRMATION, JSON.stringify(payload));
    } else {
      const expiresIn = parseFloat(process.env.NEXT_PUBLIC_AGE_EXPIRE);

      if (process.env.NEXT_PUBLIC_APP === GOVANA || this.isCheckoutOnly) {
        cookie.set(agePopup, payload, { expires: expiresIn, sameSite: 'none', secure: true });
      } else {
        cookie.set(agePopup, payload, { expires: expiresIn, sameSite: 'lax' });
      }
    }
  }

  getAgeConfirmationShowPopup(session = 0) {
    try {
      if (!session) {
        return JSON.parse(this.getItem(key.AGE_CONFIRMATION));
      }
      return cookie.get(agePopup);
      // return JSON.parse(this.getItemSession(key.AGE_CONFIRMATION));
    } catch (err) {
      return {};
    }
  }

  setDeliveryPopup(payload) {
    this.storage.setItem(key.DELIVERY_POPUP, JSON.stringify(payload));
    cookie.set(deliveryPopup, payload);
  }

  getDeliveryPopup() {
    try {
      // return JSON.parse(this.getItem(key.DELIVERY_POPUP));
      return cookie.get(deliveryPopup);
      // return JSON.parse(this.getItemSession(key.AGE_CONFIRMATION));
    } catch (err) {
      return {};
    }
  }

  setUpdatedAgeConfirmationShowPopup(payload) {
    this.storage.setItem(key.UPDATED_AGE_CONFIRMATION, JSON.stringify(payload));
  }

  getUpdatedAgeConfirmationShowPopup() {
    try {
      return JSON.parse(this.getItem(key.UPDATED_AGE_CONFIRMATION));
    } catch (err) {
      return {};
    }
  }

  setUseWalletAmount(payload) {
    this.storage.setItem(key.USE_WALLET_AMOUNT, JSON.stringify(payload));
  }

  getUseWalletAmount() {
    try {
      return JSON.parse(this.getItem(key.USE_WALLET_AMOUNT));
    } catch (err) {
      return {};
    }
  }

  setPaymentType(payload) {
    this.storage.setItem(key.PAYMENT_TYPE, JSON.stringify(payload));
  }

  getPaymentType() {
    try {
      return JSON.parse(this.getItem(key.PAYMENT_TYPE));
    } catch (err) {
      return {};
    }
  }

  setDriverTip(payload) {
    this.storage.setItem(key.DRIVER_TIP, JSON.stringify(payload));
  }

  getDriverTip() {
    try {
      return JSON.parse(this.getItem(key.DRIVER_TIP));
    } catch (err) {
      return {};
    }
  }

  deleteCouponFlag() {
    this.storage.removeItem(key.HIDE_COUPON);
  }

  getCouponFlag() {
    try {
      return JSON.parse(this.getItem(key.HIDE_COUPON));
    } catch (err) {
      return false;
    }
  }

  setCouponFlag(payload) {
    this.storage.setItem(key.HIDE_COUPON, JSON.stringify(payload));
  }

  setAmbassadorLocalStorage(payload) {
    this.storage.setItem(key.AMBASSADOR_REPO, JSON.stringify(payload));
  }

  getAmbassadorLocalStorage() {
    try {
      return JSON.parse(this.getItem(key.AMBASSADOR_REPO));
    } catch (err) {
      return {};
    }
  }

  setPromoOfferConfirmationShowPopup(payload) {
    this.storage.setItem(key.PROMO_OFFER_CONFIRMATION, JSON.stringify(payload));
  }

  getPromoOfferConfirmationShowPopup() {
    try {
      return JSON.parse(this.getItem(key.PROMO_OFFER_CONFIRMATION));
    } catch (err) {
      return {};
    }
  }

  getFnfLocal() {
    try {
      return JSON.parse(this.getItem(key.FNF) || false);
    } catch (err) {
      return false;
    }
  }

  setFnfLocal(payload) {
    this.storage.setItem(key.FNF, JSON.stringify(payload));
  }

  setInAppWebViewUser = () => {
    this.sessionStorage.setItem(key.INAPPWEBVIEWUSER, true);
  };

  removeInAppWebViewUser = () => {
    this.sessionStorage.removeItem(key.INAPPWEBVIEWUSER);
  };

  getInAppWebViewUser = () => {
    try {
      if (this.sessionStorage) {
        return JSON.parse(this.sessionStorage.getItem(key.INAPPWEBVIEWUSER));
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  setCompareDataList = payload => {
    this.storage.setItem(key.COMPARE_DATA_LIST, JSON.stringify(payload));
  };

  setCompareList = payload => {
    this.storage.setItem(key.COMPARE_LIST, JSON.stringify(payload));
  };

  getCompareDataList = () => {
    try {
      const data = JSON.parse(this.storage.getItem(key.COMPARE_DATA_LIST));
      if (data) {
        return data;
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  getCompareList = () => {
    try {
      const data = JSON.parse(this.storage.getItem(key.COMPARE_LIST));
      if (data) {
        return data;
      }
      return [];
    } catch (error) {
      return [];
    }
  };

  getAddressLocalStorage() {
    try {
      const data = JSON.parse(this.getItem(key.DELIVERY_DETAILS));
      if (!data) {
        return {};
      }
      return data;
    } catch (err) {
      return {};
    }
  }

  getZoneLocalStorage() {
    try {
      const data = JSON.parse(this.getItem(key.ZONE_NAME));
      if (!data) {
        return '';
      }
      return data;
    } catch (err) {
      return '';
    }
  }

  setZoneLocalStorage(payload) {
    this.storage.setItem(key.ZONE_NAME, JSON.stringify(payload));
  }

  setUserWithPermission(payload) {
    this.storage.setItem(key.NEWUSERWITHPROMOTIION, JSON.stringify(payload));
  }

  getUserWithPromotion() {
    return JSON.parse(this.getItem(key.NEWUSERWITHPROMOTIION) || false);
  }

  deleteUserWithPromotion() {
    this.storage.removeItem(key.NEWUSERWITHPROMOTIION);
  }

  setCouponDetails(payload) {
    this.storage.setItem(key.PROMO_REPO, JSON.stringify(payload));
  }

  getCouponDetails() {
    try {
      return JSON.parse(this.getItem(key.PROMO_REPO) || {});
    } catch (err) {
      return {};
    }
  }

  deleteCouponDetails() {
    const cpn = this.getCouponDetails();
    if (cpn.coupon_type != MULTIORDER) {
      this.storage.removeItem(key.PROMO_REPO);
    }
  }

  getRouteDetails() {
    try {
      return JSON.parse(this.getItem(key.ROUTE_DATA) || null);
    } catch (err) {
      return null;
    }
  }

  setLastKnownDriverLocation = orderObject => {
    const lastOrderObject = this.getLastKnownDriverLocation();

    const fiveMinsAgo = new Date();
    fiveMinsAgo.setMinutes(fiveMinsAgo.getMinutes() - 5);

    if (lastOrderObject) {
      const lastUpdated = new Date(lastOrderObject.lastUpdated);
      if (lastUpdated.getTime() < fiveMinsAgo.getTime() || orderObject.orderId !== lastOrderObject.orderId) {
        this.storage.setItem(key.ORDER_DETAILS_REPO, JSON.stringify(orderObject));
      }
    } else {
      this.storage.setItem(key.ORDER_DETAILS_REPO, JSON.stringify(orderObject));
    }
  };

  getLastKnownDriverLocation() {
    try {
      return JSON.parse(this.getItem(key.ORDER_DETAILS_REPO));
    } catch (err) {
      return {};
    }
  }

  setAddressLocalStorage(payload) {
    this.storage.setItem(key.DELIVERY_DETAILS, JSON.stringify(payload));
  }

  getReadOnlyCart() {
    if (this.storage.getItem(key.READONLYCART) === 'true' || this.storage.getItem(key.READONLYCART) === true) {
      return true;
    }

    return false;
  }

  setReadOnlyCart() {
    this.storage.setItem(key.READONLYCART, true);
  }

  removeReadOnlyCart() {
    this.storage.removeItem(key.READONLYCART);
  }

  setCart(payload) {
    this.storage.setItem(key.CART_REPO, JSON.stringify(payload));
  }

  deleteCart() {
    this.storage.removeItem(key.CART_REPO);
  }

  getCart() {
    try {
      const data = JSON.parse(this.getItem(key.CART_REPO));
      if (!data) {
        return [];
      }
      return data;
    } catch (err) {
      return [];
    }
  }

  getTempEmail = () => {
    return this.getItem('TEMP_EMAIL');
  };

  clearTempEmail = () => {
    this.storage.removeItem('TEMP_EMAIL');
  };

  clearUserTempData = () => {
    this.storage.removeItem('userTempData');
  };

  setRecentSearch(payload) {
    this.storage.setItem(key.RECENT_SEARCHES, JSON.stringify(payload));
  }

  getRecentSearch() {
    try {
      return JSON.parse(this.getItem(key.RECENT_SEARCHES) || []);
    } catch (err) {
      return [];
    }
  }


  addToCart(data) {
    const cart = this.getCart();

    if (data.ounce_id) {
      const ounceProduct = data;
      cart.push(ounceProduct);
    } else if (data.bundle_id) {
      const index = findIndex(cart, { bundle_id: data.bundle_id });

      if (index > -1) {
        cart[index].quantity += 1;
      } else {
        // Object fpr bundle
        const product = {
          ...data,
          bundle_id: data.bundle_id,
          category_name: data.category_name,
          quantity: data.quantity ? data.quantity : 1,
          bulk_base_price: data.bulk_base_price ? data.bulk_base_price : null,
          bulk_prices: data.bulk_prices && data.bulk_prices.length > 0 ? [...data.bulk_prices] : null,
          name: data.bundle_name,
          price: data.bundle_selling_price,
          original_price: data.original_price,
          category_currency: '$',
          image: data.product_image || data?.images?.[0] || '',
          image_thumb_medium: data.product_image || data?.images?.[0] || '',
          image_thumb_small: data.product_image || data?.images?.[0] || '',
          slug: data.slug
        };

        cart.push(product);
      }
    } else {
      const index = findIndex(cart, { product_id: data.product_id });
      if (index > -1) {
        cart[index].quantity += 1;
      } else {
        const product = {
          ...data,
          product_id: data.product_id,
          product_details_id: data.product_details_id,
          master_product_id: data.master_product_id,
          category_name: data.category_name,
          quantity: data.quantity ? data.quantity : 1,
          bulk_base_price: data.bulk_base_price ? data.bulk_base_price : null,
          bulk_prices: data.bulk_prices && data.bulk_prices.length > 0 ? [...data.bulk_prices] : null,
          name: data.product_name,
          price: data.price,
          original_price: data.price,
          originalPrice: data.price,
          category_currency: data.product_currency,
          category_weight: data.product_weight,
          category_unit: data.product_unit,
          image: data.product_image || data?.images?.[0] || '',
          image_thumb_medium: data.product_image || data?.images?.[0] || '',
          image_thumb_small: data.product_image || data?.images?.[0] || '',
          price_without_deal: data.price_without_deal,
          is_deal_available: data.is_deal_available,
          is_pre_deal_available: data.is_pre_deal_available,
          slug: data.slug,
          asap: data.asap,
          schedule: data.schedule
        };

        product.concentrated_weight = getDailyLimitValues(product).concentrated_weight;
        product.non_concentrated_weight = getDailyLimitValues(product).non_concentrated_weight;

        // let total_offer_concentrates = 0;
        // let total_offer_non_concentrates = 0;
        // let total_concentrates = product.concentrated_weight_original;
        // let total_non_concentrates = product.non_concentrated_weight_original;
        // if (product.offer_product && product.offer_product.length) {
        //   product.offer_product.map((offerProd) => {
        //     if (offerProd.products && offerProd.products.length) {
        //       offerProd.products.map((products) => {
        //         if (product.is_offer_allowed == 1) {
        //           total_offer_concentrates = products.concentrated_weight * offerProd.is_avail * products.get_quantity;
        //           total_offer_non_concentrates = products.non_concentrated_weight * offerProd.is_avail * products.get_quantity;
        //           total_concentrates = total_concentrates * product.quantity;
        //           total_non_concentrates = total_non_concentrates * product.quantity;
        //           product.concentrated_weight = total_concentrates + total_offer_concentrates;
        //           product.non_concentrated_weight = total_non_concentrates + total_offer_non_concentrates;
        //         } else {
        //           product.concentrated_weight = total_concentrates * product.quantity;
        //           product.non_concentrated_weight = total_non_concentrates * product.quantity;
        //         }
        //       })
        //     }
        //   })
        // } else {
        //   product.concentrated_weight = total_concentrates * product.quantity;
        //   product.non_concentrated_weight = total_non_concentrates * product.quantity;
        // }

        cart.push(product);
      }
    }

    this.storage.setItem(key.CART_REPO, JSON.stringify(cart));
    return cart;
  }

  updateCart({
    id,
    quantity,
    isBundle,
    price,
    offerProduct,
    isOfferAllowed,
    ounce,
    ounceProduct,
    offer_concentrates,
    offer_non_concentrates
  }) {
    const cart = this.getCart();
    let index = -1;

    if (ounce) {
      index = findIndex(cart, { ounce_id: id });
    } else if (isBundle) {
      index = findIndex(cart, { bundle_id: id });
    } else {
      index = findIndex(cart, { product_id: id });
    }

    if (index > -1) {
      if (ounceProduct) {
        cart[index] = ounceProduct;
      }

      if (quantity) {
        cart[index].quantity = quantity;
      }

      if (price) {
        cart[index].price = price;
      }

      if (offerProduct) {
        cart[index].offer_product = offerProduct;
      }

      if (isOfferAllowed || isOfferAllowed === 0) {
        cart[index].is_offer_allowed = isOfferAllowed;
      }

      if (offer_concentrates || offer_concentrates === 0) {
        cart[index].concentrated_weight = offer_concentrates;
      }

      if (offer_non_concentrates || offer_non_concentrates === 0) {
        cart[index].non_concentrated_weight = offer_non_concentrates;
      }
      cart[index].concentrated_weight = getDailyLimitValues(cart[index]).concentrated_weight;
      cart[index].non_concentrated_weight = getDailyLimitValues(cart[index]).non_concentrated_weight;
    }

    this.storage.setItem(key.CART_REPO, JSON.stringify(cart));
    return cart;
  }

  deleteProduct(cartItem) {
    const cart = this.getCart();
    let index = -1;

    if (cartItem.ounce_id) {
      index = findIndex(cart, { ounce_id: cartItem.ounce_id });
    } else if (cartItem.bundle_id) {
      index = findIndex(cart, { bundle_id: cartItem.bundle_id });
    } else {
      index = findIndex(cart, { product_id: cartItem.product_id });
    }

    if (index > -1) {
      cart.splice(index, 1);
    }
    this.storage.setItem(key.CART_REPO, JSON.stringify(cart));
    return cart;
  }

  getSubscriptionDetails = () => {
    try {
      return JSON.parse(this.getItem(key.SUBSCRIPTION_DETAILS));
    } catch (error) {
      return null;
    }
  };

  clearSubscriptionDetails = () => {
    this.storage.removeItem(key.SUBSCRIPTION_DETAILS);
    this.storage.removeItem(key.READONLYCART);
  };

  clearCartProducts(deliveryType, product) {
    let cart = this.getCart();
    cart = cart.filter(item => {
      if (deliveryType == ASAP) {
        // if (item.asap && !item.schedule) {
        //   return false;
        // }
        if (!item.asap || (item.asap && (item.available_quantity == 0 || item.available_quantity == null))) {
          return false;
        }
      } else if (deliveryType == SCHEDULE) {
        // if (item.schedule && !item.asap) {
        //   return false;
        // }
        if (!item.schedule || (item.schedule && item.product_max_quantity == 0)) {
          return false;
        }
      }
      return true;
    });
    this.storage.setItem(key.CART_REPO, JSON.stringify(cart));
    return product ? this.addToCart(product) : cart;
  }

  ApplyDiscountToCart(couponDetails) {
    const cartData = this.getCart();
    if (Array.isArray(cartData) && couponDetails.slash_price) {
      const discountedCart = cartData.map(item => {
        if (item.is_deal_available || item.is_pre_deal_available) {
          return item;
        }
        let discountedPrice = item.original_price;
        if (couponDetails.product_ids && couponDetails.product_ids.includes(item.product_id)) {
          discountedPrice = (item.original_price * ((100 - couponDetails.discount) / 100)).toFixed(2);
        } else {
          discountedPrice = (item.originalPrice * ((100 - couponDetails.discount) / 100)).toFixed(2);
        }
        return { ...item, price: discountedPrice };
      });
      this.setCart(discountedCart);
    }
  }

  SetChatBoxData(data) {
    this.storage.setItem(key.CHATBOX, JSON.stringify(data));
  }

  GetChatBoxData(data) {
    const chatBox = this.getItem(key.CHATBOX);
    if (chatBox) {
      return JSON.parse(chatBox);
    }
    return {};
  }

  clearStorage() {
    this.storage.clear();
  }

  getSaveForLaterItems({ includingDisabled = false } = {}) {
    // send data
    try {
      let data = []
      data = JSON.parse(this.getItem(key.SAVEFORLATER));
      if (!data) {
        return []
      }
      return includingDisabled ? data : data?.filter(product => product?.isDisabled != true);
    } catch (err) {
      return [];
    }
  }

  addToSaveForLater(item) {
    // add Item
    const saveForLaterItems = this.getSaveForLaterItems({ includingDisabled: true });
    saveForLaterItems.push(item);
    this.storage.setItem(key.SAVEFORLATER, JSON.stringify(saveForLaterItems));
    return saveForLaterItems;
  }

  updateSaveForLaterItems(items) {
    // updates isDisabled key for products
    const allSaveForLaterItems = this.getSaveForLaterItems({ includingDisabled: true });
    const updatedItems = allSaveForLaterItems.map(product => {
      let index = -1
      if (product?.bundle_id) {
        index = findIndex(items, { bundle_id: product.bundle_id })
      } else {
        index = findIndex(items, { product_details_id: product?.product_details_id })
      }
      if (index > -1) {
        return { ...product, isDisabled: false }
      }
      return { ...product, isDisabled: true }
    })
    this.storage.setItem(key.SAVEFORLATER, JSON.stringify(updatedItems));
  }

  isInSaveForLater(item) {
    const saveForLaterItems = this.getSaveForLaterItems()
    let index = -1;

    if (item?.bundle_id) {
      index = findIndex(saveForLaterItems, { bundle_id: item.bundle_id });
    } else {
      index = findIndex(saveForLaterItems, { product_details_id: item?.product_details_id });
    }
    if (index > -1) {
      return true
    }
    return false
  }

  deleteSaveForLaterProduct(item) {
    // delete Item
    const saveForLaterItems = this.getSaveForLaterItems({ includingDisabled: true });
    let index = -1;

    if (item.bundle_id) {
      index = findIndex(saveForLaterItems, { bundle_id: item.bundle_id });
    } else {
      index = findIndex(saveForLaterItems, { product_details_id: item?.product_details_id });
    }

    if (index > -1) {
      saveForLaterItems.splice(index, 1);
    }
    this.storage.setItem(key.SAVEFORLATER, JSON.stringify(saveForLaterItems));
    return saveForLaterItems;
  }

  deleteSaveForLaterItems() {
    this.storage.removeItem(key.SAVEFORLATER);
  }

  getFavourites({ includingDisabled = false } = {}) {
    try {
      let data = []
      data = JSON.parse(this.getItem(key.FAVORITES));
      if (!data) {
        return []
      }
      // filtering disabled products
      return includingDisabled ? data : data?.filter(product => product?.isDisabled != true);
    } catch (err) {
      return [];
    }
  }

  addToFavourites(item) {
    // add Item
    const FavouriteItems = this.getFavourites({ includingDisabled: true });
    FavouriteItems.push(item);
    this.storage.setItem(key.FAVORITES, JSON.stringify(FavouriteItems));
    return FavouriteItems;
  }

  updateFavouriteItems(items) {
    // updates isDisabled key for products
    const allFavouriteItems = this.getFavourites({ includingDisabled: true });
    const updatedItems = allFavouriteItems.map(product => {
      let index = -1
      if (product?.bundle_id) {
        index = findIndex(items, { bundle_id: product.bundle_id })
      } else {
        index = findIndex(items, { product_details_id: product?.product_details_id })
      }
      if (index > -1) {
        return { ...product, isDisabled: false }
      }
      return { ...product, isDisabled: true }
    })
    this.storage.setItem(key.FAVORITES, JSON.stringify(updatedItems));
  }

  deleteFavouriteProduct(item) {
    // delete Item
    const FavouriteItems = this.getFavourites({ includingDisabled: true });
    let index = -1;

    if (item.bundle_id) {
      index = findIndex(FavouriteItems, { bundle_id: item.bundle_id });
    } else {
      index = findIndex(FavouriteItems, { product_details_id: item?.product_details_id });
    }

    if (index > -1) {
      FavouriteItems.splice(index, 1);
    }
    this.storage.setItem(key.FAVORITES, JSON.stringify(FavouriteItems));
    return FavouriteItems;
  }

  isInFavourites(item) {
    const FavouriteItems = this.getFavourites({ includingDisabled: true })
    let index = -1;

    if (item?.bundle_id) {
      index = findIndex(FavouriteItems, { bundle_id: item.bundle_id });
    } else {
      index = findIndex(FavouriteItems, { product_details_id: item?.product_details_id });
    }
    if (index > -1) {
      return true
    }
    return false
  }

  deleteFavouriteItems() {
    this.storage.removeItem(key.FAVORITES);
  }

  setLastAppliedCoupon(data) {
    this.sessionStorage.setItem(key.LAST_APPLIED_COUPON, JSON.stringify(data));
  }

  getLastAppliedCoupon() {
    const coupon = this.sessionStorage.getItem(key.LAST_APPLIED_COUPON);
    if (coupon) return JSON.parse(coupon);
    return null;
  }

  clearLastAppliedCoupon() {
    this.sessionStorage.removeItem(key.LAST_APPLIED_COUPON);
  }
  setAutoApplyCoupon() {
    this.sessionStorage.setItem(key.AUTO_APPLY_COUPON, true);
  }

  getAutoApplyCoupon() {
    const autoApplyCoupon = this.sessionStorage.getItem(key.AUTO_APPLY_COUPON);
    if (autoApplyCoupon) return true;
    return false;
  }

  setAppliedCoupons(data) {
    this.sessionStorage.setItem(key.APPLIED_COUPONS, JSON.stringify(data));
  }

  getAppliedCoupons() {
    const appliedCoupons = this.sessionStorage.getItem(key.APPLIED_COUPONS);
    if (appliedCoupons) return JSON.parse(appliedCoupons);
    return [];
  }

  setPromoCodePopupShown() {
    this.sessionStorage.setItem(key.PROMO_CODE_POPUP_SHOWN, true);
  }

  getPromoCodePopupShown() {
    const data = this.sessionStorage.getItem(key.PROMO_CODE_POPUP_SHOWN);
    if (data) return true;
    return false;
  }

  setHexAsapDeliveryDetails(payload) {
    this.sessionStorage.setItem(key.HEX_ASAP_DELIVERY_DETAILS, JSON.stringify(payload));
  }

  getHexAsapDeliveryDetails() {
    const data = this.sessionStorage.getItem(key.HEX_ASAP_DELIVERY_DETAILS);
    return data ? JSON.parse(data) : null;
  }

  setInitialWebviewData(data) {
    this.sessionStorage.setItem(key.INITIAL_WEBVIEW_DATA, JSON.stringify(data))
  }

  getInitialWebviewData() {
    let data = this.sessionStorage.getItem(key.INITIAL_WEBVIEW_DATA)
    return data ? JSON.parse(data) : {}
  }

  getIsZipcodeCorrected() {
    return this.getItem(key.MOD_IS_ZIPCODE_CORRECTED);
  }

  setIsZipcodeCorrected(value) {
    this.setItem(key.MOD_IS_ZIPCODE_CORRECTED, value);
  }
}
export default new StorageService();

export const zipcode = '_grassdoor_zc';
export const siteType = '_site_Type';
export const agePopup = 'agePopup';
export const token = 'token';
export const isScheduleDropDownSet = 'isScheduleDropDownSet';
export const latitude = 'latitude';
export const longitude = 'longitude';
export const placeId = 'placeId';
export const siteDomain = 'siteDomain';
export const deliveryPopup = 'deliveryPopup';
export const zoneId = 'zoneId';
export const recentViewedProductsId = 'recentViewedProductsId';

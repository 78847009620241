import { useContext } from 'react';

import envAppConfig from '../constants/envAppConfig';
import { GRASSDOOR } from '../constants/website';

import storageService from '../services/storageService';

import loadScript from '../helpers/loadScript';

import appContext from '../Context/appContext';

import appConfig from '../appConfig';

import { useMyOrders } from './User';
import { useDispatch, useSelector } from 'react-redux';
import { useJitsu } from '@jitsu/jitsu-react';
import { setOptimize, setbraze } from '../redux/slices/otherSlice';

const isCheckoutOnly = process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true';

const useSegment = () => {
  let myVar;
  let myZenVar;
  let liveChatVar;
  let googleOptimizeVar;
  let liveChatOptimizerVar;
  let brazeOptimizerVar;
  const dispatchRedux = useDispatch();
  const { analytics } = useJitsu()
  // const {
  //   // state: {
  //   //   user: { userInfo }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const userInfo = useSelector(state => state.user.userInfo)

  const {
    data: { orders }
  } = useMyOrders({ offset: 0 });

  const firstOrder = orders && orders.length ? orders[0] : null;

  function livechatTimer() {
    if (window.LC_API && window.liveChatLoaded) {
      if (firstOrder && firstOrder.order_id && window.analytics) {
        window.analytics.identify({ name: userInfo.name, phone: userInfo.phone, 'Order Id': firstOrder.order_id });
      } else if (window.analytics) {
        window.analytics.identify({ name: userInfo.name, phone: userInfo.phone });
      }
      clearInterval(liveChatVar);
    } else if (window.LC_API && window.liveChatLoaded) {
      clearInterval(liveChatVar);
    }
  }
  // "LiveChat"

  function googleOptimizeTimer() {
    if (process.env.NEXT_PUBLIC_APP == GRASSDOOR) {
      if (window.google_optimize) {
        // dispatch({ type: 'setOptimize', payload: true });
        dispatchRedux(setOptimize(true))

        clearInterval(googleOptimizeVar);
      }
    } else {
      clearInterval(googleOptimizeVar);
    }
  }

  function brazeTimer() {
    if (window && window.appboy) {
      // dispatch({ type: 'setbraze', payload: true });
      dispatchRedux(setbraze(true))
      clearInterval(brazeOptimizerVar);
    }
  }

  function myZen() {
    if (appConfig.ZENDESK_KEY) {
      if (
        document.querySelectorAll(`[title="Messaging window"]`) &&
        document.querySelectorAll(`[title="Messaging window"]`).length &&
        document.querySelectorAll(`[title="Messaging window"]`)[0]
      ) {
        document.querySelectorAll(`[title="Messaging window"]`)[0].parentElement.classList.add('cart-push-up');
        clearInterval(myZenVar);
      }
    } else {
      clearInterval(myZenVar);
    }
  }

  function liveChatOptimizer() {
    if (appConfig.ZENDESK_KEY) {
      clearInterval(liveChatOptimizerVar);
    } else if (window.LC_API) {
      window.LC_API.on_chat_window_opened = function () {
        document.body.classList.add('chat-opened');
        document.body.classList.remove('chat-minimized');
      };
      window.LC_API.on_chat_window_minimized = function () {
        document.body.classList.remove('chat-opened');
        document.body.classList.add('chat-minimized');
      };
      clearInterval(liveChatOptimizerVar);
    } else if (window.LC_API) {
      clearInterval(liveChatOptimizerVar);
    }
  }

  function myTimer() {
    if (!googleOptimizeVar) {
      googleOptimizeVar = setInterval(googleOptimizeTimer, 7000);
    }

    if (
      window.appboy &&
      window.appboy.isPushSupported() &&
      !window.appboy.isPushPermissionGranted() &&
      !window.appboy.isPushBlocked()
    ) {
      window.appboy.logCustomEvent('prime-for-push');
      clearInterval(myVar);
      liveChatVar = setInterval(livechatTimer, 5000);
    } else if (window.appboy && window.appboy.isPushPermissionGranted()) {
      clearInterval(myVar);
      liveChatVar = setInterval(livechatTimer, 5000);
    }
  }

  // const {
  //   state: {
  //     user: { isLoggedIn },
  //     cart: {
  //       customerData: { email }
  //     }
  //   }
  // } = useContext(appContext);
  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const { email } = useSelector(state => state.cart?.customerData) || {}

  function initSegment({ domainName = '' }) {
    if (!window.analytics) {
      setTimeout(async () => {
        const SEGMENT_KEY = envAppConfig?.[domainName]?.SEGMENT_KEY || appConfig.SEGMENT_KEY;

        await loadScript({
          innerHTML: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.13.1";
          analytics.load("${SEGMENT_KEY}");}}();`
        });

        myZen();

        liveChatOptimizer();

        myZenVar = setInterval(myZen, 3000);
        liveChatOptimizerVar = setInterval(liveChatOptimizer, 4000);
        brazeOptimizerVar = setInterval(brazeTimer, 1000);

        if (window?.analyticsQueue?.length) {
          setTimeout(() => {
            window.analyticsQueue.forEach(({ func, param1, param2 }) => {
              window.analytics[func](param1, param2);
            });

            window.analyticsQueue = [];
          }, 1);
        }
      }, 5000);
    }
  }

  async function identifyEvent(userID, payload, integrations = {}) {
    const email = storageService.getTempEmail() || '';
    analytics.identify({
      id: userID,
      email
    })
    // if (window?.analytics?.identify && typeof window.analytics.identify === 'function') {
    //   window.analytics.identify(userID, payload, { ...integrations });
    // } else {
    //   if (!window?.analyticsQueue) {
    //     window.analyticsQueue = [];
    //   }

    //   window.analyticsQueue.push({ func: 'identify', param1: userID, param2: payload });
    // }
  }

  function resetLayer() {
    if (window.dataLayer) {
      window.dataLayer.push(function () {
        this.reset();
      });
    }
  }

  async function trackEvent(
    event,
    payload = {},
    disable
    // integration = {
    //   integrations: {
    //     Klaviyo: true
    //   }
    // }
  ) {
    const otherEmail = isCheckoutOnly ? storageService.getTempEmail() : null;

    resetLayer();

    const updatedPayload = { ...payload, eventStatus: !disable };

    if (isLoggedIn && (otherEmail || email)) {
      updatedPayload.email = otherEmail || email;
    }
    analytics?.track(event, updatedPayload)

    // if (window?.analytics?.track && typeof window.analytics.track === 'function') {
    //   window.analytics.track(event, updatedPayload, { ...integration });
    // } else {
    //   if (!window?.analyticsQueue) {
    //     window.analyticsQueue = [];
    //   }

    //   window.analyticsQueue.push({ func: 'track', param1: event, param2: updatedPayload });
    // }
  }

  async function trackPage(event, payloadVal = {}) {
    let payload = { ...payloadVal };
    if (isLoggedIn) {
      payload = { ...payload, user_login_status: 'true', user_id: userInfo.user_id };
    } else {
      payload = { ...payload, user_login_status: 'false', user_id: userInfo.user_id };
    }
    payload = {
      ...payload,
      referrer_gd: document.referrer
    };
    analytics.page(event, payload)
    // if (window.analytics && window.analytics.page && typeof window.analytics.page === 'function') {
    //   window.analytics.page(event, payload);
    // } else {
    //   if (!window.analyticsQueue) {
    //     window.analyticsQueue = [];
    //   }
    //   window.analyticsQueue.push({ func: 'page', param1: event, param2: payload });
    // }
  }

  return {
    initSegment,
    identifyEvent,
    trackEvent,
    trackPage
  };
};

export default useSegment;

export const useSegmentNew = () => {

  async function trackEvent(
    {
      event,
      payload,
      integration,
      isLoggedIn,
      email
    }
  ) {
    const otherEmail = isCheckoutOnly ? storageService.getTempEmail() : null;

    function resetLayer() {
      if (window.dataLayer) {
        window.dataLayer.push(function () {
          this.reset();
        });
      }
    }
    resetLayer();

    const updatedPayload = { ...payload };

    if (isLoggedIn && (otherEmail || email)) {
      updatedPayload.email = otherEmail || email;
    }

    if (window?.analytics?.track && typeof window.analytics.track === 'function') {
      window.analytics.track(event, updatedPayload, { ...integration });
    } else {
      if (!window?.analyticsQueue) {
        window.analyticsQueue = [];
      }

      window.analyticsQueue.push({ func: 'track', param1: event, param2: updatedPayload });
    }
  }
  return {
    trackEvent
  }

}
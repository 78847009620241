import React, { useEffect, useState, useContext } from 'react';
import { getTimerValues } from '../helpers/timeHelpers';
import storageService from '../services/storageService';
import appContext from '../Context/appContext';
import { useDeliveryDetails } from '../hooks/app';
import { useDispatch, useSelector } from 'react-redux';
import { setLastAppliedCouponData } from '../redux/slices/userSlice';

const CountDown = ({ countDownEndTime = null, setHideTimerValues, happyHoursCode, happyHoursActiveBenefits }) => {
  // const {
  //   // state: {
  //   //   user: { couponData }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const couponData = useSelector(state => state.user.couponData)
  const dispatchRedux = useDispatch()

  const { mutate } = useDeliveryDetails();

  const countDownTimer = countDownEndTime;
  const currentDate = new Date();

  const [countDown, setCountDown] = useState(countDownTimer - new Date(currentDate).getTime());

  const [showBenefits, setShowBenefits] = useState(false);

  const {
    is_coupon_discount_applicable: isCouponDiscountApplicable,
    is_free_delivery_applicable: isFreeDeliveryApplicable,
    is_tax_discount_applicable: isTaxDiscountApplicable
  } = happyHoursActiveBenefits || {};

  const removeAppliedCoupon = async () => {
    storageService.setHappyHoursCouponCode(false);
    if (storageService.getLastAppliedCoupon()?.customer_code === happyHoursCode) {
      storageService.clearLastAppliedCoupon();
      // dispatch({ type: 'setLastAppliedCouponData', payload: { lastAppliedCouponData: {} } });
      dispatchRedux(setLastAppliedCouponData({ lastAppliedCouponData: {} }))
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownTimer - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownTimer]);

  useEffect(() => {
    if (!showBenefits) return;

    const timeOutId = setTimeout(() => {
      setShowBenefits(false);
    }, 2200);
    return () => clearTimeout(timeOutId);
  }, [showBenefits]);

  const [hours, minutes, seconds] = getTimerValues(countDown);

  if ((hours + minutes + seconds < 0 || countDown < 0) && setHideTimerValues) {
    mutate();
    if (couponData.discount_code === happyHoursCode) {
      removeAppliedCoupon();
    }

    if (typeof window === 'object') {
      const ele = document?.querySelector('.ab-iam-root');
      if (ele) {
        ele?.classList?.replace('ab-show', 'd-none');
        document.querySelector('body').classList.remove('ab-pause-scrolling');
      }
    }
    storageService.setHappyHoursBanner(false);
    setHideTimerValues(true);
  }
  function onHovershowBenefits() {
    setShowBenefits(true);
  }

  return (
    <>
      {hours + minutes + seconds <= 0 ? null : (
        <div
          className={`timer-content hh-tooltip ${showBenefits ? 'benefits-open' : ''}`}
          onMouseEnter={onHovershowBenefits}
          onClick={onHovershowBenefits}
          role="button"
        >
          <div className="d-flex align-items-center timer-head-wrapper">
            <div className="timer-head mb-0 d-inline-block">Happy hour ends in</div>
            <h4 className=" timer mb-0 normal-font tab-num-font-variant">
              <b>{hours}</b>
              h:
              <b>{minutes}</b>
              m:
              <b>{seconds}</b>
              s
            </h4>
            <button type="button" className="cc-info">
              <i className="icon-info text-primary" />
            </button>
          </div>
          {showBenefits && (
            <div className="hht-sec">
              <div className="ship-disc-text ">
                {isFreeDeliveryApplicable && (
                  <div className="ship-disc-text-item">
                    <i className="icon-truck2 text-white fs-16" />
                    <span className="text-white pl-1">Free Delivery</span>
                  </div>
                )}
                {isTaxDiscountApplicable && (
                  <div className="ship-disc-text-item">
                    <i className="icon-discount-2 text-white fs-16" />
                    <span className="text-white pl-1">Tax Discount</span>
                  </div>
                )}
                {isCouponDiscountApplicable && (
                  <div className="ship-disc-text-item">
                    <i className="icon-discount-2 text-white fs-16" />
                    <span className="text-white pl-1">Coupon Discount</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default CountDown;

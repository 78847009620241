import React, { Component } from 'react';

class Orientation extends Component {
  state = {
    orientation: null
  };

  componentDidMount() {
    window.onorientationchange = this.handleOrientationChange;
    this.handleOrientationChange();
  }

  handleOrientationChange = () => {
    if (Math.abs(window.orientation) === 90) {
      this.setState({
        orientation: 'landscape'
      });
    } else {
      this.setState({
        orientation: 'portrait'
      });
    }
  };

  render() {
    const { orientation } = this.state;
    return (
      <div className={`orientation ${orientation === 'landscape' ? 'show-message' : ''}`}>
        <div className="inner text-center">
          <span className="icon-rotate-phone d-block two-rem-mt one-rem-mb" />
          <h3> Please rotate your device </h3>
          <p>We dont support landscape mode.Please go back to portrait mode for the best experience</p>
        </div>
      </div>
    );
  }
}

export default Orientation;

import React, { useContext } from 'react';
import Router from 'next/router';
import { verificationCooldownTime } from '../constants/verificationConst';
import isNumber from '../helpers/isNumber';
import otpValidationWithError from '../helpers/otpValidationWithError';
import emailValidationWithError from '../helpers/emailValidationWithError';
import notificationTypes from '../constants/modalNotificationConst';

import appContext from '../Context/appContext';

import useAPI from '../NetworkCall/API';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from '../redux/slices/cartSlice';

const getInitialState = defaultEmail => ({
  // Form state
  email: defaultEmail || '',
  emailError: null,
  otp: '',
  otpError: null,
  // UI state
  confirmingOTP: false,
  requestingOTP: false,
  displayOtpField: false,
  remainingTime: 0,
  canEnterOTP: false
});

const defaultOptions = {
  redirectTo: true,
  defaultPhone: '',
  customTrigger: false
};

function emailVerificationReducer(state, updateState) {
  // Put vulnerable checking of coming state
  return { ...state, ...updateState };
}

const useEmailVerification = email => {
  const timer = React.useRef(null);
  const emailVerificationOptions = React.useRef(email);

  const [state, dispatch] = React.useReducer(emailVerificationReducer, getInitialState(emailVerificationOptions.current));
  const dispatchRedux = useDispatch()
  const [remainingTime, setRemainingTime] = React.useState(0);
  // const {
  //   dispatch: appDispatch,
  //   // state: {
  //   //   cart: { cartData: { email: verifiedEmail } = {} }
  //   // }
  // } = useContext(appContext);
  const { email: verifiedEmail } = useSelector(state => state.cart?.cartData) || {}
  const { requestEmailOTP: callRequestEmailOTP, confirmEmailOTP } = useAPI();

  const freezeForm = () => {
    dispatch({ otpError: null, phoneError: null, confirmingOTP: false });
    if (timer.current) {
      clearInterval(timer.current);
    }
  };
  const confirmOTP = async inputOTP => {
    // Process OTP
    if (inputOTP && inputOTP.length === 4) {
      dispatch({ confirmingOTP: true });
      try {
        await confirmEmailOTP(state.email, inputOTP);
        freezeForm();
      } catch (error) {
        dispatch({ otpError: error.message, confirmingOTP: false });
      }
    }
  };

  const setEmail = event => {
    dispatch({ email: event.target.value, emailError: null });
    // appDispatch({ type: 'setCustomerInfo', payload: { email: event.target.value, emailError: null } });
    dispatchRedux(setCustomerInfo({ email: event.target.value, emailError: null }))
  };

  const setOTP = event => {
    if (isNumber(event.target.value)) {
      dispatch({ otp: event.target.value });
      confirmOTP(event.target.value);
    }
  };

  const startTimer = () => {
    setRemainingTime(verificationCooldownTime);
    timer.current = setInterval(() => {
      setRemainingTime(prevRemainingTime => {
        if (prevRemainingTime === 0) {
          clearInterval(timer.current);
        }
        return prevRemainingTime - 1;
      });
    }, 1000);
  };

  // Clear timer on unmount
  React.useEffect(
    () => () => {
      freezeForm();
    },
    []
  );

  React.useEffect(() => {
    if (verifiedEmail) {
      dispatch({ email: verifiedEmail });
    }
  }, [verifiedEmail]);

  const requestEmailOTP = async () => {
    // Validate email
    const emailError = emailValidationWithError(state.email);
    if (emailError) {
      // Set email error
      dispatch({ emailError });
    } else {
      // Request for OTP and display loader
      dispatch({ requestingOTP: true });
      try {
        await callRequestEmailOTP(state.email);
        startTimer();
        dispatch({ displayOtpField: true, emailError: null });
      } catch (error) {
        dispatch({ emailError: error.message });
      } finally {
        dispatch({ requestingOTP: false });
      }
    }
  };

  return {
    formData: state,
    setEmail,
    setOTP,
    requestEmailOTP,
    remainingTime
  };
};

export default useEmailVerification;

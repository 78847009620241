import React, { useState, useEffect, useContext } from 'react';
import { Carousel } from 'react-responsive-carousel';
import CSSTransition from 'react-transition-group/CSSTransition';
import ReactImageMagnify from 'react-image-magnify';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import getTransformedImageURLs from '../helpers/getTransformedImageURLs';
import appConfig from '../appConfig';
import ThreeSixtyImage from './ThreeSixtyImage';
import Modal from './Modal';
import notificationTypes from '../constants/modalNotificationConst';
import appContext from '../Context/appContext';
import LoadImage from './LoadImage';
import { isNewHomePageApplicable } from '../constants/feature';
import { useDispatch } from 'react-redux';
import { pushNotificationOverlap } from '../redux/slices/modalSlice';

export const rcSettings = {
  showArrows: true,
  autoPlay: false,
  showIndicators: false,
  showThumbs: false,
  infiniteLoop: false,
  interval: 4000,
  transitionTime: 800,
  showStatus: false,
  useKeyboardArrows: false,
  swipeable: true,
  emulateTouch: false
};

function ImageSection({ productDetails, selectedIndex, openInPopup }) {
  const dimensionRegex = /\d+x\d+/gm;
  const [displayLargeImage, setDisplayLargeImage] = useState(false);
  const [blurImage, setBlurImage] = React.useState('');
  const productSoldOut = productDetails.is_sold_out;
  const handleImageLoad = () => {
    setDisplayLargeImage(true);
  };
  React.useEffect(() => {
    setDisplayLargeImage(false);
    setBlurImage(
      getTransformedImageURLs(
        productDetails && productDetails.images && productDetails.images[selectedIndex],
        'png'
      ).srcLarge.replace(dimensionRegex, '40x40')
    );
  }, [selectedIndex]);
  return (
    <div className="img-container relative">
      {isNewHomePageApplicable && productDetails.is_sold_out ?
        <div className="product-tag-label d-flex align-items-center fs-14">
          Out of Stock
        </div>
        : null}
      {productDetails.fresh_drop ? <div className="fresh-drop"><span>NEW</span></div> : null}
      <ReactImageMagnify
        className="image-magnify-container"
        imageClassName={`magnifier-small-image ${productSoldOut && "opacity-5"} ${!displayLargeImage ? 'blurry-image' : 'full-image'}`}
        {...{
          smallImage: {
            alt:
              productDetails && productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
                ? productDetails.image_alt_tag
                : productDetails && (productDetails.product_name || productDetails.bundle_name),
            // isFluidWidth: true,
            width: openInPopup ? 280 : 400,
            height: openInPopup ? 280 : 400,
            src:
              selectedIndex === null
                ? appConfig.PRODUCT_PLACEHOLDER_IMG
                : !displayLargeImage
                  ? blurImage
                  : getTransformedImageURLs(productDetails && productDetails.images && productDetails.images[selectedIndex], 'png')
                    .srcLarge
          },
          largeImage: {
            alt:
              productDetails && productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
                ? productDetails.image_alt_tag
                : productDetails && (productDetails.product_name || productDetails.bundle_name),
            src:
              selectedIndex === null
                ? appConfig.PRODUCT_PLACEHOLDER_IMG
                : getTransformedImageURLs(productDetails && productDetails.images && productDetails.images[selectedIndex], 'jpeg')
                  .original,
            width: 1280,
            height: 1280
          },
          // enlargedImagePosition: openInPopup ? 'over' : 'beside',
          enlargedImageContainerStyle: { zIndex: 1, backgroundColor: 'white' },
          enlargedImageContainerClassName: `enlarged-image ${!displayLargeImage && 'd-none'}`,
          enlargedImageContainerDimensions: { width: openInPopup ? 380 : 640, height: openInPopup ? 380 : 640 }
        }}
      />
      <img
        onLoad={handleImageLoad}
        key={Date.now()}
        className="d-none"
        alt={
          productDetails && productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
            ? productDetails.image_alt_tag
            : productDetails && (productDetails.product_name || productDetails.bundle_name)
        }
        src={
          getTransformedImageURLs(productDetails && productDetails.images && productDetails.images[selectedIndex], 'jpeg')
            .original
        }
      />
    </div>
  );
}

function VideoSection({ productDetails, selectedIndex }) {
  const productSoldOut = productDetails.is_sold_out;
  return (
    <div id="videoParentDesktop" className={`video-container relative ${productSoldOut && "opacity-5"}`}>
      <iframe
        width="100%"
        height="100%"
        // src="https://www.youtube.com/embed/zQ72ypQQIRM"
        src={`${productDetails.videos[selectedIndex]}?rel=0`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

const ImageGridCarousel = ({ productDetails, openInPopup }) => {
  const [selectedType, setSelectedType] = useState('image');
  const [zoomEnabled, setZoomEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [originalIndex, setOriginalIndex] = useState(0);
  const [popUpIsOpen, setPopUpIsOpen] = useState(false);
  const [threeSixtyPopUpIsOpen, setThreeSixtyPopUpIsOpen] = useState(false);

  const productSoldOut = productDetails.is_sold_out;

  function pauseYoutubePlayer() {
    const parentDivDesktop = document.getElementById('videoParentDesktop');

    // otherwise set scope as the entire document
    // var myScope = document;

    // if there is an iframe inside maybe embedded multimedia video/audio, we should reload so it stops playing
    if (parentDivDesktop) {
      const iframesDesktop = parentDivDesktop.getElementsByTagName('iframe');
      if (iframesDesktop != null) {
        for (let i = 0; i < iframesDesktop.length; i++) {
          iframesDesktop[i].src = iframesDesktop[i].src; // causes a reload so it stops playing, music, video, etc.
        }
      }
    }

    productDetails.videos.forEach((src, index) => {
      const parentDivMobile = document.getElementById(`videoParentMobile${index}`);
      if (parentDivMobile) {
        const iframesMobile = parentDivMobile.getElementsByTagName('iframe');
        if (iframesMobile != null) {
          for (let i = 0; i < iframesMobile.length; i++) {
            iframesMobile[i].src = iframesMobile[i].src; // causes a reload so it stops playing, music, video, etc.
          }
        }
      }
    });
  }

  useEffect(() => {
    function visibilitychange() {
      if (document.hidden) {
        pauseYoutubePlayer();
      }
    }

    document.addEventListener('visibilitychange', visibilitychange);

    return () => {
      document.removeEventListener('visibilitychange', visibilitychange);
    };
  }, []);

  function handleImageClick(index) {
    if (index !== selectedIndex || selectedType !== 'image') {
      setSelectedType('image');
      setSelectedIndex(null);
      setOriginalIndex(index);
    }
  }

  useEffect(() => {
    if (selectedIndex == null) {
      setSelectedIndex(originalIndex);
    }
    if (selectedType !== 'image') {
      setOriginalIndex(0);
    }
  }, [originalIndex, selectedType]);

  function handleVideoClick(index) {
    setSelectedType('video');
    setSelectedIndex(index);
  }

  function handle360Click() {
    setSelectedType('threeSixty');
    setSelectedIndex(0);
  }

  function handleMobileImageClick(index) {
    if (!popUpIsOpen) {
      setSelectedIndex(index);
      setPopUpIsOpen(true);
      document.body.classList.add('zoom-modal-open');
    }
  }

  function removeBodyClass() {
    document.body.classList.remove('zoom-modal-open');
  }

  function handleMobileImageDoubleClick(index) {
    setSelectedIndex(index);
    setZoomEnabled(true);
  }

  function handleZoomImageDoubleClick() {
    setZoomEnabled(false);
  }
  function open360Popup() {
    if (!threeSixtyPopUpIsOpen) {
      setThreeSixtyPopUpIsOpen(true);
      document.body.classList.add('zoom-modal-open');
    }
  }
  // For Mobile Only
  function renderSliderElementsMobile() {
    const imgArr =
      productDetails &&
      productDetails.images &&
      productDetails.images.map((src, index) => (

        <div key={src + index} className={`iv-img-container iv-item ${productSoldOut && "opacity-5"}`} onDoubleClick={() => handleMobileImageClick(index)}>
          <LoadImage
            src={getTransformedImageURLs(src, 'png').srcLarge}
            height={60}
            width={60}
            alt={
              productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
                ? productDetails.image_alt_tag
                : productDetails.product_name || productDetails.bundle_name
            }
          />

          <div className="double-tap">Double tap to zoom</div>
        </div>
      ));

    const videoArr =
      productDetails &&
      productDetails.videos &&
      productDetails.videos.map((src, index) => {
        return (
          <>
            <VideoImage src={src} index={index} />

            {/* <iframe
              width="100%"
              height="100%"
              // src="https://www.youtube.com/embed/zQ72ypQQIRM"
              src={`${productDetails.videos[index]}?rel=0`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            /> */}
          </>
        );
      });

    const threeSixtyArr = [];
    if (productDetails && productDetails.images_360 && productDetails.images_360.length) {
      threeSixtyArr.push(
        <div className="iv-img-container iv-item three-60-mobile-view" style={{ touchAction: 'none' }}>
          <ThreeSixtyImage productDetails={productDetails} />
          <i className="icon-three60" />
        </div>
      );
    }

    return imgArr && imgArr.concat(videoArr).concat(threeSixtyArr);
  }
  // For Mobile Only but to render inside popup
  function renderContentForPopup() {
    const imgArr =
      productDetails &&
      productDetails.images &&
      productDetails.images.map((src, index) => (
        <div className="iv-img-container iv-item inside-popup" onDoubleClick={() => handleMobileImageDoubleClick(index)}>
          <picture>
            <source srcSet={getTransformedImageURLs(src, 'webp').srcLarge} type="image/webp" />
            <source srcSet={getTransformedImageURLs(src, 'png').srcLarge} type="image/png" />
            <source srcSet={getTransformedImageURLs(src, 'jpeg').srcLarge} type="image/jpeg" />
            <img
              src={getTransformedImageURLs(src, 'png').srcLarge}
              alt={
                productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
                  ? productDetails.image_alt_tag
                  : productDetails.product_name || productDetails.bundle_name
              }
              onError={e => {
                e.target.src = appConfig.PRODUCT_PLACEHOLDER_IMG;
              }}
            />
          </picture>
        </div>
      ));
    return imgArr;
  }

  function VideoImage({ src, index }) {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = src.match(regExp);
    let youtubeId;
    if (match && match[2]) {
      youtubeId = match[2];
    }
    const dispatchRedux = useDispatch()
    const handleVideoClick = () => {
      dispatchRedux(pushNotificationOverlap({ type: notificationTypes.youtubePopup, data: { src: youtubeId } }))
    };

    return (
      <div
        onClick={handleVideoClick}
        role="presentation"
        id={`videoParentMobile${index}`}
        className="video-container relative iv-item d-flex"
      >
        <img
          src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
          alt={
            productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
              ? productDetails.image_alt_tag
              : productDetails.product_name || productDetails.bundle_name
          }
          onError={e => {
            e.target.src = appConfig.PRODUCT_PLACEHOLDER_IMG;
          }}
        />
        <i className="icon-video-play" />
      </div>
    );
  }
  // Testing out image loading

  function renderThumbsForDesktop() {
    const imgArr =
      productDetails &&
      productDetails.images &&
      productDetails.images.map((src, index) => (
        isNewHomePageApplicable ?
          <button
            className={`mr-1 dot ${selectedIndex == index && selectedType == 'image' ? 'selected' : ''}`}
            onClick={() => handleImageClick(index)}></button>
          :
          <div
            onClick={() => handleImageClick(index)}
            key={`${src + index}d`}
            className={`c-thumb  ${selectedIndex == index && selectedType == 'image' ? 'selected' : ''}`}
          >
            <LoadImage
              src={getTransformedImageURLs(src, 'png').srcSmall}
              height={60}
              width={60}
              thumb
              alt={
                productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
                  ? productDetails.image_alt_tag
                  : productDetails.product_name || productDetails.bundle_name
              }
            />
          </div>
      ));

    const videoArr =
      productDetails &&
      productDetails.videos &&
      productDetails.videos.map((src, index) => {
        const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        const match = src.match(regExp);
        let youtubeId;
        if (match && match[2]) {
          youtubeId = match[2];
        }
        return (
          isNewHomePageApplicable ?
            <button
              className={`mr-1 dot ${selectedIndex == index && selectedType == 'video' ? 'selected' : ''}`}
              onClick={() => handleVideoClick(index)}></button>
            :
            <div
              onClick={() => handleVideoClick(index)}
              role="presentation"
              className={`${selectedIndex == index && selectedType == 'video' ? 'selected' : ''} video-thumbnail-holder c-thumb`}
            >
              <img
                src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                alt={
                  productDetails.image_alt_tag && productDetails.image_alt_tag !== ''
                    ? productDetails.image_alt_tag
                    : productDetails.product_name || productDetails.bundle_name
                }
                onError={e => {
                  e.target.src = appConfig.PRODUCT_PLACEHOLDER_IMG;
                }}
              />
              <i className="icon-video-play" />
            </div>
        );
      });

    const threeSixtyArr = [];
    if (productDetails && productDetails.images_360 && productDetails.images_360.length) {
      threeSixtyArr.push(
        isNewHomePageApplicable ?
          <button
            className={`mr-1 dot ${selectedIndex == 0 && selectedType == 'threeSixty' ? 'selected' : ''}`}
            onClick={handle360Click}></button>
          :
          <div
            onClick={handle360Click}
            role="presentation"
            className={`${selectedIndex == 0 && selectedType == 'threeSixty' ? 'selected' : ''} three-60-thumbnail-holder c-thumb`}
          >
            <i className="icon-three60" />
          </div>
      );
    }

    return imgArr.concat(videoArr).concat(threeSixtyArr);
  }

  function renderProductSectionDesktop() {
    switch (selectedType) {
      case 'video':
        return <VideoSection productDetails={productDetails} selectedIndex={selectedIndex} />;
      case 'threeSixty':
        return <ThreeSixtyImage productDetails={productDetails} />;

      default:
        return <ImageSection productDetails={productDetails} selectedIndex={selectedIndex} openInPopup={openInPopup} />;
    }
  }

  // let coverImage = productDetails && productDetails.is_disabled ? notavailable : SoldOutImage;

  const moreThanOne =
    ((productDetails && productDetails.images && productDetails.images.length) || 0) +
    ((productDetails && productDetails.images_360 && productDetails.images_360.length) || 0) +
    ((productDetails && productDetails.videos && productDetails.videos.length) || 0) >
    1;

  useEffect(() => {
    return () => {
      document.body.classList.remove('zoom-modal-open');
    };
  }, []);
  return (
    <>
      <div className="img-grid-carousel sticky-scroll">
        <div className={`d-lg-none ig-inner`}>
          {
            isNewHomePageApplicable && productDetails.is_sold_out ?
              <div className="product-tag-label d-flex align-items-center fs-12 position-absolute">
                Out of Stock
              </div> : null
          }
          {productDetails.fresh_drop ? <div className="fresh-drop"><span>NEW</span></div> : null}
          <Carousel onChange={pauseYoutubePlayer} {...rcSettings} showArrows showIndicators={moreThanOne} swipeable={moreThanOne}>
            {renderSliderElementsMobile()}
          </Carousel>
        </div>
        <div className="image-video-grid d-none d-lg-flex flex-column-reverse">
          {moreThanOne && <div className={isNewHomePageApplicable ? 'control-dots' : 'c-thumbs-parent'}>{renderThumbsForDesktop()}</div>}
          {renderProductSectionDesktop()}
        </div>
        <CSSTransition appear in={popUpIsOpen} mountOnEnter timeout={800} classNames="my-node" unmountOnExit>
          <Modal>
            <div className={`image-popup ${!zoomEnabled && 'extra-padding'}`}>
              <div
                className="inner"
                onDoubleClick={() => {
                  setPopUpIsOpen(false);
                  setZoomEnabled(false);
                  removeBodyClass();
                }}
              >
                {/* <div className="c-wrapper" style={{ opacity: zoomEnabled ? 0 : 1 }}>
                  <Carousel {...rcSettings} showIndicators={moreThanOne} swipeable={moreThanOne}>
                    {renderContentForPopup()}
                  </Carousel>
                  <div className="double-tap">Double tap to zoom</div>
                </div> */}
                <CSSTransition appear in={popUpIsOpen} mountOnEnter timeout={1000} classNames="my-node" unmountOnExit>
                  <TransformWrapper centerOnInit initialScale={1.01} doubleClick={{ disabled: true }}>
                    <TransformComponent>
                      <picture>
                        <source
                          srcSet={
                            getTransformedImageURLs(productDetails && productDetails.images[selectedIndex], 'webp')
                              .mobileZoomImage
                          }
                          type="image/webp"
                        />
                        <source
                          srcSet={
                            getTransformedImageURLs(productDetails && productDetails.images[selectedIndex], 'png').mobileZoomImage
                          }
                          type="image/png"
                        />
                        <source
                          srcSet={
                            getTransformedImageURLs(productDetails && productDetails.images[selectedIndex], 'jpeg')
                              .mobileZoomImage
                          }
                          type="image/jpeg"
                        />
                        <img
                          width="1000"
                          height="1000"
                          src={
                            getTransformedImageURLs(productDetails && productDetails.images[selectedIndex], 'png').mobileZoomImage
                          }
                        />
                      </picture>
                    </TransformComponent>
                  </TransformWrapper>
                </CSSTransition>

                <button
                  className="btn close-btn"
                  onClick={() => {
                    setPopUpIsOpen(false);
                    setZoomEnabled(false);
                    removeBodyClass();
                  }}
                >
                  <i className="icon-close" />
                </button>
              </div>
            </div>
          </Modal>
        </CSSTransition>
        {/* {threeSixtyPopUpIsOpen && (
          <Modal>
            <div className="image-popup  three-60-popup" style={{ touchAction: 'none' }}>
              <div className="inner">
                <ThreeSixtyImage productDetails={productDetails} />
                <button
                  className="btn close-btn"
                  onClick={() => {
                    setThreeSixtyPopUpIsOpen(false);
                    removeBodyClass();
                  }}
                >
                  <i className="icon-close" />
                </button>
              </div>
            </div>
          </Modal>
        )} */}
      </div>
    </>
  );
};

export default ImageGridCarousel;

export let fromURL = "https://grassdoor-public-data-pre-live.s3.us-west-2.amazonaws.com"
export let toURL = "https://prod-serverless.grassdoor.com"
export function createInitialStore() {
  const shopFilters = null;
  const brandsFilters = null;
  const categoryFilters = null;
  const favouriteFilters = null;
  const saveForLaterFilters = null;
  return { shopFilters, brandsFilters, categoryFilters, favouriteFilters, saveForLaterFilters };
}

export const actions = {};

actions.setFilters = (state, action) => {
  return { ...state, shop: { ...state.shop, ...action.payload } };
};

import React, { useContext } from 'react';

import appConfig from '../appConfig';
import { countryCodeOption } from '../constants/countryConst';
import { GRASSDOOR } from '../constants/website';
import useLogin from '../hooks/useLogin';
import VIA from '../constants/via';
import InlineLoader from './InlineLoader';
import alwaysTwoDigit from '../helpers/alwaysTwoDigit';
import appContext from '../Context/appContext';
import { useDispatch, useSelector } from 'react-redux';
import { setSmsCheckBox } from '../redux/slices/userSlice';

export default function LoginComponent(props) {
  const { redirectTo, closeDrawer, closeLoginPopup, confirmLogin } = props;
  // const {
  //   // state: { user: { smsCheckbox } = {} } = {}, 
  //   dispatch
  // } = useContext(appContext);
  const smsCheckbox = useSelector(state => state.user.smsCheckbox)
  const dispatchRedux = useDispatch()

  const {
    formData: {
      country,
      phone,
      phoneError,
      otp,
      otpError,
      smsMessage,
      apiError,
      confirmingOTP,
      generatingOTP,
      switchBtn,
      displayOtpField
    },
    remainingTime,
    setPhone,
    setOTP,
    setCountry,
    requestOTP
  } = useLogin({
    closeDrawer,
    redirectTo,
    closeLoginPopup,
    confirmLogin
  });

  const toggleSMSdisclaimer = () => {
    // dispatch({ type: 'setSmsCheckBox', payload: { smsCheckbox: !smsCheckbox } });
    dispatchRedux(setSmsCheckBox({ smsCheckbox: !smsCheckbox }))
  };

  const handleRequestOtp = otpMethod => () => {
    requestOTP(otpMethod);
  };

  return (
    <>
      <div className="form-group text-left">
        <label className="mini-label">Phone</label>

        <div className="input-box-icon">
          <select data-testid="#country_code" className="form-control country-code" value={country} onChange={setCountry}>
            {countryCodeOption.map(item => (
              <option key={item.code} value={item.code}>
                {`${item.name} +${item.code}`}
              </option>
            ))}
          </select>

          <input
            autoFocus
            data-testid="#phone_number"
            className="form-control phone-number br-0"
            type="tel"
            placeholder="Mobile"
            maxLength="10"
            value={phone}
            onChange={setPhone}
            autoComplete="one-time-code"
          />

          {generatingOTP ? <InlineLoader text="Generating code" /> : null}
        </div>

        {phoneError ? <div className="error-msg">{phoneError}</div> : null}
      </div>

      {displayOtpField ? (
        <div className="form-group text-left">
          <label className="mini-label">Verification Code</label>

          <div className="relative">
            <input
              value={otp}
              data-testid="#verification_code"
              className="form-control otp-input br-0"
              type="tel"
              placeholder="Enter Verification Code"
              maxLength="4"
              onChange={setOTP}
            />

            {confirmingOTP ? <InlineLoader text="Verifying code" /> : null}
          </div>

          {otpError ? <div className="error-msg">{otpError}</div> : null}
        </div>
      ) : null}

      {apiError ? <div className="error-msg">{apiError}</div> : null}

      <div className="otp-on-call text-center">
        {/* Primary OTP button */}
        <button
          className=" bold one-rem-mb btn btn-primary has-box-shadow rouded-button"
          onClick={switchBtn ? handleRequestOtp(VIA.Secondary.value) : handleRequestOtp(VIA.Primary.value)}
          disabled={generatingOTP || remainingTime > 0}
          type="button"
          data-testid="#get_verification"
          data-cy={switchBtn ? VIA.Secondary.dataCy : VIA.Primary.dataCy}
        >
          {remainingTime > 0 ? (
            `Resend Verification Code (00:${alwaysTwoDigit(remainingTime)})`
          ) : (
            <>
              {switchBtn ? (
                <span>
                  Resend Verification Code via
                  <span className="blink-me">{` ${VIA.Secondary.text}`}</span>
                </span>
              ) : (
                `Get Verification Code via ${VIA.Primary.text}`
              )}
            </>
          )}
        </button>

        <div className="line-behind-text">
          <span>Or</span>
        </div>

        {/* Secondary OTP button */}
        <button
          className="btn btn-sm btn-link half-rem-mt underline"
          onClick={switchBtn ? handleRequestOtp(VIA.Primary.value) : handleRequestOtp(VIA.Secondary.value)}
          disabled={generatingOTP || remainingTime > 0}
          type="button"
          data-cy={switchBtn ? VIA.Primary.dataCy : VIA.Secondary.dataCy}
        >
          {remainingTime > 0 ? (
            `Resend Verification Code (00:${alwaysTwoDigit(remainingTime)})`
          ) : (
            <>
              {switchBtn ? `Resend Verification Code via ${VIA.Primary.text}` : `Get Verification Code via ${VIA.Secondary.text}`}
            </>
          )}
        </button>
      </div>

      {smsMessage && (
        <div className="custom-checkbox one-rem-mt two-rem-mb">
          <input type="checkbox" id="smsDisclaimerCheckBox" checked={smsCheckbox} onClick={toggleSMSdisclaimer} />

          <label className="align-top m-0 text-left" htmlFor="smsDisclaimerCheckBox">
            {process.env.NEXT_PUBLIC_APP === GRASSDOOR ? (
              <span className="inner small-text ">
                By checking this box, you agree to receive marketing communications from Grassdoor via automated SMS technology.
                SMS consent is not required to purchase. Frequency of communications may vary. Message and data rates may apply.
              </span>
            ) : (
              <span className="inner small-text ">
                {`By checking this box, you agree to receive marketing communications from ${appConfig.APP_NAME} and ${appConfig.APP_NAME}'s delivery partner, Grassdoor (including Grassdoor's partner sites) via automated SMS technology. SMS consent is not required to purchase. Frequency of communications may vary. Message and data rates may apply.`}
              </span>
            )}
          </label>
        </div>
      )}
    </>
  );
}

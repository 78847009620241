export default function loadScript({ url, innerHTML }) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    if (url) {
      script.src = url;
      script.type = 'text/javascript';
    }
    if (innerHTML) {
      script.innerHTML = innerHTML;
      resolve();
    }
    script.defer = true;
    document.getElementsByTagName('head')[0].appendChild(script);
    script.onerror = reject;
    script.onload = resolve;
  });
}

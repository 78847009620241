import React, { Component } from 'react';

class FilterDrawer extends Component {
  handleBackdropClick = () => {
    const { toggleFilterDrawer } = this.props;
    toggleFilterDrawer();
  };

  render() {
    const { children, filterDrawerOpen, toggleFilterDrawer } = this.props;
    // const {
    //   shopStore: { filterDrawerOpen, transitionClassName }
    // } = this.context;

    // const { showContent } = this.state;
    return (
      <div className={` filter-drawer d-lg-none ${filterDrawerOpen ? 'open' : ''} transition`}>
        <div className="backdrop" onClick={this.handleBackdropClick} />
        <div className="content">{children}</div>
      </div>
    );
  }
}

export default FilterDrawer;

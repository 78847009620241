const SocketEmitConst = {
  // Common Socket emits
  ERROR_RESPONSE: 'errorResponse',
  ERROR: 'error',
  AUTH_ERROR: 'autherror',
  CONNECT_ERROR: 'connect_error',
  CONNECT_TIMEOUT: 'connect_timeout',
  DISCONNECT: 'disconnect',
  RECONNECT: 'reconnect',
  CONNECT: 'connect',
  UPDATE_ZONE_MENU: 'updateZoneMenu',

  // Server Emits
  ORDER_ACCEPTED: 'orderaccepted',
  ORDER_CANCELLED: 'orderCancelled',
  ORDER_ON_HOLD: 'refreshCustomerPage',

  // App Emits
  JOIN_ROOM: 'joinRoom',
  SEARCH_DRIVER: 'searchdriver',
  GET_DRIVER_LOCATION: 'getdriverlocation',
  ORDER_COMPLETED: 'orderCompleted',
  ORDER_FAILED: 'orderFailed',
  CANCEL_ORDER: 'cancelOrder',
  CUSTOMER_STATUS_CHECK: 'customerStatusCheck',
  PLACE_ORDER: 'placeOrder',
  PLACE_RE_ORDER: 'placeReOrder',
  MAKE_PAYMENT_USING_CARD: 'makePaymentUsingCard',
  MENU_PRODUCT_LIST: 'menuProductList',
  CUSTOMER_SIGNATURE: 'customerSignature',
  REFRESH_CUSTOMER_PAGE: 'refreshCustomerPage',
  ENTER_PAC: 'enterPac',
  PURCHASE_GIFTCARD: 'purchaseGiftCard',
  GET_ORDER_ETA: 'getOrderETA'
};

export default SocketEmitConst;

Number.prototype.zeroPad = function zeropad(length = 2) {
  // defaults to 2 if no parameter is passed
  // return (new Array(length).join('0') + this).slice(length * -1);
  return (new Array(length).join('0') + this).slice(length * -1);
};

export function getFullDate(date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).zeroPad()}-${date.getDate().zeroPad()} ${date.getHours().zeroPad()}:${date
    .getMinutes()
    .zeroPad()}:${date.getSeconds().zeroPad()}`;
}

export function to12HourFormat(time) {
  if (!time) {
    return '';
  }
  const hour = Number(time.split(':')[0]);
  return (hour <= 12 ? hour || 12 : hour - 12) + (hour < 12 ? 'AM' : 'PM');
}

export function to12HourMinuteFormat(time) {
  if (!time) {
    return '';
  }
  const hour = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);
  return `${(hour <= 12 ? hour || 12 : hour - 12).zeroPad()}:${minutes.zeroPad()}${hour < 12 ? ' AM' : ' PM'}`;
}

export function getFullDateInWords(date) {
  // 10 NOV 2020, 01:57 PM
  const day = date.getDate();
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  const time = to12HourMinuteFormat(date.toString().split(' ')[4]);
  return `${day} ${month} ${year}, ${time}`;
}

export function getDateString(date) {
  // 10 NOV 2020, 01:57 PM
  const day = date.getDate();
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'short' });
  return `${month} ${day}, ${year}`;
}

export function toMonthAndDay(date) {
  const components = date.split('-');
  return `${components[1]}/${components[2]}`;
}

export function getCurrentTime() {
  const time = new Date();
  return `${time.getHours().zeroPad()}:${time.getMinutes().zeroPad()}:${time.getSeconds().zeroPad()}`;
}

export function utcToLocal(timeString) {
  const localDate = new Date(timeString);
  const hour = localDate.getHours();
  return `${(localDate.getMonth() + 1).zeroPad()}/${localDate.getDate().zeroPad()}/${localDate.getFullYear()} ${(hour <= 12
    ? hour || 12
    : hour - 12
  ).zeroPad()}:${localDate.getMinutes().zeroPad()}${hour < 12 ? ' am' : ' pm'}`;
}

export function formatToMMDDYY(timeString) {
  const year = timeString.split('-')[0];
  const month = timeString.split('-')[1];
  const day = timeString.split('-')[2];
  return `${month}/${day}/${year}`;
}

export function formatToYYMMDD(timeString) {
  const month = timeString.split('/')[0];
  const day = timeString.split('/')[1];
  const year = timeString.split('/')[2];
  return `${year}-${month}-${day}`;
}

export function formatToMMMDDYYYY(timeString) {
  const year = timeString.split('-')[0];
  const day = timeString.split('-')[2];
  const month = new Date(timeString).toLocaleString('default', { month: 'short' });
  return `${month} ${day}, ${year}`;
}
export function formatToMMMDDYYYYNew(timeString) {
  const year = timeString.split('-')[0];
  const day = timeString.split('-')[2].slice(0, 2);
  const month = new Date(timeString).toLocaleString('default', { month: 'short' });
  return `${month} ${day}, ${year}`;
}

export function formatToDDMMYYYY(timeString) {
  const date = timeString.split(' ')[0];
  const day = date.split('/')[1];
  const year = date.split('/')[2];
  const month = new Date(date).toLocaleString('default', { month: 'short' });
  return `${day} ${month} ${year}`;
}

export function currentDateIsWithin(firstDate, lastDate) {
  if (!firstDate || !lastDate) {
    return false;
  }
  const date = new Date();

  const firstHours = firstDate.split(':')[0];
  const firstMinutes = firstDate.split(':')[1];
  const firstSeconds = firstDate.split(':')[2];

  const firstDay = new Date();
  firstDay.setHours(firstHours);
  firstDay.setMinutes(firstMinutes);
  firstDay.setSeconds(firstSeconds);

  const lastHours = lastDate.split(':')[0];
  const lastMinutes = lastDate.split(':')[1];
  const lastSeconds = lastDate.split(':')[2];

  const lastDay = new Date();
  lastDay.setHours(lastHours);
  lastDay.setMinutes(lastMinutes);
  lastDay.setSeconds(lastSeconds);

  if (firstDay - date < 0 && lastDay - date > 0) {
    return true;
  }
  return false;
}

export function utcToHMMA(utcTime) {
  const date = new Date(`${utcTime.replace(' ', 'T')}.000Z`);
  const hour = date.getHours();
  const hoursString = hour <= 12 ? hour || 12 : hour - 12;
  const minutes = date.getMinutes();

  return `${hoursString}:${minutes.zeroPad()}${hour < 12 ? ' AM' : ' PM'}`;
}

export function getScheduleDate(tomorrow, time) {
  const date = new Date();
  if (tomorrow) {
    date.setDate(date.getDate() + 1);
  }
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  return `${[year, month, day].join('-')} ${time}`;
}

export function modifyDate(date, years = 0, months = 0, days = 0) {
  date.setDate(date.getDate() + days);
  date.setMonth(date.getMonth() + months);
  date.setFullYear(date.getFullYear() + years);
  return date;
}

export function dateToDMY(date) {
  const d = date.getDate();
  const m = date.getMonth() + 1; // Month from 0 to 11
  const y = date.getFullYear();
  return `${d <= 9 ? `0${d}` : d}-${m <= 9 ? `0${m}` : m}-${y}`;
}

export function convertMinsToHrsMins(data) {
  if (!data) return '';
  const hours = Math.trunc(data / 60);
  const minutes = data % 60;
  let displayHrs = '';
  let displayMins = '';

  if (hours > 0) {
    displayHrs = `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }
  if (minutes > 0) {
    displayMins = ` ${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  }
  return `${displayHrs}${displayMins}`;
}

export const getWeekDayAndDate = date => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const d = new Date(date.replace(/\s/g, 'T'));
  return `${days[d?.getDay()]} ${d.getDate()} `;
};

export const checkIfNightTime = date => {
  const getHours = new Date(date.replace(/\s/g, 'T')).getHours();
  return getHours >= 18 || getHours <= 6;
};

export const splitIntoDateTimeComponents = (value, isLocalTime) => {
  if (value) {
    const dateObj = new Date(value);
    const time24 = dateObj.toLocaleTimeString('en-US', {
      timeZone: !isLocalTime ? 'America/Los_Angeles' : undefined,
      hourCycle: 'h23'
    });

    return {
      date: dateObj.getDate(),
      month: {
        index: dateObj.toLocaleString('en-US', { timeZone: !isLocalTime ? 'America/Los_Angeles' : undefined, month: 'numeric' }),
        long: dateObj.toLocaleString('en-US', { timeZone: !isLocalTime ? 'America/Los_Angeles' : undefined, month: 'long' }),
        short: dateObj.toLocaleString('en-US', { timeZone: !isLocalTime ? 'America/Los_Angeles' : undefined, month: 'short' })
      },
      year: dateObj.getFullYear(),
      day: {
        index: dateObj.toLocaleString('en-US', { timeZone: !isLocalTime ? 'America/Los_Angeles' : undefined, day: 'numeric' }),
        long: dateObj.toLocaleString('en-US', { timeZone: !isLocalTime ? 'America/Los_Angeles' : undefined, weekday: 'long' }),
        short: dateObj.toLocaleString('en-US', { timeZone: !isLocalTime ? 'America/Los_Angeles' : undefined, weekday: 'short' })
      },
      time24,
      time12: to12HourMinuteFormat(time24)
    };
  }

  return null;
};

export const getTimerValues = (countDown) => {
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { CSSTransition } from 'react-transition-group';
import LoadImage from '../LoadImage';
import Price from '../Price';
import InlineLoader from '../InlineLoader';
import getTransformedImageURLs from '../../helpers/getTransformedImageURLs';
import toFixedIfNeeded from '../../helpers/toFixedIfNeeded';
import { ASAP, Scheduled } from '../../constants/scheduleWindow';
import { SCHEDULE } from '../../constants/deliveryType';

import { useModifyCart } from '../../hooks/cart';
import { useBrand, useASAPShop, useScheduleShop } from '../../hooks/Shop';
import appContext from '../../Context/appContext';
import { useDeliveryDetails } from '../../hooks/app';
import notificationTypes from '../../constants/modalNotificationConst';
import useSegment from '../../hooks/segment';
import { isNewHomePageApplicable } from '../../constants/feature';
import { useDispatch, useSelector } from 'react-redux';
import { pushNotification, popNotification, increaseURLstack, pushNotificationOverlap } from '../../redux/slices/modalSlice';
import getDefaultTab from '../../helpers/getDefaultTab';
import { setProductFetchInfo, setUrlBeforeProduct } from '../../redux/slices/userSlice';

const OtherProduct = ({
  item,
  cartItems,
  isSomeDealAvailable,
  priceWithoutDeal,
  isLoggedIn,
  applyDiscount,
  discount,
  page,
  handlePopupClose,
  isFeaturedProduct,
  isMostPopularProduct,
  isWalletCreditWithPurchaseApplicable
}) => {
  const router = useRouter();
  const { trackEvent } = useSegment();
  const { addToCart, deleteCartItem, updateCartItem } = useModifyCart();
  // const {
  //   // state: {
  //   //   cart: {
  //   //     updatingProductsList,
  //   //     cartData: { amount = 0 }
  //   //   }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()
  const {
    updatingProductsList,
    cartData: { amount = 0 } = {}
  } = useSelector(state => state.cart) || {}
  const {
    query: { tab = getDefaultTab() }
  } = router;
  const isASAP = tab !== SCHEDULE;
  const [localLoader, setLocalLoader] = useState(false)
  const updatingQuantity = updatingProductsList.includes(item.bundle_id ? item.bundle_id : item.product_details_id);

  const isSubProductPresentInCart = !!cartItems.find(cartItem => cartItem.product_details_id === item.product_details_id);
  useEffect(() => {
    !updatingQuantity && setLocalLoader(false)
  }, [updatingQuantity])
  const onProductQuantityChange = async (change, product) => {

    setLocalLoader(true)
    const quantity = product.quantity || 0;
    if (quantity == 0 && change > 0) {
      // add to cart
      addToCart({ product, force: true, list: page, isFeaturedProduct, isMostPopularProduct });
    } else if (quantity == 1 && change < 0) {
      // delete from cart
      let walletWithPurchaseProps = null;

      if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
        walletWithPurchaseProps = {
          currentProductDetails: product || {},
          cartSubtotal: amount,
          dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
          requestedProductQuantity: 0,
          onRemove: () => deleteCartItem({ product, isFeaturedProduct, isMostPopularProduct })
        };
      }

      deleteCartItem({ product, isFeaturedProduct, isMostPopularProduct, walletWithPurchaseProps });
    } else {
      // update cart
      const updatedQuantity = change > 0 ? quantity + 1 : quantity - 1;

      let walletWithPurchaseProps = null;

      if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
        walletWithPurchaseProps = {
          currentProductDetails: product || {},
          cartSubtotal: amount,
          dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
          requestedProductQuantity: updatedQuantity,
          onRemove: () =>
            updateCartItem({
              product: { ...product, quantity: updatedQuantity },
              force: true,
              isFeaturedProduct,
              isMostPopularProduct
            })
        };
      }

      updateCartItem({
        product: { ...product, quantity: updatedQuantity },
        force: true,
        isFeaturedProduct,
        isMostPopularProduct,
        walletWithPurchaseProps
      });
    }
  };

  const showProductPage = e => {
    e.preventDefault();
    handlePopupClose();
    const { slug, bundle_id: bundleID, is_deal_available: isDealAvailable } = item;
    const isBundle = !!bundleID;
    let url = `/product/${slug}?tab=${tab}`;
    if (bundleID) {
      url = `/bundle/${slug}?tab=${tab}`;
    }
    dispatchRedux(popNotification())
    dispatchRedux(pushNotification({ type: notificationTypes.productDetails, data: { slug, tab, page, isPremium, isBundle, isDealAvailable } }))
    dispatchRedux(increaseURLstack())
    // dispatch({
    //   type: 'setUrlBeforeProduct',
    //   payload: { urlBeforeProduct: `${window.location.pathname}${window.location.search}` }
    // });
    dispatchRedux(setUrlBeforeProduct({ urlBeforeProduct: `${window.location.pathname}${window.location.search}` }))
    window.history.replaceState({ ...window.history.state, as: url, url }, '', url);
  };

  const { product_name: itemName, brand_names: brandName } = item;
  const itemImage = getTransformedImageURLs(item.product_image).srcSmall;
  const productAltText = item.image_alt_tag && item.image_alt_tag !== '' ? item.image_alt_tag : item.product_name;

  const filteredCartArrayForOtherProduct = cartItems.filter(listItem => listItem.product_details_id === item.product_details_id);
  const listItem = filteredCartArrayForOtherProduct.length ? filteredCartArrayForOtherProduct[0] : {};
  const forListSlashPriceProduct = filteredCartArrayForOtherProduct.length ? filteredCartArrayForOtherProduct[0] : item;
  const bulkBasePriceForOtherProduct =
    forListSlashPriceProduct && forListSlashPriceProduct.bulk_base_price ? forListSlashPriceProduct.bulk_base_price : false;
  const orignalPriceForOtherProduct = isSomeDealAvailable ? priceWithoutDeal : bulkBasePriceForOtherProduct;
  const forListSlashPriceProductMaxQuantity = forListSlashPriceProduct.product_max_quantity; // same key for bundles and normal products
  // const forListSlashPriceProductMaxQuantity = forListSlashPriceProduct.bundle_id
  //   ? forListSlashPriceProduct.max_quantity
  //   : forListSlashPriceProduct.product_max_quantity;

  return isNewHomePageApplicable ? (
    <div className="product-list-item">
      <div className="list-img-container">
        <LoadImage alt={productAltText} src={itemImage} />
      </div>

      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <p onClick={e => showProductPage(e)} className="mb-0 semi-bold cursor-pointer list-product-name">
              {itemName}
            </p>
            <div className="fs-12 md-grey-color product-brand">{brandName ? brandName[0] : ''}</div>
            <div className="d-flex mt-0 mb-0 justify-content-between align-items-center">
              <div className="price-parent d-flex mt-1 align-items-center bottom-sec">
                {isLoggedIn ? (
                  <Price productDetails={forListSlashPriceProduct} orignalPrice={orignalPriceForOtherProduct} />
                ) : (
                  <>
                    {isSubProductPresentInCart ? (
                      <Price
                        productDetails={{
                          ...forListSlashPriceProduct,
                          price: (applyDiscount(forListSlashPriceProduct.price) * (100 - discount)) / 100
                        }}
                        orignalPrice={forListSlashPriceProduct.price}
                      />
                    ) : (
                      <Price productDetails={forListSlashPriceProduct} />
                    )}
                  </>
                )}
                <span className="weight-container font-weight-normal md-grey-color fs-12 m-0">
                  &nbsp;•&nbsp;
                  {`${item.product_weight} ${item.product_unit}`}
                </span>
              </div>
            </div>
          </div>
          <div className=" d-flex  align-items-center">
            <button
              name="-"
              disabled={(forListSlashPriceProduct.quantity || 0) <= 0 || updatingQuantity}
              type="button"
              onClick={() => onProductQuantityChange(-1, forListSlashPriceProduct)}
              className="btn btn-sm"
            >
              <i name="minus" className="icon-minus text-primary" />
            </button>
            {localLoader || updatingQuantity ? (
              <span className="relative mx-2 bcc-loader">
                <InlineLoader />
              </span>
            ) : (
              <h6 className="count d-inline semi-bold mx-2 mb-0">{listItem.quantity || 0}</h6>
            )}
            <button
              name="+"
              disabled={(forListSlashPriceProduct.quantity || 0) >= forListSlashPriceProductMaxQuantity || updatingQuantity}
              id="plusBtn"
              type="button"
              onClick={() => onProductQuantityChange(1, forListSlashPriceProduct)}
              className="btn  btn-sm"
            >
              <i name="plus" className="icon-plus  text-primary" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="product-list-item">
      <div className="list-img-container">
        <LoadImage alt={productAltText} src={itemImage} />
      </div>

      <div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column">
            <p onClick={e => showProductPage(e)} className="mb-0 semi-bold cursor-pointer list-product-name">
              {itemName}
            </p>
            <div className="fs-12 md-grey-color product-brand">{brandName ? brandName[0] : ''}</div>
            <div className="d-flex mt-0 mb-0 justify-content-between align-items-center">
              <div className="price-parent d-flex mt-1 align-items-center bottom-sec">
                {isLoggedIn ? (
                  <Price productDetails={forListSlashPriceProduct} orignalPrice={orignalPriceForOtherProduct} />
                ) : (
                  <>
                    {isSubProductPresentInCart ? (
                      <Price
                        productDetails={{
                          ...forListSlashPriceProduct,
                          price: (applyDiscount(forListSlashPriceProduct.price) * (100 - discount)) / 100
                        }}
                        orignalPrice={forListSlashPriceProduct.price}
                      />
                    ) : (
                      <Price productDetails={forListSlashPriceProduct} />
                    )}
                  </>
                )}
                <span className="weight-container font-weight-normal md-grey-color fs-12 m-0">
                  &nbsp;•&nbsp;
                  {`${item.product_weight} ${item.product_unit}`}
                </span>
              </div>
            </div>
          </div>
          <div className=" d-flex  align-items-center">
            <button
              name="-"
              disabled={(forListSlashPriceProduct.quantity || 0) <= 0 || updatingQuantity}
              type="button"
              onClick={() => onProductQuantityChange(-1, forListSlashPriceProduct)}
              className="btn btn-sm"
            >
              <i name="minus" className="icon-minus text-primary" />
            </button>
            {updatingQuantity ? (
              <span className="relative mx-2 bcc-loader">
                <InlineLoader />
              </span>
            ) : (
              <h6 className="count d-inline semi-bold mx-2 mb-0">{listItem.quantity || 0}</h6>
            )}
            <button
              name="+"
              disabled={(forListSlashPriceProduct.quantity || 0) >= forListSlashPriceProductMaxQuantity || updatingQuantity}
              id="plusBtn"
              type="button"
              onClick={() => onProductQuantityChange(1, forListSlashPriceProduct)}
              className="btn  btn-sm"
            >
              <i name="plus" className="icon-plus  text-primary" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CategoryVolumePricingPopup = ({ data, dismiss, page }) => {
  const { addToCart, deleteCartItem, updateCartItem } = useModifyCart();
  // const {
  //   // state: {
  //   //   user: { couponData = {}, isLoggedIn } = {},
  //   //   cart: { cartData: { cart_items: cartItems = [], amount = 0 } = {}, updatingProductsList }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const { couponData = {}, isLoggedIn } = useSelector(state => state.user)
  const { cartData: { cart_items: cartItems = [], amount = 0 } = {}, updatingProductsList } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()
  const { data: { asapEnabled = true, isWalletCreditWithPurchaseApplicable } = {} } = useDeliveryDetails();

  const updatingQuantity = updatingProductsList.includes(data.bundle_id ? data.bundle_id : data.product_details_id);

  useEffect(() => {
    !updatingQuantity && setLocalLoader(false)
  }, [updatingQuantity])
  useEffect(() => {
    // dispatch({ type: 'setProductFetchInfo', payload: { shouldFetchProduct: true } });
    dispatchRedux(setProductFetchInfo({ shouldFetchProduct: true }))
  }, []);

  const router = useRouter();
  const [localLoader, setLocalLoader] = useState(false)

  const {
    query: { tab }
  } = router;

  let isASAP = tab !== SCHEDULE;

  if (!asapEnabled) {
    isASAP = false;
  }

  const {
    product_name: productName,
    brand_names,
    product_weight,
    product_unit,
    image_alt_tag: imageAltText,
    is_deal_available: isDealAvailable,
    is_pre_deal_available: isPreDealAvailable,
    price_without_deal: priceWithoutDeal,
    offer_category_name: categoryName,
    scheduleType,
    category_volume_discounts: categoryVolumeDiscounts = [],
    isFeaturedProduct,
    isMostPopularProduct
  } = data;
  const productImage =
    getTransformedImageURLs(data.product_image).srcSmall ||
    (data.images.length && getTransformedImageURLs(data.images[0]).srcSmall);
  const isSomeDealAvailable = isDealAvailable || isPreDealAvailable;

  const brandSlug = router.pathname == '/brands/[slug]' ? router.query.slug : null;
  const {
    data: { brandData: { asap_categories: brandAsapCategories = [], schedule_categories: brandScheduleCategories = [] } } = {},
    isValidating: isValidatingBrand
  } = useBrand({ slug: brandSlug });
  const { data: asapCategories = [], isValidating: isValidatingAsap } = useASAPShop({});
  const { data: scheduleCategories = [], isValidating: isValidatingSchedule } = useScheduleShop({});

  let categories = [];

  let isValidating = false;

  if (router.pathname == '/brands/[slug]') {
    categories = isASAP ? brandAsapCategories : brandScheduleCategories;
    isValidating = isValidatingBrand;
  } else {
    categories = isASAP ? asapCategories : scheduleCategories;
    if (isASAP) {
      isValidating = isValidatingAsap;
    } else {
      isValidating = isValidatingSchedule;
    }
  }
  const allProducts = [];
  categories?.forEach(category => {
    const productIDs = allProducts.map(product => product.product_details_id);
    category?.products?.forEach(product => {
      if (!productIDs.includes(product.product_details_id)) {
        allProducts.push(product);
      }
    });
  });
  const otherProducts = allProducts
    ? allProducts.filter(
      prod =>
        prod.product_details_id != data.product_details_id &&
        prod.offer_category_id &&
        prod.offer_category_id == data.offer_category_id &&
        !prod.is_sold_out &&
        !prod.is_deal_available &&
        !prod.is_pre_deal_available
    )
    : [];

  const isMainProductPresentInCart = !!cartItems.find(item => item.product_details_id === data.product_details_id);
  const forSlashPriceProduct = cartItems.find(item => item.product_details_id === data.product_details_id) || data;
  const bulkBasePrice =
    forSlashPriceProduct && forSlashPriceProduct.bulk_base_price ? forSlashPriceProduct.bulk_base_price : false;
  const orignalPrice = isSomeDealAvailable ? priceWithoutDeal : bulkBasePrice;

  const forSlashPriceProductMaxQuantity = forSlashPriceProduct.product_max_quantity; // same key for bundles and normal products

  // const forSlashPriceProductMaxQuantity = forSlashPriceProduct.bundle_id
  //   ? forSlashPriceProduct.max_quantity
  //   : forSlashPriceProduct.product_max_quantity;

  const mainQuantity = forSlashPriceProduct.quantity || 0;
  let listQuantity = 0;
  otherProducts?.forEach(prod => {
    const filteredCartArrayForProduct = cartItems.filter(item => item.product_details_id === prod.product_details_id);
    const item = filteredCartArrayForProduct.length ? filteredCartArrayForProduct[0] : prod;
    listQuantity += item.quantity || 0;
  });
  const totalQuantity = mainQuantity + listQuantity;
  const discount = (() => {
    const discounts = [...categoryVolumeDiscounts].sort((a, b) => b.units - a.units);
    let finalDiscount = 0;
    discounts.some(currentdiscount => {
      if (totalQuantity >= currentdiscount.units) {
        finalDiscount = currentdiscount.discount;
        return true;
      }
    });
    return finalDiscount;
  })();

  const applyDiscount = price => {
    const { discount: currentDiscount = 0, slash_price: slashPrice, eligible_delivery_type: eligibleDeliveryType } = couponData;
    const { asap, schedule, menu } = forSlashPriceProduct;
    if (slashPrice) {
      switch (Number(eligibleDeliveryType)) {
        case 1:
          if (asap && menu != 2) {
            return toFixedIfNeeded(price * ((100 - currentDiscount) / 100));
          }
          return price;
        case 2:
          if (schedule && menu != 1) {
            return toFixedIfNeeded(price * ((100 - currentDiscount) / 100));
          }
          return price;
        default:
          return toFixedIfNeeded(price * ((100 - currentDiscount) / 100));
      }
    } else {
      return price;
    }
  };

  const priceofcollection = () => {
    let total = 0;
    if (isLoggedIn) {
      total =
        (forSlashPriceProduct.bulk_base_price || forSlashPriceProduct.price_without_deal || 0) *
        (forSlashPriceProduct.quantity || 0);
    } else {
      total = (forSlashPriceProduct.price || 0) * (forSlashPriceProduct.quantity || 0);
    }
    let discounted = (forSlashPriceProduct.price || 0) * (forSlashPriceProduct.quantity || 0);
    otherProducts?.forEach(prod => {
      const item = cartItems.find(cartItem => cartItem.product_details_id === prod.product_details_id) || prod;
      if (isLoggedIn) {
        total += (item.bulk_base_price || item.price_without_deal || 0) * (item.quantity || 0);
      } else {
        total += (item.price || 0) * (item.quantity || 0);
      }
      discounted += (item.price || 0) * (item.quantity || 0);
    });
    discounted = applyDiscount(discounted);
    if (!isLoggedIn) {
      discounted = (discounted * (100 - discount)) / 100;
    }
    return { total, discounted };
  };

  const onProductQuantityChange = async (change, product) => {
    setLocalLoader(true)
    const quantity = product.quantity || 0;

    if (quantity == 0 && change > 0) {
      // add to cart
      addToCart({ product, scheduleType, force: true, list: page, type: data.type, isFeaturedProduct, isMostPopularProduct });
    } else if (quantity == 1 && change < 0) {
      // delete from cart
      let walletWithPurchaseProps = null;

      if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
        walletWithPurchaseProps = {
          currentProductDetails: product || {},
          cartSubtotal: amount,
          dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
          requestedProductQuantity: 0,
          onRemove: () => deleteCartItem({ product, isFeaturedProduct, isMostPopularProduct })
        };
      }

      deleteCartItem({ product, isFeaturedProduct, isMostPopularProduct, walletWithPurchaseProps });
    } else {
      // update cart
      const updatedQuantity = change > 0 ? quantity + 1 : quantity - 1;

      let walletWithPurchaseProps = null;

      if (isLoggedIn && isWalletCreditWithPurchaseApplicable) {
        walletWithPurchaseProps = {
          currentProductDetails: product || {},
          cartSubtotal: amount,
          dispatch: (payload) => dispatchRedux(pushNotificationOverlap(payload)),
          requestedProductQuantity: updatedQuantity,
          onRemove: () =>
            updateCartItem({
              product: { ...product, quantity: updatedQuantity },
              scheduleType,
              force: true,
              list: page,
              isFeaturedProduct,
              isMostPopularProduct
            })
        };
      }

      updateCartItem({
        product: { ...product, quantity: updatedQuantity },
        scheduleType,
        force: true,
        list: page,
        isFeaturedProduct,
        isMostPopularProduct,
        walletWithPurchaseProps
      });
    }
  };

  useEffect(() => {
    const quantity = forSlashPriceProduct.quantity || 0;
    if (quantity <= 0) {
      // onProductQuantityChange(1, forSlashPriceProduct);
      // addToCart({ product: data, force: true });

      setTimeout(() => {
        addToCart({
          product: data,
          scheduleType,
          force: true,
          list: page,
          type: data.type,
          isFeaturedProduct,
          isMostPopularProduct
        });
      }, 50);
    }
  }, []);

  const handlePopupClose = () => {
    dismiss();
  };

  const productAltText = imageAltText && imageAltText !== '' ? imageAltText : productName;

  return (
    // <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
    <div
      className={`modal  d-block bulk-product-popup category-volume-pricing animated-popup-wrapper ${isNewHomePageApplicable ? 'gd-new-volume-popup' : ''
        } `}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog m-scroll  " role="document">
        <div className="modal-content">
          <div className="modal-header pb-0">
            <h6 className="modal-title bold">
              <h5 className="a-category-name bold">{data.offer_category_name}</h5>
              <em>
                <div className="mini-description ta-left">{data.category_offer_description}</div>
              </em>
            </h6>
          </div>
          {isNewHomePageApplicable ? (
            <div className="modal-body pt-2">
              <div className="d-flex relative">
                <div className="align-self-center">
                  <div className="img-container">
                    <LoadImage src={productImage} alt={productAltText} />
                  </div>
                </div>
                <div className="text-center text-lg-left b-right-sec volume-popup-products-wrapper d-flex flex-column justify-content-between">
                  <div className="d-flex flex-column align-items-start">
                    <div className="d-flex align-items-center product-brand">
                      <i className="icon-sun-outline fs-12 mr-1" />
                      <div className="fs-13 ">{brand_names ? brand_names[0] : 'Sativa'}</div>
                    </div>
                    <h6 className="text-left text-lg-left semi-bold product-name">
                      {productName}
                      <span className="weight-container font-weight-normal md-grey-color fs-11 m-0">
                        {' '}
                        &nbsp;•&nbsp;{`${product_weight} ${product_unit}`}
                      </span>
                    </h6>
                    <div className="fs-13 md-grey-color brand-name">Jelly Cannabis</div>
                    {/* <span className="weight-container font-weight-normal md-grey-color fs-11 m-0">{`${product_weight} ${product_unit}`}</span> */}
                  </div>
                  <div className="product-conter-box align-items-center">
                    <div className="bottom-section white-color text-center">
                      {isLoggedIn ? (
                        <Price productDetails={forSlashPriceProduct} orignalPrice={orignalPrice} />
                      ) : (
                        <>
                          {isMainProductPresentInCart ? (
                            <Price
                              productDetails={{
                                ...forSlashPriceProduct,
                                price: (applyDiscount(forSlashPriceProduct.price) * (100 - discount)) / 100
                              }}
                              orignalPrice={forSlashPriceProduct.price}
                            />
                          ) : (
                            <Price productDetails={forSlashPriceProduct} />
                          )}
                        </>
                      )}
                    </div>
                    <div className="top-section d-flex justify-content-between align-items-center">
                      <button
                        name="-"
                        disabled={(forSlashPriceProduct.quantity || 0) <= 0 || updatingQuantity}
                        type="button"
                        onClick={() => onProductQuantityChange(-1, forSlashPriceProduct)}
                        className="btn pr-0"
                      >
                        <i name="minus" className="icon-minus text-primary" />
                      </button>
                      {localLoader || updatingQuantity ? (
                        <InlineLoader positionRelative />
                      ) : (
                        <h5 className="count d-inline semi-bold mx-2 mb-0">{forSlashPriceProduct.quantity || 0}</h5>
                      )}
                      <button
                        name="+"
                        disabled={(forSlashPriceProduct.quantity || 0) >= forSlashPriceProductMaxQuantity || updatingQuantity}
                        id="plusBtn"
                        type="button"
                        onClick={() => onProductQuantityChange(1, forSlashPriceProduct)}
                        className="btn pl-0"
                      >
                        <i name="plus" className="icon-plus  text-primary" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* *************************** LIST */}

              {!!otherProducts.length && (
                <p className="promotional-text">
                  You can choose from the following products to take advantage of this special pricing. The more you add, the
                  deeper the savings!
                </p>
              )}
              {isValidating && !otherProducts.length && (
                <div className="text-center my-2">
                  <InlineLoader positionRelative className="big" />
                </div>
              )}
              {otherProducts.length ? (
                <>
                  <div className="relative">
                    <div className="add-and-save d-flex justify-content-center align-items-center">
                      <i className="icon-dollar-hand mr-2" />
                      <span className="bold">The more you add, the deeper the savings!</span>
                    </div>
                  </div>
                  <div className="c-product-list relative">
                    {otherProducts.map(item => (
                      <OtherProduct
                        key={item.product_details_id}
                        item={item}
                        cartItems={cartItems}
                        isSomeDealAvailable={isSomeDealAvailable}
                        priceWithoutDeal={priceWithoutDeal}
                        isLoggedIn={isLoggedIn}
                        applyDiscount={applyDiscount}
                        discount={discount}
                        page={page}
                        handlePopupClose={handlePopupClose}
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          ) : (
            <div className="modal-body ">
              <div className="d-lg-flex ">
                <div className="col-lg-5 ">
                  <div className="img-container">
                    <LoadImage src={productImage} alt={productAltText} />
                  </div>
                </div>
                <div className="col-lg-7 text-center text-lg-left b-right-sec">
                  <h6 className="text-center text-lg-left semi-bold mb-0">
                    {productName}
                    <span className="weight-container font-weight-normal md-grey-color fs-11 m-0">
                      &nbsp;•&nbsp;
                      {`${product_weight} ${product_unit}`}
                    </span>
                  </h6>
                  <div className="fs-13 md-grey-color product-brand">{brand_names ? brand_names[0] : ''}</div>
                  <div className="product-conter-box">
                    <div className="top-section d-flex justify-content-between align-items-center">
                      <button
                        name="-"
                        disabled={(forSlashPriceProduct.quantity || 0) <= 0 || updatingQuantity}
                        type="button"
                        onClick={() => onProductQuantityChange(-1, forSlashPriceProduct)}
                        className="btn"
                      >
                        <i name="minus" className="icon-minus text-primary" />
                      </button>
                      {updatingQuantity ? (
                        <InlineLoader positionRelative />
                      ) : (
                        <h5 className="count d-inline semi-bold mx-2 mb-0">{forSlashPriceProduct.quantity || 0}</h5>
                      )}
                      <button
                        name="+"
                        disabled={(forSlashPriceProduct.quantity || 0) >= forSlashPriceProductMaxQuantity || updatingQuantity}
                        id="plusBtn"
                        type="button"
                        onClick={() => onProductQuantityChange(1, forSlashPriceProduct)}
                        className="btn"
                      >
                        <i name="plus" className="icon-plus  text-primary" />
                      </button>
                    </div>
                    <div className="bottom-section white-color text-center">
                      {isLoggedIn ? (
                        <Price productDetails={forSlashPriceProduct} orignalPrice={orignalPrice} />
                      ) : (
                        <>
                          {isMainProductPresentInCart ? (
                            <Price
                              productDetails={{
                                ...forSlashPriceProduct,
                                price: (applyDiscount(forSlashPriceProduct.price) * (100 - discount)) / 100
                              }}
                              orignalPrice={forSlashPriceProduct.price}
                            />
                          ) : (
                            <Price productDetails={forSlashPriceProduct} />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="one-rem-mt one-rem-mb">
                <h6 className="semi-bold mb-2">
                  TOTAL PRICE OF COLLECTION :
                  {toFixedIfNeeded(priceofcollection().total) === toFixedIfNeeded(priceofcollection().discounted) ? (
                    <>
                      &nbsp;
                      <span className="text-primary">${toFixedIfNeeded(priceofcollection().total)}</span>
                    </>
                  ) : (
                    <>
                      &nbsp;
                      <span className="text-primary">${toFixedIfNeeded(priceofcollection().discounted)}</span>
                      &nbsp;
                      <small className="strike-text">${toFixedIfNeeded(priceofcollection().total)}</small>
                    </>
                  )}
                </h6>
                {toFixedIfNeeded(priceofcollection().total) !== toFixedIfNeeded(priceofcollection().discounted) && (
                  <>
                    <h6 className="semi-bold">
                      SAVINGS :{' '}
                      <span className="soldout-limit discount-savings">
                        {' '}
                        $
                        {toFixedIfNeeded(
                          toFixedIfNeeded(priceofcollection().total) - toFixedIfNeeded(priceofcollection().discounted)
                        )}
                      </span>
                    </h6>
                  </>
                )}
              </div>

              {/* *************************** LIST */}

              {!!otherProducts.length && (
                <p>
                  You can also choose from the following products to take advantage of this special pricing.The more you add, the
                  deeper the savings!
                </p>
              )}
              {isValidating && !otherProducts.length && (
                <div className="text-center my-2">
                  <InlineLoader positionRelative className="big" />
                </div>
              )}
              <div className="c-product-list">
                {otherProducts.map(item => (
                  <OtherProduct
                    key={item.product_details_id}
                    item={item}
                    cartItems={cartItems}
                    isSomeDealAvailable={isSomeDealAvailable}
                    priceWithoutDeal={priceWithoutDeal}
                    isLoggedIn={isLoggedIn}
                    applyDiscount={applyDiscount}
                    discount={discount}
                    page={page}
                    handlePopupClose={handlePopupClose}
                    isFeaturedProduct={isFeaturedProduct}
                    isMostPopularProduct={isMostPopularProduct}
                  />
                ))}
              </div>
            </div>
          )}
          {isNewHomePageApplicable ? (
            <div className="modal-footer justify-content-between">
              <div className="m-0">
                <h6 className="semi-bold mb-2">
                  Total Price :
                  {toFixedIfNeeded(priceofcollection().total) === toFixedIfNeeded(priceofcollection().discounted) ? (
                    <>
                      &nbsp;
                      <span className="text-primary total-price bold">${toFixedIfNeeded(priceofcollection().total)}</span>
                    </>
                  ) : (
                    <>
                      &nbsp;
                      <span className="text-primary total-price bold">${toFixedIfNeeded(priceofcollection().discounted)}</span>
                      &nbsp;
                      <small className="strike-text md-grey-color fs-16">${toFixedIfNeeded(priceofcollection().total)}</small>
                    </>
                  )}
                </h6>
                {toFixedIfNeeded(priceofcollection().total) !== toFixedIfNeeded(priceofcollection().discounted) && (
                  <>
                    <h6 className="semi-bold mb-0 saving-price">
                      Savings :&nbsp;{' '}
                      <span className="soldout-limit discount-savings">
                        {' '}
                        $
                        {toFixedIfNeeded(
                          toFixedIfNeeded(priceofcollection().total) - toFixedIfNeeded(priceofcollection().discounted)
                        )}
                      </span>
                    </h6>
                  </>
                )}
              </div>
              <button
                data-testid="#other-product"
                type="button"
                className="btn btn-primary bold rouded-button has-box-shadow"
                onClick={handlePopupClose}
              >
                Okay
              </button>
            </div>
          ) : (
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-primary bold rouded-button has-box-shadow min-width-btn"
                onClick={handlePopupClose}
              >
                Ok
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
    // </CSSTransition>
  );
};
export default CategoryVolumePricingPopup;

import scheduleTimeBracketsArray from '../constants/scheduleTimeBracketsArray';

export default function getFirstAvailableTimeSlot(deliveryDate = 0, isGrassdoor, deliveryDays) {
  const scheduleTimeBrackets = scheduleTimeBracketsArray(isGrassdoor);

  if (
    // deliveryTypeSelected === SCHEDULE_TYPE_FUTURE &&
    deliveryDays &&
    deliveryDays.length &&
    deliveryDate >= 0 &&
    deliveryDays[deliveryDate].deliveryWindows &&
    deliveryDays[deliveryDate].deliveryWindows.length
  ) {
    return deliveryDays[deliveryDate].deliveryWindows[0].startTime;
  }
  return scheduleTimeBrackets[0].code;
}

import getNonWhiteSpaceCharacterLength from './getNonWhiteSpaceCharacterLength';
import validNumber from './validNumber';

export default function mobileNoValidationWithError(mobile) {
  if (getNonWhiteSpaceCharacterLength(mobile) < 1) {
    return 'Please enter mobile number';
  }
  if (!validNumber(mobile)) {
    return 'Invalid number';
  }
  return null;
}

import React from 'react';

const Loader = ({ className }) => (
  <div className={`loader loader-3dots ${className}`}>
    <span />
    <span />
    <span />
  </div>
);

export default Loader;

export const updateCompareObject = (product) => {
  let temp = {}
  temp = returnCustomAttributes(product.product_attributes)
  return { ...product, customProductAttributes: temp }
}

export const returnCustomAttributes = (attributes) => {
  let temp = {}
  attributes.map(info => {
    temp = { ...temp, [info.name]: info }
  })
  return temp

}
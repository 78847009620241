import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

const RetryImage =
  process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true'
    ? `${process.env.NEXT_PUBLIC_IMAGES_STATIC_URL}/images/retry.svg`
    : '/static/images/retry.svg';

const ItemNotFound = ({ text, className, children }) => {
  const img = RetryImage;
  return (
    <div className={`item-not-found text-center ${className}`}>
      <img className="d-block" src={img} alt="Item Not Found" />
      {children}
      <div className="item-not-found-text">{text}</div>
    </div>
  );
};

ItemNotFound.propTypes = {
  text: PropTypes.string
};

ItemNotFound.defaultProps = {
  text: 'Sorry, The item you are looking for is not found'
};

export default ItemNotFound;

import React, { useContext, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Loader from './Loader';
import { useCardList } from '../hooks/User';
import appContext from '../Context/appContext';
import appConfig from '../appConfig';
import validateCard from '../helpers/validateCard';
import useAPI from '../NetworkCall/API';
import storageService from '../services/storageService';
import DriverTip from './DriverTip';
import { NEW_CARD } from '../constants/paymentTypes';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomerInfo } from '../redux/slices/cartSlice';

const CardView = ({
  processing,
  allowEditBillingAddress,
  cardView,
  isSuperOrVerified,
  isCardVerified,
  isCheckout,
  dismiss,
  isConfirmation = false,
  payByCard,
  isProfileModule = false,
  callBackProfileModule
}) => {
  // const {
  //   // state: {
  //   //   cart: {
  //   //     customerData: { cardData: initialCardData, isNewCard }
  //   //   }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const dispatchRedux = useDispatch()
  const { cardData: initialCardData } = useSelector(state => state.cart?.customerData) || {}

  const [loading, setLoading] = useState(false);
  const [cardData, setCardData] = useState(initialCardData);

  const { data: cardList, isLoading, modifyCards, allowCardSave, mutate: mutateCardList } = useCardList();
  const [isDefault, setIsDefault] = useState(false);

  function onChangeCardData(e) {
    if (e.target.name === 'saveCard') {
      setCardData({ ...cardData, [e.target.name]: e.target.checked });
    } else {
      setCardData({ ...cardData, [e.target.name]: e.target.value, [`${e.target.name}Error`]: null });
    }
  }
  useEffect(() => {
    if (isLoading || modifyCards) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isLoading, modifyCards]);

  if (cardList?.length && isConfirmation) {
    return null;
  }

  const validateCardData = event => {
    event.preventDefault();
    // const postcode = postCodeToBeCompared || this.rootStore.postcode;
    const { cardNumber, cardExp, cardCVV, cardName, zipcode, saveCard } = cardData;
    const cardDetails = { number: cardNumber, exp: cardExp, cvv: cardCVV, name: cardName, zipcode };
    const additionalDetails = { isSuperOrVerified, isCardVerified };
    const cardErrors = validateCard(cardDetails, additionalDetails);
    if (cardErrors.number) {
      toast.error(cardErrors.number);
      setCardData({ ...cardData, cardNumberError: cardErrors.number });
      return false;
    }
    if (cardErrors.exp) {
      toast.error(cardErrors.exp);
      setCardData({ ...cardData, cardExpError: cardErrors.exp });
      return false;
    }
    if (cardErrors.cvv) {
      toast.error(cardErrors.cvv);
      setCardData({ ...cardData, cardCVVError: cardErrors.cvv });
      return false;
    }
    if (cardErrors.name) {
      toast.error(cardErrors.name);
      setCardData({ ...cardData, cardNameError: cardErrors.name });
      return false;
    }
    if (cardErrors.zipcode) {
      toast.error(cardErrors.zipcode);
      setCardData({ ...cardData, zipcodeError: cardErrors.zipcode });
      return false;
    }

    if (dismiss) {
      dismiss();
    }
    if (isConfirmation) {
      payByCard(cardData);
    } else if (isProfileModule) {
      callBackProfileModule(cardData, isDefault);
    } else {
      toast.success('Card added');
      // dispatch({ type: 'setCustomerInfo', payload: { cardData: { ...cardData, newCardChecked: true } } });
      dispatchRedux(setCustomerInfo({ cardData: { ...cardData, newCardChecked: true } }))
      // dispatch({ type: 'setCustomerInfo', payload: { isNewCard: true, checkedDebitCard: NEW_CARD, debitSelectedCard: null } });
      dispatchRedux(setCustomerInfo({ isNewCard: true, checkedDebitCard: NEW_CARD, debitSelectedCard: null }))
      mutateCardList();
      if (saveCard) {
        storageService.setDebitCardSession({ ...cardData, newCardChecked: true });
      } else {
        storageService.removeDebitCardSession();
      }
    }
    return true;
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      <div id="customer-card-details" className={`one-rem-mt card-details`} ref={cardView}>
        <div className="inner">
          <div className="relative">
            <form onSubmit={validateCardData}>
              <div className={`addressbox-parent form-group`}>
                <label className={`mini-label`}>Debit Card Number</label>

                <InputMask
                  type="tel"
                  placeholder={`Debit Card Number`}
                  name="cardNumber"
                  onChange={onChangeCardData}
                  value={cardData.cardNumber}
                  mask="9999 9999 9999 9999"
                  maskChar={null}
                  className={`form-control one-em-font ${cardData.cardNumberError ? 'err-validate' : ''}`}
                  readOnly={processing}
                />
              </div>

              <div className={`addressbox-parent form-group row`}>
                <div className={`col`}>
                  <label className={`mini-label`}>
                    {'Expiry Date'}
                  </label>

                  <InputMask
                    type="tel"
                    placeholder="MM/YY"
                    name="cardExp"
                    onChange={onChangeCardData}
                    value={cardData.cardExp}
                    mask="mM/M9"
                    maskChar=" "
                    formatChars={{
                      m: '[0-1]',
                      M: '[0-9]',
                      9: '[0-9]'
                    }}
                    className={`form-control one-em-font ${cardData.cardExpError ? 'err-validate' : ''}`}
                    readOnly={processing}
                  />
                </div>

                <div className="col">
                  <label className="mini-label">CVV</label>
                  <InputMask
                    type="password"
                    placeholder={`CVV`}
                    name="cardCVV"
                    onChange={onChangeCardData}
                    value={cardData.cardCVV}
                    mask="9999"
                    maskChar=""
                    className={`form-control one-em-font ${cardData.cardCVVError ? 'err-validate' : ''}`}
                    readOnly={processing}
                  />
                </div>
              </div>

              <div className={`addressbox-parent`}>
                <label className={`mini-label d-block`}>Card Holder Name</label>
                <input
                  type="text"
                  placeholder={`Debit Card Holder Name`}
                  name="cardName"
                  onChange={onChangeCardData}
                  value={cardData.cardName}
                  className={`form-control one-em-font ${cardData.cardNameError ? 'err-validate' : ''}`}
                  readOnly={processing}
                  maxLength="50"
                />
              </div>

              <fieldset className={`one-rem-mt`}>
                <form autoComplete="off">
                  {!isSuperOrVerified && <label className="mini-label d-none">BILLING ADDRESS</label>}
                  {!isSuperOrVerified && !isCardVerified ? (
                    <small className="d-block half-rem-mt">
                      *
                      {appConfig.isGrassdoor
                        ? `Debit card billing address zipcode must match delivery address zipcode. If that is not possible, cash payment is the only other option. `
                        : ''}
                      Photo ID will be requested by the driver and must reflect the same name as the debit card. Thank you.
                    </small>
                  ) : null}

                  {!isSuperOrVerified ? (
                    <div className={`half-rem-mt mb-3`}>
                      <div className={`row addressbox-parent`}>
                        <div className="col">
                          <label className={`mini-label d-block`}>
                            {'Zipcode'}
                          </label>

                          <input
                            autoComplete="off"
                            type="tel"
                            placeholder={`Billing Zipcode`}
                            name="zipcode"
                            onChange={onChangeCardData}
                            value={cardData.zipcode}
                            className={`form-control one-em-font ${cardData.zipcodeError ? 'err-validate' : ''
                              }`}
                            readOnly={!allowEditBillingAddress}
                            disabled={!allowEditBillingAddress}
                            maxLength="8"
                          />
                        </div>

                        <div className="col " />
                      </div>
                    </div>
                  ) : null}
                </form>
              </fieldset>

              {allowCardSave && !isProfileModule ? (
                <div className="form-group ">
                  <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="save-for-later"
                      checked={cardData.saveCard}
                      name="saveCard"
                      onChange={onChangeCardData}
                      disabled={processing}
                    />

                    <label className="align-top m-0" htmlFor="save-for-later">
                      Save debit card for future use
                    </label>
                  </div>
                </div>
              ) : null}
              {isCheckout ? (
                <div className="text-center">
                  <button type="submit" className="btn px-4 btn-primary">
                    Save
                  </button>
                </div>
              ) : null}
              {isConfirmation ? (
                <>
                  <DriverTip className='px-0' />
                  <div className={`text-center half-rem-mt`}>
                    <button
                      type="submit"
                      className={`btn btn-primary half-rem-mt btn-rounded has-box-shadow min-width-btn`}
                    >
                      Pay
                    </button>
                  </div>
                </>
              ) : null}
              {isProfileModule ? (
                <div className="modal-footer justify-content-between a-btn-container p-0">
                  <div className="form-group ">
                    <div className="custom-checkbox">
                      <input
                        type="checkbox"
                        id="add-default-address"
                        checked={isDefault}
                        name="addDefaultAddress"
                        onChange={() => setIsDefault(!isDefault)}
                      />
                      <label className="align-top m-0" htmlFor="add-default-address">
                        Make this card Default
                      </label>
                    </div>
                  </div>
                  <button type="submit" className="btn outline-btn btn-small btn-sm p-btn btn-outline-secondary">
                    Done
                  </button>
                </div>
              ) : null}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardView;

import React, { useContext, useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import appContext from '../Context/appContext';
import * as WEBSITES from '../constants/website';
import { useDispatch, useSelector } from 'react-redux';
import { setCartDetails } from '../redux/slices/cartSlice';

const tipAmounts = [2, 5, 10];
const DriverTip = ({ className }) => {
  // const {
  //   // state: {
  //   //   cart: {
  //   //     cartDetails: { driverTip, automaticTipAmount = false }
  //   //   }
  //   // },
  //   dispatch
  // } = useContext(appContext);
  const {
    cartDetails: { driverTip, automaticTipAmount = false }
  } = useSelector(state => state.cart) || {}
  const dispatchRedux = useDispatch()
  const [showCustomTip, setShowCustomTip] = useState(false);
  const [customTip, setCustomTip] = useState('');
  const [customFieldAutoFocus, setCustomFieldAutoFocus] = useState(false);

  const specificTipAmount = [0, ...tipAmounts].includes(driverTip);

  useEffect(() => {
    if (!customTip && !showCustomTip) {
      setCustomTip(specificTipAmount ? '' : driverTip);
      if (!specificTipAmount) {
        setShowCustomTip(true);
        setCustomFieldAutoFocus(true);
      }
    }
  }, [specificTipAmount]);

  function handleTipAmountClick(amount) {
    setShowCustomTip(false);
    setCustomTip('');
    if (amount !== driverTip) {
      // dispatch({ type: 'setCartDetails', payload: { driverTip: amount } });

      dispatchRedux(setCartDetails({ driverTip: amount }))
    }
  }

  function handleCustomAmount(e) {
    const amount = e.target.value;
    setCustomTip(amount);
    // dispatch({ type: 'setCartDetails', payload: { driverTip: Number(amount) } });
    dispatchRedux(setCartDetails({ driverTip: Number(amount) }))
  }

  const removeDriverTip = () => {
    // dispatch({ type: 'setCartDetails', payload: { driverTip: 0 } });
    dispatchRedux(setCartDetails({ driverTip: 0 }))
  }

  const removeDriverCustomTip = () => {
    setCustomTip('');
    removeDriverTip();
    setShowCustomTip(false);
  };

  function renderTipButtons() {
    return tipAmounts.map(tipAmount => (
      <>
        <button
          type="button"
          key={`tipAmount-${tipAmount}`}
          className={`btn tipAmount ${driverTip === tipAmount && !customTip ? 'is-active' : ''}`}
          onClick={() => {
            handleTipAmountClick(tipAmount);
          }}
        >
          {`$${tipAmount}`}
          {driverTip === tipAmount && !customTip ? <i className="icon icon-close ml-2" onClick={removeDriverTip} /> : null}
        </button>
      </>
    ));
  }

  useEffect(() => {
    if (driverTip) {
      if (!automaticTipAmount) {
        // dispatch({ type: 'setCartDetails', payload: { automaticTipAmount: true } });
        dispatchRedux(setCartDetails({ automaticTipAmount: true }))
      }
    } else {
      // dispatch({ type: 'setCartDetails', payload: { automaticTipAmount: false } });
      dispatchRedux(setCartDetails({ automaticTipAmount: false }))
    }
  }, [driverTip]);

  return (
    <>
      <div className={`${className} tip-driver`}>
        <div className="px-0">
          <div className="one-rem-mb d-lg-flex justify-content-between">
            <div className="bold">Would you like to tip the driver?</div>

            <small className="tip-text">100% of your tip goes to the driver</small>
          </div>
          <div className="d-flex">
            <>{renderTipButtons()}</>
            <div className=" ">
              {showCustomTip ? (
                <div className="ammount-parent">
                  <div className={`amount-wrapper d-inline-block relative  ${customFieldAutoFocus ? 'is-active' : ''}`}>
                    <InputMask
                      type="tel"
                      placeholder=""
                      onChange={handleCustomAmount}
                      value={customTip}
                      autoFocus={customFieldAutoFocus}
                      mask="99"
                      maskChar=""
                      className={`form-control custom-amount text-left`}
                    />
                    {customFieldAutoFocus ? <i className="icon icon-close " onClick={removeDriverCustomTip} /> : ''}
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-link ico-btn tipAmount"
                  onClick={() => {
                    setShowCustomTip(true);
                    setCustomFieldAutoFocus(true);
                  }}
                >
                  Other
                </button>
              )}
            </div>
          </div>
          <>
            <hr />
            <div className="custom-checkbox mt-3">
              <input
                className="form-check-input"
                type="checkbox"
                value={automaticTipAmount}
                disabled={driverTip === 0}
                checked={automaticTipAmount}
                onChange={() => {
                  // dispatch({ type: 'setCartDetails', payload: { automaticTipAmount: !automaticTipAmount } });
                  dispatchRedux(setCartDetails({ automaticTipAmount: !automaticTipAmount }))
                }}
                id="defaultCheck1"
              />

              <label
                className={`form-check-label fs-13 ${driverTip === 0 ? 'cursor-default' : 'cursor-pointer'}`}
                htmlFor="defaultCheck1"
              >
                Add this tip automatically for future orders
              </label>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default DriverTip;

export default function getDailyLimitValues(cartData) {
  let total_offer_concentrates = 0;
  let total_offer_non_concentrates = 0;
  let total_concentrates = cartData.concentrated_weight_original;
  let total_non_concentrates = cartData.non_concentrated_weight_original;
  if (cartData.offer_product && cartData.offer_product.length) {
    cartData.offer_product.map((offerProd) => {
      if (offerProd.products && offerProd.products.length) {
        offerProd.products.map((products) => {
          if (cartData.is_offer_allowed == 1) {
            total_offer_concentrates = products.concentrated_weight * offerProd.is_avail * products.get_quantity;
            total_offer_non_concentrates = products.non_concentrated_weight * offerProd.is_avail * products.get_quantity;
            total_concentrates = total_concentrates * cartData.quantity;
            total_non_concentrates = total_non_concentrates * cartData.quantity;
            cartData.concentrated_weight = total_concentrates + total_offer_concentrates;
            cartData.non_concentrated_weight = total_non_concentrates + total_offer_non_concentrates;
          } else {
            cartData.concentrated_weight = total_concentrates * cartData.quantity;
            cartData.non_concentrated_weight = total_non_concentrates * cartData.quantity;
          }
        })
      }
    })
  } else {
    cartData.concentrated_weight = total_concentrates * cartData.quantity;
    cartData.non_concentrated_weight = total_non_concentrates * cartData.quantity;
  }

  return { concentrated_weight: cartData.concentrated_weight, non_concentrated_weight: cartData.non_concentrated_weight };
}

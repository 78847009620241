import React from 'react';
import SearchBar from '../SearchBar';

const DrWeedyEditAddress = ({
  editAddress,
  onClickEdit,
  onClickCancel,
  address,
  onPlacesChanged,
  error,
  isLoggedIn,
  isMedicalCard,
  isNotfocus
}) => (
  <div className="addressbox-parent form-group">
    <label className={`${isMedicalCard ? 'd-none' : 'mini-label mb-2'}`}>{isMedicalCard ? '' : ' Address'}</label>
    {!editAddress ? (
      <div>
        <>
          <div className="textarea-box">
            <div className="addres-logged-in bold">{address}</div>
            <button
              onClick={onClickEdit}
              className={`btn btn-sm btn-link col-auto ml-2 one-em-font  text-decoration-none ${isMedicalCard ? 'abs-btn-medical' : 'abs-btn'
                }`}
              title="Edit"
            >
              <i className="icon-edit" /> Edit
            </button>
          </div>
          {error ? <div className="error-msg">{error}</div> : null}
        </>
      </div>
    ) : (
      <div>
        <div className="d-flex ">
          <SearchBar onPlacesChanged={onPlacesChanged} isNotfocus={isNotfocus} className="w-100 p-0" />
          <button
            onClick={onClickCancel}
            className={`btn btn-sm btn-link col-auto ml-2 one-em-font a-cancel-btn  text-decoration-none ${isMedicalCard ? 'abs-btn-medical-close' : 'abs-btn'
              }`}
          >
            <i className="icon-close" />
          </button>
        </div>
        {error ? <div className="error-msg">{error}</div> : null}
      </div>
    )}
  </div>
);

export default DrWeedyEditAddress;

import React from 'react';
import Link from 'next/link';

import ReferralBanner from '../../ReferralBanner';
import appConfig from '../../../appConfig';
import { localLicenseNumber } from '../../../constants/default';
import LicenseHolder from '../../LicenseHolder';

const GDStaticFooter = () => {
  const CategoryListGd = [
    { name: 'Accessories', url: 'cannabis-accessories' },
    { name: 'Vape', url: 'vapes' },
    { name: 'Deals', url: 'deals' },
    { name: 'Concentrates', url: 'cannabis-concentrates' },
    { name: 'All' }
  ];

  const socialMediaLinks = [
    { url: appConfig.INSTAGRAM, icon: 'icon-instagram' },
    { url: appConfig.FACEBOOK, icon: 'icon-facebook' },
    { url: appConfig.TWITTER, icon: 'icon-twitter' },
    { url: appConfig.LINKEDIN, icon: 'icon-linkedin' },
    { url: appConfig.SPOTIFY, icon: 'icon-spotify' },
    { url: appConfig.TIKTOK, icon: 'icon-tiktok' }
  ];

  const IconHolder = () => {
    return (
      <div className="icon-holder">
        {socialMediaLinks.map(({ url, icon }) => {
          return url ? (
            <Link prefetch={false} href={url}>
              <a rel="noopener noreferrer" target="_blank">
                <i className={icon} />
              </a>
            </Link>
          ) : null;
        })}
      </div>
    );
  };

  return (
    <footer className="footer">
      <div className="footer-top-section container ">
        <div className="footer-top-section-inner d-lg-flex justify-content-between">
          <div className="website-section">
            <img className="logo d-lg-block" src={appConfig.HEADER_LOGO_WHITE} alt="grassdoor_logo" />
            <h6 className="half-rem-mt d-none d-lg-block">California&apos;s safest and most reliable weed delivery</h6>
            <IconHolder />
            <ReferralBanner className="inner-white-color text-left" />
          </div>

          <div className="other-info-section footer-cat">
            <h5 className="section-title bold cursor-pointer">
              <Link prefetch={false} href="/categories">
                <a>Categories</a>
              </Link>
            </h5>

            {CategoryListGd?.map(({ name, url = '' }) => {
              return (
                <Link prefetch={false} href={`/categories/${url}`}>
                  <a href={`/categories/${url}`} className="footer-links">
                    {name}
                  </a>
                </Link>
              );
            })}
          </div>

          <div className="other-info-section">
            <h5 className="section-title bold cursor-pointer">
              <Link prefetch={false} href="/weed-delivery-near">
                <a>Areas We Serve</a>
              </Link>
            </h5>

            <Link prefetch={false} href="/weed-delivery-near/los-angeles">
              <a className="footer-links cursor-pointer">Los Angeles</a>
            </Link>

            <Link prefetch={false} href="/weed-delivery-near/san-francisco">
              <a className="footer-links cursor-pointer">San Francisco</a>
            </Link>

            <Link prefetch={false} href="/weed-delivery-near">
              <a className="footer-links cursor-pointer">All Cities in CA</a>
            </Link>
          </div>

          <div className="other-info-section">
            <h5 className="section-title bold ">Gift Cards</h5>

            <Link prefetch={false} href="/gift-cards">
              <a className="footer-links cursor-pointer">Purchase Gift Card</a>
            </Link>
            <Link prefetch={false} href="/gift-cards-redeem">
              <a className="footer-links cursor-pointer">Redeem Gift Card</a>
            </Link>
          </div>

          <div className="other-info-section">
            <h5 className="section-title bold">{process.env.NEXT_PUBLIC_APP_NAME}</h5>
            <div className="gd-footer-section">
              <div className="gd-footer-sub-section">
                <Link prefetch={false} href="/aboutus">
                  <a className="footer-links cursor-pointer">About Us</a>
                </Link>
                <Link prefetch={false} href="/contact">
                  <a className="footer-links cursor-pointer">Contact</a>
                </Link>
                <Link prefetch={false} href={appConfig.FAQ_URL ? appConfig.FAQ_URL : '/faq'}>
                  <a className="footer-links cursor-pointer">FAQ</a>
                </Link>
                <Link prefetch={false} href="/blog">
                  <a className="footer-links cursor-pointer ">Blog</a>
                </Link>
              </div>
              <div className="gd-footer-sub-section" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-section py-2 ">
        <div className="d-lg-flex justify-content-between flex-column flex-lg-row align-items-center container">
          <div className="left-sec text-center">
            {`Copyright© 2018-${new Date().getFullYear().toString().substring(2)} ${appConfig.APP_NAME
              } All rights reserved. Lic. No. `}
            <LicenseHolder />
          </div>

          <div className="right-sec text-center d-block d-lg-flex align-items-center">
            <div className="footer-links">
              <div className="item">
                <a
                  href={appConfig.PRIVACY ? appConfig.PRIVACY : '/privacypolicy'}
                  target="_blank"
                  className="footer-links"
                  rel="noreferrer"
                  test-id="#privacy_policy"
                >
                  Privacy Policy
                </a>
              </div>
              <div className="item">
                <a
                  href={appConfig.TERMS ? appConfig.TERMS : '/termsandconditions'}
                  target="_blank"
                  className="footer-links"
                  rel="noreferrer"
                  test-id="#terms_of_use"
                >
                  Terms of Use
                </a>
              </div>
              <div className="item">
                <a href="/ccpacompliance" className="footer-links">
                  Do Not Sell My Personal Information
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default GDStaticFooter;

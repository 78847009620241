export default function getTransformedImageURLs(originalUrl, imgFormat = 'webp') {
  let srcThumbnail = '';
  let srcExtraSmall = '';
  let srcSmall = '';
  let srcMedium = '';
  let srcLarge = '';
  let original = '';
  let mobileZoomImage = '';
  let largeBanner = '';

  const filterUrls = (url, filters) => {
    url.splice(3, 0, filters);
    return url.join('/');
  };

  if (originalUrl) {
    srcThumbnail = filterUrls(originalUrl.split('/'), `fit-in/75x75/filters:format(${imgFormat})`);
    srcExtraSmall = filterUrls(originalUrl.split('/'), `fit-in/150x150/filters:format(${imgFormat})`);
    srcSmall = filterUrls(originalUrl.split('/'), `fit-in/300x300/filters:format(${imgFormat})`);
    srcMedium = filterUrls(originalUrl.split('/'), `fit-in/400x400/filters:format(${imgFormat})`);
    srcLarge = filterUrls(originalUrl.split('/'), `fit-in/600x600/filters:format(${imgFormat})`);
    largeBanner = filterUrls(originalUrl.split('/'), `fit-in/2000x2000/filters:format(${imgFormat})`);
    original = filterUrls(
      originalUrl.split('/'),
      `fit-in/2000x2000/filters:fill(white)/filters:background_color(white)/filters:format(${imgFormat})`
    );
    mobileZoomImage = filterUrls(originalUrl.split('/'), `fit-in/1000x1000/filters:format(${imgFormat})`);
  }

  return { srcThumbnail, srcExtraSmall, srcSmall, srcMedium, srcLarge, largeBanner, original, mobileZoomImage };
}
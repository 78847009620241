import { VALID_OTP_MAX_LENGTH } from '../constants/verificationConst';

export default function otpValidationWithError(otp) {
  const re = /^[0-9]{4,}$/;
  if (!otp.length) {
    return 'Please enter OTP';
  }
  if (otp.length < VALID_OTP_MAX_LENGTH) {
    return 'OTP is too short';
  }
  if (!re.test(otp)) {
    return 'Please enter valid OTP';
  }
  return false;
}

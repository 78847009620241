/* eslint-disable react/no-danger */
import React from 'react';
import DOMPurify from 'isomorphic-dompurify';

const DangerousHTML = ({ html, className }) => {
  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(html)
  })

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={sanitizedData()}
    />
  )
};

export default DangerousHTML;

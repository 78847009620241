import { createSlice } from "@reduxjs/toolkit";
import { updateCompareObject } from "../../helpers/compareHelper";
import storageService from "../../services/storageService";

export function createInitialStore() {
  const productList = storageService.getCompareList() || [];
  const productDataList = storageService.getCompareDataList() || [];
  const productDataTempList = [];
  const tempCompareType = null;
  return {
    productList,
    productDataList,
    productDataTempList,
    tempCompareType
  };
}

export const compareSlice = createSlice({
  name: "compare",
  initialState: createInitialStore(),
  reducers: {
    addProductToCompare: (state, action) => {
      const temp = [action.payload.master_product_id, ...state.productList];
      const tempTwo = [updateCompareObject(action.payload), ...state.productDataList];
      storageService.setCompareList([...temp]);
      storageService.setCompareDataList([...tempTwo]);
      state.productDataList = [...tempTwo];
      state.productList = [...temp]
    },
    removeProductFromCompare: (state, action) => {
      const filtered = state.productList.filter(data => {
        return data !== action.payload.master_product_id;
      });
      const filteredData = state.productDataList.filter(data => {
        return data.master_product_id !== action.payload.master_product_id;
      });
      storageService.setCompareList([...filtered]);
      storageService.setCompareDataList([...filteredData]);
      state.productDataList = [...filteredData]
      state.productList = [...filtered]
    },
    clearAllProductToCompare: (state) => {
      storageService.setCompareList([]);
      storageService.setCompareDataList([]);
      state.productDataList = []
      state.productDataList = []

    },
    addTempProductsToCompare: (state, action) => {
      const tempTwo = action.payload.map(item => updateCompareObject(item));
      state.productDataTempList = tempTwo

    },
    removeTempProductFromCompare: (state, action) => {
      const filteredData = state.productDataTempList.filter(data => {
        return data.master_product_id !== action.payload.master_product_id;
      });
      state.productDataTempList = [...filteredData]

    },
    clearAllTempProductToCompare: (state) => {
      state.productDataTempList = []

    },
    updateTempCompareType: (state, action) => {
      state.tempCompareType = action.payload

    },
    updateCompareList: (state, action) => {
      storageService.setCompareDataList([...action.payload]);
      state.productDataList = [...action.payload]
    }
  }
})

export const {
  addProductToCompare,
  removeProductFromCompare,
  clearAllProductToCompare,
  addTempProductsToCompare,
  removeTempProductFromCompare,
  clearAllTempProductToCompare,
  updateTempCompareType,
  updateCompareList
} = compareSlice.actions
export default compareSlice.reducer
import React, { useState } from 'react';
import storageService from '../../services/storageService';
import { GRASSDOOR, GANJAGODDESS } from '../../constants/website';
import appConfig from '../../appConfig';
// import ageConfirmationAnimation from '../../public/static/lotties/AgeConfirmAnimate.json';

import useSegment from '../../hooks/segment';
import envAppConfig from '../../constants/envAppConfig';

const AgeConfirmationPopup = ({ dismiss, data }) => {
  const { trackEvent } = useSegment();
  const [checked, setChecked] = useState(false);
  const [hasError, setHasError] = useState(false);

  function handleChange(e) {
    setChecked(e.target.checked);
    setHasError(false);
  }

  function exitSite() {
    window.location.replace('about:blank');
    dismiss();
  }

  function handleConfirm() {
    if (checked) {
      trackEvent('Accept Terms', {
        category: 'User Events',
        action: 'Click',
        label: 'Accept Terms'
      });
      storageService.setAgeConfirmationShowPopup(true, true);
      storageService.setUpdatedAgeConfirmationShowPopup(true);
      trackEvent('accept-terms-condition');
      dismiss();
    } else {
      setHasError(true);
    }
  }

  const isGanjaGoddess = process.env.NEXT_PUBLIC_APP === GANJAGODDESS;
  const isGrassdoor = process.env.NEXT_PUBLIC_APP === GRASSDOOR;

  const serverHost = typeof window === 'object' ? window.location.host.split('.')[1] : '';

  return (
    <div className={`modal age-popup d-block`} tabIndex="-1" role="dialog">
      <div className={`modal-dialog modal-dialog-centered `} role="document">
        <div className="modal-content">
          <div data-testid="#age_popup_body" className={`modal-body `}>
            <div className="logo-container">
              {isGrassdoor ? (
                // <Player className="lottie-car" autoplay src={ageConfirmationAnimation} />
                <img src="/static/images/Age-popup.svg" />
              ) : (
                <img
                  src={isGanjaGoddess ? appConfig.LOGO_COLOR : appConfig.HEADER_LOGO_COLOR}
                  alt={
                    process.env.NEXT_PUBLIC_CHECKOUT_ONLY === 'true'
                      ? envAppConfig && envAppConfig[data.domainName]
                        ? envAppConfig[data.domainName].HEADER_LOGO_COLOR
                        : envAppConfig.grassdoor.HEADER_LOGO_COLOR
                      : appConfig.APP_NAME
                  }
                />
              )}
            </div>

            {isGrassdoor ? (
              <>
                <h4 className="bold text-center">
                  You must be 21+ to use this website
                  <h6 className="semibold text-center mt-2">Or 18+ with a medical card</h6>
                </h4>
                <p className="">
                  You must accept our&nbsp;
                  <a
                    href={appConfig.TERMS ? appConfig.TERMS : '/termsandconditions'}
                    target="_blank"
                    className="d-inline-block text-underline"
                    rel="noreferrer"
                    data-testid="#terms_of_use"
                  >
                    Terms of Use
                  </a>
                  ,&nbsp;
                  <a
                    href={appConfig.PRIVACY ? appConfig.PRIVACY : '/privacypolicy'}
                    target="_blank"
                    className="d-inline-block text-underline"
                    rel="noreferrer"
                    data-testid="#privacy_policy"
                  >
                    Privacy Policy
                  </a>
                  , and be 21+ years of age to visit this site.{' '}
                  {process.env.NEXT_PUBLIC_APP === GRASSDOOR &&
                    'This site uses cookies to offer you a better browsing experience. By using our site you agree to our use of cookies.'}
                </p>
                <div className="check-holder d-flex align-items-start">
                  <div className="custom-checkbox ">
                    <input
                      type="checkbox"
                      id="ageConfirmationPopupCheckBox"
                      data-testid="#age_confirmation_popup_checkBox"
                      onChange={handleChange}
                    />
                    <label className="align-top m-0 text-left" htmlFor="ageConfirmationPopupCheckBox" />
                  </div>
                  <div>
                    I agree to {appConfig.APP_NAME}
                    's{' '}
                    <a
                      className="text-underline"
                      href={appConfig.TERMS ? appConfig.TERMS : '/termsandconditions'}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="#terms_of_use"
                    >
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                      className="text-underline"
                      href={appConfig.PRIVACY ? appConfig.PRIVACY : '/privacypolicy'}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="#privacy_policy"
                    >
                      Privacy Policy.
                    </a>
                  </div>
                </div>

                {hasError && (
                  <div>
                    <small className="text-danger d-block text-left">
                      Please accept our Terms of Use and Privacy Policy to visit {appConfig.APP_NAME}.
                    </small>
                  </div>
                )}

                <div className="two-rem-mt text-center">
                  <div>
                    <button
                      type="button"
                      className="btn btn-purple rouded-button btn-block has-box-shadow age-yes-btn"
                      onClick={handleConfirm}
                      data-testid="#age_popup_confirm_btn"
                    >
                      I&apos;M OVER 21
                    </button>
                  </div>

                  <div>
                    <button
                      type="button"
                      className="btn btn-link half-rem-mt default-l-primary age-exit-btn"
                      onClick={exitSite}
                      data-testid="#age_popup_exit_btn"
                    >
                      EXIT
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="">
                  You must accept our Terms of Use & Privacy Policy, our partner's Terms of Use & Privacy Policy, and be 21+ years
                  of age to visit this site.
                </p>

                <div className="tworem-mt text-center">
                  <div>
                    <button
                      type="button"
                      className="btn btn-purple rouded-button btn-block has-box-shadow "
                      onClick={handleConfirm}
                    >
                      I&apos;m at least 21 years old
                    </button>
                  </div>

                  <div>
                    <button type="button" className="btn btn-link half-rem-mt default-l-primary" onClick={exitSite}>
                      Exit this site
                    </button>
                  </div>
                </div>

                <div className="check-holder d-flex align-items-start">
                  <div className="custom-checkbox ">
                    <input
                      type="checkbox"
                      id="ageConfirmationPopupCheckBox"
                      data-testid="#age_confirmation_popup_checkBox"
                      onChange={handleChange}
                    />
                    <label className="align-top m-0 text-left" htmlFor="ageConfirmationPopupCheckBox" />
                  </div>

                  <div>
                    I agree to {appConfig.APP_NAME}
                    <span className="">'s</span>
                    {'  '}
                    <a
                      className="text-underline"
                      href={'/termsandconditions'}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="#our_terms_of_use_link"
                    >
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                      className="text-underline"
                      href={'/privacypolicy'}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="#our_privacy_policy_link"
                    >
                      Privacy Policy
                    </a>
                    {',  '}
                    {/* our partner's
                    {'  '}
                    <a
                      className="text-underline"
                      href={appConfig.TERMS ? appConfig.TERMS : '/termsandconditions'}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="#partners_terms_of_use_link"
                    >
                      Terms of Use
                    </a>{' '}
                    and{' '}
                    <a
                      className="text-underline"
                      href={appConfig.PRIVACY ? appConfig.PRIVACY : '/privacypolicy'}
                      target="_blank"
                      rel="noreferrer"
                      data-testid="#partners_privacy_policy_link"
                    >
                      Privacy Policy.
                    </a> */}
                  </div>
                </div>

                {hasError && (
                  <div>
                    <small className="text-danger d-block text-left">
                      Please accept our Terms of Use and Privacy Policy to visit {appConfig.APP_NAME}.
                    </small>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgeConfirmationPopup;

import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isNewHomePageApplicable } from '../../../constants/feature';
import notificationTypes from '../../../constants/modalNotificationConst';
import appContext from '../../../Context/appContext';
import { pushNotification } from '../../../redux/slices/modalSlice';

function HraderDeliveryAddress({ hideOnLarge, className }) {
  // const {
  //   state: {
  //     user: { deliveryDetails } = {}
  //   }
  // } = useContext(appContext);
  const deliveryDetails = useSelector(state => state.user.deliveryDetails)
  const notifications = useSelector(state => state.modal.notifications)
  const dispatchRedux = useDispatch()

  function openAddressPopup() {
    const addressModalOpen = notifications.map(notification => notification.type).includes(notificationTypes.deliverAddressPopup);
    if (!addressModalOpen) {
      dispatchRedux(pushNotification({ type: notificationTypes.deliveryAddressDetail }))
    }
  }

  const renderAddress = () => {
    const { address, streetNumber, streetName, postcode } = deliveryDetails;
    if (address) {
      if (postcode && streetName) {
        return `${streetNumber || ''} ${streetName}, ${postcode}`;
      }
      return address;
    }
    return 'Set Delivery Address';
  };

  return (
    <div data-testid="#search-address-section" role="presentation" onClick={openAddressPopup} className="gd-search-address">
      <div
        className={`${hideOnLarge ? 'set-delivery-address-btn d-lg-none' : 'set-delivery-address-btn'} ${className} `}
        role="presentation"
      >
        <span className="bento-address p-0">
          <div>
            <i className="icon-pin icon-profile-address" />
          </div>
          <div className="add-field">
            <span className="delivery-text d-none d-lg-block">Deliver to</span>
            <div data-testid="#selected-address" className="render-address">
              {renderAddress()}
            </div>
            <i className="icon-chevron-right-e d-icon d-none" />
          </div>
        </span>
      </div>
      {isNewHomePageApplicable ? <i className="d-icon icon-location-down " /> : null}
      <div className="v-line" />
    </div>
  );
}

export default HraderDeliveryAddress;

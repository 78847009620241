export const COD = 1;
export const CARD = 2;
export const HYPUR = 11;
export const POS = 10;
export const STRONGHOLD = 12;
export const OTHER_CARD = 'other_card';
export const NEW_CARD = 'new_card'
export const PAYPAL = 3;
export const APPLE_PAY = 4;
export const WALLET = 5;
export const GRAYPAY = 8;
export const DRIVER_CREDIT = 9;
export const KIND_TAP = 14;
export const LEDGERGREEN = 15;
export const SWIFTER = 17

export const CASH = 'CASH';
export const SCARD = 'CARD';
export const SPOS = 'POS';
export const SHYPUR = 'HYPUR';
export const SSTRONGHOLD = 'STRONGHOLD';
export const SKINDTAP = 'KINDTAP';
export const SLEDGERGREEN = 'LEDGERGREEN';

import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import CSSTransition from 'react-transition-group/CSSTransition';

import appContext from '../../Context/appContext';
import useAPI from '../../NetworkCall/API';
import InputComponent from '../cart/InputComponent';
import LoginForm from '../cart/LoginForm';
import EmailComponent from '../cart/EmailComponent';
import IdUpload from '../cart/IDUpload';
import DrWeedyEditAddress from '../cart/DrWeedyEditAddress';
import nameValidationWithError from '../../helpers/nameValidationWithError';
import symptomsOptions from '../../constants/symptoms';
import { dateToDMY, modifyDate } from '../../helpers/timeHelpers';
import { useIdProof } from '../../hooks/User';
import FullscreenLoader from '../FullScreenLoader';
import useSegment from '../../hooks/segment';
import { useSelector } from 'react-redux';

const medicalSurveyQuestion = [
  'unlistedMedicalConditions',
  'onGoingMedications',
  'medicationsAllergicTo',
  'beenToHospitalForPsychiatricReason',
  'witnessedUnrealVoicesAndImages',
  'treatedForDrugAddiction',
  'diagnosedWithSchizophrenia',
  'familyHistoryOfschizophrenia',
  'smokeCigarettes',
  'drinkAlcohol'
];

const DrWeedyMedicalCard = ({ dismiss }) => {
  const { getWeedyMedical } = useAPI();
  const { trackEvent } = useSegment();

  const {
    data: { thumbnail_url: thumbnailUrl, is_medical_approved: isMedicalApproved }
  } = useIdProof();

  // const {
  //   state: {
  //     user: {
  //       userInfo: { phone }
  //     },
  //     cart: {
  //       customerData: { email: localSorageEmail }
  //     }
  //   }
  // } = useContext(appContext);

  const {
    userInfo: { phone }
  } = useSelector(state => state.user)
  const {
    customerData: { email: localSorageEmail } = {}
  } = useSelector(state => state.cart) || {}

  const [editAddress, setEditAddress] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [info, setInfo] = useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    zip: '',
    dateOfBirth: '',
    symptoms: '',
    otherSymptoms: '',
    unlistedMedicalConditions: {
      value: '',
      ans: ''
    },
    onGoingMedications: {
      value: '',
      ans: ''
    },
    medicationsAllergicTo: {
      value: '',
      ans: ''
    },
    beenToHospitalForPsychiatricReason: {
      value: '',
      ans: ''
    },
    witnessedUnrealVoicesAndImages: {
      value: '',
      ans: ''
    },
    treatedForDrugAddiction: {
      value: '',
      ans: ''
    },
    diagnosedWithSchizophrenia: {
      value: '',
      ans: ''
    },
    familyHistoryOfschizophrenia: {
      value: '',
      ans: ''
    },
    smokeCigarettes: {
      value: '',
      ans: ''
    },
    drinkAlcohol: {
      value: '',
      ans: ''
    }
  });

  useEffect(() => {
    if (isMedicalApproved) {
      dismiss();
    }
  }, [isMedicalApproved]);

  const handleValidation = () => {
    if (!phone) {
      toast.error('Please login');
      document.getElementById('customer-phone').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    const firstNameError = nameValidationWithError(info.firstName, 'First Name');
    if (firstNameError) {
      toast.error(firstNameError);
      document.getElementById('customer-firstname').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    const lastNameError = nameValidationWithError(info.lastName, 'Last Name');
    if (lastNameError) {
      toast.error(lastNameError);
      document.getElementById('customer-lastname').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (!localSorageEmail) {
      toast.error('Please enter Email');
      document.getElementById('customer-email').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (!info.dateOfBirth) {
      toast.error('Please enter Date of Birth');
      document.getElementById('customer-dateOfBirth').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    const dateSplit = info?.dateOfBirth.split(/[^0-9]/);
    const dateOfBirth = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
    const maxDate = modifyDate(new Date(), -18);

    if (dateOfBirth > maxDate) {
      toast.error(`Please enter Date of Birth before ${dateToDMY(maxDate)}`);
      document.getElementById('customer-dateOfBirth').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (!info.symptoms) {
      toast.error('Please select symptoms');
      document.getElementById('customer-symptoms').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (info.symptoms === 'Other Condition' && !info.otherSymptoms) {
      toast.error('Please enter symptoms');
      document.getElementById('customer-symptoms').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    const isValid = medicalSurveyQuestion.every(ele => {
      if (!info[ele].value) {
        if (ele === 'familyHistoryOfschizophrenia') {
          toast.error(`Please select family history of schizophrenia`);
        } else {
          toast.error(`Please select ${ele.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
        }
        document.getElementById(`customer-${ele}`).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
        return false;
      }

      if (info[ele].value === 'Yes' && !info[ele].ans && ele !== 'diagnosedWithSchizophrenia') {
        if (ele === 'familyHistoryOfschizophrenia') {
          toast.error(`Please enter family history of schizophrenia`);
        } else {
          toast.error(`Please enter ${ele.replace(/([A-Z])/g, ' $1').toLowerCase()}`);
        }

        document.getElementById(`customer-${ele}`).scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
        return false;
      }

      return true;
    });

    if (!isValid) {
      return false;
    }

    if (!info.address) {
      toast.error('Please enter address');
      document.getElementById('customer-address').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
      return false;
    }

    if (!thumbnailUrl) {
      toast.error('Please select Photo ID');
      return false;
    }

    return true;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (handleValidation()) {
      const { firstName, lastName, address, city, zip, dateOfBirth, symptoms, otherSymptoms } = info;

      const data = {
        firstName,
        lastName,
        address,
        city,
        zip,
        dateOfBirth,
        symptoms
      };

      Object.entries(info).forEach(([k, v]) => {
        if (medicalSurveyQuestion.includes(k)) {
          if (v.value === 'No') {
            data[k] = 'No';
          } else {
            data[k] = `Yes - ${v.ans}`;
          }
        }
      });

      if (symptoms === 'Other Condition') {
        data.symptoms = otherSymptoms;
      }
      setIsSubmitting(true);
      setIsLoading(true);
      await getWeedyMedical(
        {
          ...data,
          phone,
          email: localSorageEmail
        },
        dismiss
      );
      trackEvent('medical-card-applied', {
        ...data
      });
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };
  const handleOnChange = event => {
    const { name, value } = event.target;
    setInfo({ ...info, [name]: value });
  };

  async function onPlacesChanged(place) {
    setEditAddress(false);
    setInfo(prev => ({ ...prev, address: place.address, zip: place.postcode, city: place.city }));
  }

  function onClickEditAddress() {
    setEditAddress(true);
  }

  function onClickCancel() {
    setEditAddress(false);
  }

  function onSelect(event) {
    const { name, value } = event.target;
    setInfo({
      ...info,
      [name]: {
        ...info?.[name],
        value
      }
    });
  }

  function onSelectAns(event) {
    const { name, value } = event.target;
    setInfo({
      ...info,
      [name]: {
        ...info?.[name],
        ans: value
      }
    });
  }

  // mobile-bottom-sticky-desktop-center-popup
  return (
    <CSSTransition appear in mountOnEnter timeout={300} classNames="slideup-wrapper" unmountOnExit>
      <div className="modal  d-block  animated-popup-wrapper bg-overlay" tabIndex="-1" role="dialog">
        <div className="modal-dialog m-scroll weedy-med-card " role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center">
              <div>
                <h6 className="modal-title bold schedule-title d-inline ">Medical Card</h6>
              </div>
              <button type="button" className="btn" onClick={dismiss}>
                <i className="icon-close" />
              </button>
            </div>
            <div className="modal-body px-0 pt-0">
              <div className=" customer-info ">
                <div className="  ">
                  <div className=" custoinfo-padding">
                    <div className="my-2">
                      <h6 className="bold custoinfo-title ">Patient Details</h6>
                    </div>
                    <div className="custoinfo-border">
                      <LoginForm required />
                      <div className="row  ">
                        <div className="col-12">
                          <div>
                            <InputComponent
                              id="customer-firstname"
                              name="firstName"
                              icon="icon-user"
                              defaultValue={info?.firstName}
                              value={info?.firstName}
                              type="text"
                              maxLength="25"
                              placeholder="First Name"
                              label="First Name"
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div>
                            <InputComponent
                              id="customer-lastname"
                              name="lastName"
                              icon="icon-user"
                              defaultValue={info?.lastName}
                              value={info?.lastName}
                              type="text"
                              maxLength="25"
                              placeholder="Last Name"
                              label="Last Name"
                              onChange={handleOnChange}
                              required
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <EmailComponent required />
                        </div>
                      </div>
                      <div className="mt-3">
                        <InputComponent
                          id="customer-dateOfBirth"
                          name="dateOfBirth"
                          icon="icon-user"
                          defaultValue={info?.dateOfBirth}
                          value={info?.dateOfBirth}
                          type="date"
                          placeholder="Date of Birth"
                          label="Date of Birth"
                          onChange={handleOnChange}
                          max={modifyDate(new Date(), -18, 0, -1).toISOString().split('T')[0]}
                          required
                        />
                      </div>
                      <div id="customer-symptoms" className="addressbox-parent form-group col-md-4 selectbox  mt-3">
                        <label className="mini-label">Symptoms *</label>
                        <select
                          className="form-control"
                          id="symptoms"
                          name="symptoms"
                          value={info?.symptoms}
                          onChange={handleOnChange}
                        >
                          <option value="" selected disabled>
                            Choose one
                          </option>
                          {symptomsOptions.length > 0 &&
                            symptomsOptions.map((data, index) => (
                              <option value={data} key={index}>
                                {data}
                              </option>
                            ))}
                        </select>
                        <div className="mt-2">
                          {info?.symptoms == 'Other Condition' && (
                            <textarea
                              className="form-control notes"
                              id="otherSymptoms"
                              name="otherSymptoms"
                              placeholder="Other Condition"
                              defaultValue={info?.otherSymptoms}
                              value={info?.otherSymptoms}
                              maxLength="400"
                              onChange={handleOnChange}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div
                        id="customer-unlistedMedicalConditions"
                        className="addressbox-parent form-group col-md-4 selectbox  mt-3"
                      >
                        <div>
                          {' '}
                          <label className="mini-label">Do you have any medical conditions not listed above? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.unlistedMedicalConditions?.value === 'No'}
                            type="radio"
                            id="unlistedMedicalConditions-No"
                            onChange={onSelect}
                            value="No"
                            name="unlistedMedicalConditions"
                          />
                          <label htmlFor="unlistedMedicalConditions-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.unlistedMedicalConditions?.value === 'Yes'}
                            type="radio"
                            id="unlistedMedicalConditions-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="unlistedMedicalConditions"
                          />
                          <label htmlFor="unlistedMedicalConditions-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.unlistedMedicalConditions?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="unlistedMedicalConditions.ans"
                              name="unlistedMedicalConditions"
                              placeholder="(List all your medical conditions)"
                              defaultValue={info?.unlistedMedicalConditions?.ans}
                              value={info?.unlistedMedicalConditions?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div id="customer-onGoingMedications" className="addressbox-parent form-group col-md-4 selectbox  mt-3">
                        <div>
                          {' '}
                          <label className="mini-label">Are you taking any medications? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.onGoingMedications?.value === 'No'}
                            type="radio"
                            id="onGoingMedications-No"
                            onChange={onSelect}
                            value="No"
                            name="onGoingMedications"
                          />
                          <label htmlFor="onGoingMedications-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.onGoingMedications?.value === 'Yes'}
                            type="radio"
                            id="onGoingMedications-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="onGoingMedications"
                          />
                          <label htmlFor="onGoingMedications-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.onGoingMedications?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="onGoingMedications.ans"
                              name="onGoingMedications"
                              placeholder="(List all medications that you are taking)"
                              defaultValue={info?.onGoingMedications?.ans}
                              value={info?.onGoingMedications?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div id="customer-medicationsAllergicTo" className="addressbox-parent form-group col-md-4 selectbox  mt-3">
                        <div>
                          {' '}
                          <label className="mini-label">Are you allergic to any medications? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.medicationsAllergicTo?.value === 'No'}
                            type="radio"
                            id="medicationsAllergicTo-No"
                            onChange={onSelect}
                            value="No"
                            name="medicationsAllergicTo"
                          />
                          <label htmlFor="medicationsAllergicTo-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.medicationsAllergicTo?.value === 'Yes'}
                            type="radio"
                            id="medicationsAllergicTo-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="medicationsAllergicTo"
                          />
                          <label htmlFor="medicationsAllergicTo-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.medicationsAllergicTo?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="medicationsAllergicTo.ans"
                              name="medicationsAllergicTo"
                              placeholder="(List all medications you are allergic to)"
                              defaultValue={info?.medicationsAllergicTo?.ans}
                              value={info?.medicationsAllergicTo?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div
                        id="customer-beenToHospitalForPsychiatricReason"
                        className="addressbox-parent form-group col-md-4 selectbox  mt-3"
                      >
                        <div>
                          {' '}
                          <label className="mini-label">Have you ever been in a hospital for a psychiatric reason? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.beenToHospitalForPsychiatricReason?.value === 'No'}
                            type="radio"
                            id="beenToHospitalForPsychiatricReason-No"
                            onChange={onSelect}
                            value="No"
                            name="beenToHospitalForPsychiatricReason"
                          />
                          <label htmlFor="beenToHospitalForPsychiatricReason-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.beenToHospitalForPsychiatricReason?.value === 'Yes'}
                            type="radio"
                            id="beenToHospitalForPsychiatricReason-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="beenToHospitalForPsychiatricReason"
                          />
                          <label htmlFor="beenToHospitalForPsychiatricReason-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.beenToHospitalForPsychiatricReason?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="beenToHospitalForPsychiatricReason.ans"
                              name="beenToHospitalForPsychiatricReason"
                              placeholder="(Explain further and for how long)"
                              defaultValue={info?.beenToHospitalForPsychiatricReason?.ans}
                              value={info?.beenToHospitalForPsychiatricReason?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div
                        id="customer-witnessedUnrealVoicesAndImages"
                        className="addressbox-parent form-group col-md-4 selectbox  mt-3"
                      >
                        <div>
                          {' '}
                          <label className="mini-label">Have you ever heard voices or saw images that were not real? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.witnessedUnrealVoicesAndImages?.value === 'No'}
                            type="radio"
                            id="witnessedUnrealVoicesAndImages-No"
                            onChange={onSelect}
                            value="No"
                            name="witnessedUnrealVoicesAndImages"
                          />
                          <label htmlFor="witnessedUnrealVoicesAndImages-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.witnessedUnrealVoicesAndImages?.value === 'Yes'}
                            type="radio"
                            id="witnessedUnrealVoicesAndImages-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="witnessedUnrealVoicesAndImages"
                          />
                          <label htmlFor="witnessedUnrealVoicesAndImages-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.witnessedUnrealVoicesAndImages?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="witnessedUnrealVoicesAndImages.ans"
                              name="witnessedUnrealVoicesAndImages"
                              placeholder="(Explain)"
                              defaultValue={info?.witnessedUnrealVoicesAndImages?.ans}
                              value={info?.witnessedUnrealVoicesAndImages?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div
                        id="customer-treatedForDrugAddiction"
                        className="addressbox-parent form-group col-md-4 selectbox  mt-3"
                      >
                        <div>
                          {' '}
                          <label className="mini-label">Have you ever been treated for drug addiction? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.treatedForDrugAddiction?.value === 'No'}
                            type="radio"
                            id="treatedForDrugAddiction-No"
                            onChange={onSelect}
                            value="No"
                            name="treatedForDrugAddiction"
                          />
                          <label htmlFor="treatedForDrugAddiction-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.treatedForDrugAddiction?.value === 'Yes'}
                            type="radio"
                            id="treatedForDrugAddiction-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="treatedForDrugAddiction"
                          />
                          <label htmlFor="treatedForDrugAddiction-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.treatedForDrugAddiction?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="treatedForDrugAddiction.ans"
                              name="treatedForDrugAddiction"
                              placeholder="(Explain)"
                              defaultValue={info?.treatedForDrugAddiction?.ans}
                              value={info?.treatedForDrugAddiction?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div
                        id="customer-diagnosedWithSchizophrenia"
                        className="addressbox-parent form-group col-md-4 selectbox  mt-3"
                      >
                        <div>
                          {' '}
                          <label className="mini-label">Have you ever been diagnosed with schizophrenia? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.diagnosedWithSchizophrenia?.value === 'No'}
                            type="radio"
                            id="diagnosedWithSchizophrenia-No"
                            onChange={onSelect}
                            value="No"
                            name="diagnosedWithSchizophrenia"
                          />
                          <label htmlFor="diagnosedWithSchizophrenia-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.diagnosedWithSchizophrenia?.value === 'Yes'}
                            type="radio"
                            id="diagnosedWithSchizophrenia-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="diagnosedWithSchizophrenia"
                          />
                          <label htmlFor="diagnosedWithSchizophrenia-Yes">Yes</label>
                        </div>
                      </div>

                      <div
                        id="customer-familyHistoryOfschizophrenia"
                        className="addressbox-parent form-group col-md-4 selectbox  mt-3"
                      >
                        <div>
                          {' '}
                          <label className="mini-label">Do you have a family history of schizophrenia? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.familyHistoryOfschizophrenia?.value === 'No'}
                            type="radio"
                            id="familyHistoryOfschizophrenia-No"
                            onChange={onSelect}
                            value="No"
                            name="familyHistoryOfschizophrenia"
                          />
                          <label htmlFor="familyHistoryOfschizophrenia-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.familyHistoryOfschizophrenia?.value === 'Yes'}
                            type="radio"
                            id="familyHistoryOfschizophrenia-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="familyHistoryOfschizophrenia"
                          />
                          <label htmlFor="familyHistoryOfschizophrenia-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.familyHistoryOfschizophrenia?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="familyHistoryOfschizophrenia.ans"
                              name="familyHistoryOfschizophrenia"
                              placeholder="(Explain)"
                              defaultValue={info?.familyHistoryOfschizophrenia?.ans}
                              value={info?.familyHistoryOfschizophrenia?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div id="customer-smokeCigarettes" className="addressbox-parent form-group col-md-4 selectbox  mt-3">
                        <div>
                          {' '}
                          <label className="mini-label">Do you smoke cigarettes? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.smokeCigarettes?.value === 'No'}
                            type="radio"
                            id="smokeCigarettes-No"
                            onChange={onSelect}
                            value="No"
                            name="smokeCigarettes"
                          />
                          <label htmlFor="smokeCigarettes-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.smokeCigarettes?.value === 'Yes'}
                            type="radio"
                            id="smokeCigarettes-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="smokeCigarettes"
                          />
                          <label htmlFor="smokeCigarettes-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.smokeCigarettes?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="smokeCigarettes.ans"
                              name="smokeCigarettes"
                              placeholder="How often?"
                              defaultValue={info?.smokeCigarettes?.ans}
                              value={info?.smokeCigarettes?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>

                      <div id="customer-drinkAlcohol" className="addressbox-parent form-group col-md-4 selectbox  mt-3">
                        <div>
                          {' '}
                          <label className="mini-label">Do you drink alcohol? *</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.drinkAlcohol?.value === 'No'}
                            type="radio"
                            id="drinkAlcohol-No"
                            onChange={onSelect}
                            value="No"
                            name="drinkAlcohol"
                          />
                          <label htmlFor="drinkAlcohol-No">No</label>
                        </div>
                        <div className="custom-radio mt-2 mr-3">
                          <input
                            checked={info?.drinkAlcohol?.value === 'Yes'}
                            type="radio"
                            id="drinkAlcohol-Yes"
                            onChange={onSelect}
                            value="Yes"
                            name="drinkAlcohol"
                          />
                          <label htmlFor="drinkAlcohol-Yes">Yes</label>
                        </div>
                        <div className="mt-2">
                          {info?.drinkAlcohol?.value === 'Yes' && (
                            <textarea
                              className="form-control notes"
                              id="drinkAlcohol.ans"
                              name="drinkAlcohol"
                              placeholder="How often?"
                              defaultValue={info?.drinkAlcohol?.ans}
                              value={info?.drinkAlcohol?.ans}
                              maxLength="400"
                              onChange={onSelectAns}
                              style={{ width: '100%', maxHeight: 96, minHeight: 48 }}
                              rows="3"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="half-rem-mb two-rem-mt">
                      <h6 className="bold custoinfo-title  ">Address *</h6>
                    </div>
                    <div className="padding-15-side ">
                      <div className="row">
                        <div id="customer-address" className=" col-12 px-lg-0 ">
                          <DrWeedyEditAddress
                            editAddress={editAddress}
                            address={info.address}
                            onPlacesChanged={onPlacesChanged}
                            onClickEdit={onClickEditAddress}
                            onClickCancel={onClickCancel}
                            isMedicalCard
                            isNotfocus
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="cart-id-upload" className="id-upload drweedy-upload">
                    <IdUpload hideSelfie heading="Photo ID *" isMedicalFormOpened />
                  </div>
                </div>
                <div className="text-center my-2">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={handleSubmit}
                    className="text-center btn btn-primary bold has-box-shadow rouded-button"
                  >
                    Submit
                  </button>
                </div>
                {loading && <FullscreenLoader hasTransparentBg />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default DrWeedyMedicalCard;
